export default class IPShareData {
    private static instance: IPShareData
    ip?: string = undefined

    static getInstance(): IPShareData {
        if (!IPShareData.instance) {
            IPShareData.instance = new IPShareData()
        }
        return IPShareData.instance
    }
}
