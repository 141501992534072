import {Table} from 'antd'
import React, { useEffect, useState } from 'react'
import { Knowledge, KnowledgeStatus } from './model'
import { historyByTicketNumberAndStatus } from './service'
interface Params {
    Knowledge: Knowledge
}

type Props = Params

const KnowledgeHistory: React.FC<Props> = (props: Props) => {
    const [DataSource, setDataSource] = useState<Knowledge[]>([])
    useEffect(() => {
        getHistoryKM()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHistoryKM = async () => {
        historyByTicketNumberAndStatus(props.Knowledge.ticketNumber!!, KnowledgeStatus.Outdated).then((res) => {
            setDataSource(res || [])
        })
    }

    const columns = [
        {
            title: 'Version',
            key: 'id',
            render: (row) => (
                <>
                    <a href={'/KnowledgePreview/' + row.id}>{row.major}.{row.minor}</a>
                </>
            )
        },
        {
            title: 'Last modified',
            dataIndex: 'lastModifiedDatetime',
            key: 'lastModifiedDatetime'
        },
        {
            title: 'Last modified by',
            dataIndex: 'lastModifiedBy',
            key: 'lastModifiedBy'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        }
    ]
    return (
        <div>
            <Table dataSource={DataSource} columns={columns} pagination={false} rowKey={'id'}/>
        </div>
    )
}

export default KnowledgeHistory
