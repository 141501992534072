export default class PageService {
    private static instance: PageService
    data = {
        pageAsset: 1,
        pageSizeAsset: 10,
        pageAssetAdjust: 1,
        pageSizeAssetAdjust: 10,
        pagePm: 1,
        pageSizePm: 10
    }

    static getInstance(): PageService {
        if (!PageService.instance) {
            PageService.instance = new PageService()
        }
        return PageService.instance
    }
}
