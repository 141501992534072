import {
    CategoryStateEvent,
    SubCategoryStateEvent,
    ItemsStateEvent,
    GetAllCategorySuccessEvent,
    AddCategorySuccessEvent,
    AddCategoryByExcelSuccessEvent,
    DeleteCategorySuccessEvent,
    UpdateCategorySuccessEvent,
    UpdateCategoryCurrentPageSuccessEvent,
    UpdateCategoryTotalPageSuccessEvent,
    CategoryManagementStateEvent,
    DeleteAllCategorySuccessEvent,
    GetAllSubCategorySuccessEvent,
    AddSubCategorySuccessEvent,
    UpdateSubCategorySuccessEvent,
    DeleteSubCategorySuccessEvent,
    GetAllItemsSuccessEvent,
    AddItemsSuccessEvent,
    UpdateItemsSuccessEvent,
    DeleteItemsSuccessEvent
} from './state-event'
import { Category, CategoryManagementPagination, SubCategory, Categories, Item } from './model'

export const categoryStateReducer = (state: Categories[] = [], event: CategoryStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllCategorySuccessEvent.type) {        
        return event.payload || []
    } else if (event.type === AddCategorySuccessEvent.type) {
        if (state) {
            return [event.payload, ...state]
        }
    } 
    else if (event.type === AddCategoryByExcelSuccessEvent.type) {
        return [...state, ...event.payload] || []
    } 
    else if (event.type === DeleteCategorySuccessEvent.type) {
        return state.filter((it) => it.id != event.payload)
    } else if (event.type === UpdateCategorySuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else if (event.type === DeleteAllCategorySuccessEvent.type) {
        const temp : Categories[] = []
        state.forEach(it => {
            if (!(event.payload || []).includes(it)) {
                temp.push(it)
            }
        })
        state = temp
        return state
    } else {
        return state
    }
}

export const subCategoryStateReducer = (state: SubCategory[] = [], event: SubCategoryStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSubCategorySuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddSubCategorySuccessEvent.type) {
        console.log([event.payload, ...state])
        if (state) {
            return [event.payload, ...state]
        }
    } else if (event.type === DeleteSubCategorySuccessEvent.type) {
        return state.filter((it) => it.id != event.payload)
    } else if (event.type === UpdateSubCategorySuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}

export const itemsStateReducer = (state: Item[] = [], event: ItemsStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllItemsSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddItemsSuccessEvent.type) {
        if (state) {
            return [event.payload, ...state]
        }
    } else if (event.type === DeleteItemsSuccessEvent.type) {
        return state.filter((it) => it.id != event.payload)
    } else if (event.type === UpdateItemsSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}


export const categoryManagementPaginationReducer = (state: CategoryManagementPagination = {}, event: CategoryManagementStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateCategoryTotalPageSuccessEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateCategoryCurrentPageSuccessEvent.type) {
        state.page = event.payload + 1
    } else {
        return state
    }
    return state
}
