/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import Form, { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { Button, Col, Icon, Input, InputNumber, Row, Select, Switch, Table, Tooltip, Modal, message } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { CustomFiled, mySelectOption } from '../../model'
import { v4 as uuid } from 'uuid'
import { CustomFiledProblem } from '../state/model'

const mapStateToProps = (state: StoreState) => {
    return {

    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

}

interface Params {
    nameForm: string
    form: WrappedFormUtils<any>
    dataSource: CustomFiledProblem[]
    isEditMode: boolean
    getData: Function
}
const { Option } = Select
const { confirm } = Modal
const listsEdit: number[] = []
type Props = FormComponentProps & StateProps & DispatchProps & Params;
const TableCustomProblem: React.FC<Props> = (props: Props) => {
    const {
        getFieldDecorator,
        getFieldValue,
        setFieldsValue,
        resetFields
    } = props.form
    const isSubmit = false

    const [duplicatesLabel, setDuplicatesLabel] = useState<string[]>([])
    const [tempListLabel, setTempListLabel] = useState<string[]>([])
    const [arr, setArr] = useState<CustomFiledProblem[]>([])
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const [checkIsUpdate, setCheckIsUpdate] = useState<boolean>(false)

    useEffect(() => {
        if (props.nameForm) {
            setArr([])
        }
    }, [props.nameForm])

    useEffect(() => {
        if (props.dataSource) {
            setArr(props.dataSource)
        }
    }, [props.dataSource])

    const columnsView = [
        {
            title: <span>
                <span className={'requiredIcon'}>* </span>Field label
            </span>,
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.label}
                </>
            )
        },
        {
            title: 'Field description',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.description}
                </>
            )
        },
        {
            title: <span>
                <span className={'requiredIcon'}>* </span>Input type
            </span>,
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.inputType}
                </>
            )
        },
        {
            title: <span>
                <span className={'requiredIcon'}>* </span>Data type
            </span>,
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.dataType}
                </>
            )
        },
        {
            title: 'Select option',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.selectOption?.map((data) => { return data.option })?.join(', ')}
                </>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.defaultValue}
                </>
            )
        },
        {
            title: 'Placeholder',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.placeholder}
                </>
            )
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            render: (row: CustomFiledProblem) => (
                <>
                    {row.isRequireFields}
                </>
            )
        }
    ]

    const AddData = () => {
        const listLabel = arr?.map((obj) => {
            return obj.label || ''
        })
        setTempListLabel([...new Set(listLabel)])
        const small_id = uuid().slice(0, 8)
        const initData = {
            id: arr?.length || 0,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: undefined,
            key: small_id
        } as unknown as CustomFiledProblem
        arr.push(initData)
        setArr(arr)
        props.getData(arr)
        forceUpdate()
    }

    const handleChangeLabel = (row: CustomFiled, value: string) => {
        row.label = value
        row.name = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index)

    const handleBlurLabel = (value: string) => {
        const listLabel2 = arr?.map((obj) => {
            return obj.label?.toLocaleLowerCase()?.trim() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        } else {
            setDuplicatesLabel([])
        }
        tempListLabel.push(value)
    }

    const handleChangeDescription = (row: CustomFiled, value: string) => {
        row.description = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeInputType = (row: CustomFiled, value, index: number) => {
        row.inputType = value
        const setValue = {}
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        if (
            value === 'Checkbox' ||
      value === 'DropdownSelect' ||
      value === 'RadioButton'
        ) {
            setValue['dataType' + row.id + index] = 'String'
            row.dataType = 'String'
            setFieldsValue(setValue)
        } else if (value === 'DatePicker') {
            setValue['dataType' + row.id + index] = 'Date'
            row.dataType = 'Date'
            setFieldsValue(setValue)
        } else if (value === 'InputNumber') {
            setValue['dataType' + row.id + index] = 'Number'
            row.dataType = 'Number'
            setValue['defaultValue' + row.id + index] = 0
            row.defaultValue = 0
            setFieldsValue(setValue)
        } else {
            setValue['dataType' + row.id + index] = undefined
            row.dataType = undefined
            setFieldsValue(setValue)
        }

        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeDataType = (row: CustomFiled, value, index: number) => {
        const setValue = {}
        row.dataType = value
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        setFieldsValue(setValue)
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeSelectOption = (row: CustomFiled, value) => {
        const selectedValue = value as Array<string>
        const pattern = /[A-Za-z0-9ก-๙]+/
        let isErrorPattern = false
        const arr_lower = selectedValue?.map((item) => item?.toLowerCase().trim())
        const lastValue = selectedValue[selectedValue.length - 1]
      ?.toLowerCase()
      .trim()
        arr_lower.pop()
        if (arr_lower.includes(lastValue)) {
            selectedValue.pop()
        }
        selectedValue.forEach((name) => {
            if (!pattern.test(name.replace(/ +/g, ' ').trim())) {
                selectedValue.pop()
                isErrorPattern = true
            }
        })
        if (isErrorPattern) {
            return
        }
        if (!isErrorPattern) {
            const addDataOption: mySelectOption[] = value?.map((it) => {
                return {
                    option: it,
                    moduleName: 'Problem',
                    customFieldId: row.id
                }
            })
            row.selectOption = addDataOption
            if (row.id) {
                listsEdit.push(row.id)
            }
        }
    }

    const handleChangeDefaultValue = (
        row: CustomFiled,
        value: string | number
    ) => {
        row.defaultValue = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangePlaceholder = (row: CustomFiled, value: string) => {
        row.placeholder = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (format.test(value)) {
                callback()
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const validateMaxTag = (_: any, value: any, callback) => {
        if (value) {
            const valueIncorrect = value.filter((it) => it.length >= 256)
            if (valueIncorrect.length !== 0) {
                callback('error')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const getDefaultValue = (row: any, index: number) => {
        if (
            getFieldValue('inputType' + row.id + index) === 'Checkbox' ||
      getFieldValue('inputType' + row.id + index) === 'DatePicker' ||
      getFieldValue('inputType' + row.id + index) === 'RadioButton'
        ) {
            return undefined
        } else {
            if (getFieldValue('dataType' + row.id + index)) {
                return (
          getFieldValue('dataType' + row.id + index) as string
                ).toLowerCase()
            } else {
                return undefined
            }
        }
    }

    const toggleActiveness = (data: CustomFiled) => {
        console.log(data)
        data.isRequireFields = !data.isRequireFields
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    const deleteRow = (index, value, mydata) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                console.log(value)
                console.log(mydata)
                const filterTempLabel = tempListLabel.filter((it) => it !== value)
                const tempData2 = arr.filter((obj) => obj?.label !== mydata?.label)
                console.log(filterTempLabel)
                console.log(tempData2)
                if (duplicatesLabel) {
                    const filterDuplicatesLabel = duplicatesLabel?.filter(
                            (it) => it?.toLowerCase().trim() !== value?.toLowerCase().trim()
                        )
                    setDuplicatesLabel(filterDuplicatesLabel)
                }
                setArr(tempData2)
                setTempListLabel(filterTempLabel)
                // message.success('Delete data successfully.')
            },
            onCancel() { }
        })
    }

    const addAfterRow = (currentRow, index) => {
        arr.forEach((obj) => {
            if (Number(obj.id) >= currentRow + 1) {
                obj.id = Number(obj.id)
            }
        })
        const listLabel = arr?.map((obj) => {
            return obj.label || ''
        })
        const listLabel2 = arr?.map((obj) => {
            return obj.label?.toLocaleLowerCase() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        }
        setTempListLabel([...new Set(listLabel)])
        const small_id = uuid().slice(0, 8)
        arr.splice(index + 1, 0, {
            id: currentRow + 1,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: [],
            key: small_id
        })
        console.log(arr)
        resetFields()
        setArr((arr || []))
    }

    const columnsEdit = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}>
                        <Icon
                            className="edit_icon"
                            type="minus-square"
                            onClick={() => deleteRow(index, row.label, row)}
                        />
                    </Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, myData, index) => (
                <>
                    <Tooltip placement="bottom" title={'Add'}>
                        {arr.length !== 10 ? <Icon
                            className="del_icon"
                            type="plus-square"
                            onClick={() =>
                                addAfterRow(
                                    row.id,
                              arr?.findIndex((it) => {
                                  return it.key?.toString() === myData.key?.toString()
                              })
                                )
                            }
                        /> : null}
                    </Tooltip>
                </>
            )
        },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Field label
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item
                        validateStatus={
                            row.label === ''
                                ? 'error'
                                : duplicatesLabel.includes(row.label?.toLocaleLowerCase())
                                    ? 'error'
                                    : undefined
                        }
                        help={
                            row.label === ''
                                ? 'Default value is not a valid'
                                : duplicatesLabel.includes(row.label?.toLocaleLowerCase())
                                    ? 'The Field Label already exists.'
                                    : undefined
                        }
                    >
                        {getFieldDecorator('label' + row.id + index + row.key, {
                            initialValue: row.label,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Field label is required'
                                },
                                {
                                    validator: !isSubmit ? validateLabel : undefined
                                }
                            ]
                        })(
                            <Input
                                onChange={(e) => handleChangeLabel(row, e.target.value)}
                                onBlur={(e) => handleBlurLabel(e.target.value)}
                                disabled={row.lastModifiedDatetime !== undefined}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Field description',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index, {
                            initialValue: row.description,
                            rules: [{ required: false }]
                        })(
                            <Input
                                onChange={(e) => handleChangeDescription(row, e.target.value)}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Input type
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('inputType' + row.id + index, {
                            initialValue: row.inputType,
                            rules: [{ required: true, message: 'Input type is required' }]
                        })(
                            <Select
                                style={{ width: 135 }}
                                onChange={(e) => handleChangeInputType(row, e, index)}
                                disabled={row.lastModifiedDatetime !== undefined}
                                allowClear
                            >
                                <Option value="Checkbox">Checkbox</Option>
                                <Option value="DatePicker">Date picker</Option>
                                <Option value="DropdownSelect">Dropdown Select</Option>
                                <Option value="InputNumber">Input number</Option>
                                <Option value="RadioButton">Radio button</Option>
                                <Option value="TextBox">Text box</Option>
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Data type
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('dataType' + row.id + index, {
                            initialValue: row.dataType,
                            rules: [{ required: true, message: 'Data type is required' }]
                        })(
                            <Select
                                style={{ width: 135 }}
                                onChange={(e) => handleChangeDataType(row, e, index)}
                                disabled={row.lastModifiedDatetime !== undefined}
                                allowClear
                            >
                                {getFieldValue('inputType' + row.id + index) ===
                  'DropdownSelect' ||
                  getFieldValue('inputType' + row.id + index) === 'RadioButton' ||
                  getFieldValue('inputType' + row.id + index) === 'TextBox' ||
                  getFieldValue('inputType' + row.id + index) === 'Checkbox' ? (
                                        <Option value="String" key="1">
                      String
                                        </Option>
                                    ) : null}
                                {getFieldValue('inputType' + row.id + index) ===
                  'InputNumber' ? (
                                        <Option value="Number" key="2">
                      Number
                                        </Option>
                                    ) : null}
                                {getFieldValue('inputType' + row.id + index) === 'TextBox' ? (
                                    <Option value="Email" key="3">
                    Email
                                    </Option>
                                ) : null}
                                {/* {getFieldValue('inputType' + row.id) === 'Checkbox' ? <Option value="Boolean" key='4'>Boolean</Option> : null} */}
                                {getFieldValue('inputType' + row.id + index) ===
                  'DatePicker' ? (
                                        <Option value="Date" key="5">
                      Date
                                        </Option>
                                    ) : null}
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Select option',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <div key={index}>
                    <Form.Item>
                        {getFieldDecorator('selectOption' + row.id + index, {
                            initialValue: row.selectOption
                                ? row.selectOption?.map((it) => {
                                    return it.option
                                })
                                : undefined,
                            rules: [
                                {
                                    required:
                    getFieldValue('inputType' + row.id + index) ===
                    'DropdownSelect' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'Checkbox' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'RadioButton',
                                    message: 'Select option is required'
                                },
                                {
                                    validator: validateMaxTag
                                }
                            ]
                        })(
                            <Select
                                disabled={
                                    !(
                                        getFieldValue('inputType' + row.id + index) ===
                    'DropdownSelect' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'Checkbox' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'RadioButton'
                                    )
                                }
                                mode="tags"
                                style={{ width: 135 }}
                                onChange={(e) => handleChangeSelectOption(row, e)}
                            />
                        )}
                    </Form.Item>
                </div>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('defaultValue' + row.id + index, {
                            initialValue:
                getFieldValue('dataType' + row.id + index) === 'Number'
                    ? Number(row.defaultValue)
                    : row.defaultValue,
                            rules: [
                                {
                                    type: getDefaultValue(row, index),
                                    message: 'default value is not a valid'
                                },
                                {
                                    required: false
                                }
                            ]
                        })(
                            getFieldValue('dataType' + row.id + index) === 'Number' ? (
                                <InputNumber
                                    min={0}
                                    onChange={(e) => handleChangeDefaultValue(row, e!!)}
                                    style={{ width: '100%' }}
                                    maxLength={255}
                                    disabled={
                                        getFieldValue('inputType' + row.id + index) ===
                    'Checkbox' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'DatePicker' ||
                    getFieldValue('inputType' + row.id + index) ===
                    'RadioButton'
                                    }
                                />
                            ) : getFieldValue('inputType' + row.id + index) ===
                'DropdownSelect' ? (
                                    <Select
                                        disabled={
                                            getFieldValue('inputType' + row.id + index) === 'DatePicker'
                                        }
                                        onChange={(e) => handleChangeDefaultValue(row, e?.toString())}
                                        allowClear={true}
                                    >
                                        {(getFieldValue('selectOption' + row.id + index) || [])?.map(
                        (data, index) => {
                            return (
                                <Option value={data} key={index}>
                                    {data}
                                </Option>
                            )
                        }
                      )}
                                    </Select>
                                ) : (
                                    <Input
                                        onChange={(e) =>
                                            handleChangeDefaultValue(row, e.target.value)
                                        }
                                        maxLength={255}
                                        disabled={
                                            getFieldValue('inputType' + row.id + index) ===
                        'Checkbox' ||
                        getFieldValue('inputType' + row.id + index) ===
                        'DatePicker' ||
                        getFieldValue('inputType' + row.id + index) ===
                        'RadioButton'
                                        }
                                    />
                                )
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Placeholder',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('placeholder' + row.id + index, {
                            initialValue: row.placeholder,
                            rules: [{ required: false }]
                        })(
                            <Input
                                onChange={(e) => handleChangePlaceholder(row, e.target.value)}
                                disabled={
                                    getFieldValue('inputType' + row.id + index) === 'Checkbox' ||
                  getFieldValue('inputType' + row.id + index) ===
                  'DatePicker' ||
                  getFieldValue('inputType' + row.id + index) === 'RadioButton'
                                }
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            render: (row: CustomFiled) => (
                <>
                    <Tooltip
                        placement="bottom"
                        title={row.isRequireFields ? 'Deactivate' : 'Activate'}
                    >
                        <Switch
                            checked={row.isRequireFields}
                            onChange={() => toggleActiveness(row)}
                        />
                    </Tooltip>
                </>
            )
        }
    ]

    return (
        <div>
            {props.isEditMode ? <div>
                <Row>
                    <Col span={12} xs={24} sm={24} md={12} lg={12}><div className='common-font-topic'>Custom Field setting - {props.nameForm}</div></Col>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'end' }}><Button type='primary' onClick={() => AddData()} disabled={arr.length === 10}><Icon type="plus" /> &nbsp; Add List</Button></Col>
                </Row>
                <br/>
                <Table dataSource={arr} columns={columnsEdit} pagination={false} className='Table-no-background'/>
            </div> : <div>
                <Table dataSource={arr} columns={columnsView} pagination={false} className='Table-no-background'/>
            </div>}
        </div>
    )
}

export default connect(mapStateToProps, {

})(TableCustomProblem)
