/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Table, Collapse, Button, Icon, Modal, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FilterData, Preventive, related } from './Model'
// import { useReactToPrint } from 'react-to-print'
import ReactToPrint from 'react-to-print'
import DetailPMForPrint from './components/DetailPMForPrint'
import moment from 'moment'
import { getDetailAssetByIDPM, getPreventiveById } from './services'
interface Param {
    PMID: any
    valueFilter: FilterData
    valueSearch?: string
}

type Props = Param;
const pageStyle = `
  @media print {
    .page-break { page-break-inside: avoid; page-break-before: always; }
    html, body {
        -webkit-print-color-adjust: exact;
        font-family: 'Sarabun' !important;
      }
    .ant-row {
        overflow-wrap: break-word;
    }
  }
`
const { Panel } = Collapse
const ExpandListCompletePM: React.FC<Props> = (props: Props) => {
    const [isShowModalVisible, setIsShowModalVisible] = useState<boolean>(false)
    const [dataCompletePM, setDataCompletePM] = useState<Preventive>()
    const [anotherUser, setAnotherUser] = useState<any>('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    const [dataSource, setDataSource] = useState<related[]>([])
    const [totalDataSource, setTotalDataSource] = useState<number>(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const componentRef = useRef(null)

    useEffect(() => {
        setIsLoadingTable(true)
        getDetailAssetByIDPM(props.PMID, page, pageSize, props.valueFilter, props.valueSearch, true).then((res) => {
            if (res) {
                setDataSource(res.data)
                setTotalDataSource(res.total)
            }
        }).finally(() => {
            setIsLoadingTable(false)
        })
    }, [props.PMID, page, pageSize, props.valueFilter])

    const showModal = (data: related) => {
        setIsShowModalVisible(true)
        setIsLoading(true)
        getPreventiveById(props.PMID, data.serialNumber).then((res) => {
            if (res) {
                setDataCompletePM(res)
                setAnotherUser(res?.pmAdjust?.pmAdjustData?.anotherUser || '')
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const columns = [
        // {
        //     title: 'PM Plan Date',
        //     dataIndex: 'pmPlanDate',
        //     key: 'pmPlanDate'
        // },
        // {
        //     title: 'PM ID',
        //     dataIndex: 'pmId',
        //     key: 'pmId'
        // },
        {
            title: 'Serial Number',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    <Link to={'/CompletePMForm/' + props.PMID + '/' + data?.serialNumber}>{data?.serialNumber}</Link>
                </>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Group',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.group || '-'}
                </>
            )
        },
        {
            title: 'PM Template',
            dataIndex: 'templateName',
            key: 'templateName',
        },
        {
            title: 'PM Status',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.pmStatus}
                </>
            )
        },
        {
            title: 'Asset Status',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.status || '-'}
                </>
            )
        },
        {
            title: 'Owner Name',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.ownerName || '-'}
                </>
            )
        },
        {
            title: 'Department',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.department}
                </>
            )
        },
        {
            title: 'Completed Date',
            dataIndex: 'completedDatetime',
            key: 'completedDatetime'
        },
        {
            title: '',
            dataIndex: '',
            render: (row) => (
                <>
                    <div>
                        <Button type="link" onClick={() => showModal(row)}><Icon type="file-pdf" /> Download PDF</Button>
                    </div>
                </>
            )
        }
    ]

    const onchangeTable = (event) => {
        setPage(event)
    }

    return (
        <div>
            <Collapse defaultActiveKey={['1']} >
                <Panel header={props.PMID} key="1">
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        rowKey="id"
                        loading={isLoadingTable}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: totalDataSource,
                            showSizeChanger: true,
                            onShowSizeChange(current, size) {
                                setPageSize(size)
                                setPage(1)
                            },
                            pageSizeOptions: ['10', '30', '50', '80', '100'],
                            size: 'medium',
                            onChange: (event) => { onchangeTable(event) }
                        }}
                    />
                </Panel>
            </Collapse>
            <Modal
                style={{ minWidth: '80%' }}
                visible={isShowModalVisible}
                onCancel={() => setIsShowModalVisible(false)}
                footer={null}>
                <Spin spinning={isLoading}>
                    <div ref={componentRef} style={{ fontFamily: 'sans-serif' }}>
                        <div className="herder-export">{`PM ID : ${props.PMID || ''} / ${dataCompletePM?.asset?.assetGroupType?.assetGroup.name === 'IT Asset' ? 'IT Asset' : dataCompletePM?.asset?.assetGroup?.name || ''} `}</div>
                        <DetailPMForPrint Group={dataCompletePM?.asset?.assetGroupType?.assetGroup.name} isView={true} dataPMComplete={dataCompletePM} isOnline={false} dataAsset={dataCompletePM?.asset} anotherUser={anotherUser} />
                    </div>
                    <ReactToPrint
                        trigger={() => <div style={{ textAlign: 'end', marginTop: 30 }}><Button
                            type='ghost'
                        >
                            <Icon type="upload" /> Print
                        </Button> </div>}
                        content={() => componentRef.current}
                        pageStyle={pageStyle}
                        copyStyles={true}
                        documentTitle={props.PMID + '_' + dataCompletePM?.asset?.serialNumber + '_' + moment().format('YYYY-MM-DD')}
                    />
                </Spin>
            </Modal>
        </div>
    )
}

export default ExpandListCompletePM
