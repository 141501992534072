import {SlaPolicy} from './model'
import {
    AddSlaPolicySuccessEvent,
    DeleteSlaPolicySuccessEvent,
    GetAllSlaPolicySuccessEvent,
    UpdateSlaPolicyPageEvent,
    UpdateSlaPolicySuccessEvent,
    UpdateTotalContentSlaPolicyEvent
} from './state-event'
import {axiosDelete, axiosGet, axiosGetWithPagination, axiosPatch, axiosPost} from '../rest'
import {Dispatch} from 'redux'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { herderPostPatch } from '../../common-misc'

export async function getSlaPolicyById(id: string): Promise<SlaPolicy> {
    try {
        const res = await axiosGet<SlaPolicy>(`/api/sla-policies/${id}`)
        return decryptBody(res.data.data!! || '')
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAllSlaPolicy = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<SlaPolicy[]>('/api/sla-policies')
            if (response.data.data) {
                dispatch(GetAllSlaPolicySuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAllSlaPolicyWithPagination = (page: number, pageSize: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                page: page - 1,
                size: pageSize
            }
            const response = await axiosGetWithPagination<SlaPolicy[]>('/api/sla-policies/pagination', {params})
            if (response.data) {
                const searchResult = decryptBody(response.data.data!!)
                dispatch(GetAllSlaPolicySuccessEvent.build(searchResult.content || []))
                dispatch(UpdateSlaPolicyPageEvent.build(searchResult.number + 1 || searchResult.total))
                dispatch(UpdateTotalContentSlaPolicyEvent.build(searchResult.totalElements || searchResult?.pageable?.page))
            }

            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addSlaPolicy = (slaPolicy: SlaPolicy) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(slaPolicy))
            const response = await axiosPost<SlaPolicy>('/api/sla-policies', encryptData, herderPostPatch)
            dispatch(AddSlaPolicySuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteSlaPolicy = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/sla-policies/${id}`)
            dispatch(DeleteSlaPolicySuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateSlaPolicy = (patch: SlaPolicy) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPatch<SlaPolicy>(`/api/sla-policies/${id}`, encryptData, herderPostPatch)
            dispatch(UpdateSlaPolicySuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}
