export default class PageIncidentService {
    private static instance: PageIncidentService
    data = {
        pageAllTicket: 1,
        pageSizeAllTicket: 10,
        pageTicketSLA: 1,
        pageSizeTicketSLA: 10,
        pageTicketRelateTicket: 1,
        pageSizeTicketRelateTicket: 10,
        pageTicketRelateAsset: 1,
        pageSizeTicketRelateAsset: 10,
        pageTicketRelateKM: 1,
        pageSizeTicketRelateKM: 10,
        pageTicketRelateWF: 1,
        pageSizeTicketRelateWF: 10
    }

    static getInstance(): PageIncidentService {
        if (!PageIncidentService.instance) {
            PageIncidentService.instance = new PageIncidentService()
        }
        return PageIncidentService.instance
    }
}
