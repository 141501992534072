import { AddNotifyWebSocketSuccessEvent, DeleteNotifyWebSocketSuccessEvent, GetAllNotifyWebSocketSuccessEvent, NotifyWebSocketStateEvent, UpdateNotifyWebSocketSuccessEvent } from './state-event'
import { NotifyWebSocket } from './web-socket-model-notify'

export const NotifyWebSocketStateReducer = (state: NotifyWebSocket[] = [], event: NotifyWebSocketStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllNotifyWebSocketSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddNotifyWebSocketSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteNotifyWebSocketSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateNotifyWebSocketSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
