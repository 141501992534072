import React, {useState, useEffect} from 'react'
import {Row, Col, Input, Drawer, Table, message} from 'antd'
import {Link} from 'react-router-dom'
import {Space} from '../../../../common-components/Space'
import CategoryForm from './CategoryForm'
import {searchCatagory, addCatagory} from '../../service'
import { SelfServiceCatagory, SelfServiceCatalog, ResponseContentDetails } from '../../Model'
import {connect, useDispatch} from 'react-redux'
import { StoreState } from '../../../../store'
interface Params {
    catalog?: SelfServiceCatalog
    setCountCategory: Function
}

const mapStateToProps = (state: StoreState) => {
    return {
        SelfServiceCatagory: state.selfServiceCatagories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addCatagory: (data) => Promise<any>
}

type Props = Params & DispatchProps & StateProps
const { Search } = Input
const CatagoryList: React.FC<Props> = (props: Props) => {
    const pageSize = 10
    const [dataSource, setDataSource] = useState<ResponseContentDetails<SelfServiceCatagory[]>>()
    const [page, setPage] = useState<number>(1)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [onSearch, setOnsearch] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        searchCatagory(page, pageSize, `${props.catalog?.id}`, sortBy , orderBy, undefined, undefined, onSearch).then((res) => {
            setDataSource(res)
            if (props.setCountCategory) {
                props.setCountCategory(res?.totalElements || res?.total)
            }
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, onSearch])

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        <Link to={`/CategoryView/${row.id}`}>{data}</Link>
                    </div>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Parent',
            dataIndex: 'parent.name',
            key: 'parent',
            sorter: true,
        }
    ]

    const insertData = (data) => {
        setLoading(true)
        props.addCatagory(data).then((catagory) => {
            message.success('You have Successfully save the data.')
            if (catagory) {
                // eslint-disable-next-line no-unused-expressions
                dataSource?.content.unshift(catagory)
                // dispatch(AddSelfServiceCatagorySuccessEvent.build(catagory))
                const data = dataSource
                setDataSource(data)
            }
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
            searchCatagory(page, pageSize, `${props.catalog?.id}`, sortBy, orderBy, undefined, undefined, undefined).then((res) => {
                setDataSource(res)
                if (props.setCountCategory) {
                    props.setCountCategory(res?.total || res?.totalElements)
                }
            }).catch((err) => {
                message.error(err)
            }).finally(() => {
                setLoading(false)
            })
        })
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleSearch = (search: string) => {
        setPage(1)
        setOnsearch(search)
        // searchCatagory(page, pageSize, `${props.catalog?.id}`, sortBy, orderBy, undefined, undefined, search).then((catagory) => {
        //     if (catagory) {
        //         setDataSource(catagory)
        //     }
        // })
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortBy(sorter.field)
        if (sorter.order === 'ascend') {
            setOrderBy('asc')
        } else if (sorter.order === 'descend') {
            setOrderBy('desc')
        } else {
            setOrderBy('desc')
            setSortBy('createdDatetime')
        }
    }

    const catagoryParent = () => {
        if (dataSource) {
            return dataSource.content.filter(cat => cat.parent === undefined)
        }
    }

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={10}>
                    <Search
                        placeholder="Can search Category Name"
                        onSearch={handleSearch}
                        allowClear={true}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={14}>
                    <div style={{textAlign: 'end'}}><Link to={'#'} onClick={() => setVisible(true)}>Add Category</Link></div>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Table
                    dataSource={dataSource?.content}
                    loading={loading}
                    columns={columns}
                    pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: dataSource?.totalElements,
                        onChange: (event) => {
                            handleOnClickPageNumber(event)
                        }
                    }}
                    onChange={handleChangeTable} />
            </Row>
            <Drawer
                title={'Create New Category'}
                placement="right"
                onClose={() => setVisible(false)}
                visible={visible}
                width={500}
            >
                <CategoryForm catalog={props.catalog} setVisible={setVisible} isVisible={visible} catagoryAction={insertData} parents={catagoryParent()} categoryList={dataSource?.content}/>
            </Drawer>
        </>
    )
}

export default connect(mapStateToProps,{addCatagory})(CatagoryList)

