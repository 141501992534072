import React, { memo, FC, useEffect } from 'react'
import {Input, Col, Row, Card, Form} from 'antd'
import { Handle, Position, NodeProps, Connection, Edge, useUpdateNodeInternals } from 'react-flow-renderer'
import './dnd.css'
import startFlowIcon from '../../../../assets/images/startFlow.png'
import { FormComponentProps } from 'antd/es/form'

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params)

type Props = NodeProps & FormComponentProps
const StartNode: FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const updateNodeInternals = useUpdateNodeInternals()
    const isView = window.location.pathname.includes('/WorkflowFormView')

    useEffect(() => {
        setFieldsValue({
            workFlowName: props.data.data.name,
            description: props.data.data.desc
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        updateNodeInternals(props.id)
    }, [props.data, props.id, updateNodeInternals])

    const onChangeName = (e) => {
        if (e?.target?.value) {
            const { value } = e.target
            props.data.data.name = value
        }
    }

    const onChangeDesc = (e) => {
        if (e?.target?.value) {
            const { value } = e.target
            props.data.data.desc = value
        }
    }

    return (
        <>
            <Handle type="target" position={Position.Top} onConnect={onConnect} hidden={true}/>
            <Card
                title={
                    <Row style={{backgroundColor: '#F6#8F7'}}>
                        <Col span={5} className="dotForWorkflowIcon" style={{backgroundColor: '#EB5757'}}><img src={startFlowIcon} alt={'startFlowIcon'} className="workFlowIcon" /></Col>
                        <Col span={19} style={{marginLeft: '10px', width: '80%'}}><p>Start Workflow</p></Col>
                    </Row>
                }
                className="targetHandleStyle"
            >
                <Form>
                    <Form.Item label="Workflow name">
                        {getFieldDecorator('workFlowName', {
                        })(
                            <Input onChange={(e) => onChangeName(e)} disabled={isView}/>
                        )}
                    </Form.Item>
                    <Form.Item label="Description">
                        {getFieldDecorator('description', {
                        })(
                            <Input onChange={(e) => onChangeDesc(e)} disabled={isView}/>
                        )}
                    </Form.Item>
                </Form>
            </Card>
            <Handle type="source" position={Position.Bottom} id="s" isConnectable={props.isConnectable} />
        </>
    )
}

const StartNodes = Form.create<Props>()(StartNode)
export default memo(StartNodes)
