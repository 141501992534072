import React, { useState, useEffect } from 'react'
import { Card, Col, Collapse, Row, Select, Spin, Form} from 'antd'
import { FormComponentProps } from 'antd/es/form'
import CommonPanalReport from './CommonPanalReport'
import { accessToken, tokenDecode } from '../../common-misc'

interface Param {
}

type Props = Param & FormComponentProps
const {Panel} = Collapse
const {Option} = Select
const ReportHome: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator } = props.form
    const [isFetchingFirstAllAllTicket, setisFetchingFirstAllAllTicket] = useState<boolean>(false)
    const [show, setShow] = useState<string>('All')
    const [type, setType] = useState<string>('0')
    const [service, setService] = useState<any>([])

    useEffect(() => {
        setService(tokenDecode()?.service.serviceId)
    }, [])

    return (
        <Spin spinning={isFetchingFirstAllAllTicket} tip="Wating to Create File!!">
            <p className={'content'}>Report</p>
            <Card headStyle={{borderBottom: 'none', paddingTop: '23px'}}>
                <Form>
                    <Row gutter={[16, 16]}>
                        <Col span={18}><h3 style={{ paddingLeft: 5 }}>Report</h3></Col>
                        <Col span={3} xs={24} sm={24} md={3} lg={3}>
                            <span>Show :</span>
                            <Form.Item>
                                {getFieldDecorator('Show', {
                                    initialValue: show
                                })(
                                    <Select onChange={(e) => setShow(e.toString())}>
                                        <Option value="All">All</Option>
                                        <Option value="MyGroup">My Group</Option>
                                        <Option value="MyTicket">My Ticket</Option>
                                        <Option value="TotalGroup">Total Group</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={3} xs={24} sm={24} md={3} lg={3}>
                            <span>Ticket Type :</span>
                            <Form.Item >
                                {getFieldDecorator('type', {
                                    initialValue: type
                                })(
                                    <Select onChange={(e) => setType(e.toString())}>
                                        <Option value="0">All</Option>
                                        <Option value="1">Incident</Option>
                                        <Option value="2">Request</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header={'All Ticket'} key="1">
                            <CommonPanalReport form={props.form} name='allTicket'/>
                        </Panel>
                        <Panel header={'Ticket SLA'} key="2">
                            <CommonPanalReport form={props.form} name='ticketSla'/>
                        </Panel>
                        <Panel header={'Ticket Ralate Ticket'} key="3">
                            <CommonPanalReport form={props.form} name='ticketRelatedTicket'/>
                        </Panel>
                        {service.includes(2) ? <Panel header={'Ticket Ralate Asset'} key="4">
                            <CommonPanalReport form={props.form} name='ticketRelatedAsset'/>
                        </Panel> : null}
                        {service.includes(5) ? <Panel header={'Ticket Ralate Knowledge'} key="5">
                            <CommonPanalReport form={props.form} name='ticketRelatedKnowledge'/>
                        </Panel> : null}
                        {service.includes(4) ? <Panel header={'Ticket Ralate Workflow'} key="6">
                            <CommonPanalReport form={props.form} name='ticketRelatedWorkflow'/>
                        </Panel> : null}
                    </Collapse>
                </Form>
            </Card>
        </Spin>
    )
}

const ReportHomeForm = Form.create<Props>({name: 'ReportHome'})(ReportHome)
export default ReportHomeForm
