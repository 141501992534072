import {Dispatch} from 'redux'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import {axiosGet, axiosPost} from '../rest'
import {PriorityLevel, PriorityMatrix} from './model'
import {
    GetAllPrioritySuccessEvent,
    GetPriorityMatrixSuccessEvent,
    CreatePriorityMatrixNewVersionSuccessEvent
} from './state-event'

export const getAllPriority = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityLevel[]>('/api/priorities-level/priority')
            dispatch(GetAllPrioritySuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function resolvePriority(impact: number, urgency: number, incidentId?: number): Promise<PriorityMatrix> {
    try {
        const params = {
            id: incidentId
        }
        const response = await axiosGet<PriorityMatrix>(`/api/priorities/${impact}/${urgency}`, {params})
        return decryptBody(response.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const getPriorityConfiguration = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityMatrix>('/api/priorities/matrix')
            dispatch(GetPriorityMatrixSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const createPriorityMatrixNewVersion = (patch: PriorityMatrix[] | undefined) => {
    return async (dispatch: Dispatch) => {
        try {
            // const id = patch.id!!
            // delete patch.id
            // console.log(patch)
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPost<PriorityMatrix[]>('/api/priorities/matrix', encryptData, herderPostPatch)
            dispatch(CreatePriorityMatrixNewVersionSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const createNewPriorityLevel = async (newItem: PriorityLevel[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<PriorityLevel[]>('/api/priorities-level/list', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}
