import {
    GetAllIncidentSuccessEvent,
    GetIncidentByIdSuccessEvent,
    IncidentDeletedEvent,
    IncidentStateEvent,
    IncidentUpdatedEvent,
    SearchIncidentsSuccessEvent,
    SlaCalculationsUpdatedEvent,
    GetAllSatisfactionSuccessEvent
} from './state-event'
import {Incident, IncidentWithSlaCalculations, Satisfaction} from './model'
import { IncidentWebSocketEvent } from './web-socket-model'
import { SlaCalculationDataSuitReceivedEvent } from '../../sla-management/sla-calculation/state-event'
import { buildEmptySlaCalculationDataSuit, SlaCalculationDataSuit } from '../../sla-management/sla-calculation/service'
import { GenericStateEvent } from '../../common-redux'
import { combineReducers } from 'redux'
import { SlaPolicy } from '../../sla-management/sla-policy/model'
import {
    BusinessHour
} from '../../sla-management/business-hour'
import moment from 'moment'

export const incidentListStateReducer = (
    state: IncidentWithSlaCalculations[] = [], event: GenericStateEvent<any> = { type: undefined, payload: undefined}
): IncidentWithSlaCalculations[] => {
    if (event.type === SearchIncidentsSuccessEvent.type) {
        const payload: IncidentWithSlaCalculations[] | undefined = event.payload
        const incidents = payload || []
        return incidents
    } else if (event.type === IncidentUpdatedEvent.type) {
        const incident: IncidentWithSlaCalculations = event.payload
        return state.map((it) => {
            if (it.id !== incident.id) {
                return it
            } else {
                incident.slaCalculations = it.slaCalculations
                incident.slaPolicyHistories = it.slaPolicyHistories
                return incident
            }
        })
    } else if (event.type === IncidentDeletedEvent.type) {
        const incidentId: number = event.payload
        return state.filter((it) => it.id !== incidentId)
    } else if (event.type === SlaCalculationsUpdatedEvent.type) {
        const payload: IncidentWebSocketEvent = event.payload
        const incident = state.find((it) => it.id === Number(payload.incidentId))
        if (incident) {
            incident.slaCalculations = payload.slaCalculations!!
            incident.slaPolicyHistories = payload.slaPolicyHistories!!
            return state.map((it) => it.id === incident.id ? incident : it)
        } else {
            return state
        }
    } else if (event.type === SlaCalculationDataSuitReceivedEvent.type) {
        const dataSuit: SlaCalculationDataSuit = event.payload
        return state.map((it) => {
            return {...it, slaCalculations: dataSuit.slaCalculationsMap[it.id!!] || [], slaPolicyHistories: dataSuit.slaPolicyHistories || [] }
        })
    } else {
        return state
    }
}

export const incidentDetailsViewStateReducer = (
    state: IncidentWithSlaCalculations | null = null, event: IncidentStateEvent<any> = { type: undefined, payload: undefined}
): IncidentWithSlaCalculations | null => {
    if (event.type === GetIncidentByIdSuccessEvent.type) {
        const payload: IncidentWithSlaCalculations = event.payload
        return {...payload, slaCalculations: state?.slaCalculations || [], slaPolicyHistories: state?.slaPolicyHistories || []}
    } else if (event.type === IncidentUpdatedEvent.type && (state === null || event.payload.id === state?.id)) {
        const payload: IncidentWithSlaCalculations = event.payload
        return {...payload, slaCalculations: state?.slaCalculations || [], slaPolicyHistories: state?.slaPolicyHistories || []} as IncidentWithSlaCalculations
    } else if (event.type === IncidentDeletedEvent.type && event.payload === state?.id) {
        return null
    } else if (event.type === SlaCalculationsUpdatedEvent.type && (state === null || event.payload.incidentId === state?.id)) {
        const payload: IncidentWebSocketEvent = event.payload
        return {...(state || {}), slaCalculations: payload.slaCalculations, slaPolicyHistories: payload.slaPolicyHistories || []} as IncidentWithSlaCalculations
    } else if (event.type === SlaCalculationDataSuitReceivedEvent.type) {
        const dataSuit: SlaCalculationDataSuit = event.payload
        if (state !== null && state.id!! in dataSuit.slaCalculationsMap) {
            return {...state, slaCalculations: dataSuit.slaCalculationsMap[state.id!!] || [], slaPolicyHistories: dataSuit.slaPolicyHistories || []} as IncidentWithSlaCalculations
        } else {
            return state
        }
    } else {
        return state
    }
}

export const slaPolicyHistoryStateReducer = (
    // state: Map<string, History<SlaPolicy>> = new Map(), event: GenericStateEvent<any> = { type: undefined, payload: undefined}
    state: Map<string, SlaPolicy> = new Map(), event: GenericStateEvent<any> = { type: undefined, payload: undefined}
// ): Map<string, History<SlaPolicy>> => {
): Map<string, SlaPolicy> => {
    if (event.type === SlaCalculationsUpdatedEvent.type) {
        const payload: IncidentWebSocketEvent = event.payload
        payload.slaPolicyHistories!!.forEach((it) => state.set(it.id!!, it))
        return state
    } else if (event.type === SlaCalculationDataSuitReceivedEvent.type) {
        const dataSuit: SlaCalculationDataSuit = event.payload
        dataSuit.slaPolicyHistories.forEach((it) => state.set(it.id!!, it))
        return state
    } else {
        return state
    }
}

export const businessHourHistoryStateReducer = (
    // state: Map<string, History<BusinessHour>> = new Map(), event: GenericStateEvent<any> = { type: undefined, payload: undefined}
    state: Map<string, BusinessHour> = new Map(), event: GenericStateEvent<any> = { type: undefined, payload: undefined}
// ): Map<string, History<BusinessHour>> => {
): Map<string, BusinessHour> => {
    if (event.type === SlaCalculationsUpdatedEvent.type) {
        const payload: IncidentWebSocketEvent = event.payload
        payload.businessHourHistories!!.forEach((it) => state.set(it.id!!.toString(), it))
        return state
    } else if (event.type === SlaCalculationDataSuitReceivedEvent.type) {
        const dataSuit: SlaCalculationDataSuit = event.payload
        dataSuit.businessHourHistories.forEach((it) => state.set(it.id!!.toString(), it))
        return state
    } else {
        return state
    }
}

export const slaDisplayStateReducer = (
    state: SlaCalculationDataSuit | null = null, event: IncidentStateEvent<any> = { type: undefined, payload: undefined}
): SlaCalculationDataSuit | null => {
    if (event.type === SlaCalculationsUpdatedEvent.type) {
        const payload: IncidentWebSocketEvent = event.payload
        state = state || buildEmptySlaCalculationDataSuit()
        state.slaCalculationsMap[payload.incidentId] = payload.slaCalculations!!
        state.slaPolicyHistories = [...state.slaPolicyHistories, ...payload.slaPolicyHistories!!]
        state.businessHourHistories = [...state.businessHourHistories, ...payload.businessHourHistories!!]
        state.versionTimestamp = moment()
        return {...state} as SlaCalculationDataSuit
    } else if (event.type === SlaCalculationDataSuitReceivedEvent.type) {
        return event.payload as SlaCalculationDataSuit
    } else {
        return state
    }
}

export interface IncidentState {
    incidents: IncidentWithSlaCalculations[],
    incident: IncidentWithSlaCalculations | null,
    slaCalculationDataSuit: SlaCalculationDataSuit | null
    // slaPolicyHistories: Map<string, History<SlaPolicy>>,
    slaPolicyHistories: Map<string, SlaPolicy>,
    // businessHourHistories: Map<string, History<BusinessHour>>
    businessHourHistories: Map<string, BusinessHour>
    allIncident: Incident[]
}

export const incidentStateReducer = (state: Incident[] = [], event: IncidentStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllIncidentSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}

export const satisfactionStateReducer = (state: Satisfaction[] = [], event: GenericStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSatisfactionSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}

export const incidentStateReducers = combineReducers<IncidentState>({
    incidents: incidentListStateReducer,
    incident: incidentDetailsViewStateReducer,
    slaCalculationDataSuit: slaDisplayStateReducer,
    slaPolicyHistories: slaPolicyHistoryStateReducer,
    businessHourHistories: businessHourHistoryStateReducer,
    allIncident: incidentStateReducer
})
