import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { Department } from '../Model'

export const PmActivityStateContext = createContext({})

const initialState: Department[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const PmActivityStateReducer = (state: Department[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_PmActivityState':
        sessionStorage.setItem('pmActivity', JSON.stringify(action.payload))
        return action.payload || [] as Department[]
    case 'ADD_PmActivityState':
        return [...state, action.payload]
    default:
        return state
    }
}

export const PmActivityStateProvider = (props: props) => {
    const [PmActivityStateState, PmActivityStateDispatch] = useReducer(PmActivityStateReducer, initialState)

    const GetPmActivityState = (payload: Department[]) => {
        PmActivityStateDispatch({ type: 'GET_PmActivityState', payload })
    }

    const AddPmActivityState = (payload: Department) => {
        PmActivityStateDispatch({ type: 'ADD_PmActivityState', payload })
    }

    return (
        <PmActivityStateContext.Provider value={{ PmActivityStateState, GetPmActivityState, AddPmActivityState }}>
            {props.children}
        </PmActivityStateContext.Provider>
    )
}
