import React, { memo, FC, useEffect, useState } from 'react'
import {Input, Select, Checkbox, Col, Row, Card, Form, Button, Modal, InputNumber, message} from 'antd'
import { Handle, Position, NodeProps, Connection, Edge, useUpdateNodeInternals } from 'react-flow-renderer'
import './dnd.css'
import jobIcon from '../../../../assets/images/job.png'
import { StoreState } from '../../../../store'
import { connect } from 'react-redux'
import { UserSupportTeam } from '../../../../authorization-module/support-team-management/model'
import { Properties } from '../../Service/Model'
import { replaceName } from '../../../../common-file'
import PositionSignatureFormModal from './positionSignatureForm'

const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params)

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeams: state.supportTeams
    }
}

const { Option } = Select
type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & NodeProps
const JobNode: FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.data.form
    const [assignTo, setAssignTo] = useState<UserSupportTeam[]>([])
    const [properties, setProperties] = useState<any>(props.data.properties)
    const updateNodeInternals = useUpdateNodeInternals()
    const isView = window.location.pathname.includes('/WorkflowFormView')
    const isPreviewWorkflow = window.location.pathname.includes('WoFormEdit')
    const [isSetPositionSignature, setIsSetPositionSignature] = useState<boolean>(false)
    const [numberOfSign, setNumberOfSign] = useState<number | undefined>(1)
    const [isChangeNumberOfSign, setIsChangeNumberOfSign] = useState(false)

    useEffect(() => {
        setFieldsValue({
            topicJob: props.data.properties.topic ? props.data.properties.topic.replace(/ +/g, ' ').trim() : undefined,
            assignmentGroupJob: props.data.properties.supportTeam,
            assignmentToJob: props.data.properties.assignee,
            notifyJob: props.data.properties.notify
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])
    
    useEffect(() => {
        if (props?.supportTeams && props.data.properties?.supportTeam) {
            setAssignTo(props.supportTeams.find((data) => data.name === props.data.properties.supportTeam)?.assignees || [])
        }
    }, [props?.supportTeams])

    useEffect(() => {
        updateNodeInternals(props.id)
    }, [props.data, props.id, updateNodeInternals])

    useEffect(() => {
        if (properties?.numberOfSignature!! === undefined) {
            properties.numberOfSignature = 1
        }
        if (properties.positionName === undefined) {
            properties.positionName = null!!
        }
        props.data.properties = properties
    }, [properties, props.data])

    const onChangeTeam = (e) => {
        const obj = {}
        obj[replaceName('assignmentToJob' + props.id)] = undefined
        setFieldsValue(obj)
        setAssignTo([])
        if (e) {
            if (props.supportTeams) {
                setAssignTo(props.supportTeams.find((data) => data.name === e)?.assignees || [])
            }
        }
        const findAssigngroupId = props.supportTeams.find((data) => { return data.name === e })
        setProperties(prevState => {
            return {
                ...prevState,
                supportTeam: e,
                assignmentGroupId: Number(findAssigngroupId?.id)
            }
        })
    }

    const onChangeAssignTo = (e) => {
        if (e) {
            const targetId = e?.split(':')[0]?.trim()
            const targetUser = e?.split(':')[1]?.trim()
            console.log(targetId)
            console.log(targetUser)
            const findAssignTo = assignTo.find((user) => { return user.users.fullName === targetUser && user.users?.employeeId?.toString() === targetId?.toString() })
            console.log(findAssignTo)
            if (e !== undefined) {
                setProperties(prevState => {
                    return {
                        ...prevState,
                        assignee: targetUser,
                        assigneeId: Number(findAssignTo?.users.id) || undefined
                    }
                })
            } else {
                setProperties(prevState => {
                    return {
                        ...prevState,
                        assignee: targetUser,
                        assigneeId: Number(findAssignTo?.users.id) || undefined
                    }
                })
            }
        } else {
            setProperties(prevState => {
                return {
                    ...prevState,
                    assignee: undefined,
                    assigneeId: undefined
                }
            })
            const obj = {}
            obj['assignmentToJob' + props.id] = undefined
            setFieldsValue(obj)
        }
    }

    const onChangeName = (e) => {
        if (e?.target?.value) {
            const { value } = e.target
            setProperties(prevState => {
                return {
                    ...prevState,
                    topic: String(value)
                }
            })
        } else {
            setProperties(prevState => {
                return {
                    ...prevState,
                    topic: ''
                }
            })
        }
    }

    const onChangeSignatureForm = (e) => {
        if (e?.target) {
            const { checked } = e.target
            setProperties(prevState => {
                return {
                    ...prevState,
                    signature: checked
                }
            })
        }
    }

    const onChangeNotify = (e) => {
        if (e?.target) {
            const { checked } = e.target
            setProperties(prevState => {
                return {
                    ...prevState,
                    notify: checked
                }
            })
        }
    }

    const onChangeExpense = (e) => {
        if (e?.target) {
            const { checked } = e.target
            setProperties(prevState => {
                return {
                    ...prevState,
                    expense: checked
                }
            })
        }
    }

    const validateTopicJob = (_: any, value: any, callback) => {
        if (value) {
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(value)) {
                callback()
            } else {
                callback('Default value is not a valid')
            }
        } else {
            callback()
        }
    }

    const onChangeNumberSign = (e) => {
        if (e) {
            setIsChangeNumberOfSign(true)
            setNumberOfSign(e)
            setProperties(prevState => {
                return {
                    ...prevState,
                    numberOfSignature: Number(e)
                }
            })
        } else {
            setNumberOfSign(undefined)
        }
    }

    const onChangeDocumentName = (e) => {
        if (e?.target) {
            const { value } = e.target
            setProperties(prevState => {
                return {
                    ...prevState,
                    documentName: String(value).trim()
                }
            })
        }
    }

    const handleShowPosition = () => {
        setIsSetPositionSignature(true)
    }

    const getCloseModal = (value) => {
        setIsSetPositionSignature(value)
    }

    const getDataPosition = (data) => {
        if (data) {
            setIsChangeNumberOfSign(false)
            setProperties(prevState => {
                return {
                    ...prevState,
                    positionName: data
                }
            })
        }
    }

    const validateNumberSignature = (_: any, value: any, callback) => {
        if (value && isNaN(value)) {
            const obj = {}
            obj[replaceName('numberSign' + props.id)] = 1
            setFieldsValue(obj)
        } else {
            callback()
        }
    }

    const onBlurnumberSign = (e) => {
        if (e.target.value) {
            if (e.target.value === '1' || e.target.value === '2' || e.target.value === '3') {
                setNumberOfSign(Number(e.target.value))
            } else {
                const obj = {}
                obj[replaceName('numberSign' + props.id)] = 3
                setFieldsValue(obj)
                setNumberOfSign(3)
                setProperties(prevState => {
                    return {
                        ...prevState,
                        numberOfSignature: 3
                    }
                })
            }
        } else {
            setNumberOfSign(3)
            const obj = {}
            obj[replaceName('numberSign' + props.id)] = 3
            setFieldsValue(obj)
            setProperties(prevState => {
                return {
                    ...prevState,
                    numberOfSignature: 3
                }
            })
        }
    }

    return (
        <>
            <Handle type="target" position={Position.Top} onConnect={onConnect} />
            <Card
                title={
                    <div>
                        <Row>
                            <Col span={5} className="dotForWorkflowIcon" style={{backgroundColor: '#ED9E28'}}><img src={jobIcon} alt={'jobIcon'} className="workFlowIcon" /></Col>
                            <Col span={19} style={{marginLeft: '10px', width: '80%'}}><p>Job</p></Col>
                        </Row>
                    </div>
                }
                className="targetHandleStyle"
            >
                <Form.Item label="Topic">
                    {getFieldDecorator('topicJob' + props.id, {
                        initialValue: props.data.properties.topic ? props.data.properties.topic.replace(/ +/g, ' ').trim() : undefined || undefined,
                        rules: [{
                            required: true,
                            message: 'Please input Topic!'
                        },
                        {
                            validator: validateTopicJob
                        }]
                    })(
                        <Input placeholder="topic" onChange={(e) => onChangeName(e)} maxLength={60} disabled={isView || isPreviewWorkflow}/>
                    )}
                </Form.Item>
                <Form.Item label="Assignment group">
                    {getFieldDecorator('assignmentGroupJob' + props.id, {
                        initialValue: props.data?.properties?.supportTeam || undefined,
                        rules: [{
                            required: true,
                            message: 'Please input Assignment group!'
                        }]
                    })(
                        <Select style={{ width: '100%' }} placeholder="Select assignment group" allowClear onChange={(e) => onChangeTeam(e)} disabled={isView || isPreviewWorkflow}>
                            {props.supportTeams?.map((supportTeam, index) => {
                                return (<Option key={index} value={supportTeam.name}>{supportTeam.name}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Assigned to">
                    {getFieldDecorator('assignmentToJob' + props.id, {
                        initialValue: props.data?.properties?.assignee || undefined
                    })(
                        <Select style={{ width: '100%' }} placeholder="Select Assigned to" allowClear onChange={(e) => onChangeAssignTo(e)} disabled={isView || isPreviewWorkflow}>
                            {assignTo.map((assign, index) => {
                                return (<Option key={index} value={assign.users.employeeId + ' : ' + assign.users.fullName}>{assign.users.employeeId + ' : ' + assign.users.fullName}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Row>
                    <Col span={8}>
                        <Form.Item>
                            {getFieldDecorator('signFormJob' + props.id, {
                                initialValue: properties.signature,
                                valuePropName: 'checked'
                            })(
                                <Checkbox onChange={(e) => onChangeSignatureForm(e)} disabled={isView || isPreviewWorkflow}>Signature Form</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item>
                            {getFieldDecorator('notifyJob' + props.id, {
                                initialValue: props.data?.properties?.notify || undefined,
                                valuePropName: 'checked'
                            })(
                                <Checkbox onChange={(e) => onChangeNotify(e)} disabled={isView || isPreviewWorkflow}>Email Notification</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item>
                            {getFieldDecorator('expenseJob' + props.id, {
                                initialValue: props.data?.properties?.expense || undefined,
                                valuePropName: 'checked'
                            })(
                                <Checkbox onChange={(e) => onChangeExpense(e)} disabled={isView || isPreviewWorkflow}>Expense</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {properties.signature
                    ? <>
                        <Form.Item label="Document Name">
                            {getFieldDecorator('documentName' + props.id, {
                                initialValue: properties.documentName,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input Document Name!'
                                    },
                                    {
                                        whitespace: true,
                                        message: 'Please input Document Name!'
                                    }
                                ]
                            })(
                                <Input placeholder="Document Name" onChange={(e) => onChangeDocumentName(e)} disabled={isView || isPreviewWorkflow}/>
                            )}
                        </Form.Item>
                        <Form.Item label="Number of Signatures (maximum 3)">
                            {getFieldDecorator('numberSign' + props.id, {
                                initialValue: properties.numberOfSignature,
                                rules: [
                                    {
                                        validator: validateNumberSignature
                                    }
                                ]
                            })(
                                <Input
                                    size='default'
                                    style={{ width: '100%' }}
                                    onChange={(e) => onChangeNumberSign(e.target.value)}
                                    onBlur={(e) => onBlurnumberSign(e)}
                                    disabled={isView || isPreviewWorkflow}
                                />
                            )}
                        </Form.Item>
                        <Row>
                            {!isView ? <span className='feck-link-Button' onClick={handleShowPosition}>Set Signatures</span> : null}
                        </Row>
                    </>
                    : null}
                <Modal title="Set the position of the signature" visible={isSetPositionSignature} closable={false} footer={null}>
                    <PositionSignatureFormModal numberOfSign={properties.numberOfSignature || numberOfSign} positionName={properties.positionName} closeModal={getCloseModal} getDataPosition={getDataPosition} isOpen={isSetPositionSignature} IsChangeNumberOfSign={isChangeNumberOfSign}/>
                </Modal>
            </Card>
            <Handle type="source" position={Position.Bottom} id="j" isConnectable={props.isConnectable} />
        </>
    )
}

export default connect(mapStateToProps, {})(memo(JobNode))
