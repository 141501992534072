import { PMActivityField } from '.'
import { GetAllPMActivityFieldSuccessEvent, PMActivityFieldStateEvent } from './state-event-pm-activity'

export const PMActivityFieldStateReducer = (state: PMActivityField[] = [], event: PMActivityFieldStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllPMActivityFieldSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
