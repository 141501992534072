import {GetAllUrgencySuccessEvent, UrgencyStateEvent} from './state-event'
import {PriorityLevel} from '../priority'

export const urgencyStateReducer = (state: PriorityLevel[] = [], event: UrgencyStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllUrgencySuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
