import { SupportTeam } from '../incident/model'
import { GetAllSupportTeamWithDeleteGroupSuccessEvent } from './state-event-with-delete-group'
import { SupportTeamStateEvent } from './state-event-with-permission'

export const supportTeamWithDeleteGroupReducer = (state: SupportTeam[] = [], event: SupportTeamStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSupportTeamWithDeleteGroupSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
