import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const ZoneContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const ZoneReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_Zone':
        sessionStorage.setItem('zone', JSON.stringify(action.payload))
        return action.payload
    case 'ADD_Zone':
        return [...state, action.payload]
    default:
        return state
    }
}

export const ZoneProvider = (props: props) => {
    const [ZoneState, ZoneDispatch] = useReducer(ZoneReducer, initialState)

    const GetZone = (payload) => {
        ZoneDispatch({ type: 'GET_Zone', payload })
    }

    const AddZone = (payload) => {
        ZoneDispatch({ type: 'ADD_Zone', payload })
    }

    return (
        <ZoneContext.Provider value={{ ZoneState, GetZone, AddZone }}>
            {props.children}
        </ZoneContext.Provider>
    )
}
