import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const AssetStateContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const AssetStateReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_AssetState':
        sessionStorage.setItem('assetStatus', JSON.stringify(action.payload))
        return action.payload
    case 'ADD_AssetState':
        return [...state, action.payload]
    default:
        return state
    }
}

export const AssetStateProvider = (props: props) => {
    const [AssetStateState, AssetStateDispatch] = useReducer(AssetStateReducer, initialState)

    const GetAssetState = (payload) => {
        AssetStateDispatch({ type: 'GET_AssetState', payload })
    }

    const AddAssetState = (payload) => {
        AssetStateDispatch({ type: 'ADD_AssetState', payload })
    }

    return (
        <AssetStateContext.Provider value={{ AssetStateState, GetAssetState, AddAssetState }}>
            {props.children}
        </AssetStateContext.Provider>
    )
}
