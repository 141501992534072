import pdf from '../assets/icons/pdf.png'
import document from '../assets/icons/document.png'
import javascript from '../assets/icons/javascript.png'
import mp3 from '../assets/icons/mp3.png'
import mp4 from '../assets/icons/mp4.png'
import powerpoint from '../assets/icons/powerpoint.png'
import txt from '../assets/icons/txt.png'
import zip from '../assets/icons/zip.png'
import ai from '../assets/icons/ai.png'
import avi from '../assets/icons/avi.png'
import css from '../assets/icons/css.png'
import svg from '../assets/icons/svg.png'
import json from '../assets/icons/json.png'
import csv from '../assets/icons/csv.png'
import xml from '../assets/icons/xml.png'
import html from '../assets/icons/html.png'
import xls from '../assets/icons/xls.png'
export default function exportIcon(fileType: string) {
    let result
    switch (fileType) {
    case 'application/pdf':
        result = pdf
        break
    case 'text/plain':
        result = txt
        break
    case 'application/json':
        result = json
        break
    case 'application/document':
        result = document
        break
    case 'text/xml':
        result = xml
        break
    case 'application/x-zip-compressed':
        result = zip
        break
    case 'text/html':
        result = html
        break
    case 'image/svg+xml':
        result = svg
        break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        result = document
        break
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        result = xls
        break
    case 'application/postscript':
        result = ai
        break
    case 'text/javascript':
        result = javascript
        break
    case 'application/vnd.ms-excel':
        result = csv
        break
    case 'audio/mpeg':
        result = mp3
        break
    case 'text/css':
        result = css
        break
    case 'video/avi':
        result = avi
        break
    case 'video/mp4':
        result = mp4
        break
    case 'application/vnd.ms-powerpoint':
        result = powerpoint
        break
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        result = powerpoint
        break
    }
    return result
}
