import { IncidentFilter, IncidentView } from './model'
import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { IncidentViewState } from './state-model'

export interface IncidentViewStateEvent<T> extends GenericStateEvent<T> {}

export const GetCurrentIncidentViewSuccessEvent = {
    type: 'GetCurrentIncidentViewSuccessEvent',
    build: (payload: IncidentViewState) => buildStateEvent(GetCurrentIncidentViewSuccessEvent.type, payload)
}

export const SetCurrentIncidentViewSuccessEvent = {
    type: 'SetCurrentIncidentViewSuccessEvent',
    build: (payload: IncidentViewState) => buildStateEvent(SetCurrentIncidentViewSuccessEvent.type, payload)
}

export const AddIncidentViewSuccessEvent = {
    type: 'AddIncidentViewSuccessEvent',
    build: (payload: IncidentView) => buildStateEvent(AddIncidentViewSuccessEvent.type, payload)
}

export const UpdateIncidentViewSuccessEvent = {
    type: 'UpdateIncidentViewSuccessEvent',
    build: (payload: IncidentView) => buildStateEvent(UpdateIncidentViewSuccessEvent.type, payload)
}

export const CurrentViewCriteriaChangedEvent = {
    type: 'CurrentViewCriteriaChangedEvent',
    build: (payload: IncidentFilter) => buildStateEvent(CurrentViewCriteriaChangedEvent.type, payload)
}

export const UserChangeSortByEvent = {
    type: 'UserChangeSortByEvent',
    build: (payload: string) => buildStateEvent(UserChangeSortByEvent.type, payload)
}

export const UserChangeOrderByEvent = {
    type: 'UserChangeOrderByEvent',
    build: (payload: string) => buildStateEvent(UserChangeOrderByEvent.type, payload)
}

export const DeleteIncidentViewSuccessEvent = {
    type: 'DeleteIncidentViewSuccessEvent',
    build: (payload: any[]) => buildStateEvent(DeleteIncidentViewSuccessEvent.type, payload)
}
