import {Dispatch} from 'redux'
import {axiosGet} from '../rest'
import {GetAllUrgencyPriortySuccessEvent} from './state-event'
import {PriorityLevelProblem} from '../priority'
import { checkRedirect } from '../../common-misc'
import { decryptBody } from '../../common-misc/encryptBody'

export const getAllUrgencyPriorty = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityLevelProblem[]>('/api/priority-levels/priorities?types=Urgency')
            // console.log(decryptBody(response.data.data!!)?.urgency)
            dispatch(GetAllUrgencyPriortySuccessEvent.build(decryptBody(response.data.data!!)?.urgency))
            return response.status
        } catch (err) {
            console.log(err.response)
            checkRedirect(err.response.status)
            throw err
        }
    }
}
