import {GetAllWorklogByJobNoSuccessEvent, WorklogStateEvent} from './state-event'
import {JobWorkLog} from './model'

export const jobWorklogStateReducer = (state: JobWorkLog[] = [], event: WorklogStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllWorklogByJobNoSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
