import { IncidentWebSocket } from '../web-socket'
import { Frame } from 'stompjs'
import store from '../../store'
import { CommentWebSocketEvent, CommentWebSocketEventType } from './web-socket-model'
import { CommentAddedEvent } from './state-event'

function onCommentEvent(frame: Frame) {
    const event: CommentWebSocketEvent = JSON.parse(frame.body)
    if (event.eventType === CommentWebSocketEventType.Inserted) {
        store.dispatch(CommentAddedEvent.build(event.comment))
    }
}

export const CommentWebSocketChannel = {
    subscribe: () => {
        IncidentWebSocket.getClient()
            .then((client) => {
                client.subscribe(IncidentWebSocket.buildDestination(client, '/comments/event'), onCommentEvent)
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    },
    subscribeToIncidentIds: (incidentIds: string[], unsubscribeAll: boolean = true) => {
        IncidentWebSocket.getClient()
            .then((client) => {
                if (unsubscribeAll) {
                    client.send('/app/comments.unsubscribeAll', {})
                }
                client.send('/app/comments.subscribe', {}, JSON.stringify(incidentIds))
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    }
}
