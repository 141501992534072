import {axiosFactory as commonAxiosFactory, ResponseContentDetails, ResponseDetails} from '../common-rest'
import { AxiosRequestConfig } from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL_SELF_SERVICE!!

export const axiosFactory = commonAxiosFactory(BASE_URL)

export function axiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseDetails<T>>(url, config)
}
export function axiosDelete<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().delete<ResponseDetails<T>>(url, config)
}
export function axiosPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().post<ResponseDetails<T>>(url, data, config)
}
export function axiosPut<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().put<ResponseDetails<T>>(url, data, config)
}
export function axiosPatch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().patch<ResponseDetails<T>>(url, data, config)
}
export function axiosGetWithPagination<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseContentDetails<T>>(url, config)
}

