import {
    NotifyStateEvent,
    UpdateNotifySuccessEvent
} from './state-event'

export const NotifyStateReducer = (state:number = 0, event: NotifyStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateNotifySuccessEvent.type) {
        return event.payload || 0
    } else {
        return state
    }
}
