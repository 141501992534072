import { SlaCalculation } from './model'
import { axiosGet } from '../rest'
import { SlaPolicy } from '../sla-policy/model'
import { TicketType } from '../sla-global-setting'
import { BusinessHour } from '../business-hour'
import { Dispatch } from 'redux'
import { SlaCalculationDataSuitReceivedEvent } from './state-event'
import moment from 'moment'
import { decryptBody } from '../../common-misc/encryptBody'

export interface SlaCalculationDataSuit {
    slaCalculationsMap: Map<string, Array<SlaCalculation>>
    // slaPolicyHistories: Array<History<SlaPolicy>>
    slaPolicyHistories: Array<SlaPolicy>
    // businessHourHistories: Array<History<BusinessHour>>
    businessHourHistories: Array<BusinessHour>
    versionTimestamp: moment.Moment
}

export const buildEmptySlaCalculationDataSuit = () => ({
    slaCalculationsMap: new Map<string, Array<SlaCalculation>>(),
    slaPolicyHistories: [],
    businessHourHistories: [],
    versionTimestamp: moment()
} as SlaCalculationDataSuit)

export const getAllSlaCalculationByTicketTypeAndTicketIds = (ticketIds: string[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                ids: ticketIds.join(',')
            }
            const res = await axiosGet<SlaCalculationDataSuit>('/api/sla-calculations/list', {params})
            dispatch(SlaCalculationDataSuitReceivedEvent.build(decryptBody(res.data.data!!)))
            return res.status
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export async function getAllSlaCalculationByTicketTypeAndTicketId(id: string): Promise<SlaCalculationDataSuit> {
    try {
        const params = {
            ids: id
        }
        const res = await axiosGet<SlaCalculationDataSuit>('/api/sla-calculations/list', {params})
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        throw error
    }
}

class ForTicketType {
    // ticketType: string;
    //
    // constructor(ticketType: string) {
    //     this.ticketType = ticketType
    // }

    getAllSlaCalculationByTicketIds = (ticketIds: string[]) => {
        return async (dispatch: Dispatch) => {
            return getAllSlaCalculationByTicketTypeAndTicketIds(ticketIds)(dispatch)
        }
    }
}

export const forTicketType = (ticketType: string) => new ForTicketType()

export const ForIncident = forTicketType(TicketType.Incident as string)
