/* eslint-disable indent */
import React, {useContext, useEffect, useState} from 'react'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Drawer,
    Form,
    Icon,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Tag,
    Upload
} from 'antd'
import {Link} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import {StoreState} from '../../store'

import {FormComponentProps} from 'antd/lib/form'
import WrappedFormPeople from '../../authorization-module/people/PeopleForm'
import {FieldDetail, GeneralIncidentSetting, Incident, IncidentSetting, SupportTeam, TicketStatus} from './model'
import {Categories, getAllCategory, getAllItems, getAllSubCategory, Item, SubCategory} from '../category'
import {addIncident, searchIncidents} from './service'
import {getAllImpact} from '../impact'
import {getAllUrgency} from '../urgency'
import {getAllSupportTeamByPermission} from '../support-team'
import {getAllStatus, getTicketStatus} from '../status'
import {getAllPriority, PriorityLevel, PriorityMatrix, resolvePriority} from '../priority'
import {DataSourceItemObject} from 'antd/lib/auto-complete'
import {SelectValue} from 'antd/lib/select'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {getAllGeneralIncidentSetting} from '../general-incident-setting/service'
import {UploadFile} from 'antd/lib/upload/interface'
import {UploadLink} from '../../knowledge-management/knowledge/uploads'
import exportIcon from '../../common-file/icon-file'
import FSS from '../../file-server-storage'
import {CustomFiled, CustomFileds, getAllCustomFields, GetAllCustomFiledsSuccessEvent} from '../../configurable-fields'
import moment from 'moment'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model'
import {People} from '../../authorization-module/people/model'

import {DownloadAnyFile} from '../../common-misc'
import {searchUserRoleWithPagination} from '../../authorization-module/user-role/duck/service'
import RelatedTabpane from './relatedAsset/RelatedTabpane'
import {AssetRelatedContext} from '../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import {TicketRelatedContext} from '../../Preventive/ContextAPI/TicketRelatedContext'
import {KnowledgeRelatedContext} from '../../Preventive/ContextAPI/KnowledgeRelatedContext'
import {WorkOrder} from '../../msp-wo/wo-management'
import {WorkFlow} from '../../serviceCatalog/workflow/Service/Model'
import {WorkflowRelatedSelectContext} from '../../Preventive/ContextAPI/WorkflowRelatedSelectContext'
import {getAllWorkflowNoRedux} from '../../serviceCatalog/workflow/Service/service'

const mapStateToProps = (state: StoreState) => {
    return {
        incidents: state.incidentState?.incidents || [],
        impacts: state.impacts,
        urgencies: state.urgencies,
        priority: state.priorities,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        statuses: state.statuses,
        userRolesAndPermissions: state.userRolesAndPermissions,
        generalIncidentSetting: state.generalIncidentSetting,
        customFields: state.customFields
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    searchIncidents: () => Promise<number>
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    addIncident: (incident: Incident) => Promise<Incident>
    getAllStatus: (status: string | undefined) => Promise<number>
    getAllPriority: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllCustomFields: (value: string) => Promise<number>
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const { Option } = Select
const { TextArea } = Input
const IncidentForm: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const [myToggleInput, setMyToggleInput] = useState<boolean>(false)
    const [myStatus, setMyStatus] = useState<TicketStatus | undefined>(getTicketStatus(props.statuses, 'Incident', 'New'))
    const [myPriority, setMyPriority] = useState<PriorityMatrix>()
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [subcategoryName, setSubcategoryName] = useState<string[]>([])
    const [items, setItems] = useState<string[] | undefined>([])
    const [requester, setRequester] = useState<People | undefined>()
    const [requesterSuggestions, setRequesterSuggestions] = useState<People[]>([])
    const [tempCategory, setTempCategory] = useState<string | undefined>(undefined)
    const [tempSubcategory, setTempSubcategory] = useState<string | undefined>(undefined)
    const [tempItem, setTempItem] = useState<string | undefined>(undefined)
    const [tempAssignTo, setTempAssignTo] = useState<string | undefined>(undefined)
    const [user, setUser] = useState<People | undefined>()
    const [tempAssignGroup, setTempAssignGroup] = useState<SupportTeam>()
    const [loading, setLoading] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [validateCustomField, setValidateCustomField] = useState<boolean>(false)

    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [myCategory, setMyCategory] = useState<string[]>([])
    let mySupportTeamName: string[] = []
    const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [isRelateOpen, setIsRelateOpen] = useState<boolean>(false)
    const [isVisibleRequester, setIsVisibleRequester] = useState<boolean>(false)
    const [tempFieldDetails, setTempFieldDetails] = useState<FieldDetail[]>()
    const [validateEmail, setValidateEmail] = useState<boolean>(false)
    const testEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const { AssetRelatedState, GetAssetRelated } = useContext<any>(AssetRelatedContext)
    const { TicketRelatedState, GetTicketRelated } = useContext<any>(TicketRelatedContext)
    const { KnowledgeRelatedState, GetKnowledgeRelated } = useContext<any>(KnowledgeRelatedContext)
    const { WorkflowRelatedSelectState, GetWorkflowRelatedSelect } = useContext<any>(WorkflowRelatedSelectContext)
    const [isCheckTicketType, setIsCheckTicketType] = useState<boolean>(false)
    const [workflowList, setWorkflowList] = useState<WorkFlow[]>([])
    const [ticketType, setTicketType] = useState<string>('Incident')

    useEffect(() => {
        props.form.setFieldsValue({
            description: ''
        })
        // fetchDataToImpact().catch((err) => message.error(`Failed loading initial impact data. ${err}`))
        // fetchDataToUrgency().catch((err) => message.error(`Failed loading initial urgency data. ${err}`))
        // fetchDataToStatus(undefined).catch((err) => message.error(`Failed loading initial status data. ${err}`))
        // fetchPriority().catch((err) => message.error(`Failed loading initial priority data. ${err}`))
        // props.getAllCategory().catch((err) => message.error(`Failed loading all category data. ${err}`))
        // props.getAllSubCategory().catch((err) => message.error(`Failed loading all subcategory data. ${err}`))
        // props.getAllItems().catch((err) => message.error(`Failed loading all items data. ${err}`))
        // props.getAllSupportTeamByPermission().catch((err) => message.error(`Failed loading all support team data. ${err}`))
        // props.getAllStatus('all').catch((err) => message.error(`Failed fetching all status. ${err}`))
        // fetchTicketTypeAndChannel()
        const promiseAll: any[] = []
        promiseAll.push(props.getAllSupportTeamByPermission())
        promiseAll.push(props.getAllCategory())
        promiseAll.push(fetchDataToImpact())
        promiseAll.push(fetchDataToUrgency())
        promiseAll.push(fetchPriority())
        promiseAll.push(props.getAllSubCategory())
        promiseAll.push(props.getAllItems())
        promiseAll.push(props.getAllStatus('all'))
        promiseAll.push(props.getAllGeneralIncidentSetting())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Failed fetching all Data. ${err}`)
        })
        GetAssetRelated([])
        GetTicketRelated([])
        GetKnowledgeRelated([])
        GetWorkflowRelatedSelect([])
        getAllWorkflowNoRedux().then((data) => {
            if (data) {
                setWorkflowList(data)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (sessionStorage.getItem('cloneData') && props.supportTeamsWithPermission) {
            const templete = JSON.parse(sessionStorage.getItem('cloneData') || '')
            if (templete) {
                console.log(templete)
                resolvePriority(templete?.priority?.impact?.id, templete?.priority?.urgency?.id).then((res) => {
                    setMyPriority(res)
                }).catch((err) => {
                    message.error(`${err}`)
                })

                // if (templete?.requesterId.fullName.length >= 3) {
                //     searchUserRoleWithPagination(templete?.requesterId.fullName).then((res) => {
                //         setRequesterSuggestions(res.data)
                //         const requester = res.data.find((it) => it.id?.toString() === templete?.requesterId.id?.toString())
                //         setRequester(requester)
                //     })
                // }

                const targetSupportTeams: any = props.supportTeamsWithPermission.find((supportTeam: SupportTeam) => {
                    return supportTeam.name === templete?.assignmentGroup.value
                })
                if (templete?.assignmentGroup?.id) {
                    setTempAssignGroup(templete?.assignmentGroup)
                    setAssignee(targetSupportTeams?.assignees)
                }

                setTempAssignTo(templete?.assignedTo.fullName)
                const target = targetSupportTeams?.assignees?.find(it => it.users.id?.toString() === templete?.assignedTo?.id?.toString())
                setUser(target?.users)

                // setTempCategory(templete?.categoryId?.name)
                // const mySubcategories = props.subCategories.filter((subcategory: SubCategory) => {
                //     return subcategory.category === templete?.categoryId?.name && subcategory.active
                // })
                // setSubcategoryName(mySubcategories.map((subcategory: SubCategory) => subcategory.subCategory))

                // const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === templete?.subcategoryId?.name && item.category === templete?.categoryId?.name && item.active)
                // setItems(itemsInSubcat.map((item: Item) => item.item))
                // setTempSubcategory(templete?.subcategoryId?.name)

                // setTempItem(templete?.itemId?.name)
                // setMyToggleInput(true)
                // toggleInput()
                setFieldsValue({
                    topic: templete?.topic,
                    description: templete?.description,
                    // requester: templete?.requesterId?.fullName,
                    Impact: templete?.priority?.impact?.id,
                    Urgency: templete?.priority?.urgency?.id,
                    // Channel: templete?.channelId?.id,
                    'Ticket Type': templete?.ticketType?.id
                })
            }
        }
    }, [sessionStorage.getItem('cloneData'), props.supportTeamsWithPermission])

    useEffect(() => {
        const ticketType = props.generalIncidentSetting.ticketTypes?.find(it => it.id === props.form.getFieldValue('Ticket Type'))
        if (ticketType?.value === 'Request') {
            setIsCheckTicketType(true)
        } else {
            setIsCheckTicketType(false)
        }
        if (ticketType) {
            setTicketType(ticketType.value)
            fetchCustomFields(ticketType.value)
            fetchDataToStatus(ticketType.value)
        }
        setTempAssignGroup(undefined)
        resetFields(['Assignment_group', 'Assigned_to'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('Ticket Type')])
    
    useEffect(() => {
        if (props.statuses) {
            setMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            if (sessionStorage.getItem('cloneData')) {
                const templete = JSON.parse(sessionStorage.getItem('cloneData') || '')
                if (templete) {
                    if (templete?.assignmentGroup.id && templete?.assignedTo.id) {
                        changeMyStatus(getTicketStatus(props.statuses, ticketType, 'InProgress'))
                    } else if (templete?.assignmentGroup.id && !templete?.assignedTo.id) {
                        changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
                    }
                }
            }
        }
    }, [props.statuses])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsNonDesktop(true)
        } else {
            setIsNonDesktop(false)
        }
    }, [isNonDesktop])

    useEffect(() => {
        const temMyCategory: string[] = []
        if (props.categories) {
            props.categories.forEach((category: Categories) => {
                if (category.active) {
                    temMyCategory.push(category.name)
                }
            })
            setMyCategory(temMyCategory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    const fetchDataToStatus = (status: string | undefined) => {
        return props.getAllStatus(status)
    }

    const fetchDataToImpact = () => {
        return props.getAllImpact()
    }

    const fetchDataToUrgency = () => {
        return props.getAllUrgency()
    }

    const fetchPriority = () => {
        return props.getAllPriority()
    }

    const fetchTicketTypeAndChannel = () => {
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
    }

    const fetchCustomFields = (value: string) => {
        props.getAllCustomFields(value).catch(() => {
            const CustomFieldEmpty: CustomFileds = {
                ticketType: props.form.getFieldValue('Ticket Type'),
                fields: []
            }
            dispatch(GetAllCustomFiledsSuccessEvent.build(CustomFieldEmpty))
        })
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const saveIncident = (incident: Incident) => {
        props.addIncident(incident)
            .then((res) => {
                // message.success('You have successfully saved the data.')
                sessionStorage.removeItem('cloneData')
                Modal.success({
                    title: <b style={{ color: '#323276' }}>Success</b>,
                    content: renderResultCreateTicket(res.number!!),
                    okText: 'OK',
                    okType: 'danger',
                    onOk: () => {
                        window.location.href = '/IncidentList'
                    },
                    onCancel: () => {
                        window.location.href = '/IncidentList'
                    }
                })
                setLoading(false)
                setIsLoading(false)
            })
            .catch((err) => {
                message.error(`You have unSuccessfully save the data. ${err}`)
                setLoading(false)
                setIsLoading(false)
            })
    }

    const renderResultCreateTicket = (incidentNumber: string) => {
        return <div>
            <p style={{ color: '#323276', fontWeight: 500, fontSize: '15px' }}>You have successfully saved the data<br></br></p>
            <p style={{ color: '#2F80ED', fontWeight: 500 }}>Ticket Number: {incidentNumber}</p>
        </div>
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (err) {
                console.log(' err');
                (props.customFields.fields || []).forEach((obj: CustomFiled) => {
                    if (obj.isRequireFields === true && !obj.defaultValue) {
                        setValidateCustomField(true)
                    }
                })
            } else {
                setIsLoading(true)
                // console.log('no err')
                const isStatusNew: boolean = false
                let check: boolean = false
                let checkTemp: boolean = false
                const channel = props.generalIncidentSetting.channels?.find(it => it.id === props.form.getFieldValue('Channel'))
                const ticketType = props.generalIncidentSetting.ticketTypes?.find(it => it.id === props.form.getFieldValue('Ticket Type'))
                if (validateEmail) {
                    setValidateCustomField(true)
                    checkTemp = true
                }
                if (tempFieldDetails) {
                    tempFieldDetails.forEach((obj) => {
                        if (obj.isRequireFields === true && (obj.value === undefined || obj.value === null || obj.value === '')) {
                            setValidateCustomField(true)
                            checkTemp = true
                        }
                    })
                }
                const fieldDetails: FieldDetail[] = (props.customFields.fields || []).map((obj: CustomFiled) => {
                    if (obj.isRequireFields === true && !obj.defaultValue && !tempFieldDetails) {
                        setValidateCustomField(true)
                        check = true
                    }
                    const generalIncidentSetting: GeneralIncidentSetting = {
                        id: ticketType?.id
                    }
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox') {
                            if (getFieldValue(obj.name)) {
                                if (typeof getFieldValue(obj.name) !== 'string') {
                                    value = getFieldValue(obj.name).join('|')
                                } else {
                                    value = getFieldValue(obj.name)
                                }
                            }
                        } else if (obj.inputType === 'DatePicker') {
                            value = moment(getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            value = getFieldValue(obj.name)
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        ticketId: undefined,
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        ticketTypeId: generalIncidentSetting,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields)
                    }
                    return fieldDetail
                })
                const setting: IncidentSetting[] = [
                    {
                        incidentId: undefined,
                        incGeneralSetting: {
                            id: channel?.id
                        }
                    }, {
                        incidentId: undefined,
                        incGeneralSetting: {
                            id: ticketType?.id
                        }
                    }]
                // console.log(values)
                const ticketRelatedId = TicketRelatedState.map((it) => {
                    return it.id
                })
                const kmrelateModel: {
                    id: number,
                    ticketNumber: string
                }[] = []
                KnowledgeRelatedState.map((it) => {
                    kmrelateModel.push({ id: it.id, ticketNumber: it.ticketNumber })
                })

                const convertDataToWo: WorkOrder[] = WorkflowRelatedSelectState.map((it: WorkFlow) => {
                    const findData = workflowList.find((data) => data.name === it.name)
                    let findAssignmentgroupId: SupportTeam | undefined
                    let supportTeamName: string | undefined = ''
                    let assignName: string | undefined = ''
                    let assignId: string | undefined = ''
                    let statusWorkflow: string | undefined = 'Assigned'
                    if (findData) {
                        const findWorkflowNodes = findData?.nodes.find((data) => data.type === 'WorkOrder')
                        findAssignmentgroupId = props.supportTeamsWithPermission.find((data) => { return data.name === findWorkflowNodes?.properties.supportTeam!! })
                        if (findWorkflowNodes) {
                            supportTeamName = findWorkflowNodes?.properties.supportTeam!!
                            assignName = findWorkflowNodes?.properties.assignee!!
                            assignId = findWorkflowNodes?.properties.assigneeId!!
                        }
                    }
                    if (supportTeamName && assignName) {
                        statusWorkflow = 'InProgress'
                    }
                    return {
                        number: undefined,
                        topic: values.topic,
                        description: values.description,
                        priority: myPriority?.priority?.id!!,
                        status: statusWorkflow,
                        type: ticketType?.value,
                        appointmentDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                        supportTeam: supportTeamName || undefined,
                        assignee: assignName || undefined,
                        assigneeId: assignId || undefined,
                        userId: {
                            id: requester?.id
                        },
                        estimateDay: 0,
                        estimateHour: 0,
                        estimateMinute: 0,
                        customFields: it.customFields,
                        workflowId: it.id,
                        workflowName: it.name,
                        workflowNumbers: it.workflowNumber,
                        assignmentGroupId: findAssignmentgroupId?.id!! || undefined
                    } as WorkOrder
                })
                const incident: Incident = {
                    // number: ticketNumber,
                    subject: values.topic,
                    description: values.description,
                    company: requester?.company?.companyName || undefined,
                    category: tempCategory || values.Categories,
                    subcategory: tempSubcategory || values.subcategory,
                    item: tempItem || values.Item,
                    supportTeam: tempAssignGroup || null,
                    user: tempAssignGroup && user ? user || null : null,
                    priority: myPriority,
                    ticketStatus: myStatus!!,
                    pendingReason: undefined,
                    pendingUntil: undefined,
                    people: requester!!,
                    viewed: isStatusNew,
                    incidentSetting: setting,
                    customField: myToggleInput ? fieldDetails : tempFieldDetails,
                    assetRelated: AssetRelatedState,
                    relatedTicket: ticketRelatedId,
                    relatedKm: kmrelateModel,
                    workflows: ticketType?.value === 'Request' ? convertDataToWo : undefined
                }
                console.log(incident)
                console.log(check)
                console.log(checkTemp)
                if (check) {
                    if (!myToggleInput) {
                        setIsLoading(false)
                        message.error('Please fill in the required fields')
                    } else {
                        checkSaveIncident(incident)
                    }
                } else if (checkTemp) {
                    if (!myToggleInput) {
                        setIsLoading(false)
                        message.error('Please fill in the required fields')
                    } else {
                        checkSaveIncident(incident)
                    }
                } else {
                    checkSaveIncident(incident)
                }
            }
        })
    }

    const checkSaveIncident = (incident: Incident) => {
        if (fileList.length > 0 && fileList.length <= 5) {
            FSS.putFile(fileList, '/incident/', (uploadLink: UploadLink[]) => {
                incident.uploadLink = uploadLink?.map((data) => {
                    return {
                        name: data.name,
                        url: data.url,
                        type: data.type
                    } as UploadLink
                })
                saveIncident(incident)
                setValidateCustomField(false)
            })
        } else if (fileList.length > 5) {
            setIsLoading(false)
            message.error('Upload File Max 5')
        } else {
            saveIncident(incident)
            setValidateCustomField(false)
        }
    }

    const toggleInput = async () => {
        setMyToggleInput(!myToggleInput)
        const currentAssignmentGroup = props.form.getFieldValue('Assignment_group')
        const currentAssignTo = props.form.getFieldValue('Assigned_to')
        if (currentAssignmentGroup) {
            const findData = props.supportTeamsWithPermission?.find((data) => { return data.name === currentAssignmentGroup })
            setTempAssignGroup(findData)
        }
        if (currentAssignTo) {
            const findData = props.supportTeamsWithPermission?.find((data) => { return data.name === currentAssignmentGroup })
            const findDataAssign = findData?.assignees.find((dataAssign) => { return dataAssign.id?.toString() === currentAssignTo.toString() })
            setUser(findDataAssign?.users)
            setTempAssignTo(currentAssignTo)
        }
        const ticketType = props.generalIncidentSetting.ticketTypes?.find(it => it.id === props.form.getFieldValue('Ticket Type'))
        const fieldDetails: FieldDetail[] = (props.customFields.fields || []).map((obj) => {
            const generalIncidentSetting: GeneralIncidentSetting = {
                id: ticketType?.id
            }
            let value = ''
            if (obj.name) {
                if (obj.inputType === 'Checkbox') {
                    if (getFieldValue(obj.name)) {
                        if (typeof getFieldValue(obj.name) !== 'string') {
                            value = getFieldValue(obj.name).join('|')
                        } else {
                            value = getFieldValue(obj.name)
                        }
                    }
                } else if (obj.dataType?.toLowerCase() === 'email') {
                    value = getFieldValue(obj.name)
                    if (value) {
                        if (!testEmail.test(value)) {
                            setValidateEmail(true)
                        } else {
                            setValidateEmail(false)
                        }
                    }
                } else if (obj.inputType === 'DatePicker') {
                    value = moment(getFieldValue(obj.name)).format('YYYY-MM-DD')
                } else {
                    value = getFieldValue(obj.name)
                }
            }
            const fieldDetail: FieldDetail = {
                ticketId: undefined,
                id: obj.id,
                name: obj.name,
                value: value,
                ticketTypeId: generalIncidentSetting,
                label: obj.label,
                description: obj.description,
                inputType: obj.inputType,
                dataType: obj.dataType,
                selectOption: obj.selectOption,
                defaultValue: obj.defaultValue,
                placeholder: obj.placeholder,
                isRequireFields: Boolean(obj.isRequireFields)
            }
            return fieldDetail
        })

        if (fieldDetails) {
            setTempFieldDetails(fieldDetails)
        }

        if (tempFieldDetails) {
            (tempFieldDetails || fieldDetails).forEach((data) => {
                if (data.value && data.name) {
                    const obj = {}
                    obj[data.name] = data.value
                    setFieldsValue(obj)
                    const CurrentFieldsDetails = props.form.getFieldValue(obj[data.name])
                    if (CurrentFieldsDetails) {
                        setFieldsValue(obj)
                    }
                }
            })
        }
        if (sessionStorage.getItem('cloneData')) {
            const templete = JSON.parse(sessionStorage.getItem('cloneData') || '')
            if (templete) {
                setFieldsValue({
                    Assignment_group: templete?.assignmentGroup?.value,
                    Assigned_to: templete?.assignedTo?.value
                    // Categories: templete?.categoryId?.name,
                    // subcategory: templete?.subcategoryId?.name,
                    // Item: templete?.itemId?.name
                })

                const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
                    return supportTeam.name === templete?.assignmentGroup.value
                })
                if (templete?.assignmentGroup?.id) {
                    setTempAssignGroup(templete?.assignmentGroup)
                }

                targetSupportTeams.forEach((res) => {
                    setAssignee(res.assignees)
                })
                setTempAssignTo(templete?.assignedTo.fullName)
                const target = targetSupportTeams[0]?.assignees?.find(it => it.users.id?.toString() === templete?.assignedTo.id.toString())
                console.log(target)
                setUser(target?.users)

                // setTempCategory(templete?.categoryId?.name)
                // const mySubcategories = props.subCategories.filter((subcategory: SubCategory) => {
                //     return subcategory.category === templete?.categoryId?.name && subcategory.active
                // })
                // setSubcategoryName(mySubcategories.map((subcategory: SubCategory) => subcategory.subCategory))

                // const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === templete?.subcategoryId?.name && item.category === templete?.categoryId?.name && item.active)
                // setItems(itemsInSubcat.map((item: Item) => item.item))
                // setTempSubcategory(templete?.subcategoryId?.name)

                // setTempItem(templete?.itemId?.name)
            }
        }
        setValidateCustomField(false)
    }

    const changeMyStatus = (newStatus: TicketStatus): void => {
        setMyStatus(newStatus)
    }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impacts?.find(it => it.id === myImpact)?.id!!
        const urgency = props.urgencies?.find(it => it.id === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriority(impact, urgency).then((res) => {
                setMyPriority(res)
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    const FilterSupportTeam = async (e: SelectValue) => {
        await props.form.setFieldsValue({
            Assigned_to: null
        })
        const target = props.supportTeamsWithPermission.find(it => it.name === e)
        setTempAssignGroup(target)
        setUser(undefined)
        changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
        if (props.form.getFieldValue('Assignment_group') === '') {
            changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            props.form.setFields({
                Assignment_group: null,
                Assigned_to: null
            })
        }
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: undefined
                })
                changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
                setAssignee([])
            }
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e).catch((err) => message.error(`Failed filter support team. ${err}`))
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignGroup(undefined)
            setTempAssignTo(undefined)
            setUser(undefined)
        }
    }

    const changeAssigned = (e: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')

        if (e === '') {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignTo(undefined)
            setAssignee([])
        } else {
            if (currentAssignGroup) {
                changeMyStatus(getTicketStatus(props.statuses, ticketType, 'InProgress'))
            } else {
                console.log('here')
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')
        const target = assignee.find(it => it.id?.toString() === value)
        setUser(target?.users)
        if (assignee) {
            const isAssignee = assignee.find(it => it.id?.toString() === value)
            if (!isAssignee && !currentAssignGroup) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                changeMyStatus(getTicketStatus(props.statuses, ticketType, 'New'))
            } else if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                changeMyStatus(getTicketStatus(props.statuses, ticketType, 'Assigned'))
            }
        }
    }

    const FilterMyCategory = (e: SelectValue) => {
        const mySubcategories = props.subCategories.filter((subcategory: SubCategory) => {
            return subcategory.category === e && subcategory.active
        })
        setSubcategoryName(mySubcategories.map((subcategory: SubCategory) => subcategory.subCategory))
        resetFields(['subcategory', 'Item'])
        setItems(undefined)
        const currentCategory = props.form.getFieldValue('Categories')
        setTempCategory(currentCategory)
    }

    const checkCategoryField = (valueInCategory: SelectValue) => {
        setTempSubcategory(undefined)
        setTempItem(undefined)
        if (valueInCategory) {
            FilterMyCategory(valueInCategory)
        } else {
            props.form.setFieldsValue({
                subcategory: undefined,
                Item: undefined
            })
            setTempCategory(undefined)
            setSubcategoryName([])
            setItems([])
        }
    }

    const checkSubcategoryField = (valueInSubcategory: SelectValue) => {
        setTempItem(undefined)
        const currentSubcategory = props.form.getFieldValue('subcategory')
        setTempSubcategory(currentSubcategory)
        if (valueInSubcategory) {
            FilterMyItems(valueInSubcategory)
        } else {
            props.form.setFieldsValue({
                Item: undefined
            })
            setTempSubcategory(undefined)
            setItems([])
        }
    }

    const FilterMyItems = (e: SelectValue) => {
        const currentSubcategory = props.form.getFieldValue('subcategory')
        setTempSubcategory(currentSubcategory)
        resetFields(['Item'])
        const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === e && item.category === props.form.getFieldValue('Categories') && item.active)
        setItems(itemsInSubcat.map((item: Item) => item.item))
    }

    const selectedItem = (e) => {
        setTempItem(e)
    }

    const onSelectRequest = (value: SelectValue) => {
        const requester = requesterSuggestions.find((it) => it.id?.toString() === value)
        setRequester(requester)
    }

    const onSearchRequester = (searchText: string) => {
        if (searchText.length >= 3) {
            searchUserRoleWithPagination(searchText).then((res) => {
                setRequesterSuggestions(res.data)
            })
        }
    }

    const validateRequester = (_: any, value: any, callback) => {
        if (value) {
            if (requesterSuggestions) {
                let isSuggested
                if (sessionStorage.getItem('cloneData')) {
                    isSuggested = requesterSuggestions.find((it) => it.fullName === value) !== undefined
                } else {
                    isSuggested = requesterSuggestions.find((it) => it.id === value) !== undefined
                }

                if (!isSuggested && isNaN(Number(value))) {
                    // setRequester(undefined)
                    callback(<span> This requester does not exist in the system. <span className='feck-link' onClick={() => {
                        setIsVisibleRequester(true)
                    }}>Add requester</span></span>)
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    // const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
    //     if (!((file.url) as string).includes('jpg')) {
    //         setPreviewVisible(false)
    //     } else {
    //         setPreviewVisible(true)
    //     }
    //     setPreviewImage(file.url || file.preview)
    // }
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        // if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
        if (file.type.includes('image')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only File type : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const modalConfirmCancel = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                sessionStorage.removeItem('cloneData')
                window.location.href = '/IncidentList'
            }
        })
    }

    const validateTopicAndDes = (_rule: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            // const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            // const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (CheckValue.length >= 3) {
                callback()
            } else {
                callback('Enter more than 3 characters')
            }
            // if (pattern.test(CheckValue)) {
            //     if (CheckValue.length >= 3) {
            //         callback()
            //     } else {
            //         callback('Enter more than 3 characters')
            //     }
            // } else {
                // eslint-disable-next-line
                // if (format.test(CheckValue)) {
                //     callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                // } else {
                //     callback('Enter more than 3 characters')
                // }
            // }
        } else {
            callback()
        }
    }

    const customFieldLayout = () => {
        let rowContents: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        const contents = (props.customFields.fields || []).reduce((acc: JSX.Element[], obj, index) => {
            rowContents.push(
                <div>
                    <Col span={12} lg={12} md={12} sm={24} xs={24} key={index} style={{ marginBottom: 10 }}>
                        {obj.inputType === 'Checkbox' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Checkbox.Group style={{ width: '100%', minHeight: 20 }}>
                                        <Row>
                                            {
                                                obj.selectOption.map((it, i) => {
                                                    return <Col span={8} key={i}>
                                                        <Checkbox value={it.option}>{it.option}</Checkbox>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DatePicker' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? moment(obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DropdownSelect' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? (obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Select style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} allowClear>
                                        {
                                            obj.selectOption.map((it, i) => {
                                                return <Option value={it.option} key={i}>{it.option}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'InputNumber' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? Number(obj.defaultValue) : undefined,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <InputNumber min={0} style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'RadioButton' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Radio.Group style={{ width: '100%', minHeight: 20 }}>
                                        {
                                            obj.selectOption.map((it, i) => {
                                                return <Radio value={it.option} key={i}>{it.option}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'TextBox' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue || undefined,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <Input placeholder={obj.placeholder as string || undefined} />
                                )}
                            </Form.Item>
                        ) : null}
                    </Col>
                </div>
            )
            if (index % 2 === 1) {
                acc.push(
                    <>
                        <Row gutter={16}>{rowContents}</Row>
                    </>
                )
                rowContents = []
            }
            return acc
        }, [])
        contents.push(<Row>{rowContents}</Row>)

        return (
            <div>
                {contents}
            </div>
        )
    }

    const dynamicInputFields = <>
        {
            !myToggleInput ? (<></>) : (<>
                {props.supportTeamsWithPermission.length !== 0 ? (<span style={{ display: 'none' }}>{
                    mySupportTeamName = props.supportTeamsWithPermission.map((supportTeam: SupportTeam) => {
                        return supportTeam.name
                    })
                }</span>
                ) : null}
                <Row gutter={16}>
                    <Col span={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item label="Assignment group">
                            {getFieldDecorator('Assignment_group', { initialValue: tempAssignGroup?.name })(
                                <AutoComplete
                                    dataSource={(mySupportTeamName || []).map((it) => {
                                        return it
                                    })}
                                    onChange={(e) => checkAssignee(e)}
                                    onSelect={(e) => FilterSupportTeam(e)}
                                    onBlur={(e) => onBlurTeam(e)}
                                    allowClear={true}
                                    placeholder={'Select an assignment group'}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item label="Assigned to">
                            {getFieldDecorator('Assigned_to', { initialValue: tempAssignTo })(
                                <AutoComplete
                                    dataSource={assignee?.map((item, index: number) => {
                                        return <Option value={item.id?.toString()}
                                            key={index}>{item.users.fullName}</Option>
                                    })}
                                    onChange={changeAssigned}
                                    onFocus={() => FilterAssignee()}
                                    onBlur={(e) => onBlurAssignee(e)}
                                    allowClear={true}
                                    placeholder={'Select an assignee'}

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8} lg={8} md={8} sm={24} xs={24} style={{ marginRight: 38 }}>
                        <Form.Item label={'Category'}>
                            {getFieldDecorator('Categories', { initialValue: tempCategory })(
                                <Select
                                    placeholder="Select a category"
                                    onSelect={(e) => FilterMyCategory(e)} key={0}
                                    onChange={(e) => checkCategoryField(e)}
                                    // value={tempCategory}
                                    allowClear={true}
                                >
                                    {[...new Set(myCategory)].map((item: string, index: number) => {
                                        return <Option value={item}
                                            key={index}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={7} lg={7} md={7} sm={24} xs={24} style={{ marginRight: 38 }}>
                        {subcategoryName.length !== 0 ? (<>
                            <Form.Item label={'Subcategory'}>
                                {getFieldDecorator('subcategory', { initialValue: tempSubcategory })(
                                    <Select
                                        placeholder="Select a subcategory"
                                        onSelect={(e) => FilterMyItems(e)} key={1}
                                        onChange={(e) => checkSubcategoryField(e)}
                                        allowClear={true}
                                    >
                                        {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                            return <Option value={item}
                                                key={index}>{item}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </>) : null}
                    </Col>
                    <Col span={7} lg={7} md={7} sm={24} xs={24}>{items !== undefined && items?.length > 0 ? (<>
                        <Form.Item label={'Item'}>
                            {getFieldDecorator('Item', { initialValue: tempItem })(
                                <Select
                                    placeholder="Select an item"
                                    key={2}
                                    onSelect={(e) => selectedItem(e)}
                                    onChange={(e) => selectedItem(e)}
                                    allowClear={true}
                                >
                                    {([...new Set(items)]?.map((item: string, index: number) => {
                                        if (item) {
                                            return <Option value={item}
                                                key={index}>{item}</Option>
                                        }
                                    }))}
                                </Select>
                            )}
                        </Form.Item>
                    </>
                    ) : null}</Col>
                </Row>
                {getFieldValue('Ticket Type')
                    ? customFieldLayout() : null}
                <br />
            </>)
        }
    </>

    const onRelateClose = () => {
        setIsRelateOpen(false)
    }
    const onRelateOpen = () => {
        setIsRelateOpen(true)
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item onClick={() => sessionStorage.removeItem('cloneData')}>
                    <Link to={'/IncidentList'}>Ticket Management</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>New Ticket</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Spin spinning={loading}>
                <Row gutter={20}>
                    <Col lg={17} xs={24}>
                        {isNonDesktop
                            ? <Row>
                                <Col>
                                    <Button onClick={onRelateOpen} style={{
                                        color: 'blue',
                                        border: 'none',
                                        float: 'right',
                                        marginBottom: '10px'
                                    }}>Related</Button>
                                </Col>
                            </Row>
                            : null}
                        <Card style={{ minHeight: 700, minWidth: 300 }}>
                            <div>
                                <span> Ticket Status : {myStatus?.status}</span>
                                <span style={{ marginLeft: 20 }}>
                                    {props.priority.map((priority: PriorityLevel, index) => {
                                        return (
                                            myPriority?.priority?.name === priority.name ? (
                                                <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }}
                                                    key={index}>{myPriority.priority?.name}</Tag>
                                            ) : null
                                        )
                                    })}
                                </span>
                            </div>
                            <br />
                            <div style={{ padding: 20 }}>
                                <Form onSubmit={handleSubmit} layout="vertical">
                                    <Row>
                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Requester">
                                                    {getFieldDecorator('requester',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                whitespace: true,
                                                                message: 'Requester is required'
                                                            },
                                                            // , { min: 3, message: 'Enter more than 3 characters' },
                                                            { validator: validateRequester }
                                                            ]
                                                        })(
                                                            // <Input type="text" placeholder="Name" maxLength={255} />
                                                            <AutoComplete
                                                                dataSource={(requesterSuggestions || []).map((it: People) => {
                                                                    return {
                                                                        value: it.id?.toString(),
                                                                        text: it.firstName + ' ' + it.lastName
                                                                    } as DataSourceItemObject
                                                                })}
                                                                style={{ width: '100%' }}
                                                                onSelect={onSelectRequest}
                                                                onSearch={onSearchRequester}
                                                                onChange={() => setRequester(undefined)}
                                                                placeholder="Name"
                                                            />
                                                        )}

                                                </Form.Item>
                                                <div
                                                    style={{ color: '#828282', display: requester ? undefined : 'none' }}>
                                                    <span style={{ marginRight: 5 }}><Icon
                                                        type="mail" /> {requester?.email ? requester.email.length > 0 ? requester.email : '-' : '-'}</span>
                                                    <span style={{ marginRight: 5 }}><Icon
                                                        type="phone" /> {requester?.tel ? requester.tel.length > 0 ? requester.tel : '-' : '-'}</span>
                                                    <span style={{ marginRight: 5 }}><Icon
                                                        type="bank" /> {requester?.company ? requester?.company?.companyName.length > 0 ? requester?.company.companyName : '-' : '-'} </span>
                                                </div>

                                                <Modal
                                                    title="Add new requester"
                                                    width={898}
                                                    visible={isVisibleRequester}
                                                    footer={null}
                                                    onCancel={() => {
                                                        setIsVisibleRequester(false)
                                                    }}
                                                >
                                                    <WrappedFormPeople getValueCreate={() => { setIsVisibleRequester(false) }} isEdit={false} clearData={isVisibleRequester} isLoading={() => { }} />
                                                </Modal>
                                            </Col>
                                            <Col span={6} lg={6} md={6} sm={24} xs={24}>
                                                <Form.Item label="Impact">
                                                    {getFieldDecorator('Impact', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an impact"
                                                            onSelect={() => callApiGetPriorities()}>
                                                            {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                                                return <Option value={item.id}
                                                                    key={index}>{item.name}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} lg={6} md={6} sm={24} xs={24}>
                                                <Form.Item label="Urgency">
                                                    {getFieldDecorator('Urgency', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an urgency"
                                                            onSelect={() => callApiGetPriorities()}>
                                                            {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                                                return <Option value={item.id}
                                                                    key={index}>{item.name}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Channel">
                                                    {getFieldDecorator('Channel', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an channel">
                                                            {props.generalIncidentSetting.channels?.length === 0 ? null : (props.generalIncidentSetting.channels?.map((item, index: number) => {
                                                                return <Option value={item.id}
                                                                    key={index}>{item.value}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Ticket Type">
                                                    {getFieldDecorator('Ticket Type', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an Ticket Type">
                                                            {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                                                return <Option value={item.id}
                                                                    key={index}>{item.value}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>
                                                <Form.Item label="Topic">
                                                    {getFieldDecorator('topic',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Topic is required'
                                                            },
                                                            { validator: validateTopicAndDes }]
                                                        })(
                                                            <Input type="text" placeholder="Topic" maxLength={255} />
                                                        )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ height: 140 }}>
                                                <Form.Item label="Description">
                                                    {getFieldDecorator('description',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Description is required'
                                                            }, { validator: validateTopicAndDes }]
                                                        })(
                                                            <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} placeholder={'Description'} />
                                                        )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24}
                                                style={{ marginBottom: '20px', marginTop: '10px' }}>
                                                <Upload {...uploadProps}
                                                    listType="picture"
                                                    className={'upload-list-inline'}
                                                    fileList={fileList}
                                                    onPreview={(e: any) => handlePreview(e)}
                                                >
                                                    <Button disabled={fileList.length >= 5}>
                                                        <Icon type="upload" /> Upload
                                                    </Button>
                                                </Upload>
                                            </Col>
                                        </Row>
                                        <Modal visible={previewVisible} footer={null}
                                            onCancel={() => setPreviewVisible(false)}>
                                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Row>

                                    <Row>
                                        {dynamicInputFields}
                                    </Row>

                                    <Row>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24}>
                                            {!myToggleInput ? (
                                                <Button onClick={toggleInput} style={{
                                                    width: '100%',
                                                    borderColor: validateCustomField ? 'red' : undefined
                                                }}>Show More
                                                    <Icon type="down" style={{ marginBottom: '10px' }} /></Button>
                                            ) : (
                                                <Button onClick={toggleInput} style={{ width: '100%' }}>Show Less
                                                    <Icon type="up" style={{ marginBottom: '5px' }} /></Button>
                                            )}
                                        </Col>
                                    </Row>

                                    <br />
                                    <Form.Item style={{ float: 'right', marginTop: 10 }}>
                                        <Button htmlType="button"
                                            onClick={() => modalConfirmCancel()}
                                            style={{ marginRight: 10 }}>
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit"
                                            disabled={!checkRolesState(RoleType.Incident, 'CreateIncident')}
                                            loading={isLoading}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Card>
                    </Col>
                    {!isNonDesktop
                        ? <Col span={7} lg={7} md={7} sm={24} xs={24}>
                            <Card style={{ minHeight: 700, maxHeight: 800 }}>
                                <h2 className="sub-title">Related</h2>
                                <RelatedTabpane ticketTypeIsRequest={isCheckTicketType}/>
                            </Card>
                        </Col>
                        : null}
                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={onRelateClose}
                        visible={isRelateOpen}
                        width={window.innerWidth >= 768 ? '50%' : '75%'}
                    >
                        <Col span={24}>
                            <Card style={{ minHeight: 700, maxHeight: 800 }}>
                                <h2 className="sub-title">Related</h2>
                                <RelatedTabpane ticketTypeIsRequest={isCheckTicketType}/>
                            </Card>
                        </Col>
                    </Drawer>
                </Row>
            </Spin>
        </>
    )
}

const IncidentFormMain = Form.create({ name: 'IncidentForm' })(IncidentForm)

export default connect(mapStateToProps,
    {
        searchIncidents,
        getAllCategory,
        getAllSubCategory,
        getAllItems,
        getAllSupportTeamByPermission,
        addIncident,
        getAllStatus,
        getAllPriority,
        getAllUrgency,
        getAllImpact,
        getAllGeneralIncidentSetting,
        getAllCustomFields: getAllCustomFields
    })(IncidentFormMain)
