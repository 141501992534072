import { axiosFactory as commonAxiosFactory, ResponseContentDetails, ResponseDetails, axiosNonAuthFactory } from '../common-rest'

import { BASE_URL } from './service'
import { AxiosRequestConfig } from 'axios'

export const axiosFactory = commonAxiosFactory(BASE_URL)
export const axiosLoginFactory = axiosNonAuthFactory(BASE_URL)

export function axiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseDetails<T>>(url, config)
}
export function axiosGetWithPagination<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseContentDetails<T>>(url, config)
}
export function axiosDelete<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().delete<ResponseDetails<T>>(url, config)
}
export function axiosPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().post<ResponseDetails<T>>(url, data, config)
}
export function axiosPut<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().put<ResponseDetails<T>>(url, data, config)
}
export function axiosPatch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().patch<ResponseDetails<T>>(url, data, config)
}

// non Auth factory
export function axiosNonAuthGet<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosLoginFactory().get<ResponseDetails<T>>(url, config)
}
export function axiosNonAuthGetWithPagination<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosLoginFactory().get<ResponseContentDetails<T>>(url, config)
}
export function axiosNonAuthDelete<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosLoginFactory().delete<ResponseDetails<T>>(url, config)
}
export function axiosNonAuthPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosLoginFactory().post<ResponseDetails<T>>(url, data, config)
}
export function axiosNonAuthPut<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosLoginFactory().put<ResponseDetails<T>>(url, data, config)
}
export function axiosNonAuthPatch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosLoginFactory().patch<ResponseDetails<T>>(url, data, config)
}

export function axiosPostWithPagination<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().post<ResponseContentDetails<T>>(url, data, config)
}
