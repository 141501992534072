import { UploadFile } from 'antd/lib/upload/interface'

export class UploadLink {
    name: string
    url: string
    type: string
    fullName?: string
    constructor(name: string, url: string, type: string) {
        this.name = name
        this.url = url
        this.type = type
    }

    convertToUploadFile(): UploadFile {
        const uploadFile: UploadFile = {
            uid: this.name,
            name: this.name,
            status: 'done',
            url: this.url,
            type: this.type,
            size: 0
        }
        return uploadFile
    }
}
