import {Dispatch} from 'redux'
import {axiosGet, axiosPatch, axiosPost} from '../../rest'
import {Job} from './model'
import {GetAllJobSuccessEvent, JobCreatedEvent} from './state-event'
import { decryptBody, encryptBody } from '../../../common-misc/encryptBody'
import { herderPostPatch } from '../../../common-misc'

export const getAllJob = (woId?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Job[]>('/api/jobs/related/' + woId)
            dispatch(GetAllJobSuccessEvent.build(decryptBody(response.data.data!!)))
            return decryptBody(response.data.data!!)
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const addJob = (job: Job[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Job>('/api/jobs/list', job)
            dispatch(JobCreatedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const checkIsCurrentJob = (index: number, data: Job[], expandedRow: Job) => {
    let currentJob = false
    let currentJobIndex = 0
    let selectedIndex
    (data || []).forEach((job, index) => {
        if (job.id === expandedRow.id) {
            selectedIndex = index
        }
    });
    (data || []).forEach((job, jobIndex) => {
        if (job.jobStatus !== 'Closed' && currentJobIndex === jobIndex) {
            currentJobIndex = jobIndex
            if (currentJobIndex === selectedIndex) {
                currentJob = true
            }
        }
        if ((job.jobStatus === 'Closed' || job.jobStatus === 'Cancelled') && currentJobIndex === jobIndex) {
            currentJobIndex = currentJobIndex + 1
        }
    })
    return currentJob
}

export const getCurrentJobNo = (data: Job[]) => {
    let currentJobNo
    let currentJobIndex = 0;
    (data || []).forEach((job, jobIndex) => {
        if ((job.jobStatus !== 'Closed' && currentJobIndex === jobIndex) ||
            (job.jobStatus !== 'Rejected' && currentJobIndex === jobIndex) ||
            (job.jobStatus !== 'Cancelled' && currentJobIndex === jobIndex)) {
            currentJobIndex = jobIndex
            currentJobNo = job.jobNo
        }
        if ((job.jobStatus === 'Closed' || job.jobStatus === 'Rejected' || job.jobStatus === 'Cancelled') && currentJobIndex === jobIndex) {
            currentJobIndex = currentJobIndex + 1
        }
    })
    return currentJobNo
}

export async function updateJobStatus (id: string, job: Object) : Promise<Job> {
    try {
        const encryptData = encryptBody(JSON.stringify(job))
        const response = await axiosPatch<Job>(`/api/jobs/${id}`, encryptData, herderPostPatch)
        // dispatch(JobUpdatedEvent.build(response.data.data!!))
        return decryptBody(response.data.data!!) 
    } catch (err) {
        console.log(id)
        console.log(job)
        console.log(err)
        throw err
    }
}

export async function updateFirstJobStatus (woId?: string) : Promise<Job> {
    try {
        const response = await axiosPatch<Job>(`/api/jobs/first-tier/${woId}`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export async function updateJobSignature (id: string, Signature: Object) : Promise<Job> {
    try {
        const response = await axiosPatch<Job>(`/api/jobs/${id}`, Signature)
        // dispatch(JobUpdatedEvent.build(response.data.data!!))
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

// export async function cancelAllJobs (ids: string[]) : Promise<Job[]> {
//     try {
//         const response = await axiosPost<Job[]>('/api/jobs/Cancel', ids)
//         return response.data.data!!
//     } catch (err) {
//         console.log(err)
//         throw err
//     }
// }

export const updateJobWithExpenseCost = async (id?: string, job?: Job) => {
    try {
        const encryptData = encryptBody(JSON.stringify(job))
        const response = await axiosPatch<Job>(`/api/jobs/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}

// export const getAllJobWoList = async (woId?: string) => {
//     try {
//         const response = await axiosPost<Job>(`/api/jobs/${woId}/jobs`)
//         return decryptBody(response.data.data!!)
//     } catch (err) {
//         console.log(err)
//         throw err
//     }
// }

export const getAllJobwithListWorkflow = async (woId?: string) => {
    try {
        const response = await axiosGet(`/api/jobs/related/${woId}`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}
