/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Card, Col, message, Modal, Row, Select, Table} from 'antd'
import {Link} from 'react-router-dom'
import {Space} from '../../../common-components/Space'
import {FormComponentProps} from 'antd/lib/form'
import {AssetItems} from './model'
import {getAssetItemsByLocation} from './service'
import {getSearchAllFieldValue, searchFetch} from '../../asset-management'
import PMSignature from './PMSignature'
import {convertCamelCaseToSentenceCase} from '../../../knowledge-management/util'

type Props = FormComponentProps

const { Option } = Select

const AssetsForSign: React.FC<Props> = (props: Props) => {
    const [assetsForSign, setAssetsForSign] = useState<AssetItems[]>([])
    const [locations, setLocations] = useState<string[]>()
    const [filterLocation, setFilterLocation] = useState<string[]>()
    const [groupOfAsset, setGroupOfAsset] = useState<Object>({})
    const [isShowSignModal, setIsShowSignModal] = useState<boolean>(false)
    const [selectedRowsArray, setSelectedRowsArray] = useState<any>([])
    const [searchValue, setSearchValue] = useState<string>()
    const [totalItem, setTotalItem] = useState<number>()
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)

    useEffect(() => {
        getSearchAllFieldValue('location').then(res => {
            setLocations(res)
            setFilterLocation(res.slice(0, 20))
        }).catch(err => {
            message.error(`You have unSuccessfully get the data location. ${err}`)
        })
    }, [])

    useEffect(() => {
        if (!isShowSignModal) {
            fetch(searchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowSignModal])

    const handleOnSearch = (location) => {
        searchFetch(location, locations || [], setFilterLocation)
    }

    const handleOnChange = (location) => {
        if (location === undefined) {
            setFilterLocation(locations?.slice(0, 20)!!)
            setGroupOfAsset({})
            setSelectedRowsArray([])
            setAssetsForSign([])
            setTotalItem(0)
        } else {
            setSearchValue(location)
            setSelectedRowsArray([])
            fetch(location)
        }
    }

    const fetch = (location) => {
        getAssetItemsByLocation(location).then(res => {
            setAssetsForSign(res)
            setTotalItem(res.length)
        }).catch(err => {
            message.error(`You have unSuccessfully get the data. ${err}`)
        })
    }

    const createOption = (dataSource: string[]) => {
        const options = dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
        return options
    }

    useEffect(() => {
        if (window.innerWidth > 765) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth])

    const columns = [
        {
            title: 'Serial No',
            dataIndex: 'serialNo',
            key: 'serialNo'
        },
        {
            title: 'Status',
            dataIndex: 'pmStatus',
            key: 'pmStatus',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed'
                }, {
                    text: '-',
                    value: '-'
                }
            ],
            onFilter: (value, record) => filter(value, record),
            render: (record) => {
                return <>{record === 'Completed' ? 'Completed' : '-' }</>
            }
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (data: string) => (
                <>
                    <div>
                        {console.log(data)}
                        <p>{data ? convertCamelCaseToSentenceCase(data) : null}</p>
                    </div>
                </>
            )
        }
    ]

    function filter(v, r) : boolean {
        if (v === r.pmStatus) {
            return true
        } else {
            if (v === '-') {
                if (r.pmStatus === null) {
                    return true
                }
            }
            return false
        }
    }

    const groupAsset = (selectedRows) => {
        const group = selectedRows.reduce((r, a) => {
            r[a.woNo] = [...r[a.woNo] || [], a]
            return r
        }, {})
        setGroupOfAsset(group)
    }

    const rowSelection = {
        selectedRowKeys: selectedRowsArray,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowsArray([...selectedRowKeys])
            groupAsset(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: record.pmStatus !== 'Completed',
            name: record.pmStatus
        }),
        onSelectAll: (selected, selectedRow, changeRow) => {
            if (selected) {
                const arr: string[] = []
                const arrObj: AssetItems[] = [];
                (assetsForSign || []).forEach((r, i) => {
                    if (r.pmStatus === 'Completed') {
                        ++i
                        arr.push(i.toString())
                        arrObj.push(r)
                    }
                })
                setSelectedRowsArray([...arr])
                groupAsset(arrObj)
            } else {
                setSelectedRowsArray([])
            }
        }
    }

    const handleClickSign = () => {
        if (selectedRowsArray.length > 0) {
            setIsShowSignModal(true)
        } else {
            setIsShowSignModal(false)
        }
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        setTotalItem(extra.currentDataSource.length)
    }

    const handleIsSign = (isSign) => {
        if (isSign) {
            setSelectedRowsArray([])
        }
    }

    return (<>
        <Breadcrumb separator='>' className={'content'}>
            <Breadcrumb.Item><Link to={'/Preventive'}>Preventive Maintenance</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to={'/locationForSign'}>Select location for sign</Link></Breadcrumb.Item>
        </Breadcrumb>
        <Space size={20} />
        <Card>
            <div>
                <div>
                    <h3 className='main-title'>Select location for sign</h3>
                </div>
                <Row gutter={[8, 8]}>
                    <Col span={ isChangeSize ? 5 : 24}>
                        <Select
                            placeholder='Select location of asset'
                            style={{ width: '100%' }}
                            showSearch
                            // optionFilterProp="children"
                            filterOption={(input, option) =>
                            option.props.children!!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                            onSearch={(value) => { handleOnSearch(value) }}
                            onChange={(value) => { handleOnChange(value) }}
                        >{createOption(filterLocation || [])}
                        </Select>
                    </Col>
                    <Col span={ isChangeSize ? 19 : 24}>
                        <Button style={{ float: 'right', width: 100 }} onClick={handleClickSign}>Sign</Button>
                    </Col>
                </Row>
                <br />
                <div style={{ overflow: 'auto'}}>
                    <div style={{border: '1px solid #e8e8e8', borderBottom: 'none', padding: 10}}>
                        <span>{totalItem} Items found</span>
                    </div>
                    <div style={{border: '1px solid #e8e8e8', borderBottom: 'none', overflow: 'auto'}} >
                        <Table
                            rowKey= 'rowKey'
                            columns={columns}
                            dataSource={assetsForSign}
                            rowSelection={rowSelection}
                            pagination={{
                                // pageSize: 10,
                                total: totalItem,
                                showSizeChanger: true
                            }}
                            onChange={onTableChange}
                        />
                    </div>
                </div>
                <Modal
                    visible={isShowSignModal}
                    onCancel={() => { setIsShowSignModal(false) }}
                    footer={null}
                    width={'auto'}
                    style={{ padding: '5%', minWidth: 1024}}
                >
                    <PMSignature assetItems={groupOfAsset} isClosed={!isShowSignModal} isForPrint={false} handleSign={handleIsSign}/>
                </Modal>
            </div>
        </Card>
    </>)
}
export default AssetsForSign
