import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const DepartmentContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const DepartmentReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_Department':
        return action.payload
    case 'ADD_Department':
        return [...state, action.payload]
    default:
        return state
    }
}

export const DepartmentProvider = (props: props) => {
    const [DepartmentState, DepartmentDispatch] = useReducer(DepartmentReducer, initialState)

    const GetDepartment = (payload) => {
        DepartmentDispatch({ type: 'GET_Department', payload })
    }

    const AddDepartment = (payload) => {
        DepartmentDispatch({ type: 'ADD_Department', payload })
    }

    return (
        <DepartmentContext.Provider value={{ DepartmentState, GetDepartment, AddDepartment }}>
            {props.children}
        </DepartmentContext.Provider>
    )
}
