import React from 'react'
import {Row, Col} from 'antd'
import {SubOrderDetailList} from '../Services/model'

interface Param {
    data: SubOrderDetailList[]
}

type props = Param
const SubOrderDetail = (props: props) => {
    return (
        <div style={{backgroundColor: 'F2F2F2', padding: 5}}>
            <p ><u>Add-ons</u></p>
            {props.data.map((it, index) => {
                return <Row key={index}>
                    <Col span={8}>
                        {it.title}
                    </Col>
                    <Col span={8}>
                        {it.value}
                    </Col>
                    <Col span={8}>
                        {it.price}
                    </Col>
                </Row>
            })}
        </div>
    )
}

export default SubOrderDetail
