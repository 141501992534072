import { Empty, Tag, Icon, Row, Col, Modal, Pagination } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { AssetRelatedContext } from '../../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { TicketRelatedContext } from '../../../Preventive/ContextAPI/TicketRelatedContext'
import { WorkFlow } from '../../../serviceCatalog/workflow/Service/Model'
import { KnowledgeRelatedContext } from '../../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { WorkflowRelatedSelectContext } from '../../../Preventive/ContextAPI/WorkflowRelatedSelectContext'
import { WoRalateIncidentCard } from '../../../msp-wo/wo-management'
import { WorkflowRelatedContext } from '../../../Preventive/ContextAPI/WorkflowRelatedContext'

interface Param {
    tabpaneName?: string
}

type Props = Param;
const { confirm } = Modal
const SelectedDataRelated: React.FC<Props> = (props: Props) => {
    const { DeleteAssetRelated, AssetRelatedState } = useContext<any>(AssetRelatedContext)
    const { DeleteTicketRelated, TicketRelatedState } = useContext<any>(TicketRelatedContext)
    const { DeleteWorkflowRelatedSelect, WorkflowRelatedSelectState } = useContext<any>(WorkflowRelatedSelectContext)
    const {GetWorkflowRelated} = useContext<any>(WorkflowRelatedContext)
    const { DeleteKnowledgeRelated, KnowledgeRelatedState } = useContext<any>(KnowledgeRelatedContext)
    const [ticketCurrentPage, setTicketCurrentPage] = useState<number>(1)
    const [assetCurrentPage, setAssetCurrentPage] = useState<number>(1)
    const [kmCurrentPage, setKmCurrentPage] = useState<number>(1)
    const [woCurrentPage, setWoCurrentPage] = useState<number>(1)

    useEffect(() => {
        if (WorkflowRelatedSelectState.length === 0) {
            GetWorkflowRelated([])
        } else {
            GetWorkflowRelated(WorkflowRelatedSelectState)
        }
    }, [WorkflowRelatedSelectState])

    const deleteAssetRelatedTicket = (relateId: number) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm delete of this data ?',
            onOk() {
                if (props.tabpaneName === 'Asset') {
                    DeleteAssetRelated(relateId)
                } else {
                    DeleteKnowledgeRelated(relateId)
                }
            },
            onCancel() { }
        })
    }

    const deleteTicketRelatedTicket = (ticketId: number) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm delete of this data ?',
            onOk() {
                DeleteTicketRelated(ticketId)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const deleteWorkflowRelated = (id: number) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm delete of this data ?',
            onOk() {
                DeleteWorkflowRelatedSelect(id)
                WorkflowRelatedSelectState.find((data) => {
                    if (data.id === id) {
                        data.customFields.forEach((obj) => {
                            obj.value = undefined
                        })
                    }
                })
            },
            onCancel() {}
        })
    }

    const GenerateDataRelatedCard = () => {
        if (props.tabpaneName === 'Ticket') {
            const tempTicketSliceShow = TicketRelatedState.slice((ticketCurrentPage - 1) * 10, ticketCurrentPage * 10)
            return TicketRelatedState.length > 0 ? <div> {tempTicketSliceShow.map((it) => {
                return (
                    <div key={it.number} className="TicketRelateCard">
                        <Row>
                            <Col span={22} lg={22} md={20}>
                                <Row>
                                    <Col span={24}>
                                        <span> Ticket Number : {it.number}<Tag style={{ backgroundColor: it.priority.priority.tagColor, color: '#fff', marginLeft: 10 }}>{it.priority.priority.name}</Tag></span>
                                    </Col>
                                    <Col span={24}>
                                        <span> Topic : {it.subject}</span>
                                    </Col>
                                    <Col span={24}>
                                        <span> Status : {it.ticketStatus.status}</span>
                                    </Col>
                                    <Col span={24}>
                                        <span> Requester : {it.people.fullName}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2} lg={2} md={4} className='TicketRelateCard-DeleteButton'> <Icon onClick={(e) => { deleteTicketRelatedTicket(it.id) }} type="delete" /></Col>
                        </Row>
                    </div>
                )
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        } else if (props.tabpaneName === 'Asset') {
            const tempAssetSliceShow = AssetRelatedState.slice((assetCurrentPage - 1) * 10, assetCurrentPage * 10)
            return AssetRelatedState.length > 0 ? <div> { tempAssetSliceShow.map((it) => {
                return (
                    <div key={it.serialNumber} className="TicketRelateCard">
                        <Row>
                            <Row>
                                <Row>
                                    <Col span={21} xl={21} lg={19} md={18}>
                                        <span> Serial Number : {it.serialNumber}</span>
                                    </Col>
                                    <Col span={2} xl={2} lg={2} md={4} className='TicketRelateCard-DeleteButton'>
                                        <Icon onClick={(e) => { deleteAssetRelatedTicket(it.id) }} type="delete" />
                                    </Col>
                                </Row>
                                <Col span={24}>
                                    <span> Owner ID : {it.people?.employeeId} - {it.people?.fullName}</span>
                                </Col>
                                <Col span={24}>
                                    <span> Location : {it.location} </span>
                                </Col>
                                <Col span={24}>
                                    <span> Asset Status : {it.status}</span>
                                </Col>
                            </Row>
                        </Row>
                    </div>
                )
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        } else if (props.tabpaneName === 'Workflow') {
            const tempWoRelatedSliceShow = WorkflowRelatedSelectState.slice((woCurrentPage - 1) * 10, woCurrentPage * 10)
            return WorkflowRelatedSelectState.length > 0 ? <div>{tempWoRelatedSliceShow.map((related: WorkFlow, index) => {
                return <div className="TicketRelateCard" key={index}>
                    <Row>
                        <Col span={22} lg={22} md={20}>
                            <Row>
                                <Col span={24}>
                                    <span> Workflow Number : {related.workflowNumber} </span>
                                </Col>
                                <Col span={24}>
                                    <span> Workflow Name : {related.name} </span>
                                </Col>
                                <Col span={24}>
                                    <span> Decription : {related.description === 'null' ? '-' : related.description} </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={2} lg={2} md={4} className='TicketRelateCard-DeleteButton'> <Icon onClick={(e) => deleteWorkflowRelated(related.id!!)} type="delete" /></Col>
                    </Row>
                </div>
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        } else {
            const tempKnowledgeSliceShow = KnowledgeRelatedState.slice((kmCurrentPage - 1) * 10, kmCurrentPage * 10)
            return KnowledgeRelatedState.length > 0 ? <div> {tempKnowledgeSliceShow.map((it, index) => {
                return <div className="TicketRelateCard" key={index}>
                    <Row>
                        <Row>
                            <Row>
                                <Col span={22} xl={21} lg={19} md={18}>
                                    <span> Knowledge Number : {it.ticketNumber} </span>
                                </Col>
                                <Col span={2} xl={2} lg={2} md={4} className='TicketRelateCard-DeleteButton'>
                                    <Icon onClick={() => deleteAssetRelatedTicket(it.ticketNumber)} type="delete" />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22}>
                                    <span> Topic : {it.topic}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22}>
                                    <span> Created By : {it.createdByDisplay}  </span>
                                </Col>
                            </Row>
                        </Row>
                    </Row>
                    <br />
                    <Row>
                        <Col span={2}>
                            <Icon type="like" theme="filled" />
                        </Col>
                        <Col span={2}>
                            <span>{it.helpful ? it.helpful : 0}</span>
                        </Col>
                        <Col span={2}>
                            <Icon type="dislike" theme="filled" />
                        </Col>
                        <Col span={2}>
                            <span>{it.notHelpful ? it.notHelpful : 0}</span>
                        </Col>
                        <Col span={2}>
                            <Icon type="eye" theme="filled" />
                        </Col>
                        <Col span={2}>
                            <span>{it.views ? it.views : 0}</span>
                        </Col>
                        <Col span={2}>
                            <Icon type="check-square" />
                        </Col>
                        <Col span={2}>
                            <span>{it.used ? it.used : 0}</span>
                        </Col>
                    </Row>
                </div>
            })}
            </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    }

    const GeneratePagination = () => {
        if (props.tabpaneName === 'Ticket' && TicketRelatedState.length > 0) {
            return <Pagination total={TicketRelatedState.length} pageSize={10} current={ticketCurrentPage} onChange={(page) => setTicketCurrentPage(page)} />
        } else if (props.tabpaneName === 'Asset' && AssetRelatedState.length > 0) {
            return <Pagination total={AssetRelatedState.length} pageSize={10} current={assetCurrentPage} onChange={(page) => setAssetCurrentPage(page)} />
        } else if (props.tabpaneName === 'Workflow' && WorkflowRelatedSelectState.length > 0) {
            return <Pagination total={WorkflowRelatedSelectState.length} pageSize={10} current={woCurrentPage} onChange={(page) => setWoCurrentPage(page)} />
        } else if (props.tabpaneName === 'Knowledge' && KnowledgeRelatedState.length > 0) {
            return <Pagination total={KnowledgeRelatedState.length} pageSize={10} current={kmCurrentPage} onChange={(page) => setKmCurrentPage(page)} />
        }
        return <></>
    }

    return (AssetRelatedState.length > 0 || TicketRelatedState.length > 0 || KnowledgeRelatedState.length > 0 || WorkflowRelatedSelectState.length > 0 ? <>
        <div style={{ minHeight: 600, overflowY: 'scroll', maxHeight: 600 }}>
            <GenerateDataRelatedCard />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10 }}>
            <GeneratePagination />
        </div>
    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
}

export default SelectedDataRelated
