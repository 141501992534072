import React, { useState } from 'react'
import { Col, Form, Button, Input } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { CommentResponseModel, addComment } from '.'

const { TextArea } = Input

interface Props extends FormComponentProps {
    knowledgeId: string
    replyOf: string
    getAllComment: Function
}

const ReplyForm: React.FC<Props> = (props: Props) => {
    const [replySubmitting, setReplySubmitting] = useState<boolean>(false)
    const { getFieldDecorator, getFieldValue, resetFields } = props.form
    const username = sessionStorage.getItem('username')

    const handleReplySubmit = () => {
        if (!getFieldValue('Reply')) {
            return
        }
        setReplySubmitting(true)
        const commentBody: CommentResponseModel = {
            knowledgeId: props.knowledgeId,
            content: getFieldValue('Reply'),
            replyOf: props.replyOf,
            lastModifiedBy: username || '',
            createdBy: username || ''
        }
        addComment(commentBody).then(response => {
            setReplySubmitting(false)
            props.getAllComment()
            resetFields()
        })
    }

    return (
        <Col>
            <Form.Item>
                { getFieldDecorator('Reply')(
                    <TextArea rows={2}/>
                )}
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" loading={replySubmitting} onClick={handleReplySubmit} type="primary">
                    Reply
                </Button>
            </Form.Item>
        </Col>
    )
}

const MyReplyForm = Form.create<Props>()(ReplyForm)

export default MyReplyForm
