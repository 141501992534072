import React, {useEffect, useState} from 'react'
import {Breadcrumb, Row, Col, Card, Icon, Button, Form, Input} from 'antd'
import { Space } from '../../common-components/Space'
import '../CustomStyle.css'
import {FormComponentProps} from 'antd/lib/form'
import {useParams} from 'react-router'
import {Link} from 'react-router-dom'
import {CreditCard, Order} from '../Services/model'
import {getOrderById} from '../Services/Service'

type Props = FormComponentProps
const PayInvoice = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [getOrder, setOrder] = useState<Order>()
    const { id }: any = useParams()

    useEffect(() => {
        getOrderById(id).then((res) => {
            if (res) {
                setOrder(res)
            }
        })
    }, [id])

    const HerderOrder = () => {
        return <div style={{textAlign: 'center', backgroundColor: '#F2F2F2', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
            <div style={{padding: 5}}>Pay Invoice</div>
        </div>
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                const convertData: CreditCard = {
                    nameCard: values.Name,
                    cardNumber: values.CardNumber,
                    expiryDate: values.ExpiryDate,
                    secureCode: values.SecureCode,
                    total: getOrder?.billing.amount!!
                }
                console.log(convertData)
            }
        })
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to="/Setting">Setting</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Logo & Display Company Name</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'Box'} style={{display: 'flex', justifyContent: 'center'}}>
                <Card title={HerderOrder()} className={'mycardtest'} style={{width: 750}}>
                    <div><p>Credit Card / Debit Card</p></div>
                    <Form onSubmit={handleSubmit} >
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div><span className={'TextRed'}>* </span> Name on Card</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('Name', {
                                        rules: [{ required: true, message: 'Please input your Name!' }]
                                    })(
                                        <Input placeholder="Name - Lastname" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div><span className={'TextRed'}>* </span> Card Number</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('CardNumber', {
                                        rules: [{ required: true, message: 'Please input your Card Number!' }]
                                    })(
                                        <Input placeholder="Card Number" addonAfter={<Icon type="setting" />}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div><span className={'TextRed'}>* </span> Expiry Date</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('ExpiryDate', {
                                        rules: [{ required: true, message: 'Please input your Expiry Date!' }]
                                    })(
                                        <Input placeholder="DD / YY" addonAfter={<Icon type="calendar" />}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div><span className={'TextRed'}>* </span> Secure Code</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('SecureCode', {
                                        rules: [{ required: true, message: 'Please input your Secure code!' }]
                                    })(
                                        <Input placeholder="Secure Code" addonAfter={<Icon type="calendar" />}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div>Total</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('Total', {
                                        initialValue: getOrder?.billing.amount || 0
                                    })(
                                        <Input placeholder="Total" disabled={true}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{textAlign: 'end'}}>
                            <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} > <Icon type="lock" /> Submit Payment</Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

const MyPayInvoice = Form.create<Props>({ name: 'PayInvoice' })(PayInvoice)
export default MyPayInvoice
