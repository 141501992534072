import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { PMNameAndAssetName } from '../Model'

export const AssetNameContext = createContext({})

const initialState: PMNameAndAssetName[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const AssetNameReducer = (state: PMNameAndAssetName[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_AssetName':
        sessionStorage.setItem('deskside_name', JSON.stringify(action.payload || []))
        return action.payload || [] as PMNameAndAssetName[]
    case 'ADD_AssetName':
        return [...state, action.payload]
    default:
        return state
    }
}

export const AssetNameProvider = (props: props) => {
    const [AssetNameState, AssetNameDispatch] = useReducer(AssetNameReducer, initialState)

    const GetAssetName = (payload: PMNameAndAssetName[]) => {
        AssetNameDispatch({ type: 'GET_AssetName', payload })
    }

    const AddAssetName = (payload: PMNameAndAssetName) => {
        AssetNameDispatch({ type: 'ADD_AssetName', payload })
    }

    return (
        <AssetNameContext.Provider value={{ AssetNameState, GetAssetName, AddAssetName }}>
            {props.children}
        </AssetNameContext.Provider>
    )
}
