import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const WorkflowRelatedContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const WorkflowRelatedReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_WorkflowRelated':
        return action.payload
    case 'ADD_WorkflowRelated':
        return [...state, action.payload]
    case 'UpdateWorkflowRelated':
        return state.filter((it) => it.id !== action.payload)
    case 'DELETE_WorkflowRelated':
        const filterWorkflowRelated = state.filter((it) => it.id !== action.payload)
        return filterWorkflowRelated
    default:
        return state
    }
}

export const WorkflowRelatedProvider = (props: props) => {
    const [WorkflowRelatedState, WorkflowRelatedDispatch] = useReducer(WorkflowRelatedReducer, initialState)

    const GetWorkflowRelated = (payload) => {
        WorkflowRelatedDispatch({ type: 'GET_WorkflowRelated', payload })
    }

    const AddWorkflowRelated = (payload) => {
        WorkflowRelatedDispatch({ type: 'ADD_WorkflowRelated', payload })
    }

    const UpdateWorkflowRelated = (payload) => {
        WorkflowRelatedDispatch({ type: 'UpdateWorkflowRelated', payload })
    }

    const DeleteWorkflowRelated = (payload) => {
        WorkflowRelatedDispatch({ type: 'DELETE_WorkflowRelated', payload })
    }
    return (
        <WorkflowRelatedContext.Provider value={{ WorkflowRelatedState, GetWorkflowRelated, AddWorkflowRelated, UpdateWorkflowRelated, DeleteWorkflowRelated }}>
            {props.children}
        </WorkflowRelatedContext.Provider>
    )
}
