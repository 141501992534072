/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useState} from 'react'
import {Col, Icon, Row, Table} from 'antd'
import moment from 'moment'
import {convertCamelCaseToSentenceCase} from '../../../knowledge-management/util'

interface Param {
    signAssets: Object
}
type Props = Param
let table: HTMLElement[] = []
const TableSignPM: React.FC<Props> = (props : Props) => {
    let no = 0; let wov = ''
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])

    useEffect(() => {
        renderTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.signAssets])

    const renderTable = () => {
        table = []
        for (const property in props.signAssets) {
            forceUpdate()
            const arrObj = props.signAssets[property]
            // @ts-ignore
            table.push(<div key={arrObj.serialNo}>
                <Row gutter={16} className='page-break'>
                    <Col span={12}>
                        <div className='BG_Gray' style={{ minHeight: 140 }}>
                            <p className='sub-title' style={{ textAlign: 'center' }}>Contact User</p>
                            <div style={{ paddingLeft: '1%' }}>
                                <h3 className='content'> Name : {arrObj[0].contactUserName}</h3>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="mail" /> : {arrObj[0].contactUserEmail}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="phone" /> : {arrObj[0].contactUserPhone}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="home" /> : {arrObj[0].contactUserLocation}</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='BG_Gray' style={{ minHeight: 140 }}>
                            <p className='sub-title' style={{ textAlign: 'center' }}>PM Team</p>
                            <Row>
                                <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                    <div style={{ paddingLeft: '1%' }}>
                                        <p className='content' > PM Name : {arrObj[0].engineerName} ({arrObj[0].engineerOnSite}) </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <span><Icon type="profile" />&nbsp;&nbsp;<b>Work Order : {property}</b></span><br/>
                        <Table
                            dataSource={arrObj}
                            columns={columns}
                            pagination={false}
                            rowKey='serialNo'
                        /><br/><br/><br/><br/>
                    </Col>
                </Row>
            </div>)
        }
    }

    const columns = [
        {
            title: <b>No.</b>,
            render: (data) => {
                if (data.woNo !== wov) { no = 0 }
                wov = data.woNo
                return (<>{++no}</>)
            },
            width: '5%'
        }, {
            title: <b>Serial No.</b>,
            dataIndex: 'serialNo',
            width: '16%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        }, {
            title: <b>PM Status</b>,
            dataIndex: 'pmStatus',
            width: '16%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        }, {
            title: <b>Reason</b>,
            dataIndex: 'reason',
            width: '16%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{convertCamelCaseToSentenceCase(data)}</p>
                    </div>
                </>
            )
        }, {
            title: <b>Note</b>,
            dataIndex: 'note',
            width: '16%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        }, {
            title: <b>Complete Date</b>,
            dataIndex: 'createdDatetime',
            width: '16%',
            render: (data) => (
                <>
                    <div>
                        {moment(data.createdDatetime).format('YYYY-MM-DD')}
                    </div>
                </>
            )
        }
    ]

    return (<>
        {table}
    </>)
}
export default TableSignPM
