export default class UserShareData {
    private static instance: UserShareData
    token?: string = undefined

    static getInstance(): UserShareData {
        if (!UserShareData.instance) {
            UserShareData.instance = new UserShareData()
        }
        return UserShareData.instance
    }
}
