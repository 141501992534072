import { CustomFiledAsset } from '.'
import {
    CustomFiledAssetStateEvent,
    GetAllCustomFiledAssetSuccessEvent
} from './state-event-asset'

export const CustomFiledAssetStateReducer = (state: CustomFiledAsset[] = [], event: CustomFiledAssetStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllCustomFiledAssetSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
