import { CustomFiledWithWorkFlow } from '.'
import {
    CustomFiledWithWorkFlowStateEvent, GetAllCustomFiledWithWorkFlowSuccessEvent, AddCustomFiledWithWorkFlowSuccessEvent,
    DeleteCustomFiledWithWorkFlowSuccessEvent, UpdateCustomFiledWithWorkFlowSuccessEvent
} from './state-event-custom-workflow'

export const CustomFiledWithWorkFlowStateReducer = (state: CustomFiledWithWorkFlow[] = [], event: CustomFiledWithWorkFlowStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllCustomFiledWithWorkFlowSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddCustomFiledWithWorkFlowSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteCustomFiledWithWorkFlowSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateCustomFiledWithWorkFlowSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
