import React, { useContext, useEffect, useState } from 'react'
import CustomSelect from '../CustomSelect'
import { AssetStateContext } from '../../ContextAPI/AssetStateContext'
import { BrandContext } from '../../ContextAPI/BrandContext'
import { ZoneContext } from '../../ContextAPI/ZoneContext'
import { PeopleContext } from '../../ContextAPI/UserHRContext'
import { CustomFiledAsset } from '../../../configurable-fields'
import { getAllAssetGroup, searchFetch } from '../../../msp-wo/asset-management'
import { AutoComplete, Checkbox, Col, DatePicker, Form, Icon, Input, Row, Select, Spin } from 'antd'
import { replaceName } from '../../../common-file'
import RenderFiled from '../../../configurable-fields/RenderFiled'
import moment from 'moment'
import { Asset, LocationBuilding } from '../../Model'
import { FieldDetail } from '../../../incident-management/incident'
import CustomInputGroupAsset from '../../../msp-wo/asset-management/CustomInputGroupAsset'
import CustomInputGroupAsset2 from '../../../msp-wo/asset-management/CustomInputGroupAsset2'
import { LocationBuildingContext } from '../../ContextAPI/LocationBuildingContext'
import { DataComputerNameContext } from '../../ContextAPI/DataComputerNameContext'
import { DataSerialNumberContext } from '../../ContextAPI/DataSerialNumberContext'
import { DataModelContext } from '../../ContextAPI/DataModelContext'
import CustomSelectGroupAsset from '../../../msp-wo/asset-management/CustomSelectGroupAsset'
import { AssetGroupContext } from '../../ContextAPI/AssetGroupContext'
import RenderFiledCopyCheckbox from '../../../configurable-fields/RenderFiledcopyCheckbox'
import { SetNoneAssetDetailFieldListContext } from './ContextAPI/SetNoneAssetDetailFieldList'
import { SetNoneCustomFieldListContext } from './ContextAPI/SetNoneCustomFieldList'

interface Param {
    form: any,
    isOriginalAsset: boolean
    CustomFiledAssetDetails: CustomFiledAsset[],
    serialNumber: string[],
    setSerialNumber: Function,
    computerName: string[],
    setComputerName: Function,
    postfix?: string
    isDisableItems?: boolean
    locationBuilding: LocationBuilding[],
    customHeader?: string,
    isFromSyncPM?: boolean,
    showCopyCheckbox?: boolean
}

type Props = Param

const { Option } = Select

const SyncPMFieldBox = (props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue, getFieldsValue } = props.form
    const { AssetStateState } = useContext<any>(AssetStateContext)
    const { BrandState } = useContext<any>(BrandContext)
    const [messageError, setMessageError] = useState('')
    const { PeopleState } = useContext<any>(PeopleContext)
    const { DataComputerNameState } = useContext<any>(DataComputerNameContext)
    const { DataSerialNumberState } = useContext<any>(DataSerialNumberContext)
    const { DataModelState } = useContext<any>(DataModelContext)
    const { ZoneState } = useContext<any>(ZoneContext)
    const { AssetGroupState } = useContext<any>(AssetGroupContext)
    const { SetNoneAssetDetailFieldListState, GetSetNoneAssetDetailFieldList } = useContext<any>(SetNoneAssetDetailFieldListContext)
    const { SetNoneCustomFieldListState, GetSetNoneCustomFieldList } = useContext<any>(SetNoneCustomFieldListContext)
    const postfix = props.postfix

    const customCheckDuplicateInput = (name: string,
        required: boolean,
        validator: Object[],
        maxLength?: number) => {
        return (
            <Row>
                <Col xl={24} md={24} style={{ marginTop: 10 }}>
                    <Col xl={10} md={24}>
                        <div style={{
                            marginRight: 8,
                            marginBottom: 8
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    {props.showCopyCheckbox ? <Col xl={14} md={24}>
                        <div style={{
                            marginTop: 4,
                            float: 'right'
                        }}>
                            <Checkbox value={replaceName(name)} onChange={(value) => handleCopyAsset(value, name)}>Set None</Checkbox>
                        </div>
                    </Col> : null}
                    <Col xl={24} md={24}>
                        <Form.Item
                            validateStatus={name === 'Serial Number' && messageError === 'Serial Number. is already exists. It must be unique. Please try another one.' ? 'error' : undefined}
                            help={name === 'Serial Number' && messageError !== '' ? messageError : undefined}
                        >
                            {getFieldDecorator(replaceName(name + postfix), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: 255, message: 'message max 255 characters' },
                                    ...validator
                                ]
                            })(
                                <Input placeholder={`${name}`} id={`${name}`} maxLength={maxLength} disabled={props.isDisableItems || SetNoneAssetDetailFieldListState.includes(replaceName(name))} />
                            )}
                        </Form.Item>

                    </Col>
                </Col>
            </Row>
        )
    }

    const customSelectWithFilter = (name: string,
        required: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        setState?: Function,
        maxInput?: number) => {
        return (
            <Row>
                <Col xl={24} md={24} style={{ marginTop: 10 }}>
                    <Col xl={10} md={24}>
                        <div style={{
                            marginRight: 8,
                            marginBottom: 8
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    {props.showCopyCheckbox ? <Col xl={14} md={24}>
                        <div style={{
                            marginTop: 4,
                            float: 'right'
                        }}>
                            <Checkbox value={replaceName(name)} onChange={(value) => handleCopyAsset(value, name)}>Set None</Checkbox>
                        </div>
                    </Col> : null}
                    <Col xl={24} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name + postfix), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: maxInput || 250, message: `message max ${maxInput || 250} characters` }
                                ]
                            })(
                                <Select placeholder={`Select ${name}`}
                                    onSearch={(value) => {
                                        searchFetch(value, fullData || [], setState!!)
                                    }}
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) =>
                                        String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                    }
                                    disabled={props.isDisableItems || SetNoneAssetDetailFieldListState.includes(replaceName(name))}
                                    showArrow={false}
                                >
                                    {(autoCompleteData || []).map((item, index) => {
                                        return <Option value={item} key={index} title={item}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Col>
            </Row>
        )
    }

    const customInput = (name: string,
        maxInput?: number,
        validator?: Object[],
        required: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        isITAsset?: boolean,
        setState?: Function,
    ) => {
        return (
            <Row>
                <Col xl={24} md={24} style={{ marginTop: 10 }}>
                    <Col xl={10} md={24}>
                        <div style={{
                            marginRight: 8,
                            marginBottom: 8
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    {props.showCopyCheckbox ? <Col xl={14} md={24}>
                        <div style={{
                            marginTop: 4,
                            float: 'right'
                        }}>
                            <Checkbox value={replaceName(name)} onChange={(value) => handleCopyAsset(value, name)}>Set None</Checkbox>
                        </div>
                    </Col> : null}
                    <Col xl={24} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name + postfix), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: maxInput || 250, message: `message max ${maxInput || 250} characters` },
                                    ...validator || []
                                ]
                            })(
                                autoCompleteData.length > 0 ? (
                                    <AutoComplete
                                        dataSource={autoCompleteData}
                                        placeholder={isITAsset && name === 'Asset Name' ? `${name}` + ', Computer Name' : `${name}`}
                                        id={`${name}`}
                                        onChange={(value) => {
                                            searchFetch(value, fullData || [], setState!!)
                                        }}
                                        disabled={props.isDisableItems || SetNoneAssetDetailFieldListState.includes(replaceName(name))}
                                    />
                                ) : (
                                    <Input
                                        placeholder={isITAsset && name === 'Asset Name' ? `${name}` + ', Computer Name' : `${name}`}
                                        id={`${name}`} maxLength={maxInput} disabled={props.isDisableItems || SetNoneAssetDetailFieldListState.includes(replaceName(name))} />
                                )
                            )}
                        </Form.Item>
                    </Col>
                </Col>
            </Row>
        )
    }

    const createOption = (dataSource: string[]) => {
        return dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
    }

    const customSelect = (name: string, options: string[], required: boolean) => {
        return (
            <Row>
                <Col xl={24} style={{ marginTop: 10 }}>
                    <Col xl={10} md={24}>
                        <div style={{
                            marginRight: 8,
                            marginBottom: 8
                        }}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={24} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name + postfix), {
                                initialValue: options[5],
                                rules: [
                                    { required: false, message: `${name} is required` }
                                ]
                            })(
                                <Select
                                    placeholder='Select Asset Status'
                                    style={{ width: '100%' }}
                                    disabled={props.isDisableItems}
                                >{createOption(options || [])}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Col>
            </Row>
        )
    }

    // const handleChangecheckboxCopy = (value) => {
    //     if (value.length) {
    //         value.map((it) => {
    //             const tempField = {}
    //             tempField[replaceName(it + postfix)] = undefined
    //             setFieldsValue(tempField)
    //         })
    //     }
    // }

    const handleCopyAsset = (value, name) => {
        const tempField = {}
        if (value.target.checked) {
            if (value.target.value === 'User_ID') {
                const relateFields = ['User Name', 'Department']
                tempField[replaceName(name + postfix)] = undefined
                setFieldsValue(tempField)
                relateFields.forEach((it) => {
                    tempField[replaceName(it + postfix)] = undefined
                    setFieldsValue(tempField)
                })
            } else if (value.target.value === 'Site') {
                const relateFields = ['Location', 'Building', 'Floor']
                tempField[replaceName(name + postfix)] = undefined
                setFieldsValue(tempField)
                relateFields.forEach((it) => {
                    tempField[replaceName(it + postfix)] = undefined
                    setFieldsValue(tempField)
                })
            } else {
                tempField[replaceName(name + postfix)] = undefined
                setFieldsValue(tempField)
            }
        } else {
            if (value.target.value === 'User_ID') {
                const relateFields = ['User Name', 'Department']
                tempField[replaceName(name + postfix)] = getFieldValue(replaceName(name + 'Ad'))
                setFieldsValue(tempField)
                relateFields.forEach((it) => {
                    tempField[replaceName(it + postfix)] = getFieldValue(replaceName(it + 'Ad'))
                    setFieldsValue(tempField)
                })
            } else if (value.target.value === 'Site') {
                const relateFields = ['Location', 'Building', 'Floor']
                tempField[replaceName(name + postfix)] = getFieldValue(replaceName(name + 'Ad'))
                setFieldsValue(tempField)
                relateFields.forEach((it) => {
                    tempField[replaceName(it + postfix)] = getFieldValue(replaceName(it + 'Ad'))
                    setFieldsValue(tempField)
                })
            } else {
                tempField[replaceName(name + postfix)] = getFieldValue(replaceName(name + 'Ad'))
                setFieldsValue(tempField)
            }
        }
    }

    return (
        <div className='syncPM-field-box'>
            <Row style={{ backgroundColor: '#D0D5DD', borderRadius: '5px 5px 0px 0px' }}>
                {props.customHeader ? <p className="main-title" style={{ margin: 12 }}>{props.customHeader}</p> : props.isOriginalAsset ? <p className="main-title" style={{ margin: 12 }}>Original Asset Detail </p> : <p className="main-title" style={{ margin: 12 }}>  <Icon type='edit' /> Adjust Asset Detail </p>}
            </Row>
            <div style={{ padding: 10 }}>
                <Checkbox.Group style={{ width: '100%' }} value={SetNoneAssetDetailFieldListState} onChange={(value) => GetSetNoneAssetDetailFieldList(value)}>
                    {customSelect('Asset Status', AssetStateState, true)}
                    {customCheckDuplicateInput('Asset Tag', false, [], 60)}
                    {customInput('Asset Name', undefined, [], false, props.computerName, DataComputerNameState, getFieldValue('Group') === 1, props.setComputerName)}
                    {customInput('Manufacturer', 60, [], false)}
                    {customSelectWithFilter('Model', false, DataModelState)}
                    {customSelectWithFilter('Brand', false, BrandState)}
                    {customSelectWithFilter('Type', false, (AssetGroupState || []).map((data) => {
                        return data.type
                    }))}
                    <CustomSelectGroupAsset form={props.form}
                        isPreview={false}
                        isEdit={false}
                        locationBuildingList={props.locationBuilding}
                        dataZone={ZoneState}
                        postfix={postfix}
                        fullWidth={true}
                        disableItem={props.isDisableItems}
                        showCopyCheckbox={props.showCopyCheckbox}
                        handleCopyAsset={handleCopyAsset} />
                    <CustomInputGroupAsset2 form={props.form}
                        isOpenModel={true}
                        isPreview={props.isDisableItems || false}
                        isEdit={!props.isDisableItems}
                        maxInput={255}
                        DataHR={PeopleState}
                        isFromSyncPM={props.isFromSyncPM}
                        postfix={postfix}
                        disableItem={props.isDisableItems}
                        fullWitdh={true}
                        showCopyCheckbox={props.showCopyCheckbox}
                        handleCopyAsset={handleCopyAsset} />
                    {customSelectWithFilter('Paired IT Asset', false, props.serialNumber, DataSerialNumberState, props.setSerialNumber)}
                </Checkbox.Group>
                <Row>
                    <Checkbox.Group style={{ width: '100%' }} value={SetNoneCustomFieldListState} onChange={(value) => GetSetNoneCustomFieldList(value)}>
                        <Col xl={24} md={24}>
                            {props.showCopyCheckbox ? <RenderFiledCopyCheckbox fields={props.CustomFiledAssetDetails} form={props.form} colSpan={24}
                                colSM={24} labelColSm={24} wrapperColSm={24} maxWidth={'100%'}
                                genCol={2} disableFields={props.isDisableItems || props.isOriginalAsset} isShowToggle={false} uniqueName={postfix} /> :
                                <RenderFiled fields={props.CustomFiledAssetDetails} form={props.form} colSpan={24}
                                    colSM={24} labelColSm={24} wrapperColSm={24} maxWidth={'100%'}
                                    genCol={2} disableFields={props.isDisableItems || props.isOriginalAsset} isShowToggle={false} uniqueName={postfix} />}
                        </Col>
                    </Checkbox.Group>
                </Row>
            </div>

        </div >
    )
}

export default SyncPMFieldBox