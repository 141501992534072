import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { nullOrEmptyObject } from '../../common-misc'
import { StoreState } from '../../store'
import { DataForChart } from '../model'
import { Empty } from 'antd'

const mapStateToProps = (state: StoreState) => {
    return {
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Params {
    Height?: number
    minHeight?:number
    myData?: DataForChart
    marginTop: string
    showLabels: boolean
    fixPosition?: string
    textInsideDoughnut?: boolean
    cutout?: number
}

type Props = Params & StateProps

const DoughnutChart: React.FC<Props> = (props: Props) => {
    const data = {
        labels: [
            'Red',
            'Green',
            'Yellow'
        ],
        datasets: [{
            data: [90, 50, 100],
            backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
            ],
            hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
            ]
        }]
    }
    
    const plugins = [{
        beforeDraw: function (chart: any) {
            const chartInstance = chart
            const height = chart.height
            const ctx = chart.ctx;
            ctx.restore();
            const fontSize = (height / 140).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            props.myData?.datasets.forEach(function (dataset, i) {
                const meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    const text = dataset.data[index];
                    const text2 = `Knowledge`
                    ctx.fillStyle = ('#323276')
                    ctx.fillText(text,centerX, centerY * 0.9);
                    ctx.fillText(text2, centerX, centerY * 1.1);
                });
            });
            ctx.save();
        }
    }]

    return (
        <div
            style={{ marginTop: `${props.marginTop}` }}
        >
            {!nullOrEmptyObject(props.myData?.labels)
                ? <Doughnut
                    data={props.myData?.labels ? props.myData : data}
                    height={props.Height !== undefined ? props.Height : 100}
                    // width={100}
                    // height={50}
                    // options={{ maintainAspectRatio: false }}
                    // width={100}
                    // options={{
                    //     rotation: 1 * Math.PI,
                    //     circumference: 1 * Math.PI,
                    //     // legend: {
                    //     //     display: false
                    //     // },
                    //     // tooltip: {
                    //     //     enabled: false
                    //     // },
                    //     cutoutPercentage: 120
                    // }}
                    options={{
                        legend: {
                            position: props.fixPosition ? props.fixPosition : 'bottom',
                            display: props.showLabels
                        },
                        tooltips: {
                            enabled: true
                        },
                        // tooltips: {
                        //     callbacks: {
                        //         label: function(tooltipItem) {
                        //             return tooltipItem
                        //         }
                        //     }
                        // }
                        cutoutPercentage: props.cutout ? props.cutout : 50
                    }}
                    plugins={props.textInsideDoughnut ? plugins:undefined}
                    
                    
                />
                : <div style={{height: props.minHeight!!}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, {

})(DoughnutChart)
