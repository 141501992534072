import { Col, Dropdown, Icon, Menu, Row, Select } from 'antd'
import React from 'react'
import { AssetGroups } from '../model'

interface Params {
    header: string
    groupList: AssetGroups[]
    selectTicketType?: string
    setTicketTypes: Function
    isDisabled?: boolean
    justify?: string
    isSubmit?: boolean
    required?: boolean
}

const { Option } = Select
const RenderSelectOption = (props: Params) => {

    const menu = () => {
        return (
            <Menu>
                {props.groupList.map((it, index) => {
                    return (<Menu.Item key={index} onClick={() => props.setTicketTypes(it.name, it.id!!)}>
                        <span>{it.name}</span>
                    </Menu.Item>)
                })}
            </Menu>
        )
    }

    return (
        <div style={{ display: 'flex', justifyContent: props.justify ? props.justify : 'left' }}>
            <span style={{ fontWeight: 'bold', color: '#323276', paddingTop: 3, marginRight: 10 }} className={props.required ? 'ant-form-item-required' : ''}>{props.header} : </span>
            {props.isDisabled ? <span style={{ paddingTop: 3 }}>{props.selectTicketType || '-'}</span> : <div>
                <Row>
                    <Select style={{ width: 250}} placeholder={'Select ' + props.header} value={props.selectTicketType} onChange={(value) => props.setTicketTypes(value)}>
                        {props.groupList.map((it) => {
                            return <Option key={it.id} value={it.name}> {it.name}</Option>
                        })}
                    </Select>
                </Row>
                <Row>
                    {props.isSubmit && !props.selectTicketType && !props.isDisabled ? <p style={{ color: 'red', marginTop: 5 }}>{props.header} is required</p> : null}
                </Row>
            </div>}
        </div>
    )
}

export default RenderSelectOption