import React from 'react'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { Col, Row, Tag } from 'antd'
import { waitingGetSessions } from '../../../guard/route'
import { Problem } from '../Model'

const mapStateToProps = (state: StoreState) => {
    return {

    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

}

interface Param {
    isPTask: boolean
    data?: any
}

type Props = StateProps & DispatchProps & Param;
const ProblemRow: React.FC<Props> = (props: Props) => {
    return (
        <div style={{ padding: 15, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgb(0 0 0 / 1%) 0px 6px 20px 0px', borderRadius: '5px', marginBottom: 10 }}>
            <div style={{ backgroundColor: props.data?.pbStatus === 'Assess' ? '#FFFAF1' : '#F5F9FF', borderRadius: '5px', padding: 10 }}>
                <Row>
                    <Col span={props.isPTask ? 3 : 2}>
                        <Link to={props.isPTask ? '/ProblemTaskDetailView/' + props.data?.id : '/ProblemDetailView/' + props.data?.id} target={props.isPTask ? '_blank' : ''} onClick={() => waitingGetSessions()}><b>{props.data?.number}</b></Link>
                    </Col>
                    <Col span={props.isPTask ? 3 : 2}>
                        <div style={{ color: '#323276' }}> <b>{props.data?.topic}</b></div>
                    </Col>
                    <Col span={props.isPTask ? 3 : 2}>
                        <Tag style={{ marginLeft: 10 }} color={props.data?.pbPriority.tagColor}>{props.data?.pbPriority.name}</Tag>
                    </Col>
                    <Col span={props.isPTask ? 15 : 18} style={{ textAlign: 'end' }}>
                        {props.data?.pbStatus === 'Assess' ? <Tag color='#ED9E28'>Awaiting Approval</Tag> : null}
                        {props.data?.viewed === true ? <Tag color="green">New</Tag> : null}
                    </Col>
                </Row>
            </div>
            <div style={{ padding: 10 }}>
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={props.isPTask ? 5 : 4}>
                                <p><b>Status : </b></p>
                            </Col>
                            <Col span={props.isPTask ? 19 : 20}>
                                <p> {props.data?.pbStatus} </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={props.isPTask ? 5 : 4}>
                                <p><b>Assigned To : </b></p>
                            </Col>
                            <Col span={props.isPTask ? 19 : 20}>
                                <p> {props.data?.assignmentGroupAndAssignedTo?.split('/')[1]} </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={props.isPTask ? 5 : 4}>
                                <p><b>Created : </b></p>
                            </Col>
                            <Col span={props.isPTask ? 19 : 20}>
                                <p> {props.data?.created} </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={props.isPTask ? 5 : 4}>
                                <p><b>Requester : </b></p>
                            </Col>
                            <Col span={props.isPTask ? 19 : 20}>
                                <p> {props.data?.requesterName} </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={props.isPTask ? 5 : 4}>
                                <span><b>Start Date : </b></span>
                            </Col>
                            <Col span={props.isPTask ? 19 : 20}>
                                <span> {props.data?.startDate}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={props.isPTask ? 5 : 4}>
                                <span><b>Due Date : </b></span>
                            </Col>
                            <Col span={props.isPTask ? 19 : 20}>
                                <span> {props.data?.endDate}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {

})(ProblemRow)
