/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Icon, message, Modal, Row, Upload, Input, Form, Tooltip } from 'antd'
import { Asset, pmAdjustData, PMNameAndAssetName, Preventive, PreventiveWithAsset, pmUploadLink, customFieldPicture } from '../Model'
import { UploadFile } from 'antd/lib/upload/interface'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css'
import moment from 'moment'
// import { updateDataPMWithAssetInDBOffline } from '../../IndexDB/Service'
// import { updatePreventiveById } from './Services/service'
import exportIcon from '../../common-file/icon-file'
import './stylePM.css'
import { getDataPMApprove, getDataPMNameOrDeskside, updatePreventiveById } from '../services'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { People } from '../../authorization-module/people/model'
import { PMActivityField } from '../../configurable-fields'
import Compress from 'compress.js'
import { DownloadAnyFile, tokenDecode } from '../../common-misc'
import { User } from '../../authorization-module/user-role/duck/model'
import { FormComponentProps } from 'antd/es/form'
import { CustomFileds } from './../../configurable-fields/model'
import CustomPicture from './CustomPicture'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'
import ImgApproved from '../../assets/images/approved.png'

interface Param {
    Group?: string
    isView: boolean
    dataPM?: PreventiveWithAsset
    dataAsset?: Asset
    isOnline: boolean
    pmAdjusted?: pmAdjustData
    getIsUserSignature?: Function
    getIsPMApproveSignature?: Function
    getIsAllSignature?: Function
    PmActivity: PMActivityField[]
    dataHR?: People
    isClearSignature?: boolean
}

type Props = Param & FormComponentProps
const compress = new Compress()
const { TextArea } = Input
const DetailPM: React.FC<Props> = (props: Props) => {
    const [isShowSignPadModal, setIsShowSignPadModal] = useState<boolean>(false)
    const [isCheck, setIsCheck] = useState<boolean>(false)
    const [Who, setWho] = useState<string>()
    const [userSignature, setUserSignature] = useState<string>()
    const [userSignatureDate, setUserSignatureDate] = useState<string>()
    const [pmTeamSignature, setPmTeamSignature] = useState<string>()
    const [pmTeamSignatureDate, setPmTeamSignatureDate] = useState<string>()
    const [pmApproveSignature, setPmApproveSignature] = useState<string>()
    const [pmApproveSignatureDate, setPmApproveSignatureDate] = useState<string>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [desksideTeam, setDesksideTeam] = useState<any[]>([])
    const [pmName, setPMName] = useState<any[]>([])
    const [tempPMApprove, setTempPMApprove] = useState<PMNameAndAssetName>()
    const [tempPMUser, setTempPMUser] = useState<PMNameAndAssetName>()
    const [dataReasonApproved, setDataReasonApproved] = useState('')
    const [fullNameOwner, setFullNameOwner] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const filterPmAdjusted = props.dataPM?.preventiveMaintenance?.pmAdjust
    const filterCustomFieldsAssetDetails = props.dataPM?.preventiveMaintenance.pendingReason && props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData === null
        ? props.dataPM?.preventiveMaintenance?.pmAdjust?.originalAsset?.customField?.filter((it) => { return it.headerGroup === 'AssetDetails' && it.isShowPm === true })
        : props.dataPM?.preventiveMaintenance?.pmAdjust?.pmAdjustData?.customField?.filter((it) => { return it.headerGroup === 'AssetDetails' && it.isShowPm === true })
    const { getFieldDecorator, resetFields, getFieldValue } = props.form
    const [dataPicture, setDataPicture] = useState<any[]>([])
    const [dataCustomPicture, setdataCustomPicture] = useState<customFieldPicture[]>([])
    const [previewIndex, setPreviewIndex] = useState<number | null>(null)
    const [pmApproveImage, setPmApproveImage] = useState<any>(null)

    useEffect(() => {
        if (props.dataPM) {
            const customPicFields = (props.dataPM?.preventiveMaintenance?.pmUploadLink?.map((value) => value.customField!!))
            if (customPicFields) {
                setdataCustomPicture(customPicFields)
            }
        }
    }, [props.dataPM])

    let sigPad: any = {}

    useEffect(() => {
        if (props.dataPM?.preventiveMaintenance.userSignature) {
            setFullNameOwner(JSON.parse(props.dataPM?.preventiveMaintenance.userSignature || '')?.fullName)
        }
        getDataPMApprove().then((res) => {
            if (res) {
                setDesksideTeam(res)
            }
        })
        getDataPMNameOrDeskside().then((res) => {
            if (res) {
                setPMName(res)
            }
        })
    }, [])

    useEffect(() => {
        console.log(props.isClearSignature)
        if (props.isClearSignature) {
            setTempPMUser(undefined)
            setFullNameOwner(undefined)
            closeModal()
        }
    }, [props.isClearSignature])

    useEffect(() => {
        if (isShowSignPadModal) {
            setIsCheck(false)
            clearSign()
        }
    }, [isShowSignPadModal])

    useEffect(() => {
        if (props.dataPM) {
            if (props.dataPM.preventiveMaintenance.userSignature && props.dataPM.preventiveMaintenance.userSignature.length <= 300) {
                const file = JSON.parse(props.dataPM.preventiveMaintenance.userSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setUserSignature(uploadFile.url)
                setUserSignatureDate(props.dataPM.preventiveMaintenance.userSignatureDatetime)
                if (props.getIsUserSignature) {
                    props.getIsUserSignature(true)
                }
            } else {
                setUserSignature(props.dataPM.preventiveMaintenance.userSignature)
                setUserSignatureDate(props.dataPM.preventiveMaintenance.userSignatureDatetime)
            }
            if (props.dataPM.preventiveMaintenance.pmTeamSignature && props.dataPM.preventiveMaintenance.pmTeamSignature.length <= 300) {
                const file = JSON.parse(props.dataPM.preventiveMaintenance.pmTeamSignature)
                const uploadFile: UploadFile = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setPmTeamSignature(uploadFile.url)
                setPmTeamSignatureDate(props.dataPM.preventiveMaintenance.pmTeamSignatureDatetime)
            } else {
                setPmTeamSignature(props.dataPM.preventiveMaintenance.pmTeamSignature)
                setPmTeamSignatureDate(props.dataPM.preventiveMaintenance.pmTeamSignatureDatetime)
            }
            if (props.dataPM.preventiveMaintenance.pmApproverSignature && props.dataPM.preventiveMaintenance.pmApproverSignature.length <= 300) {
                const file = JSON.parse(props.dataPM.preventiveMaintenance.pmApproverSignature)
                const uploadFile: any = {
                    uid: file.name || file[0].name,
                    name: file.name || file[0].name,
                    status: 'done',
                    url: file.url || file[0].url,
                    type: file.type || file[0].type,
                    thumbUrl: exportIcon(file.type!! || file[0].type),
                    size: 0
                }
                setPmApproveSignature(uploadFile.url)
                setPmApproveSignatureDate(props.dataPM.preventiveMaintenance.pmApproverSignatureDatetime)
            } else {
                setPmApproveSignature(props.dataPM.preventiveMaintenance.pmApproverSignature)
                setPmApproveSignatureDate(props.dataPM.preventiveMaintenance.pmApproverSignatureDatetime)
            }
        }
        if (props.dataPM?.preventiveMaintenance.attachFile) {
            const attachFile = JSON.parse(props.dataPM?.preventiveMaintenance.attachFile)
            const fileList: UploadFile[] = attachFile?.map((file: UploadLink) => {
                const uploadFile: UploadFile = {
                    uid: file.name,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                    type: file.type,
                    thumbUrl: exportIcon(file.type!!),
                    size: 0
                }
                return uploadFile
            })
            setFileList(fileList)
        }
        if (props.dataPM?.preventiveMaintenance.pmUploadLink) {
            const temp: any[] = []
            const tempFile = props.dataPM.preventiveMaintenance.pmUploadLink.filter((file: pmUploadLink) => {
                return file.url !== null
            })

            tempFile.forEach((file: pmUploadLink) => {
                if (file.url) {
                    const uploadFile: pmUploadLink = {
                        uid: file.name || '',
                        name: file.name || '',
                        status: 'done',
                        url: file.url,
                        type: file.type || '',
                        thumbUrl: exportIcon(file.type || ''),
                        size: 0,
                        customPictureId: file.customPictureId,
                        pictureName: file.customField?.pictureName
                    }
                    temp.push(uploadFile)
                }
            })

            setDataPicture(temp)
        } else {
            setDataPicture([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPM])

    const onChangeCheckBox = (e: { target: { checked: any; }; }) => {
        console.log(`checked = ${e.target.checked}`)
        setIsCheck(e.target.checked)
    }

    const renderPMActivity = (PM?: Preventive) => {
        const tempCheck: PMActivityField[] = []
        if (PM?.pmActivityCustomField) {
            (PM?.pmActivityCustomField || []).forEach((res) => {
                if (res.customField) {
                    tempCheck.push({ ...res.customField!!, remark: res.remark })
                }
            })
        }
        if (tempCheck.length === 0 && props?.PmActivity?.length !== 0) {
            tempCheck.push(...props?.PmActivity)
        }
        return <>
            {(tempCheck).map((res, index) => {
                if (res) {
                    return <div key={index} className="striped">{index + 1}. {res.activityLabel}
                        {res.description ? <div style={{ paddingLeft: '1em', color: '#828282' }} >{res.description}</div> : null}
                        {res.remark ? <div style={{ paddingLeft: '1em' }} >Remark : <span style={{ color: '#828282' }}>{res.remark}</span></div> : null}
                    </div>
                } else {
                    return null
                }
            })}
        </>
    }

    const clearSign = () => {
        try {
            sigPad.clear()
        } catch (e) {
            sigPad = {}
        }
    }

    const confirm = (e) => {
        e.preventDefault()
        const convertData = props.dataPM!!

        if (getFieldValue('ReasonApprove') && sigPad.isEmpty()) {
            convertData.preventiveMaintenance.ignoredReason = getFieldValue('ReasonApprove') || dataReasonApproved
            convertData.preventiveMaintenance.actionInStep = 4
            updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                if (res) {
                    setIsCheck(false)
                    message.success('You have Successfully save the data.')
                }
            }).catch((err) => {
                setIsCheck(false)
                message.error('Update Ticket Fail' + err)
            }).finally(() => {
                setIsLoading(false)
                resetFields(['ReasonApprove'])
            })
            closeModal()
        }

        if (sigPad.isEmpty()) {
            return
        }
        if (Who === 'User' && !(props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData?.people?.fullName || props.dataHR?.fullName)) {
            props.form.validateFields(async (_err, values) => {
                if (!_err) {
                    setFullNameOwner(values.OwnerName.trim())
                    console.log('sigpad : ',sigPad.getTrimmedCanvas())
                    const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
                    console.log('base64String : ',base64String)
                    UpdateSign(base64String)
                }
            })
        } else {
            console.log('sigpad : ',sigPad.getTrimmedCanvas())
            const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
            console.log('base64String : ',base64String)
            UpdateSign(base64String)
        }
    }
    
    const UpdateSign = (base64String: string) => {
        const convertData = props.dataPM!!
        const Time = moment().format('YYYY-MM-DD HH:mm:ss')
        if (Who === 'User') {
            convertData.preventiveMaintenance.pmStatus = 'WaitingForSign'
            convertData.preventiveMaintenance.userSignature = base64String
            convertData.preventiveMaintenance.userSignatureDatetime = Time
            setUserSignature(base64String)
            setUserSignatureDate(Time)
            if (props.getIsUserSignature) {
                props.getIsUserSignature(true)
            }
        } else if (Who === 'Engineer') {
            const tempAssetNameUser = (pmName || []).find((res: PMNameAndAssetName) => res.id === tokenDecode()?.userId)
            setTempPMUser(tempAssetNameUser)
            convertData.preventiveMaintenance.pmUser = { id: tempAssetNameUser.id, fullName: tempAssetNameUser.fullName } as User
            convertData.preventiveMaintenance.pmTeamSignature = base64String
            convertData.preventiveMaintenance.pmTeamSignatureDatetime = Time
            setPmTeamSignature(base64String)
            setPmTeamSignatureDate(Time)
        } else if (Who === 'Approved') {
            const tempAssetName = (desksideTeam || []).find((res: PMNameAndAssetName) => res.id === tokenDecode()?.userId)
            setTempPMApprove(tempAssetName)
            convertData.preventiveMaintenance.pmApprover = { id: tempAssetName.id, fullName: tempAssetName.fullName } as User
            convertData.preventiveMaintenance.pmApproverSignature = base64String
            convertData.preventiveMaintenance.pmApproverSignatureDatetime = Time
            if (dataReasonApproved) {
                convertData.preventiveMaintenance.ignoredReason = dataReasonApproved
            }
            setPmApproveSignature(base64String)
            setPmApproveSignatureDate(Time)
            if (props.getIsPMApproveSignature) {
                props.getIsPMApproveSignature(true)
            }
        } 
        // if (convertData.preventiveMaintenance.pmAdjust) {
        //     if (filterPmAdjusted?.pmAdjustData?.id === undefined && props.isOnline) {
        //         convertData.preventiveMaintenance.pmAdjust = undefined
        //     } else {
        //         convertData.preventiveMaintenance.pmAdjust = filterPmAdjusted
        //     }
        // }
        convertData.preventiveMaintenance.asset = props.dataPM?.asset!!
        if (convertData.preventiveMaintenance.isPmApproved) {
            if (convertData.preventiveMaintenance.userSignature && convertData.preventiveMaintenance.pmTeamSignature && convertData.preventiveMaintenance.pmApproverSignature) {
                if (convertData.preventiveMaintenance.pendingReason) {
                    convertData.preventiveMaintenance.pmStatus = 'Ignored'
                } else {
                    convertData.preventiveMaintenance.pmStatus = 'Completed'
                }
                convertData.preventiveMaintenance.lastModifiedDatetime = Time
            }
        } else {
            if (convertData.preventiveMaintenance.userSignature && convertData.preventiveMaintenance.pmTeamSignature) {
                if (convertData.preventiveMaintenance.pendingReason) {
                    convertData.preventiveMaintenance.pmStatus = 'Ignored'
                } else {
                    convertData.preventiveMaintenance.pmStatus = 'Completed'
                }
                convertData.preventiveMaintenance.lastModifiedDatetime = Time
            }
        }
        if (userSignature && pmTeamSignature) {
            if (props.getIsAllSignature) {
                props.getIsAllSignature(true)
            }
        }
        // else {
        //     convertData.preventiveMaintenance.pmStatus = 'WaitingForSign'
        // }
        setIsLoading(true)
        if (props.isOnline) {
            const stringLength = base64String.length - 'data:image/png;base64,'.length
            const fileSignature: UploadFile = {
                uid: 'signature_pm' + Who + moment(),
                name: 'signature_pm' + Who + moment(),
                status: 'done',
                url: base64String,
                type: 'image/png',
                size: stringLength
            }
            const fileList: UploadFile[] = []
            fileList.push(fileSignature)
            FSS.putFile(fileList, '/signature/', (uploadLink: UploadLink[]) => {
                // const signature = { attachedSignatureReplace: JSON.stringify(uploadLink) }
                if (Who === 'User') {
                    uploadLink[0].fullName = fullNameOwner || getFieldValue('OwnerName')
                    console.log(uploadLink[0])
                    convertData.preventiveMaintenance.userSignature = JSON.stringify(uploadLink[0])
                } else if (Who === 'Engineer') {
                    convertData.preventiveMaintenance.pmTeamSignature = JSON.stringify(uploadLink[0])
                } else if (Who === 'Approved') {
                    convertData.preventiveMaintenance.pmApproverSignature = JSON.stringify(uploadLink[0])
                }

                // eslint-disable-next-line no-unused-expressions
                // (convertData?.preventiveMaintenance?.pmActivityCustomField || [])?.forEach((res) => {
                //     delete res.id
                //     delete res.createdBy
                //     delete res.createdDatetime
                //     delete res.customField
                //     delete res.lastModifiedBy
                //     delete res.lastModifiedDatetime
                // })
                console.log(convertData.preventiveMaintenance)
                convertData.preventiveMaintenance.actionInStep = 4
                updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                    if (res) {
                        setIsCheck(false)
                        message.success('You have Successfully save the data.')
                    }
                }).catch((err) => {
                    setIsCheck(false)
                    message.error('Update Ticket Fail' + err)
                }).finally(() => {
                    setIsShowSignPadModal(false)
                    clearSign()
                    setIsLoading(false)
                    resetFields()
                    setIsLoading(false)
                })
            })
        } else {
            const getKeys = sessionStorage.getItem('key')
            if (getKeys) {
                const keys: string[] = JSON.parse(getKeys)
                keys.push(convertData.asset.assetTag)
                sessionStorage.setItem('key', JSON.stringify([...new Set(keys)]))
            } else {
                sessionStorage.setItem('key', JSON.stringify([convertData.asset.assetTag]))
            }
            convertData.isUpdate = true
            // updateDataPMWithAssetInDBOffline(convertData).then(() => {
            //     setIsCheck(false)
            // })
            message.success('You have Successfully save the data.')
        }
    }

    const checkWhoSign = (e: string) => {
        setDataReasonApproved('')
        setWho(e)
        setIsShowSignPadModal(true)
    }

    const closeModal = () => {
        setIsCheck(false)
        setIsShowSignPadModal(false)
        clearSign()
        resetFields()
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const dataURLtoFile = (dataUrl: any, filename: any) => {
        const arr = dataUrl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
    }

    const uploadProps = {
        multiple: true,
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }
            // setLoading(true)
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                // fileList = fileList.slice(-2);
                // if (file.size > fileSize) {
                //     message.error('Maximum permitted size of 5 Mb')
                //     return false
                // }
                let fillOffFileList = fileList
                const temp: any[] = []
                const promiseAll: Promise<void>[] = []
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                for (const file of fillOffFileList) {
                    if (file.url?.includes('data:image/png;base64') || file.url?.includes('data:image/jpeg;base64')) {
                        const originalFile = dataURLtoFile(file.url, file.fileName)
                        promiseAll.push(compress.compress([originalFile], {
                            size: 1, // the max size in MB, defaults to 2MB
                            quality: 0.75, // the quality of the image, max is 1,
                            maxWidth: 1280, // the max width of the output image, defaults to 1920px
                            maxHeight: 1280, // the max height of the output image, defaults to 1920px
                            resize: true // defaults to true, set false if you do not want to resize the image width and height
                        }).then((data) => {
                            temp.push({
                                uid: file.uid,
                                name: file.name,
                                status: 'done',
                                url: data[0].prefix + data[0].data,
                                type: data[0].ext,
                                size: e.total
                            })
                        }))
                    } else {
                        temp.push(file)
                    }
                }
                Promise.all(promiseAll).finally(() => {
                    setFileList(temp)
                    // setIsFile(true)
                    // setLoading(false)
                })
            }
            return false
        },
        showUploadList: { showDownloadIcon: true, showRemoveIcon: false },
        onDownload: (file) => {
            console.log(file)
            if (file.url.startsWith('https://')) {
                window.open(file.url)
            } else {
                message.warning("Can't Download Files")
            }
        }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }, index: number) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewIndex(index)
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleClosePreview = () => {
        setPreviewVisible(false)
        setPreviewIndex(null)
    }

    const convertPngToBase64 = (imageUrl: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.crossOrigin = 'Anonymous'
            img.src = imageUrl
    
            img.onload = () => {
                const canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height
                const ctx = canvas.getContext('2d')
                ctx?.drawImage(img, 0, 0)
                const dataURL = canvas.toDataURL('image/png')
                resolve(dataURL)
            }
    
            img.onerror = (error) => reject(error)
        })
    }

    const handleConfirmApproved = () => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm the approval of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                const base64Image = await convertPngToBase64(ImgApproved)
                console.log('base64 : ',base64Image)
                console.log('props.isOnline : ',props.isOnline)
                updateApprovedWithNoSign(base64Image as string, 'Approved')
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const updateApprovedWithNoSign = (base64String: string, Who: string) => {
        const convertData = props.dataPM!!
        const tempAssetName = (desksideTeam || []).find((res: PMNameAndAssetName) => res.id === tokenDecode()?.userId)
        const Time = moment().format('YYYY-MM-DD HH:mm:ss')
        if (Who === 'Approved') {
            convertData.preventiveMaintenance.pmApprover = { id: tempAssetName.id, fullName: tempAssetName.fullName } as User
            convertData.preventiveMaintenance.pmApproverSignature = base64String
            convertData.preventiveMaintenance.pmApproverSignatureDatetime = Time
            setTempPMApprove(tempAssetName)
            setPmApproveSignatureDate(Time)
            setPmApproveImage(base64String)
            if (props.getIsPMApproveSignature) {
                props.getIsPMApproveSignature(true)
            }
        }
        setIsLoading(true)
        if (props.isOnline) {
            const stringLength = base64String.length - 'data:image/png;base64,'.length
            const fileSignature: any = {
                uid: 'signature_pm' + Who + moment(),
                name: 'signature_pm' + Who + moment(),
                status: 'done',
                url: base64String,
                type: 'image/png',
                size: stringLength,
                isApprovedImg: true
            }
            const fileList: UploadFile[] = []
            fileList.push(fileSignature)
            FSS.putFile(fileList, '/signature/', (uploadLink: any[]) => {
                if (Who === 'Approved') {
                    convertData.preventiveMaintenance.pmApprover = { id: tempAssetName.id, fullName: tempAssetName.fullName } as User
                    convertData.preventiveMaintenance.pmApproverSignature = JSON.stringify(uploadLink[0])
                    convertData.preventiveMaintenance.pmApproverSignatureDatetime = Time
                }
                console.log(convertData.preventiveMaintenance)
                convertData.preventiveMaintenance.actionInStep = 4
                uploadLink.forEach((file) => {
                    delete file?.isApprovedImg
                })
                updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                    if (res) {
                        message.success('You have Successfully save the data.')
                    }
                }).catch((err) => {
                    message.error('Update Ticket Fail' + err)
                }).finally(() => {
                    setIsLoading(false)
                })
            })
        }
    }

    return (
        <div style={{ wordWrap: 'break-word' }}>
            <div className="herder-form">Preventive Maintenance Form</div>
            <Modal
                title="Signature"
                visible={isShowSignPadModal}
                footer={false}
                onCancel={() => closeModal()}
            >
                <Form onSubmit={confirm}>
                    {Who === 'User' ? <p>
                        <Checkbox onChange={onChangeCheckBox} checked={isCheck}>
                            <span>By sign this form, I am hereby that I have already checked and confirmed that all the information and the equipment is correct as specified in the document and have, therefore, affixed my signature below.</span>
                            <br />
                            <span>โดยการลงนามในแบบฟอร์มนี้ ข้าพเจ้าขอรับรองว่าได้ตรวจสอบและยืนยันว่าข้อมูลและอุปกรณ์ทั้งหมดถูกต้อง
                                ตามที่ระบุไว้ในเอกสาร และได้ลงลายมือชื่อไว้ด้านล่างแล้ว</span>
                        </Checkbox>
                        <br />
                    </p> : null}
                    {Who === 'User' && !(props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData?.people?.fullName || props.dataHR?.fullName) ? <>
                        {/* <p><span style={{ color: 'red' }}>* </span>Owner Name : </p>
                        <Input placeholder='input your Owner Name' onChange={(e) => setFullNameOwner(e.target.value)} style={{ borderColor: fullNameOwner === '' ? 'red' : '#323276' }} value={fullNameOwner}/> */}
                        <Form.Item
                            label="Owner Name"
                        >
                            {getFieldDecorator('OwnerName', {
                                rules: [{ required: true, message: 'Please input your Owner Name' }, { whitespace: true, message: 'Please enter more than 1 letters owner name.' }]
                            })(
                                <Input placeholder='input your Owner Name' max={255} maxLength={255} />
                            )}
                        </Form.Item>
                        <br />
                    </> : null}
                    {Who === 'Approved' ? <>
                        <Form.Item
                            label="Reason Approve : "
                        >
                            {getFieldDecorator('ReasonApprove', {
                                rules: [{ required: false, message: 'Please input your Owner Name' }]
                            })(
                                <TextArea rows={4} autoSize={{ minRows: 3, maxRows: 4 }} placeholder='input your Reason' onChange={(e) => setDataReasonApproved(e.target.value)} />
                            )}
                        </Form.Item>
                        <br />
                        <p style={{ color: '#323276', fontSize: 13 }}>PM Approve Sign : </p>
                    </> : null}
                    <SignaturePad ref={(ref) => { sigPad = ref }} canvasProps={{ className: styles.sigPad, id: 'my-SignaturePad', height: window.innerWidth <= 768 ? 450 : 200 }} clearOnResize={true} />
                    <br />
                    <div style={{ textAlign: 'end' }}>
                        <Button htmlType="button" onClick={() => closeModal()}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 5 }} loading={isLoading} disabled={Who === 'User' ? !isCheck : false}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal>

            {/* <Row>
                {props.dataPM?.preventiveMaintenance.pmUploadLink || props.dataPM?.preventiveMaintenance.attachFile ? (
                    <div className="Box-Wrap">
                        {[...fileList, ...dataPicture]?.map((value, index) => {
                            return <Col key={index} >
                                <Tooltip title={previewIndex === null ? value?.pictureName || '' : null}>
                                    <Upload
                                        {...uploadProps}
                                        listType="picture-card"
                                        fileList={[value]}
                                        onPreview={(e: any) => handlePreview(e, index)}
                                    />
                                    <Modal visible={previewVisible} footer={null} onCancel={handleClosePreview}>
                                        <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Tooltip>
                            </Col>
                        })}
                    </div>
                ) : null
                }
            </Row> */}

            <Row>
                {fileList.length !== 0 ? <>
                    <Card title="Original Images Asset" className="card-back">
                        <PhotoProvider >
                            <Row gutter={[12, 12]}>
                                {[...fileList]?.map((value, index) => {
                                    return <Col span={3} key={index} >
                                        <div className='image-view-box'>
                                            <Tooltip title={value.name}>
                                                <PhotoView src={value.url} >
                                                    <img src={value.url} alt={value.name} height={80} width={80} />
                                                </PhotoView>
                                                <a href={value.url} target="_blank" rel="noreferrer"><div className='feck-link image-view-text-name' >{value.name.length < 20 ? value.name : value.name.slice(0, 19) + '...'}</div></a>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </PhotoProvider>
                    </Card>
                    <br />
                </> : null}
            </Row>

            <Row>
                {dataPicture.length !== 0 ? <>
                    <Card title="Desired Image for PM" className="card-back">
                        <PhotoProvider >
                            <Row gutter={[12, 12]}>
                                {[...dataPicture]?.map((value, index) => {
                                    return <Col span={3} key={index} >
                                        <div className='image-view-box'>
                                            <Tooltip title={value.name}>
                                                <PhotoView src={value.url} >
                                                    <img src={value.url} alt={value.name} height={80} width={80} />
                                                </PhotoView>
                                                <a href={value.url} target="_blank" rel="noreferrer"><div className='feck-link image-view-text-name' >{value.name.length < 20 ? value.name : value.name.slice(0, 19) + '...'}</div></a>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </PhotoProvider>
                    </Card>
                    <br />
                </> : null}
            </Row>

            {/* </div> */}
            <Row>
                <Col span={24}>
                    <Card title="Asset Details" className="card-back">
                        <Row gutter={[8, 8]}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Serial Number : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.serialNumber || props.dataAsset?.serialNumber || '-'}</div>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Asset Status : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.status || props.dataAsset?.status || '-'}</div>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Asset Tag : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.assetTag || props.dataAsset?.assetTag || '-'}</div>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Asset Name : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.assetName || props.dataAsset?.assetName || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Manufacturer : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.manufacturer || props.dataAsset?.manufacturer || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Model : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.model || props.dataAsset?.model || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Brand : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.brand || props.dataAsset?.brand || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Type : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.assetGroupType?.type || props.dataAsset?.assetGroupType?.type || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Owner ID : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.people?.employeeId || props.dataHR?.employeeId || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Owner Name : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.people?.fullName || props.dataHR?.fullName || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Department : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.people?.employeeId ? filterPmAdjusted?.pmAdjustData?.people?.department?.departmentName : filterPmAdjusted?.pmAdjustData?.people?.department?.departmentName || props.dataAsset?.people?.department?.departmentName || '-'}</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12}>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Site : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.site || props.dataAsset?.site || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Location : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.location || props.dataAsset?.location || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Building : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.building || props.dataAsset?.building || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Floor : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.floor || props.dataAsset?.floor || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Zone : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.zone || props.dataAsset?.zone || '-'}</div>
                                    </Col>
                                </Row>

                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Paired IT Asset : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{filterPmAdjusted?.pmAdjustData?.pairedItAsset || props.dataAsset?.pairedItAsset || '-'}</div>
                                    </Col>
                                </Row>

                                {filterCustomFieldsAssetDetails ? filterCustomFieldsAssetDetails?.map((data, index) => {
                                    return <Row gutter={[8, 8]} key={index}>
                                        <Col span={12}>
                                            <div>{data.label} : </div>
                                        </Col>
                                        <Col span={12}>
                                            <div>{data.valueAdjust || data.value || '-'}</div>
                                        </Col>
                                    </Row>
                                }) : null}

                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="PM Activity" className="card-back">
                        {props.dataPM?.preventiveMaintenance.pendingReason ? null : renderPMActivity(props.dataPM?.preventiveMaintenance)}
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="PM Team" className="card-back">
                        <Row gutter={[8, 8]}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>PM Name : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{props.dataPM?.preventiveMaintenance?.pmUser?.fullName}</div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div>Completed Date : </div>
                                    </Col>
                                    <Col span={12}>
                                        <div>{props.dataPM?.preventiveMaintenance?.pmStatus === 'Completed' || props.dataPM?.preventiveMaintenance?.pmStatus === 'Ignored' ? props.dataPM?.preventiveMaintenance?.completedDatetime || props.dataPM?.preventiveMaintenance?.lastModifiedDatetime : ''}</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="Issue" className="card-back">
                        <p>{props.dataPM?.preventiveMaintenance?.commentPicture}</p>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Card title="Reason" className="card-back">
                        <Row>
                            <Col span={24}>
                                <p>{props.dataPM?.preventiveMaintenance.reworkReason ? <>Rework Reason : {props.dataPM?.preventiveMaintenance.reworkReason}</> : null}</p>
                                <p>{props.dataPM?.preventiveMaintenance.otherReason || props.dataPM?.preventiveMaintenance.pendingReason ? <>Pending Reason : {props.dataPM?.preventiveMaintenance.otherReason || props.dataPM?.preventiveMaintenance.pendingReason}</> : null}</p>
                                <p>{props.dataPM?.preventiveMaintenance.isPmApproved && props.dataPM?.preventiveMaintenance.ignoredReason ? <div>Approve Reason : {props.dataPM?.preventiveMaintenance.ignoredReason}</div> : null} </p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={[8, 8]}>
                <Col span={props.dataPM?.preventiveMaintenance?.isPmApproved ? 8 : 12} xs={24} sm={24} md={props.dataPM?.preventiveMaintenance?.isPmApproved ? 8 : 12} xl={props.dataPM?.preventiveMaintenance?.isPmApproved ? 8 : 12}>
                    <div className="box-signature">
                        <p>Owner - Sign Confirm</p>
                        <p>Sign Date :  {userSignatureDate || '-'}</p>
                        <div style={{ height: 80, textAlign: 'center' }}>{userSignature ? <img src={userSignature} alt='userSignature' style={{ maxWidth: '100%', maxHeight: '100%' }} /> : null}</div>
                        <br />
                        <Row>
                            <Col span={22}><hr style={{ marginTop: 20 }} /></Col>
                            <Col span={2}><p> {(!props.isView && !userSignature) && checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan') ? <span className="feck-link"><Icon type='edit' onClick={() => checkWhoSign('User')} /></span> : null}</p></Col>
                        </Row>
                        <div style={{ textAlign: 'center' }}>( {props.dataPM?.preventiveMaintenance.pmAdjust?.pmAdjustData?.people?.fullName || props.dataHR?.fullName || fullNameOwner} )</div>
                    </div>
                </Col>
                <Col span={props.dataPM?.preventiveMaintenance?.isPmApproved ? 8 : 12} xs={24} sm={24} md={props.dataPM?.preventiveMaintenance?.isPmApproved ? 8 : 12} xl={props.dataPM?.preventiveMaintenance?.isPmApproved ? 8 : 12}>
                    <div className="box-signature">
                        <p>PM Team - Sign Confirm</p>
                        <p>Sign Date :  {pmTeamSignatureDate || '-'}</p>
                        <div style={{ height: 80, textAlign: 'center' }}>{pmTeamSignature ? <img src={pmTeamSignature} alt='pmTeamSignature' style={{ maxWidth: '100%', maxHeight: '100%' }} /> : null}</div>
                        <br />
                        <Row>
                            <Col span={22}><hr style={{ marginTop: 20 }} /></Col>
                            <Col span={2}><p>{(!props.isView && !pmTeamSignature) && checkRolesState(RoleType.PM, 'PMList') ? <span className="feck-link"><Icon type='edit' onClick={() => checkWhoSign('Engineer')} /></span> : null}</p></Col>
                        </Row>
                        <div style={{ textAlign: 'center' }}>( {props.dataPM?.preventiveMaintenance?.pmUser?.fullName || props.dataPM?.preventiveMaintenance?.pmUser?.people?.fullName || tempPMUser?.fullName} )</div>
                    </div>
                </Col>
                {props.dataPM?.preventiveMaintenance?.isPmApproved ? <Col span={8} xs={24} sm={24} md={8} xl={8}>
                    <div className="box-signature">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>PM Approve - Sign Confirm</p>
                            {(pmApproveImage && !pmApproveSignature) || (!pmApproveImage && pmApproveSignature) ? null
                                : 
                                <Button 
                                    htmlType="button" 
                                    onClick={handleConfirmApproved} 
                                    style={{ color: '#fff', backgroundColor: '#323276', borderRadius: '54px' }}
                                >
                                    Approve
                                </Button>
                            }
                        </div>
                        <p>Sign Date :  {pmApproveSignatureDate || '-'}</p>
                        <div style={{ height: 80, textAlign: 'center' }}>
                            { pmApproveImage ? (
                                <img src={pmApproveImage} alt='pmApproveSignature' style={{ maxWidth: '100%', maxHeight: '100%', width: '80%' }} />
                            ) : pmApproveSignature ? (
                                <img src={pmApproveSignature} alt='pmApproveSignature' style={{ maxWidth: '100%', maxHeight: '100%', width: '80%' }} />
                            ) : null}
                        </div>
                        <br />
                        <Row>
                            <Col span={22}><hr style={{ marginTop: 20 }} /></Col>
                            <Col span={2}>
                                <p>
                                    {(pmApproveImage && !pmApproveSignature) || (!pmApproveImage && pmApproveSignature)
                                        ? null
                                        : (
                                            (!props.isView && !pmApproveSignature) && checkRolesState(RoleType.PM, 'ApprovePM') ? <span className="feck-link"><Icon type='edit' onClick={() => checkWhoSign('Approved')} /></span> : null
                                        )
                                    }
                                </p>
                            </Col>
                        </Row>
                        <div style={{ textAlign: 'center' }}>( {props.dataPM?.preventiveMaintenance?.pmApprover?.fullName || props.dataPM?.preventiveMaintenance?.pmApprover?.people?.fullName || tempPMApprove?.fullName} )</div>
                    </div>
                </Col> : null}
            </Row>
        </div >
    )
}

const MyFormDetailPM = Form.create<Props>({ name: 'detailPm' })(DetailPM)
export default MyFormDetailPM
