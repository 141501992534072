import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Icon, Input, message, Modal, Row } from 'antd'
import TableRelateAsset from './TableRelateAsset'
import TableSpareAsset from './TableSpareAsset'
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css'
import FSS from '../../../file-server-storage'
import { UploadLink } from '../../../file-server-storage/model'
import { UploadFile } from 'antd/lib/upload/interface'
import { getAllJob, Job, updateJobSignature, updateJobStatus } from '../../Relate/Job'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import {getWorkOrderById} from '../../wo-management/service'
import { AssignmentGroups, WorkOrder } from '../model'
import moment from 'moment'
import { getAssignmentUser } from '../service'
import { Asset, AssetForWO, getAssetForWOByWOId, getAssetRelatedWo } from '../../Relate/Asset'
import { useLocation } from 'react-router-dom'
import { ChargingGroup } from '../../Relate/Charging/Model'
import { getChargingByWoId } from '../../Relate/Charging/service'
import TableCharging from './TableCharging'
import { FormComponentProps } from 'antd/lib/form'
import { sendLineNotify } from '../../../authorization-module/role-and-permissions/service'
import { decryptDataVspace } from '../../../common-misc'
import { useReactToPrint } from 'react-to-print'

interface Param {
    woId: string,
    idJob: string,
    JobTopic: string,
    isCloseStep?: (boolean) => void,
    dataRelateAsset: Asset[]
    isVisible: boolean,
    handleCloseModal: (boolean) => void,
    isApprove: boolean,
    vSpaceIncidentNo: string
}

const mapStateToProps = (state: StoreState) => {
    return {
        job: state.job,
        chargingGroup: state.chargingGroup
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllJob: (woId?: string) => Promise<Job[] | undefined>,
    getChargingByWoId: (woId: number) => Promise<number>
}

const pageStyle = `
  @media print {
    .page-break {
        page-break-inside: avoid;
    }
  }
`

type Props = Param & FormComponentProps & StateProps & DispatchProps
const DetailSignature: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const dataVspace = decryptDataVspace()
    let username: string = ''
    let roleId: string = ''
    if (typeof dataVspace === 'object') {
        username = dataVspace.email
        roleId = dataVspace.roleId.toString()
    }
    const [visibleModal, setVisibleModal] = useState<boolean>(false)
    const [trimmedDataURL, setTrimmedDataURL] = useState<string>('')
    const [trimmedDataURL2, setTrimmedDataURL2] = useState<string>('')
    const [trimmedDataURLApprove, setTrimmedDataURLApprove] = useState<string>('')
    const [lastModifyReplace, setLastModifyReplace] = useState<string>('')
    const [lastModifyReturn, setLastModifyReturn] = useState<string>('')
    const [lastModifyApprove, setLastModifyApprove] = useState<string>('')
    const [dataWO, setDataWo] = useState<WorkOrder>()
    const [User, setUser] = useState<AssignmentGroups[]>([])
    const [phone, setPhone] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [tempAsset, setTempAsset] = useState<AssetForWO[] | []>([])
    const [tempSpare, setTempSpare] = useState<AssetForWO[] | []>([])
    const [tempLogistic, setTempLogistic] = useState<ChargingGroup[]>([])
    const [tempRepair, setTempRepair] = useState<ChargingGroup[]>([])
    const [isShowButton, setIsShowButton] = useState<boolean>(false)
    const [isShowRejectModal, setIsShowRejectModal] = useState<boolean>(false)
    const [engineerName, setEngineerName] = useState<string>('')
    const [approveName, setApproveName] = useState<string>('')
    const [approveReason, setApproveReason] = useState<string>('')
    const location = useLocation()
    const componentRef = useRef(null)

    const jobStatus = props.job.filter(job => job.id === props.idJob).map(job => job.jobStatus)[0]
    const jobApproveReason = props.job.filter(job => job.id === props.idJob).map(job => job.approverReason)[0]

    let sigPad: any = {}

    useEffect(() => {
        fetchDataWO()
        fetchAssignmentUser()
        fetchDataCharging()
        if (location.pathname.includes('/WoFormEdit') || location.pathname === '/WoList') {
            if (props.job) {
                (props.job || []).forEach((res) => {
                    if (res.jobTopic === 'Replace' && res.jobStatus === 'Closed') {
                        setLastModifyReplace(res.lastModifiedDatetime!!)
                    }
                    if (res.jobTopic === 'Return' && res.jobStatus === 'Closed') {
                        setLastModifyReturn(res.lastModifiedDatetime!!)
                    }
                    if (res.jobTopic === 'Approve' && res.jobStatus === 'Closed') {
                        setLastModifyApprove(res.lastModifiedDatetime!!)
                        setApproveName(res.approverName!!)
                    }
                    if (res.attachedSignatureReplace !== '') {
                        const SignatureReplace = JSON.parse(res.attachedSignatureReplace!!)
                        if (SignatureReplace) {
                            setTrimmedDataURL(SignatureReplace[0].url)
                        }
                    }
                    if (res.attachedSignatureReturn !== null) {
                        const SignatureReturn = JSON.parse(res.attachedSignatureReturn!!)
                        if (SignatureReturn) {
                            setTrimmedDataURL2(SignatureReturn[0].url)
                        }
                    }
                    if (res.attachedSignatureApprove !== null) {
                        const SignatureApprove = JSON.parse(res.attachedSignatureApprove!!)
                        if (SignatureApprove) {
                            setTrimmedDataURLApprove(SignatureApprove[0].url)
                        }
                    }
                })
            }
            setTempLogistic([])
            setTempRepair([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisible])

    useEffect(() => {
        User.forEach((res) => {
            if (res.name === dataWO?.engineerGroup) {
                (res.assignees || []).forEach((it) => {
                    if (it.name === dataWO.engineerName) {
                        setPhone(it.phone)
                        setEmail(it.email)
                    }
                    if (it.email === dataWO.engineerName) {
                        setEngineerName(it.name)
                    }
                })
            } else {
                const result = res.assignees.filter(data => data.email === username)
                if (result[0] !== undefined && roleId === '799') {
                    setApproveName(result[0]?.name)
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [User])

    useEffect(() => {
        fetchDataAsset(props.woId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataRelateAsset, props.isVisible])

    useEffect(() => {
        if (props.chargingGroup) {
            classifyCharging(props.chargingGroup)
        }
    }, [props.chargingGroup])

    const fetchDataAsset = async (id) => {
        const res = await getAssetRelatedWo(id)
        if (res) {
            classifyAsset(res)
        }
    }

    const fetchDataCharging = () => {
        props.getChargingByWoId(props.woId as unknown as number).catch((err) => console.log(err))
    }

    const fetchDataWO = () => {
        getWorkOrderById(props.woId).then((res) => {
            setDataWo(res)
        }).catch((err) => {
            if (location.pathname.includes('/WoFormEdit')) {
                message.error(`Failed loading initial TicketNumber data. ${err}`)
            }
        })
    }

    const fetchAssignmentUser = () => {
        getAssignmentUser().then((res) => {
            setUser(res)
        }).catch((err) => {
            if (location.pathname.includes('/WoFormEdit')) {
                message.error(`Failed loading initial TicketNumber data. ${err}`)
            }
        })
    }

    const showModal = () => {
        setVisibleModal(true)
    }

    const handleOk = () => {
        if (sigPad.isEmpty()) {
            return
        }
        setVisibleModal(false)
        if (props.JobTopic === 'Replace') {
            const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
            const stringLength = base64String.length - 'data:image/png;base64,'.length

            const fileSignature: UploadFile = {
                uid: 'signature1' + props.woId,
                name: 'signature1' + props.woId,
                status: 'done',
                url: base64String,
                type: 'image/png',
                size: stringLength
            }

            const fileList: UploadFile[] = []
            fileList.push(fileSignature)
            FSS.putFile(fileList, '/signature/', (uploadLink: UploadLink[]) => {
                const data = { attachedSignatureReplace: JSON.stringify(uploadLink) }
                updateJobSignature(props.idJob, data).then(() => {
                    if (props.isCloseStep) {
                        props.isCloseStep(true)
                        setLastModifyReplace(moment().format('YYYY-MM-DD HH:mm:ss'))
                    }
                }).catch((err) => {
                    console.log(err)
                })
            })
            setTrimmedDataURL(base64String)
        } else if (props.JobTopic === 'Approve') {
            const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
            const stringLength = base64String.length - 'data:image/png;base64,'.length

            const fileSignature: UploadFile = {
                uid: 'signature3' + props.woId,
                name: 'signature3' + props.woId,
                status: 'done',
                url: base64String,
                type: 'image/png',
                size: stringLength
            }

            const fileList: UploadFile[] = []
            fileList.push(fileSignature)
            FSS.putFile(fileList, '/signature/', (uploadLink: UploadLink[]) => {
                const data = {
                    attachedSignatureApprove: JSON.stringify(uploadLink),
                    approverName: approveName,
                    lastModifiedBy: username,
                    approverReason: approveReason
                }
                setFieldsValue({
                    ApproveReason: null
                })
                updateJobSignature(props.idJob, data).then(() => {
                    if (props.isCloseStep) {
                        props.isCloseStep(true)
                        setLastModifyApprove(moment().format('YYYY-MM-DD HH:mm:ss'))
                    }
                    setApproveReason('')
                    setIsShowButton(false)
                    console.log('isShowButton', isShowButton)
                    sendLineNotify(`
คุณ ${engineerName}<${dataWO?.engineerName}> 
Ticket has approved (Charging is Approved) 
Order ID: ${dataWO?.number}
Related Ticket Number : ${props.vSpaceIncidentNo ? props.vSpaceIncidentNo : '-'}
Approved by: ${username}
Topic: ${dataWO?.topic}
Location: ${dataWO?.contactUserLocation}
${window.location.href}`, dataWO?.supportTeam || ''
                    )
                    if (dataWO?.supportTeam !== dataWO?.engineerGroup) {
                        sendLineNotify(`
คุณ ${engineerName}<${dataWO?.engineerName}> 
Ticket has approved (Charging is Approved) 
Order ID: ${dataWO?.number}
Related Ticket Number : ${props.vSpaceIncidentNo ? props.vSpaceIncidentNo : '-'}
Approved by: ${username}
Topic: ${dataWO?.topic}
Location: ${dataWO?.contactUserLocation}
${window.location.href}`, dataWO?.engineerGroup || ''
                        )
                    }
                }).catch((err) => {
                    console.log(err)
                })
            })
            setTrimmedDataURLApprove(base64String)
        } else {
            const base64String = sigPad.getTrimmedCanvas().toDataURL('image/png')
            const stringLength = base64String.length - 'data:image/png;base64,'.length

            const fileSignature: UploadFile = {
                uid: 'signature2' + props.woId,
                name: 'signature2' + props.woId,
                status: 'done',
                url: base64String,
                type: 'image/png',
                size: stringLength
            }

            const fileList: UploadFile[] = []
            fileList.push(fileSignature)
            FSS.putFile(fileList, '/signature/', (uploadLink: UploadLink[]) => {
                const data = { attachedSignatureReturn: JSON.stringify(uploadLink) }
                updateJobSignature(props.idJob, data).then(() => {
                    if (props.isCloseStep) {
                        sessionStorage.setItem('isClose', 'true')
                        props.isCloseStep(true)
                        setLastModifyReturn(moment().format('YYYY-MM-DD HH:mm:ss'))
                    }
                }).catch((err) => {
                    console.log(err)
                })
            })
            setTrimmedDataURL2(base64String)
        }
    }

    const handleCancel = () => {
        setVisibleModal(false)
        setIsShowRejectModal(false)
        setApproveReason('')
        setFieldsValue({
            ApproveReason: null
        })
        try {
            sigPad.clear()
        } catch (e) {
            sigPad = {}
        }
    }

    const classifyAsset = (data) => {
        const asset = data.filter(it => it.assetType === 'asset' || it.assetType === null)
        const spare = data.filter(it => it.assetType === 'spare')
        setTempAsset(asset)
        setTempSpare(spare)
    }

    const classifyCharging = (data) => {
        const Logistic = data.filter(it => it.chargeType === 'Logistic')
        const Repair = data.filter(it => it.chargeType === 'Repair')
        setTempLogistic(Logistic)
        setTempRepair(Repair)
    }

    const handleReject = () => {
        setIsShowRejectModal(false)
        const promiseAll: Promise<Job>[] = []
        const data = {
            lastModifiedBy: username,
            jobStatus: '',
            approverReason: approveReason
        }
        props.job.forEach((job) => {
            if (job.jobTopic === 'Approve') {
                data.jobStatus = 'Rejected'
                promiseAll.push(updateJobStatus(job.id!!, data))
                sendLineNotify(`
คุณ ${engineerName}<${dataWO?.engineerName}> 
Ticket has not approved (Charging is not Approved)
Order ID: ${dataWO?.number}
Related Ticket Number : ${props.vSpaceIncidentNo ? props.vSpaceIncidentNo : '-'}
Rejected by: ${username}
Topic: ${dataWO?.topic}
Location: ${dataWO?.contactUserLocation}
${window.location.href}`, dataWO?.supportTeam || ''
                )
                if (dataWO?.supportTeam !== dataWO?.engineerGroup) {
                    sendLineNotify(`
คุณ ${engineerName}<${dataWO?.engineerName}> 
Ticket has not approved (Charging is not Approved)
Order ID: ${dataWO?.number}
Related Ticket Number : ${props.vSpaceIncidentNo ? props.vSpaceIncidentNo : '-'}
Rejected by: ${username}
Topic: ${dataWO?.topic}
Location: ${dataWO?.contactUserLocation}
${window.location.href}`, dataWO?.engineerGroup || ''
                    )
                }
            }
            if (job.jobTopic === 'Repair' || job.jobTopic === 'QC' || job.jobTopic === 'Return') {
                data.jobStatus = 'Cancelled'
                promiseAll.push(updateJobStatus(job.id!!, data))
            }
            setFieldsValue({
                ApproveReason: null
            })
            setApproveReason('')
        })
        Promise.all(promiseAll).finally(() => {
            props.getAllJob(props.woId)
        })
    }

    const checkHasJobTopic = (jobTopic) => {
        const hasJob = props.job.find(job => job.jobTopic === jobTopic)
        return !!(hasJob)
    }

    const handlePrint = useReactToPrint({
        documentTitle: 'WO_' + dataWO?.id + '_' + moment().format('YYYY-MM-DD'),
        pageStyle: pageStyle,
        fonts: [
            {
                family: 'HFMonorita',
                source: "url('https://fonts.cdnfonts.com/css/hfmonorita')"
                // source: "url('%PUBLIC_URL%/HFMonorita-Regular.ttf')"
            }
        ],
        content: () => componentRef.current!!
    })

    return (
        <>
            <div className='FontTest' style={{ padding: '5%' }} ref={componentRef}>
                <div style={{ margin: '5%', textAlign: 'center' }}>
                    <h2>{props.isApprove ? 'Asset Approval Form' : 'Asset Received Form'}</h2>
                </div>
                <Row gutter={16} className='page-break'>
                    <Col span={12} >
                        <div className='BG_Gray' style={{ minHeight: 140 }}>
                            <p className='sub-title' style={{ textAlign: 'center' }}>Contact User</p>
                            <div style={{ paddingLeft: '1%' }}>
                                <h3 className='content'> Name : {dataWO?.contactUserName}</h3>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="mail" /> : {dataWO?.contactUserEmail}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="phone" /> : {dataWO?.contactUserPhone}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="home" /> : {dataWO?.contactUserLocation}</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='BG_Gray' style={{ minHeight: 140 }}>
                            <p className='sub-title' style={{ textAlign: 'center' }}>Engineer on site</p>
                            <div style={{ paddingLeft: '1%' }}>
                                <p className='content'> Engineer name : {engineerName} {dataWO?.engineerGroup ? <span>( {dataWO?.engineerGroup} )</span> : null} </p>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="mail" /> : {email}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        <span className='TextFrontDesc'><Icon type="phone" /> : {phone}</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br /> <br /><hr />
                <div>
                    <h3 className='sub-title'>Related Asset</h3>
                </div>
                <div style={{ paddingLeft: '2%' }}>
                    <Icon type="laptop" /><span style={{ fontWeight: 'bold' }}> Asset</span>
                    <div>
                        <TableRelateAsset assetItem={tempAsset} />
                    </div>
                    <br /><br />
                    <Icon type="laptop" /><span style={{ fontWeight: 'bold' }}> Spare Asset</span>
                    <div>
                        <TableSpareAsset spareItem={tempSpare} />
                    </div>
                </div>
                <br /><br />
                {props.isApprove
                    ? <>
                        <div>
                            <h3 className='sub-title'>Charging</h3>
                        </div>
                        <div style={{ paddingLeft: '2%' }}>
                            <Icon type="laptop" /><span style={{ fontWeight: 'bold' }}> Logistic Charging</span>
                            <div>
                                <TableCharging data={tempLogistic[0]} />
                                <Row style={{ marginTop: 5 }}>
                                    <Col style={{ color: '#828282' }} span={12}>
                                        <span>Summary Amount</span>
                                    </Col>
                                    <Col style={{ textAlign: 'end' }} span={12}>
                                        <span style={{ color: '#828282', marginRight: 15 }}>Total</span>
                                        <span>{tempLogistic[0]?.total ? tempLogistic[0]?.total : 0}</span>
                                        <span style={{ color: '#828282', marginLeft: 15, marginRight: 30 }}>THB</span>
                                    </Col>
                                </Row>
                            </div>
                            <br /><br />
                            <Icon type="laptop" /><span style={{ fontWeight: 'bold' }}> Repair Charging</span>
                            <div>
                                <TableCharging data={tempRepair[0]} />
                                <Row style={{ marginTop: 5 }}>
                                    <Col style={{ color: '#828282' }} span={12}>
                                        <span>Summary Amount</span>
                                    </Col>
                                    <Col style={{ textAlign: 'end' }} span={12}>
                                        <span style={{ color: '#828282', marginRight: 15 }}>Total</span>
                                        <span>{tempRepair[0]?.total ? tempRepair[0]?.total : 0}</span>
                                        <span style={{ color: '#828282', marginLeft: 15, marginRight: 30 }}>THB</span>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div>
                                <Row style={{ marginTop: 5 }}>
                                    <Col style={{ color: '#828282' }} span={12}>
                                        <span>Summary Amount (Logistic Charging & Repair Charging)</span>
                                    </Col>
                                    <Col style={{ textAlign: 'end' }} span={12}>
                                        <span style={{ color: '#828282', marginRight: 15 }}>Total</span>
                                        <span>{(tempLogistic[0]?.total as number || 0) + (tempRepair[0]?.total as number || 0)}</span>
                                        <span style={{ color: '#828282', marginLeft: 15, marginRight: 30 }}>THB</span>
                                    </Col>
                                </Row>
                            </div>
                        </div><br />

                    </>
                    : null}
                <br />
                {props.isApprove
                    ? (
                        <>
                            <Row gutter={16} className='page-break'>
                                <Col span={12} >
                                    <div className='BG_Gray' style={{ height: 255 }}>
                                        <p>Reason : </p>
                                        <p>{jobApproveReason}</p>
                                    </div>
                                </Col>
                                <Col span={12} style={{ float: 'right' }}>
                                    <div className='BG_Gray'>
                                        <p>Signature date : {lastModifyApprove}</p>
                                        <span>Signature</span>
                                        {props.JobTopic === 'Repair' ? <span style={{ float: 'right' }}>{!trimmedDataURLApprove ? <Icon type="edit" onClick={() => showModal()} /> : null} </span> : null}
                                        <br />
                                        <div style={{ width: 'auto', height: '150px', textAlign: 'center' }}>
                                            {trimmedDataURLApprove
                                                ? <img alt='trimmedDataURLApprove' style={{ maxWidth: '100%', maxHeight: '100%' }} src={trimmedDataURLApprove} />
                                                : null}
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col span={2}>
                                                <span>(</span>
                                            </Col>
                                            <Col span={20} style={{ textAlign: 'center' }}>
                                                <span>{approveName}</span>
                                            </Col>
                                            <Col span={2}>
                                                <span>)</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row gutter={16} className='page-break'>
                            { checkHasJobTopic('Replace')
                                ? (<Col span={12} >
                                    <div className='BG_Gray'>
                                        <p>Replace date :  {lastModifyReplace}</p>
                                        <span>Signature</span>
                                        {props.JobTopic === 'Replace' ? <span style={{ float: 'right' }}>{!trimmedDataURL ? <Icon type="edit" onClick={() => props.JobTopic === 'Replace' ? showModal() : null} /> : null}</span> : null}
                                        <br />
                                        <div style={{ width: 'auto', height: '150px', textAlign: 'center' }}>
                                            {trimmedDataURL
                                                ? <img alt='trimmedDataURL' style={{ maxWidth: '100%', maxHeight: '100%' }} src={trimmedDataURL || undefined} />
                                                : null}
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col span={2}>
                                                <span>(</span>
                                            </Col>
                                            <Col span={20} style={{ textAlign: 'center' }}>
                                                <span>{dataWO?.contactUserName}</span>
                                            </Col>
                                            <Col span={2}>
                                                <span>)</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>) : null
                            }
                            {
                                checkHasJobTopic('Return') ? (
                                    <Col span={12} >
                                        <div className='BG_Gray'>
                                            <p>Return date : {lastModifyReturn}</p>
                                            <span>Signature</span>
                                            {props.JobTopic === 'Return' ? <span style={{ float: 'right' }}>{!trimmedDataURL2 ? <Icon type="edit" onClick={() => showModal()} /> : null} </span> : null}
                                            <br />
                                            <div style={{ width: 'auto', height: '150px', textAlign: 'center' }}>
                                                {trimmedDataURL2
                                                    ? <img alt='trimmedDataURL2' style={{ maxWidth: '100%', maxHeight: '100%' }} src={trimmedDataURL2} />
                                                    : null}
                                            </div>
                                            <hr />
                                            <Row>
                                                <Col span={2}>
                                                    <span>(</span>
                                                </Col>
                                                <Col span={20} style={{ textAlign: 'center' }}>
                                                    <span>{dataWO?.contactUserName}</span>
                                                </Col>
                                                <Col span={2}>
                                                    <span>)</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ) : null
                            }
                        </Row>)
                }
                {props.isApprove && roleId === '799' && !trimmedDataURLApprove && jobStatus !== 'Rejected'
                    ? <Row gutter={8}>
                        <Col span={24} >
                            <span className='approval-button-group' style={{ float: 'right', marginTop: 20 }}>
                                <Button type="danger" loading={false} style={{ width: 80, marginRight: 10 }} onClick={() => setIsShowRejectModal(true)}>Reject</Button>
                                <Button type="primary" loading={false} style={{ width: 80 }} onClick={() => setVisibleModal(true)}>Approve</Button>
                            </span>
                        </Col>
                    </Row> : null}

                <Modal
                    title="Signature"
                    visible={visibleModal}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={'Confirm'}
                >
                    <SignaturePad ref={(ref) => { sigPad = ref }} canvasProps={{ className: styles.sigPad }} />
                    {props.isApprove
                        ? <Form>
                            <Form.Item label='Reason of approve'>
                                {getFieldDecorator('ApproveReason',
                                    {})(
                                    <Input placeholder="Please enter reason of approve" maxLength={255} onBlur={(e) => setApproveReason(e.target.value)} />
                                )}                        </Form.Item>
                        </Form> : null}
                </Modal>
                <Modal
                    title="Reject"
                    visible={isShowRejectModal}
                    onOk={handleReject}
                    onCancel={handleCancel}
                    okText={'Reject'}
                >
                    <Form>
                        <Form.Item label='Reason of reject'>
                            {getFieldDecorator('ApproveReason',
                                {})(
                                <Input placeholder="Please enter reason of reject" maxLength={255} onBlur={(e) => setApproveReason(e.target.value)} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            <div style={{paddingLeft: '5%'}}>
                <Button type='ghost' onClick={handlePrint} loading={false} icon='upload'> Export</Button>
            </div>
        </>
    )
}

const DetailSignatureForm = Form.create<Props>({})(DetailSignature)
export default connect(mapStateToProps,
    {
        getAllJob, getChargingByWoId
    })(DetailSignatureForm)
