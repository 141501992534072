import { BaseDocument } from '../../common-model'
import {CustomFiled} from '../../configurable-fields'

export interface FilterCategory {
    CatalogName: string[]
    Status: string[]
    Parent: string[]
}

export interface SearchFilterCategory {
    CatalogName?: string
    Status: string [] | string
    Parent?: string
    CatalogId: string
    ParentId: string
}

export interface FilterItem {
    CatalogName?: string
    CategoryName?: string
    CatalogId?: number
    CategoryId?: number
    Status?: string[] | string
    Type?: string[] | string
}

export interface ResponseContentDetails<T> {
    content: T
    totalElements: number
    totalPages: number
    number: number
    size: number
    total: number
    data?: T
}

export const defaultResponseContentDetails = {
    content: [],
    totalElements: 0,
    totalPages: 0,
    number: 0,
    size: 0,
    total: 0
}

export interface WorkFlowPagination {
    page?: number
    total?: number
    sortBy?: string
    orderBy?: any
    filter?: any[]
    searchField?: string
    searchValue?: string
}

export interface SelfServiceCatalog extends BaseDocument {
    name: string
    description?: string
    status: string
}

export interface SelfServiceCatagory extends BaseDocument {
    name: string
    description?: string
    status: string
    parent?: any
    catalog: SelfServiceCatalog
}

export interface SelfServiceItem extends BaseDocument {
    name: string
    category: SelfServiceCatagory
    description?: string
    type: string
    status: string
    imageUrl?: string
    details?: string
    fields: CustomFiled[]
    impact?: string
    urgency?: string
    workflowId?: number
    needApproval?: boolean
    expectedCompletion?: number
    priority?: string
}

export interface PageServiceModel {
    pageCatalog: number
    pageCategory: number
    pageItemCatalog: number
}
