
interface data {
    id: string
    value: string
}

class DropdownData {
    dataList: data[]
    constructor(data: data[]) {
        this.dataList = data
    }

    getValueById: Function = (id: string) => {
        const dataSelected = this.dataList.find(dropDownData => dropDownData.id === id)
        if (dataSelected) {
            return dataSelected.value
        }
        return '-'
    }
}

export const dropdownDataManageBy = new DropdownData([
    { id: 'Change Management', value: 'Change Management' },
    { id: 'Database Management', value: 'Database Management' },
    { id: 'Hardware Management', value: 'Hardware Management' },
    { id: 'Incident Management', value: 'Incident Management' },
    { id: 'Major Incident Management', value: 'Major Incident Management' },
    { id: 'Service Design', value: 'Service Design' }
])

export const dropdownDataPublishWorkflow = new DropdownData([
    { id: '0', value: 'Instant Publish' },
    { id: '1', value: 'Approval Publish' }
])

export const dropdownDataRetireWorkflow = new DropdownData([
    { id: '0', value: 'Instant Retire' },
    { id: '1', value: 'Approval Retire' }
])

export const dropdownDataVisibleTo = new DropdownData([
    { id: '0', value: 'Public' },
    { id: '1', value: 'All Agent Groups' },
    { id: '2', value: 'Selected Agent Groups' }
])

export const dropdownDataType = new DropdownData([
    { id: '0', value: 'FAQ' },
    { id: '1', value: 'How To' },
    { id: '2', value: 'URL' },
    { id: '3', value: 'Known Error' },
    { id: '4', value: 'Problem Solution' }
])

export const defaultKnowledge = {
    id: undefined,
    topic: undefined,
    status: undefined,
    categoryName: undefined,
    categoryId: undefined,
    folderName: undefined,
    folderId: undefined,
    type: undefined,
    disableComment: undefined,
    detail: undefined,
    tagsList: undefined,
    helpful: undefined,
    notHelpful: undefined,
    views: undefined,
    used: undefined,
    major: undefined,
    minor: undefined,
    createdBy: undefined,
    createdDatetime: undefined
}
