import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { Adjust } from '../Model'

export const AdjustContext = createContext({})

const initialState: Adjust[] = []

interface Param{}

type props = PropsWithChildren<Param>
const AdjustReducer = (state: Adjust[], action: {type: string; payload: any}) => {
    switch (action.type) {
    case 'GET_Adjust':
        return action.payload || [] as Adjust[]
    default:
        return state
    }
}

export const AdjustProvider = (props: props) => {
    const [AdjustState, AdjustDispatch] = useReducer(AdjustReducer, initialState)
    const GetAdjust = (payload: Adjust[]) => {
        AdjustDispatch({type: 'GET_Adjust', payload})
    }
    return (
        <AdjustContext.Provider value = {{ AdjustState, GetAdjust}} >
            {props.children}
        </AdjustContext.Provider>
    )
}
