/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Icon, Table, Tooltip, Modal, message, Tag, Row, Col, Input, Button, Form } from 'antd'
import { FilterData, PreventiveWithRelated } from '../Model'
import ExpandListAsset from './ExpandListAsset'
import { deletePMPlan, updateDescriptionPMPlan } from '../services'
import './stylePM.css'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import PageService from '../services/PageService'
import ReactHtmlParser from 'react-html-parser'
import { FormComponentProps } from 'antd/es/form'
interface Param {
    dataTransfer: PreventiveWithRelated[]
    isLoading: boolean
    reloadPage: Function
    getDataEdit: Function
    changePage: Function
    changePageSize: Function
    changeSortByAndOrderBy: Function
    total: number
    pageSize: number
    page: number
    valueFilter: FilterData
    valueSearch?: string
}

type Props = Param & FormComponentProps
const { confirm } = Modal
const { TextArea } = Input
const PreventiveTable: React.FC<Props> = (props: Props) => {
    // const [page, setPage] = useState<number>(PageService.getInstance().page)
    const [isForceFetch, setIsForceFetch] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [valueDescription, setValueDescription] = useState<string>()
    const [valuePMID, setValuePMID] = useState<string>()
    const [isLoadingEditDesc, setIsLoadingEditDesc] = useState(false)

    useEffect(() => {
        if (props.isLoading) {
            setIsForceFetch(true)
        }
    }, [props.isLoading])

    const handleOpenModal = (data, pmid) => {
        setValuePMID(pmid)
        setValueDescription(data)
        setIsVisible(true)
    }

    const columns = [
        {
            title: 'Plan Date',
            dataIndex: '',
            key: 'pmPlanDate',
            sorter: true,
            width: '8%',
            render: (data: PreventiveWithRelated) => (
                <>
                    {data?.pmPlanDate}
                </>
            )
        },
        {
            title: 'ID',
            dataIndex: '',
            key: 'pmId',
            sorter: true,
            width: '5%',
            render: (data: PreventiveWithRelated) => (
                <>
                    {data.pmId}
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: '',
            key: 'description',
            sorter: true,
            width: '25%',
            render: (data: PreventiveWithRelated) => {
                return (<>
                    {data.description ? <span>{(data.description as string).length > 50 ? <>
                        <Tooltip placement='bottom' title={data.description}>{(data.description as string).substring(0, 50) + '...'}</Tooltip>
                    </> : data.description}</span> : ''}
                </>)
            }
        },
        {
            title: 'PM Location',
            dataIndex: '',
            key: 'pmLocation',
            sorter: true,
            render: (data: PreventiveWithRelated) => {
                const tempPMlocation: string[] = []
                if (data.pmLocation) {
                    // eslint-disable-next-line no-unused-expressions
                    data.pmLocation?.forEach((it, index) => {
                        if (index > 2) {
                            tempPMlocation.push('- ' + it)
                        }
                    })
                }

                return (
                    <span>
                        {data.pmLocation?.map((it, index) => {
                            if (index <= 2) {
                                return <Tag color='geekblue' key={index}>{it}</Tag>
                            }
                        })}
                        {data.pmLocation?.length > 3 && <Tooltip placement='bottom' title={ReactHtmlParser(tempPMlocation.join('</br>'))}><Tag color='geekblue'> +{data.pmLocation?.length - 3}</Tag></Tooltip>}
                    </span>
                )
            }
        },
        {
            title: 'Overall Status',
            dataIndex: '',
            key: 'pmIdStatus',
            sorter: true,
            render: (data: PreventiveWithRelated) => (
                <>
                    {/* {
                        data?.preventiveMaintenance.pmInProgress && data?.preventiveMaintenance.pmTotal
                            ? data?.preventiveMaintenance.pmInProgress === data?.preventiveMaintenance.pmTotal
                                ? 'Completed' : 'On Process' : data?.preventiveMaintenance.pmIdStatus
                    } */}
                    {data?.pmIdStatus}
                </>
            )
        },
        {
            title: 'Progress',
            dataIndex: '',
            key: '',
            render: (data: PreventiveWithRelated) => (
                <>
                    {data?.progress}
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (data: PreventiveWithRelated) => (
                <>
                    {data?.pmIdStatus === 'OnProcess' && checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan') ? <>
                        <Icon type="edit" onClick={() => handleOpenModal(data.description, data.pmId)}/>
                    </> : null}
                    {data?.pmIdStatus === 'Open' && checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')
                        ? <Tooltip placement="bottom" title={'Edit'}><Icon className="edit_icon" type="edit" onClick={() => UpdatePm(data)}/></Tooltip>
                        : null}
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (data: PreventiveWithRelated) => (
                <>
                    {data?.pmIdStatus === 'Open' && checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')
                        ? <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => deletePM(data.pmId)} /></Tooltip>
                        : null}
                </>
            )
        }
    ]

    const UpdatePm = (data) => {
        if (props.getDataEdit) {
            props.getDataEdit(data)
        }
    }

    const deletePM = (pmID) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                deletePMPlan(pmID).then(() => {
                    message.success('This Preventive was deleted!!')
                    if (props.reloadPage) {
                        props.reloadPage(true)
                    }
                }).catch(() => {
                    message.warning('Delete Data Error!!')
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const expandedRowRender = (expandedRow: PreventiveWithRelated) => {
        setIsForceFetch(false)
        return <ExpandListAsset PMID={expandedRow.pmId} isParentsLoading={props.isLoading || isForceFetch} valueFilter={props.valueFilter} valueSearch={props.valueSearch}/>
    }

    const handleOnclickPageNumber = (page: number) => {
        PageService.getInstance().page = page
        // setPage(page)
        props.changePage(page)
    }

    const onChange = (pagination, filters, sorter) => {
        props.changePageSize(pagination.pageSize)
        if (sorter.order) {
            props.changeSortByAndOrderBy({ sortBy: sorter.column.key, orderBy: sorter.order === 'ascend' ? 'asc' : 'desc' })
        } else {
            props.changeSortByAndOrderBy({ sortBy: 'lastModifiedDatetime', orderBy: undefined })
        }
    }

    const UpdateDescription = () => {
        setIsLoadingEditDesc(true)
        updateDescriptionPMPlan(valuePMID, valueDescription?.replace(/ +/g, ' ')?.trim()).then(() => {
            message.success('The Update has finished successfully.')
            if (props.reloadPage) {
                props.reloadPage(true)
            }
        }).catch((err) => {
            message.error('Update Ticket Fail' + err)
        }).finally(() => {
            setIsVisible(false)
            props.form.resetFields(['Description'])
            setIsLoadingEditDesc(false)
        })
    }

    const validateDescription = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(CheckValue)) {
                if (CheckValue.length >= 1) {
                    callback()
                } else {
                    callback('Enter more than 1 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(CheckValue)) {
                    callback()
                } else {
                    callback('Enter more than 1 characters')
                }
            }
        } else {
            callback()
        }
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'You want to leave this page ?',
            onOk() {
                props.form.resetFields(['Description'])
                setIsVisible(false)
            },
            onCancel() {
                // setDataSource2([])
                // dataSource = []
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            <Modal
                title="Edit Description"
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                closable={false}
                footer={null}
            >
                <Form>
                    <Form.Item
                        label="Description"
                    >
                        {props.form.getFieldDecorator('Description', {
                            initialValue: valueDescription,
                            rules: [
                                {required: false, message: 'Please Input your Description!'},
                                {validator: validateDescription},
                                { whitespace: true }
                            ]
                        })(
                            <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} placeholder="Description" onChange={(e) => setValueDescription(e.target.value)} maxLength={255}/>
                        )}
                    </Form.Item>
                </Form>
                <div style={{ textAlign: 'end', paddingTop: 5 }}>
                    <Button htmlType="button" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType='button' className="ml5" onClick={() => UpdateDescription()} disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}
                        style={{ marginLeft: 5}} loading={isLoadingEditDesc}>
                        Submit
                    </Button>
                </div>
            </Modal>
            <Table
                dataSource={props.dataTransfer || []}
                columns={columns}
                loading={props.isLoading}
                scroll={{ x: 'max-content' }}
                rowKey={record => record.pmId}
                expandedRowRender={expandedRowRender}
                pagination={{
                    pageSize: props.pageSize,
                    current: props.page,
                    total: props.total,
                    onShowSizeChange(current, size) {
                        props.changePage(1)
                        props.changePageSize(size)
                    },
                    onChange: (event) => { handleOnclickPageNumber(event) },
                    showSizeChanger: true
                }}
                onChange={onChange}
            />
        </div>
    )
}

const PreventiveTableForm = Form.create<Props>({ name: 'PreventiveTableForm' })(PreventiveTable)
export default PreventiveTableForm
