import { Incident } from './model'
import { SlaCalculation } from '../../sla-management/sla-calculation/model'
import { SlaPolicy } from '../../sla-management/sla-policy/model'
import { BusinessHour } from '../../sla-management/business-hour'

export enum IncidentWebSocketEventType {
    Updated = 'Updated',
    Deleted = 'Deleted',
    SlaCalculationsUpdated = 'SlaCalculationsUpdated',
    GroupDashboardUpdate = 'GroupDashboardUpdate',
    StatusDashboardUpdate = 'StatusDashboardUpdate',
    PriorityDashboardUpdate = 'PriorityDashboardUpdate',
    SlaCalculationsDashboardUpdated = 'SlaCalculationsDashboardUpdated'
}

export interface IncidentWebSocketEvent {
    eventType: IncidentWebSocketEventType
    incidentId: number
    incident?: Incident
    slaCalculations?: SlaCalculation[],
    // slaPolicyHistories?: History<SlaPolicy>[],
    slaPolicyHistories?: SlaPolicy[],
    // businessHourHistories?: History<BusinessHour>[]
    businessHourHistories?: BusinessHour[]
}
