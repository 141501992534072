import {Button, Card, Col, Form, Icon, message, Row} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import React, {useEffect, useState} from 'react'
import {getPackageFreeTrial} from '../../TenantSetting/Services/Service'
import {packages} from '../../TenantSetting/Services/model'

interface Param {
    current: number
    setCurrent: Function
    freeUser: any
    setFreeUser: Function
}

type Props = Param & FormComponentProps
const Step1Free: React.FC<Props> = (props: Props) => {
    const [packageFreeTrial, setPackageFreeTrial] = useState<packages>()
    useEffect(() => {
        getPackageFreeTrial().then((res) => {
            if (res) {
                console.log(res)
                setPackageFreeTrial(res)
            }
        }).catch((err) => {
            message.error(err?.response?.message || 'Error get Packages Master')
        })
    }, [])

    const handleSubmit = () => {
        props.freeUser.userRegistration = {}
        props.freeUser.packageRegister = packageFreeTrial?.id
        props.freeUser.durationDay = packageFreeTrial?.durationDay
        props.setFreeUser(props.freeUser)
        props.setCurrent(props.current + 1)
    }
    return (
        <div className={'step-content'}>
            <Row>
                <Col className={'card-center'}>
                    <Card title="Free Trial Package" bordered={true} style={{width: '80%', borderRadius: 5}}
                        className={'card-title'}>
                        <Row><b>Service that you can use {packageFreeTrial?.durationDay} Days free trial.</b></Row>
                        <br/>
                        <div>
                            <Row gutter={[8, 8]}>
                                {packageFreeTrial?.packageService.map((item, index) => {
                                    return <div key={index}>
                                        {index % 2 !== 0
                                            ? (<Col span={12}><span className={'ql-indent-1'}><Icon
                                                style={{color: '#1890ff'}}
                                                type="check-circle"/> {item.service.servicesName}</span></Col>)
                                            : (<Col span={12}><span className={'ql-indent-1'}><Icon type="check-circle"
                                                style={{color: '#1890ff'}}/> {item.service.servicesName}</span></Col>)
                                        }
                                    </div>
                                })}
                            </Row>
                        </div>
                        <Row style={{paddingTop: 10}}>
                            <Row><b>Limit User</b></Row>
                            <br/>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="user"
                                style={{color: '#1890ff'}}/> {packageFreeTrial?.quotaMaster.limitGoServiceUser} User</span></Col>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="team"
                                style={{color: '#1890ff'}}/> {packageFreeTrial?.quotaMaster.limitServicePortal} People</span></Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Row><b>Limit Storage</b></Row>
                            <br/>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="upload"
                                style={{color: '#1890ff'}}/> File storage size {packageFreeTrial?.quotaMaster.limitStorageSize} GB.</span></Col>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="database"
                                style={{color: '#1890ff'}}/> Database size {packageFreeTrial?.quotaMaster.limitDbSize} GB.</span></Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row style={{paddingTop: '5%'}}>
                <Col span={12} style={{paddingTop: 10}}>
                    <span style={{textAlign: 'start'}}> Already have login and password? <a
                        href={'/'}> Sign in </a></span>
                </Col>
                <Col span={12} style={{textAlign: 'end'}}>
                    <Button type='primary' onClick={handleSubmit}>Next</Button>
                </Col>
            </Row>
        </div>
    )
}

const MyStep1Free = Form.create<Props>({name: 'Step1Free'})(Step1Free)
export default MyStep1Free
