import React, { useState } from 'react'
import {Button, Col, Icon, Row} from 'antd'
import AutoAvatar from '../common-components/autoAvatar'
import './Profile.css'
import {Space} from '../common-components/Space'
import {UserProfile} from '../authorization-module/user-role/duck/model'
import {logOutMSP} from '../authorization-module/user-role/duck/service'
import {tokenDecode} from '../common-misc'
import { deleteCookie } from '../guard/route'

interface Param {
    dataUser: UserProfile
}

const Profile = (props:Param) => {
    const [isloading, setIsLoading] = useState(false)
    // const [dataUser, setDataUser] = useState<UserProfile>()
    // useEffect(() => {
    //     getUserProfile().then((res) => {
    //         if (res) {
    //             setDataUser(res)
    //         }
    //     })
    // }, [])

    const logout = () => {
        setIsLoading(true)
        logOutMSP().then(r => {
            if (r) {
                console.log(r)
            }
        }).finally(() => {
            setIsLoading(false)
            sessionStorage.clear()
            deleteCookie('access_token')
            window.location.href = '/'
        })
    }

    return (
        <div className='profile-show' style={{padding: 15}}>
            <Row gutter={[8, 8]} style={{borderBottom: '1px solid' }}>
                <Col span={12}>
                    <span className={'Herder'}>Profile</span>
                </Col>
                <Col span={12} style={{textAlign: 'end'}}>
                    {/* <Icon type="close" /> */}
                </Col>
            </Row>
            <Space size={10} />
            <Row >
                <Col span={24} style={{textAlign: 'center'}}>
                    <AutoAvatar title={props.dataUser?.pictureProfile ? JSON.parse(props.dataUser?.pictureProfile)[0].url : tokenDecode()?.sub || ''} broken={false} username={sessionStorage.getItem('username')?.toString()!!} size={85}/>
                </Col>
            </Row>
            <Row>
                <Col span={24} >
                    <div className={'Header'} style={{justifyContent: 'center', padding: 10, display: 'ruby' }}>{props.dataUser?.company?.companyName}</div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className={'Text'} style={{justifyContent: 'center'}}>{props.dataUser?.fullName}</div>
                </Col>
            </Row>
            <Space size={10} />
            <Row >
                <Col span={24} style={{textAlign: 'start'}}><p><Icon type="phone" /> {props.dataUser?.tel}</p></Col>
            </Row>
            <Row >
                <Col span={24} style={{textAlign: 'start'}}><p><Icon type="mail" /> {props.dataUser?.email}</p></Col>
            </Row>
            <hr/>
            <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Button type={'primary'} onClick={logout} loading={isloading}><Icon type="logout" />   Log out</Button>
                    {/* <Button type={'primary'} onClick={logout} ><Icon type="logout" />logout</Button> */}
                </Col>
            </Row>
        </div>
    )
}

export default Profile
