import { Dispatch } from "redux"
import { decryptBody, encryptBody } from "../../../common-misc/encryptBody"
import { AssetOwnerLocation, GetAllAssetOwnerLocationEvent } from "../../../msp-wo/asset-management"
import { axiosPatch, axiosPost, axiosGet } from "../../../msp-wo/asset-management/rest"
import { herderPostPatch } from "../../../common-misc"
import { Asset } from "../../../msp-wo/Relate/Asset"


export const SearchTicketRelatedIncidentView = (TicketId: number | undefined, search?: string | undefined, sortBy?: string, orderBy?: string, skip?: number, limit?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                page: skip,
                size: limit,
                sort: `${sortBy},${orderBy}`,
                value: search || null,
                id: TicketId
            }
            const path = '/api/assets/search-asset-related'
            const response = await axiosGet<AssetOwnerLocation>(path, {params})
            // @ts-ignore
            dispatch(GetAllAssetOwnerLocationEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const DeletedTicketRelatedIncidentView = async (TicketId: number | undefined, DeleteData) => {
    try {
        const encryptData = encryptBody(JSON.stringify(DeleteData))
        const response = await axiosPatch(`/api/related-tickets/${TicketId}`, encryptData, herderPostPatch)
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const CreateRelatedTicketIncidentView = async (IncidentId: number, AssetData: Asset[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(AssetData))
        const response = await axiosPost(`/api/related-tickets/${IncidentId}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const GetAllTicketRelatedInIncidentView = async (ticketId: number) => {
    try {
        const response = await axiosGet(`/api/related-tickets/${ticketId}/list`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}
