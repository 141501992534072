export const getRemoteFileAsBase64String = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

export const replaceName = (name: string) => {
    return name.replace(/ /g, '_')
}

export const checkAllObjectsAreNull = (obj: any) => {
    if (obj !== undefined) {
        return Object.values(obj).every((e) => e === null || e === undefined)
    } else {
        return true
    }
}

export const compareTwoObjects = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}
