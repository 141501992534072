import {
    CreatePriorityMatrixNewProblemVersionSuccessEvent,
    GetAllPriorityProblemSuccessEvent,
    GetPriorityMatrixProblemSuccessEvent,
    PriorityProblemStateEvent
} from './state-event'
import {PriorityLevelProblem, PriorityMatrixProblem} from './model'

export const priorityProblemStateReducer = (state: PriorityLevelProblem[] = [], event: PriorityProblemStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllPriorityProblemSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}

export const priorityProblemConfigurationStateReducer = (state: PriorityMatrixProblem[] = [], event: PriorityProblemStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetPriorityMatrixProblemSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === CreatePriorityMatrixNewProblemVersionSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
