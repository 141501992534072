import moment from 'moment'
import {
    AddIncidentViewSuccessEvent, CurrentViewCriteriaChangedEvent,
    GetCurrentIncidentViewSuccessEvent,
    IncidentViewStateEvent, UserChangeOrderByEvent, UserChangeSortByEvent,
    SetCurrentIncidentViewSuccessEvent,
    UpdateIncidentViewSuccessEvent,
    DeleteIncidentViewSuccessEvent
} from './state-event'
import { IncidentViewState } from './state-model'

export const incidentViewStateReducer = (state: IncidentViewState = {}, event: IncidentViewStateEvent<any> = { type: undefined, payload: undefined }) => {
    if (event.type === GetCurrentIncidentViewSuccessEvent.type ||
        event.type === SetCurrentIncidentViewSuccessEvent.type
    ) {
        state = event.payload
    } else if (event.type === AddIncidentViewSuccessEvent.type) {
        state.currentView = event.payload
        state.allVisibleViews = Array.from([...(state.allVisibleViews || []), event.payload.name]).sort()
    } else if (event.type === UpdateIncidentViewSuccessEvent.type) {
        state.currentView = event.payload
        state.allVisibleViews = Array.from([...(state.allVisibleViews || [])]).sort()
    } else if (event.type === CurrentViewCriteriaChangedEvent.type) {
        state.currentView!!.incFilter = event.payload
        state.currentView!!.isCriteriaDirty = true
    } else if (event.type === UserChangeSortByEvent.type) {
        state.currentView!!.incFilter.sortBy = event.payload
    } else if (event.type === UserChangeOrderByEvent.type) {
        state.currentView!!.incFilter.orderBy = event.payload
    } else if (event.type === DeleteIncidentViewSuccessEvent.type) {
        const combinedArray = [...state.allVisibleViews!!, ...event.payload];
        const occurrenceMap = combinedArray.reduce((map, item) => {
            map.set(item.id, (map.get(item.id) || 0) + 1);
            return map;
        }, new Map());
        state.allVisibleViews = combinedArray.filter(item => occurrenceMap.get(item.id) === 1)
    } else {
        return state
    }
    return { ...state, version: moment() }
}
