import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const SetNoneAssetDetailFieldListContext = createContext({})

const initialState: string[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const SetNoneAssetDetailFieldListReducer = (state: string[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_SetNoneAssetDetailFieldList':
        return action.payload || [] as string[]
    default:
        return state
    }
}

export const SetNoneAssetDetailFieldListProvider = (props: props) => {
    const [SetNoneAssetDetailFieldListState, SetNoneAssetDetailFieldListDispatch] = useReducer(SetNoneAssetDetailFieldListReducer, initialState)

    const GetSetNoneAssetDetailFieldList = (payload: string[]) => {
        SetNoneAssetDetailFieldListDispatch({ type: 'GET_SetNoneAssetDetailFieldList', payload })
    }


    return (
        <SetNoneAssetDetailFieldListContext.Provider value={{ SetNoneAssetDetailFieldListState, GetSetNoneAssetDetailFieldList }}>
            {props.children}
        </SetNoneAssetDetailFieldListContext.Provider>
    )
}
