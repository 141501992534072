import {People, PeopleManagementPagination} from '../../authorization-module/people/model'
import {
    AddPeopleSuccessEvent,
    DeletePeopleSuccessEvent,
    GetAllPeopleSuccessEvent,
    PeopleManagementStateEvent,
    UpdatePeopleCurrentPageSuccessEvent,
    UpdatePeopleFilterSuccessEvent,
    UpdatePeopleOrderBySuccessEvent,
    UpdatePeopleSearchFieldSuccessEvent,
    UpdatePeopleSearchValueSuccessEvent,
    UpdatePeopleSortBySuccessEvent,
    UpdatePeopleSuccessEvent,
    UpdatePeopleTotalPageSuccessEvent
} from './state-event'

export const PeopleManagementStateReducer = (state: People[] = [], event: PeopleManagementStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllPeopleSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddPeopleSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === UpdatePeopleSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else if (event.type === DeletePeopleSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else {
        return state
    }
}

export const PeoplePaginationStateReducer = (state: PeopleManagementPagination = {}, event: PeopleManagementStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdatePeopleTotalPageSuccessEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdatePeopleCurrentPageSuccessEvent.type) {
        state.page = event.payload
    } else if (event.type === UpdatePeopleOrderBySuccessEvent.type) {
        state.orderBy = event.payload
    } else if (event.type === UpdatePeopleFilterSuccessEvent.type) {
        state.filter = event.payload
    } else if (event.type === UpdatePeopleSearchFieldSuccessEvent.type) {
        state.searchField = event.payload
    } else if (event.type === UpdatePeopleSearchValueSuccessEvent.type) {
        state.searchValue = event.payload
    } else if (event.type === UpdatePeopleSortBySuccessEvent.type) {
        state.sortBy = event.payload
    } else {
        return state
    }
    return state
}
