import React, {useEffect, useState} from 'react'
import {Breadcrumb, Row, Col, Card, Table, Radio, Button} from 'antd'
import { Space } from '../../common-components/Space'
import '../CustomStyle.css'
import SubOrderDetail from './SubOrderDetail'
import {Redirect, useParams} from 'react-router'
import {getOrderById} from '../Services/Service'
import {Order, SubOrderDetailList} from '../Services/model'
import {Link} from 'react-router-dom'

interface Param {

}

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    color: '#323276'
}

interface mainTable {
    id: number
    MainPackage: string
    StartDate: string
    EndDate: string
    Price: string
}

type props = Param
const OrderDetail = (props: props) => {
    const [payType, setPayType] = useState(0)
    const [path, setPath] = useState<string>()
    const [getOrder, setOrder] = useState<Order>()
    const [getMainPackage, setMainPackage] = useState<mainTable[]>([])
    const [getAddOnPackage, setAddOnPackage] = useState<SubOrderDetailList[]>([])
    const { id }: any = useParams()

    useEffect(() => {
        getOrderById(id).then((res) => {
            if (res) {
                setOrder(res)
                const packageMain = res.packageSubscription.find((it) => it.packages.packageType === 'm')
                const packageAddon = res.packageSubscription.filter((it) => it.packages.packageType !== 'm')
                if (packageMain) {
                    const convertDataMainPackage = {
                        id: 1,
                        MainPackage: packageMain.packages.packageName,
                        StartDate: packageMain.packages.startDate,
                        EndDate: packageMain.packages.endDate,
                        Price: packageMain.packages.price + ' Baht'
                    }
                    setMainPackage([convertDataMainPackage])
                }
                if (packageAddon) {
                    const convertDataSubOrderDetailList: SubOrderDetailList[] = []
                    packageAddon.forEach((it) => {
                        const convertDataSubOrderDetail: SubOrderDetailList = {
                            title: it.packages.packageName,
                            price: it.price + ' Baht',
                            value: it.subscriptionTotal + (it.packages.packageType === 'u' ? ' User' : ' GB')
                        }
                        convertDataSubOrderDetailList.push(convertDataSubOrderDetail)
                    })
                    setAddOnPackage(convertDataSubOrderDetailList)
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'Main Package',
            dataIndex: 'MainPackage',
            key: 'MainPackage'
        },
        {
            title: 'Start Date',
            dataIndex: 'StartDate',
            key: 'StartDate'
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            key: 'EndDate'
        },
        {
            title: 'Price',
            dataIndex: 'Price',
            key: 'Price'
        }
    ]

    const HerderOrder = () => {
        return <div style={{backgroundColor: '#F2F2F2', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
            <div style={{padding: 5}}>Pay</div>
        </div>
    }

    const expandedRowRender = (expandedRow) => {
        return <SubOrderDetail data={getAddOnPackage}/>
    }

    const checkRedirect = () => {
        console.log(payType)
        if (payType === 1) {
            setPath('/PayInvoice/' + id)
        } else if (payType === 2) {
            setPath('/PaymentConfirmation/' + id)
        }
    }

    const onChangePayType = (e) => {
        setPayType(e.target.value)
    }

    return (
        <div>
            {path ? <Redirect to={path} /> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to="/Setting">Setting</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Order</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'Box'}>
                <div><p className={'HerderText'}>Order Number : {getOrder?.orderNumber}</p></div>
                <Row>
                    <Col span={2}>
                        <div>Order Date :</div>
                    </Col>
                    <Col span={22}>
                        <div>{getOrder?.createdDatetime}</div>
                    </Col>
                </Row>
                <Space size={10} />
                <Row>
                    <Col span={2}>
                        <div>
                            Requester :
                        </div>
                    </Col>
                    <Col span={22}>
                        <div>
                            {getOrder?.billing.billingName}
                        </div>
                    </Col>
                </Row>
                <Space size={10} />
                <Row>
                    <Col span={14}>
                        <Table
                            dataSource={getMainPackage}
                            columns={columns}
                            expandedRowRender={expandedRowRender}
                            pagination={false}
                            rowKey={'id'}
                            defaultExpandedRowKeys={[1]}
                        />
                        <div style={{textAlign: 'end'}}>
                            <span>Total</span>&emsp;
                            <span style={{backgroundColor: '#F2F2F2', padding: 10, marginRight: 25}}>{getOrder?.billing.amount}&emsp; Baht</span>
                        </div>
                        <div>
                            <span style={{color: 'red'}}>หมายเหตุ : ขอสงวอนสิทธิ์ในการยกเลิกใบสั่งซื้อกรณีชำเงินไม่สำเร็จภายใน 6 ชั่วโมง</span>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={8}>
                        <Card title={HerderOrder()} className={'mycardtest'} >
                            <div >
                                <Radio.Group onChange={onChangePayType}>
                                    <Radio style={radioStyle} value={1}>
                                        Internet Banking
                                    </Radio>
                                    <Row>
                                        <Col span={2}>

                                        </Col>
                                        <Col span={22}>
                                            <div>ธนาคารไทยพาณิชย์ (x-xxx-xxxxx-x)</div>
                                            <div>ชื่อบัญชี โกเซอร์วิส สาขา เซนทรัลพระราม 3</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={2}>

                                        </Col>
                                        <Col span={22}>
                                            <div>ธนาคารกรุงเทพ (x-xxx-xxxxx-x)</div>
                                            <div>ชื่อบัญชี โกเซอร์วิส สาขา เซนทรัลพระราม 3</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={2}>

                                        </Col>
                                        <Col span={22}>
                                            <div>ธนาคารกสิกร (x-xxx-xxxxx-x)</div>
                                            <div>ชื่อบัญชี โกเซอร์วิส สาขา เซนทรัลพระราม 3</div>
                                        </Col>
                                    </Row>
                                    <div>คุณสามารถเลือกชำระโดนการโอนเงินผ่านธนาคารได้ โดยหลังจากที่คุณทำการคลิก Pay ระบบจะพาไปยังหน้าการแจ้งโอน</div>
                                    <Radio style={radioStyle} value={2}>
                                        ชำระเงินออนไลน์
                                    </Radio>
                                    <div>ชำระสินค้าผ่านบัตรเครดิต เเละบัตรเดบิตออนไลน์ (Credit Card / Debit Card)</div>
                                </Radio.Group>
                            </div>
                            <div style={{textAlign: 'end'}}>
                                <Button type="primary" htmlType="button" style={{ float: 'right', marginLeft: 15 }} onClick={checkRedirect}>Pay</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default OrderDetail
