import { Frame } from 'stompjs'
import { NotifyWebSocket } from './web-socket-model-notify'
import store from '../store'
import { UpdateNotifySuccessEvent } from '../authorization-module/people/state-event'
import { AddNotifyWebSocketSuccessEvent } from './state-event'
import { message } from 'antd'
import { CategoryWebSocket } from '../incident-management/web-socket/categoryWs'

function onUserEvent(frame: Frame) {
    console.log(JSON.parse(frame.body))
    const event: NotifyWebSocket = JSON.parse(frame.body)
    console.log(event)
    if (event.httpStatus.toString() === '202') {
        console.log('checking TenantService Uploaded')
    } else if (event.httpStatus.toString() === '429') {
        if (event) {
            const data = JSON.parse(JSON.stringify(event.responseData))
            message.error(data?.messageFail)
        }
    } else {
        store.dispatch(UpdateNotifySuccessEvent.build(store.getState().notify + 1))
        store.dispatch(AddNotifyWebSocketSuccessEvent.build(event))
    }
}

let isSubscribed = false
export const CategoryWebSocketChannel = {
    subscribe: (email:string) => {
        if (!isSubscribed) {
            CategoryWebSocket.getClient()
                .then((client) => {
                    client.subscribe(CategoryWebSocket.buildDestination4(`${email}/importCategory`), onUserEvent)
                    isSubscribed = true
                })
                .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
                })
        }
    }
}
