export default class ChatGPTShareData {
    private static instance: ChatGPTShareData
    data?: string[] = []

    static getInstance(): ChatGPTShareData {
        if (!ChatGPTShareData.instance) {
            ChatGPTShareData.instance = new ChatGPTShareData()
        }
        return ChatGPTShareData.instance
    }
}
