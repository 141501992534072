import { Button, Card, Col, Form, Input, Modal, Row, Table } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { Problem } from '../../problem-management/problems/Model'
import { Link } from 'react-router-dom'
import { waitingGetSessions } from '../../guard/route'
import { convertCamelCaseToSentenceCase } from '../util'

interface Params {
    problemsList: Problem[]
    totalElements: number
}

type Props = Params & FormComponentProps
const ApproveChange: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [onSearch, setOnSearch] = useState<boolean>(false)
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
    const [isLoadingForButton, setIsLoadingForButton] = useState<boolean>(false)

    useEffect(() => {
        if (onSearch) {
            console.log(currentPage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch, currentPage])

    const columns = [{
        title: 'Ticket Number',
        dataIndex: '',
        key: '',
        width: '15%',
        render: (data) => {
            return (
                <Link to={`/ProblemDetailView/${data.itemId}`} target="_blank" onClick={() => waitingGetSessions()}>{data.itemNumber?.split('/')[0]}</Link>
            )
        }
    },
    {
        title: 'Topic',
        dataIndex: 'itemSummary',
        key: 'itemSummary',
        width: '45%'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        // eslint-disable-next-line react/display-name
        render: (status: string) => {
            return (
                <span>{convertCamelCaseToSentenceCase(status || '')}</span>
            )
        }
    },
    {
        title: '',
        key: 'action',
        width: '20%',
        // eslint-disable-next-line react/display-name
        render: (data: Problem) => {
            if (data.pbStatus.status === 'Access') {
                return (
                    <Row gutter={[16, 16]}>
                        <span className='approval-button-group'>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="danger" id={data.id?.toString()} onClick={onReject}>Reject</Button>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="primary"
                                    id={data.id?.toString()}
                                    onClick={onSubmitApprove}>Approve</Button>
                            </Col>
                        </span>
                    </Row>
                )
            } else if (data.pbStatus.status === 'Root Cause Analysis') {
                return (
                    <span>You have Successfully Approved.</span>
                )
            } else if (data.pbStatus.status === 'New') {
                return (
                    <span>You have Successfully Rejected.</span>
                )
            }
        }
    }]

    const onReject = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        console.log(event.currentTarget.id)
        setIsVisibleModal(true)
    }

    const onSubmitApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        console.log(event.currentTarget.id)
        setIsVisibleModal(true)
    }

    const handleOnClickPageNumber = (page: number) => {
        setCurrentPage(page)
        setOnSearch(true)
    }

    const onCancel = () => {
        setIsVisibleModal(false)
        resetFields()
    }

    const onApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        console.log('onApprove', event.currentTarget.id)
        validateFields((error, row) => {
            if (error) {
                return
            }
            setIsLoadingForButton(true)
            // updateApproveServiceRequestStatus(inputReq ? 'reject' : 'approve', serviceReqId, row.Reason).then(() => {
            //     message.success(`You have Successfully ${inputReq ? 'reject' : 'approve'} the data.`)
            //     updateServiceRequestStatus(serviceReqId)
            //     setIsVisibleModal(false)
            //     setIsLoadingForButton(false)
            //     setFieldsValue({ Reason: undefined })
            // }).catch(() => {
            //     message.error('Error can not Update Data.')
            // }).finally(() => {
            //     setIsVisibleModal(false)
            //     setIsLoadingForButton(false)
            // })
        })
    }

    return (
        <div>
            <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
                <Card className='card-height-approval-default'>
                    <Row>
                        <h2 className="main-title">
                            Service Request ({props?.totalElements || '0'})
                        </h2>
                    </Row>
                    <div style={{ overflowX: 'auto' }}>
                        <Row>
                            <Table
                                className="table-configherder"
                                dataSource={props?.problemsList || []}
                                columns={columns}
                                rowKey="id"
                                style={{ minWidth: 500 }}
                                pagination={{
                                    pageSize: pageSize,
                                    current: currentPage,
                                    total: props?.totalElements || 0,
                                    onChange: (event) => { handleOnClickPageNumber(event) }
                                }}
                            />
                        </Row>
                    </div>
                </Card>
                <Modal
                    title="Reason"
                    visible={isVisibleModal}
                    onCancel={onCancel}
                    footer={[
                        <Button key="back" onClick={onCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" loading={isLoadingForButton} type="primary" onClick={onApprove}>
                            Submit
                        </Button>
                    ]}>
                    <Form>
                        <Form.Item label='reason'>
                            {getFieldDecorator('Reason', {
                                rules: [{ required: true, message: 'Approve reason is required' },
                                    { min: 3, message: 'Enter more than 3 characters' }]
                            })(
                                <Input.TextArea rows={4} placeholder="please enter description" />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
        </div>
    )
}

const ApproveChangeListForm = Form.create<Props>()(ApproveChange)
export default ApproveChangeListForm
