import { axiosGet, axiosPost} from '../../asset-management/rest'
import {AssetItems} from './model'
import {Job} from '../../Relate/Job'

export async function getAssetItemsByLocation(location: string): Promise<AssetItems[]> {
    try {
        const response = await axiosGet<AssetItems[]>(`/api/signedAsset/getAssetForSigned/${location}`)
        return response.data.data!! || []
    } catch (error) {
        console.log(error)
        throw error
    }
}
export async function getSignNumber(): Promise<string> {
    try {
        const params = {
            type: 'SignNumber'
        }
        const response = await axiosGet<string>('/api/running-number', {params})
        return response.data.data!! || ''
    } catch (error) {
        console.log(error)
        throw error
    }
}
export async function addListOfSignItem(signItem: AssetItems[]): Promise<number> {
    try {
        const response = await axiosPost<Job>('/api/signedAsset/list', signItem)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}
