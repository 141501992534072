import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const RemarkContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const RemarkReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_Remark':
        sessionStorage.setItem('remark', JSON.stringify(action.payload))
        return action.payload
    case 'ADD_Remark':
        return [...state, action.payload]
    default:
        return state
    }
}

export const RemarkProvider = (props: props) => {
    const [RemarkState, RemarkDispatch] = useReducer(RemarkReducer, initialState)

    const GetRemark = (payload) => {
        RemarkDispatch({ type: 'GET_Remark', payload })
    }

    const AddRemark = (payload) => {
        RemarkDispatch({ type: 'ADD_Remark', payload })
    }

    return (
        <RemarkContext.Provider value={{ RemarkState, GetRemark, AddRemark }}>
            {props.children}
        </RemarkContext.Provider>
    )
}
