import { Col, Icon, List, Row, Comment, Tooltip } from 'antd'
import React, { useState } from 'react'
import CommentListProblem from './CommentListProblem'
import AutoAvatar from '../../../../common-components/autoAvatar'
import moment from 'moment'
import { LinkOrUnLike, Reply, WorkNoteProblems } from '../../Model'
import { updateLikeOrUnLikeWorkNote } from '../../service'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'
interface Param {
    data: WorkNoteProblems
    reloadWorknote: Function
}

type Props = Param
const DisplayReplyProblem = (props: Props) => {
    const [isReply, setIsReply] = useState(false)
    const [like, setLike] = useState(props.data.likeCount || 0)
    const [disLike, setDisLike] = useState(props.data.disLikeCount || 0)
    const [userReaction, setUserReaction] = useState(props.data.userReaction)
    const [comments, setComments] = useState<Reply[]>(props.data?.reply || [])
    const isRenderPTask = window.location.pathname.includes('/ProblemTaskDetailView')

    const CommentList = () => (
        <List
            dataSource={comments}
            header={`${comments?.length} ${(comments || []).length > 1 ? 'comments' : 'comment'}`}
            itemLayout="horizontal"
            // eslint-disable-next-line react/prop-types
            renderItem={(props: any) => <Row>
                <Comment
                    datetime={<div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{moment(props?.createdDatetime).fromNow()} <span>( {props?.createdDatetime} )</span> </div>}
                    author={<div className='feck-link'><b>{props.createdBy?.fullName?.toString()}</b></div>}
                    {...props}
                    avatar={<Tooltip title={props.replyByTooltip}><AutoAvatar title={props.createdBy?.pictureProfile ? JSON.parse(props.createdBy?.pictureProfile)[0].url : props.createdBy.email?.toString()} broken={false} /></Tooltip>}
                />
            </Row>}
        />
    )

    const handleLikeOrNull = () => {
        if (userReaction === null) {
            const convertData: LinkOrUnLike = {
                userReaction: true
            }
            if (isRenderPTask) {
                convertData.ptaskWorknoteId = props.data.id!!
            } else {
                convertData.pbWorknoteId = props.data.id!!
            }
            updateLikeOrUnLikeWorkNote(convertData, isRenderPTask).then((data) => {
                setUserReaction(data.userReaction)
                setLike(like + 1)
            })
        } else if (userReaction === true) {
            const convertData: LinkOrUnLike = {
                userReaction: null
            }
            if (isRenderPTask) {
                convertData.ptaskWorknoteId = props.data.id!!
            } else {
                convertData.pbWorknoteId = props.data.id!!
            }
            updateLikeOrUnLikeWorkNote(convertData, isRenderPTask).then((data) => {
                setUserReaction(data.userReaction)
                setLike(like - 1)
            })
        } else if (userReaction === false) {
            const convertData: LinkOrUnLike = {
                userReaction: true
            }
            if (isRenderPTask) {
                convertData.ptaskWorknoteId = props.data.id!!
            } else {
                convertData.pbWorknoteId = props.data.id!!
            }
            updateLikeOrUnLikeWorkNote(convertData, isRenderPTask).then((data) => {
                setUserReaction(data.userReaction)
                setLike(like + 1)
                setDisLike(disLike - 1)
            })
        }
    }

    const handleUnLikeOrNull = () => {
        if (userReaction === null) {
            const convertData: LinkOrUnLike = {
                userReaction: false
            }
            if (isRenderPTask) {
                convertData.ptaskWorknoteId = props.data.id!!
            } else {
                convertData.pbWorknoteId = props.data.id!!
            }
            updateLikeOrUnLikeWorkNote(convertData, isRenderPTask).then((data) => {
                setUserReaction(data.userReaction)
                setDisLike(disLike + 1)
            })
        } else if (userReaction === false) {
            const convertData: LinkOrUnLike = {
                pbWorknoteId: props.data.id!!,
                userReaction: null
            }
            if (isRenderPTask) {
                convertData.ptaskWorknoteId = props.data.id!!
            } else {
                convertData.pbWorknoteId = props.data.id!!
            }
            updateLikeOrUnLikeWorkNote(convertData, isRenderPTask).then((data) => {
                setUserReaction(data.userReaction)
                setDisLike(disLike - 1)
            })
        } else if (userReaction === true) {
            const convertData: LinkOrUnLike = {
                pbWorknoteId: props.data.id!!,
                userReaction: false
            }
            if (isRenderPTask) {
                convertData.ptaskWorknoteId = props.data.id!!
            } else {
                convertData.pbWorknoteId = props.data.id!!
            }
            updateLikeOrUnLikeWorkNote(convertData, isRenderPTask).then((data) => {
                setUserReaction(data.userReaction)
                setDisLike(disLike + 1)
                setLike(like - 1)
            })
        }
    }

    const getComment = (data) => {
        if (comments.length === 0) {
            props.reloadWorknote(true)
        }
        if (data) {
            setComments([
                ...comments,
                data
            ])
        }
    }

    return (
        <div>
            <div style={{ padding: '10px 20px'}}>
                <Row style={{ paddingLeft: 40 }}>
                    <Col span={1}>
                        {checkRolesState(RoleType.Problem, 'UpdateProblem') ? <Icon type="like" className='feck-link' onClick={handleLikeOrNull} theme={userReaction === true ? 'filled' : 'outlined'}/> : <Icon type="like" />}
                    </Col>
                    <Col span={1}>
                        <div>{like}</div>
                    </Col>
                    <Col span={1}>
                        {checkRolesState(RoleType.Problem, 'UpdateProblem') ? <Icon type="dislike" className='feck-link' onClick={handleUnLikeOrNull} theme={userReaction === false ? 'filled' : 'outlined'}/> : <Icon type="dislike" />}
                    </Col>
                    <Col span={1}>
                        <div>{disLike}</div>
                    </Col>
                    <Col span={2}>
                        {checkRolesState(RoleType.Problem, 'UpdateProblem') ? <span className='feck-link' onClick={() => setIsReply(!isReply)}>Reply to</span> : null}
                    </Col>
                </Row>
            </div>
            {(props.data.reply || []).length > 0 ? <Row>
                <Col span={24} style={{ padding: '10px 20px', paddingLeft: 60 }} className='List-Comment-Problem'>
                    {CommentList()}
                </Col>
            </Row> : null}
            {isReply ? <Row>
                <Col span={24} style={{ padding: '10px 20px', paddingLeft: 60 }}>
                    <CommentListProblem id={props.data?.id!!} data={props.data} getData={getComment}/>
                </Col>
            </Row> : null}
        </div>
    )
}

export default DisplayReplyProblem
