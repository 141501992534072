/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { StoreState } from '../../../store'
import { FormComponentProps } from 'antd/lib/form'
import { Form, Select, Input } from 'antd'
import { Roles } from '../duck/model'
import UserRoleShareData from './UserRoleShareData'

const { Option } = Select

const EditableContext = UserRoleShareData.getInstance().Context
const mapStateToProps = (state: StoreState) => ({
    user_roles: state.userRoles,
    roles: state.roles
})
type StateProps = ReturnType<typeof mapStateToProps>

interface Item {
    key: string
    username: string
    fullName: string
    roles: string[]
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement>, FormComponentProps {
    editing?: boolean
    dataIndex: string
    title?: any
    inputType: InputType
    record?: Item[]
    index?: number
    children?: React.ReactNode
    Context: React.Context<any>
}

enum InputType {
    select = 'select',
    text = 'text'
}

type Props = StateProps & EditableCellProps

const EditableCell: React.FC<Props> = (props: Props) => {
    const { dataIndex, inputType, record, roles, editing, children } = props
    const { getFieldDecorator, getFieldValue, getFieldError } = props.form
    useEffect(() => {
        if (editing) {
            if (getFieldValue(dataIndex)) {
                if (dataIndex === 'fullName') {
                    UserRoleShareData.getInstance().editData.userrole.fullName = getFieldValue(dataIndex)
                } else if (dataIndex === 'roles') {
                    UserRoleShareData.getInstance().editData.userrole.roles = getFieldValue(dataIndex)
                    console.log(getFieldValue(dataIndex))
                    console.log(UserRoleShareData.getInstance().editData.userrole)
                }
            }
            UserRoleShareData.getInstance().editData.error = getFieldError(dataIndex)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue(dataIndex)])

    const editingCell = () => {
        if (inputType === InputType.select) {
            const optionRole = roles.map((role: Roles) => {
                return (<Option key={role.name}>{role.name}</Option>)
            })
            return (
                <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator(dataIndex, {
                        rules: [
                            {
                                required: true
                            }
                        ],
                        initialValue: record ? record[dataIndex] : ''
                    })(
                        <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select">
                            {optionRole}
                        </Select>
                    )}
                </Form.Item>
            )
        } else if (inputType === InputType.text) {
            return (
                <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator(dataIndex, {
                        rules: [
                            {
                                required: true
                            }
                        ],
                        initialValue: record ? record[dataIndex] : ''
                    })(
                        <Input />
                    )}
                </Form.Item>
            )
        }
    }

    const renderCell = () => {
        return (
            <td>
                {editing ? editingCell() : (children)}
            </td>
        )
    }
    return (<EditableContext.Consumer>{renderCell}</EditableContext.Consumer>)
}

const UserEditableCellFrom = Form.create<any>()(EditableCell)

const connectEditableCell = connect(mapStateToProps)(UserEditableCellFrom)

export default connectEditableCell
