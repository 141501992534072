import {AutoComplete, Button, DatePicker, Form, Select, message} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import React, {useContext, useEffect, useState} from 'react'
import {getAllPeople, getAllUsersByUserId} from '../../authorization-module/user-role/duck/action'
import {AssetStateContext} from '../ContextAPI/AssetStateContext'
import {DepartmentContext} from '../ContextAPI/DepartmentContext'
import {PeopleContext} from '../ContextAPI/UserHRContext'
import {FilterData} from '../Model'
import {getDataItems, getDataLocationBuilding} from '../services'
import moment from 'moment'
import {getAllAssetGroup, searchFetch} from '../../msp-wo/asset-management'
import { DepartmentAuth, People } from '../../authorization-module/people/model'
import { getAllDepartmentAuth } from '../../authorization-module/user-role/duck/service'
import { LocationBuildingContext } from '../ContextAPI/LocationBuildingContext'
import { AssetGroups } from '../../configurable-fields'
import { DataSourceItemObject } from 'antd/lib/auto-complete'

interface Param {
    valueFilter: FilterData
    getValueFilter: Function
    isCompletePm: boolean
    isRender: boolean
    handleOnclose: (visible: boolean) => void
    isDeletePMStatus?: boolean
}

type Props = FormComponentProps & Param
const {Option} = Select
const {RangePicker} = DatePicker

const FilterDataForm: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue} = props.form
    const {AssetStateState, GetAssetState} = useContext<any>(AssetStateContext)
    const { PeopleState, GetPeople } = useContext<any>(PeopleContext)
    const [department, setDepartment] = useState<DepartmentAuth[]>([])
    const [location, setLocation] = useState<string[]>([])
    const [dataLocation, setDataLocation] = useState<string[]>([])
    const [assetType, setAssetType] = useState<AssetGroups[]>([])
    const [isSearch, setIsSearch] = useState(false)

    useEffect(() => {
        // getDataItems('deptName').then((res) => {
        //     if (DepartmentState.length === 0) {
        //         GetDepartment(res)
        //         setDepartment(res.slice(0, 20) || [])
        //     }
        // })
        getAllAssetGroup().then((res) => {
            if (res) {
                setAssetType(res)
            }
        })
        getAllDepartmentAuth().then((res) => {
            if (res) {
                setDepartment(res)
            }
        })
        getDataItems('assetStatus').then((res) => {
            if (AssetStateState.length === 0) {
                GetAssetState(res)
            }
        })
        // getAllPeople().then((res) => {
        //     if (GetPeople) {
        //         GetPeople(res)
        //     }
        // })
        getDataLocationBuilding().then((res) => {
            const tempLocation: string[] = []
            res.forEach((data) => {
                if (data.location) {
                    tempLocation.push(data.location)
                }
            })
            setLocation([...new Set(tempLocation)])
            setDataLocation([...new Set(tempLocation)].slice(0, 20))
        })

        if (props.valueFilter) {
            setFieldsValue({
                Status: props.valueFilter.pmStatus,
                PMIDStatus: props.valueFilter.pmIdStatus,
                CompletedDate: props.valueFilter.completeStart ? [moment(props.valueFilter.completeStart, 'YYYY-MM-DD'), moment(props.valueFilter.completeEnd, 'YYYY-MM-DD')] : [],
                // Department: props.valueFilter.department,
                PMPlan: props.valueFilter.start ? [moment(props.valueFilter.start, 'YYYY-MM-DD'), moment(props.valueFilter.end, 'YYYY-MM-DD')] : [0],
                // Group: props.valueFilter.groups,
                // Owner_name: props.valueFilter.userName,
                // Asset_status: props.valueFilter.assetStatus,
                Location: props.valueFilter.pmLocation
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.form.getFieldValue('User_name')) {
            const timer = setTimeout(() => {
                setIsSearch(true)
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [props.form.getFieldValue('User_name')])

    useEffect(() => {
        if (isSearch) {
            getAllUsersByUserId(props.form.getFieldValue('User_name')).then((res) => {
                if (res) {
                    if (GetPeople) {
                        GetPeople(res)
                    }
                }
            }).catch(() => {
                message.warning('Data is null')
            }).finally(() => {
                setIsSearch(false)
            })
        }
    }, [isSearch])

    useEffect(() => {
        console.log(props.valueFilter)
        if (props.isCompletePm) {
            if (props.valueFilter?.completeEnd !== '' && props.valueFilter?.completeStart !== '' &&
            // props.valueFilter?.department?.length === 0 && props.valueFilter?.end === '' && props.valueFilter?.groups?.length === 0 &&
            props.valueFilter?.pmIdStatus?.length === 0 && props.valueFilter?.pmStatus?.length === 1 &&
            props.valueFilter?.start === '') {
                setFieldsValue({
                    Status: props.valueFilter.pmStatus,
                    PMIDStatus: props.valueFilter.pmIdStatus,
                    CompletedDate: props.valueFilter.completeStart ? [moment(props.valueFilter.completeStart, 'YYYY-MM-DD'), moment(props.valueFilter.completeEnd, 'YYYY-MM-DD')] : [],
                    // Department: props.valueFilter.department,
                    PMPlan: props.valueFilter.start ? [moment(props.valueFilter.start, 'YYYY-MM-DD'), moment(props.valueFilter.end, 'YYYY-MM-DD')] : [0],
                    // Group: props.valueFilter.groups,
                    // userName: props.valueFilter.userName,
                    // Asset_status: props.valueFilter.assetStatus,
                    Location: props.valueFilter.pmLocation
                })
            } else {
                setFieldsValue({
                    Status: props.valueFilter.pmStatus,
                    PMIDStatus: props.valueFilter.pmIdStatus,
                    CompletedDate: props.valueFilter.completeStart ? [moment(props.valueFilter.completeStart, 'YYYY-MM-DD'), moment(props.valueFilter.completeEnd, 'YYYY-MM-DD')] : [],
                    // Department: props.valueFilter.department,
                    PMPlan: props.valueFilter.start ? [moment(props.valueFilter.start, 'YYYY-MM-DD'), moment(props.valueFilter.end, 'YYYY-MM-DD')] : [0],
                    // Group: props.valueFilter.groups,
                    // userName: props.valueFilter.userName,
                    // Asset_status: props.valueFilter.assetStatus,
                    Location: props.valueFilter.pmLocation
                })
            }
            // if (props.valueFilter.userName === undefined || props.valueFilter?.userName?.length === 0) props.form.resetFields(['User_name'])
        } else {
            // if (props.valueFilter.assetStatus === undefined || props.valueFilter?.assetStatus?.length === 0) props.form.resetFields(['Asset_status'])
            if (props.valueFilter.completeEnd === '') props.form.resetFields(['CompletedDate'])
            if (props.valueFilter.completeStart === '') props.form.resetFields(['CompletedDate'])
            // if (props.valueFilter?.department === undefined || props.valueFilter.department?.length === 0) props.form.resetFields(['Department'])
            if (props.valueFilter.end === '') { props.form.resetFields(['PMPlan']) } else {
                props.form.setFieldsValue({
                    PMPlan: [moment(props.valueFilter.start, 'YYYY-MM-DD'), moment(props.valueFilter.end, 'YYYY-MM-DD')]
                })
            }
            if (props.valueFilter.start === '') { props.form.resetFields(['PMPlan']) } else {
                props.form.setFieldsValue({
                    PMPlan: [moment(props.valueFilter.start, 'YYYY-MM-DD'), moment(props.valueFilter.end, 'YYYY-MM-DD')]
                })
            }
            // if (props.valueFilter.userName === undefined || props.valueFilter?.userName?.length === 0) props.form.resetFields(['User_name'])
            if (props.valueFilter.pmIdStatus === undefined || props.valueFilter?.pmIdStatus?.length === 0) { props.form.resetFields(['PMIDStatus']) } else {
                props.form.setFieldsValue({
                    PMIDStatus: props.valueFilter.pmIdStatus
                })
            }
            if (props.valueFilter.pmStatus === undefined || props.valueFilter?.pmStatus?.length === 0) { props.form.resetFields(['Status']) } else {
                props.form.setFieldsValue({
                    Status: props.valueFilter.pmStatus
                })
            }
            // if (props.valueFilter.groups === undefined || props.valueFilter?.groups.length === 0) props.form.resetFields(['Group'])
            if (props.valueFilter.pmLocation === undefined || props.valueFilter?.pmLocation.length === 0) { props.form.resetFields(['Location']) } else {
                props.form.setFieldsValue({
                    Location: props.valueFilter.pmLocation
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isRender, props.isDeletePMStatus])

    const onFinish = () => {
        props.form.validateFields(async (_err, values) => {
            const ConvertData: FilterData = {
                // department: values.Department,
                pmStatus: values.Status,
                pmIdStatus: values.PMIDStatus,
                // groups: values.Group,
                start: '',
                end: '',
                completeStart: '',
                completeEnd: '',
                // userName: values.User_name ? [values.User_name] : undefined,
                // assetStatus: values.Asset_status,
                pmLocation: values.Location
            }
            if (values.PMPlan?.length === 2) {
                ConvertData.start = moment(values.PMPlan[0]).format('YYYY-MM-DD')
                ConvertData.end = moment(values.PMPlan[1]).format('YYYY-MM-DD')
            }
            if (props.isCompletePm) {
                // ConvertData.pmStatus = ['Completed', 'Ignored']
                if (values.CompletedDate?.length === 2) {
                    ConvertData.completeStart = moment(values.CompletedDate[0]).format('YYYY-MM-DD')
                    ConvertData.completeEnd = moment(values.CompletedDate[1]).format('YYYY-MM-DD')
                }
            }
            props.getValueFilter(ConvertData)
        })
    }

    const closeForm = () => {
        props.form.resetFields()
        props.handleOnclose(false)
    }

    const searchFetch = (value: string) => {
        if (typeof value === 'string' && value.length >= 1) {
            const filterData = location.filter((data: string) => data.toUpperCase().includes(value.toUpperCase()))
            if (filterData.length > 0) {
                if (filterData.length < 20) {
                    setDataLocation(filterData)
                } else {
                    setDataLocation(filterData.slice(0, 20))
                }
            }
        } else {
            if (location) {
                setDataLocation(location.slice(0, 20))
            }
        }
    }

    return (
        <div>
            {props.isCompletePm ? (
                <Form
                    name="basic"
                    layout={'vertical'}
                >
                    <Form.Item
                        label="Completed Date"
                    >
                        {getFieldDecorator('CompletedDate')(
                            <RangePicker format={'YYYY-MM-DD'} style={{width: '100%'}}/>
                        )}
                    </Form.Item>
                    {/* <Form.Item
                        label="Department"
                    >
                        {getFieldDecorator('Department')(
                            <Select mode="multiple"
                                showSearch
                                loading={department?.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                placeholder="Select Department"
                            >
                                {(department || []).map((res, index: number) => {
                                    return <Option value={res.departmentName} key={index}>{res.departmentName}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item> */}
                    <Form.Item
                        label="Plan Date"
                    >
                        {getFieldDecorator('PMPlan')(
                            <RangePicker style={{width: '100%'}} format={'YYYY-MM-DD'}/>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Overall Status"
                    >
                        {getFieldDecorator('PMIDStatus')(
                            <Select placeholder="Select Overall Status" mode="multiple">
                                <Option value="OnProcess">On Process</Option>
                                <Option value="Completed">Completed</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="PM Status"
                    >
                        {getFieldDecorator('Status')(
                            <Select placeholder="Select PM Status" mode="multiple">
                                <Option value="Completed">Completed</Option>
                                <Option value="Ignored">Ignored</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="PM Location"
                    >
                        {getFieldDecorator('Location')(
                            <Select mode="multiple"
                                showSearch
                                loading={location.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                allowClear
                                placeholder={'Select Location'}
                                onSearch={(e) => searchFetch(e)}
                            >
                                {(dataLocation || []).map((res, index: number) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    {/* <Form.Item
                        label="Group"
                    >
                        {getFieldDecorator('Group')(
                            <Select placeholder="Select Group" mode="multiple">
                                {assetType.map((data, i) => {
                                    return <Option value={data.name} key={i}>{data.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item> */}

                    {/* <Form.Item
                        label="User Name"
                    >
                        {getFieldDecorator('User_Name')(
                            <Select placeholder="Select User Name" mode="multiple">
                                <Option value="001">001</Option>
                                <Option value="002">002</Option>
                            </Select>
                        )}
                    </Form.Item> */}

                    {/* <Form.Item
                        label="Owner Name"
                    >
                        {getFieldDecorator('User_name')(
                            // <Select mode="multiple"
                            //     showSearch
                            //     loading={PeopleState?.length === 0}
                            //     optionFilterProp="children"
                            //     filterOption={(inputValue, option: any) =>
                            //         (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            //     }
                            //     placeholder="Select Owner Name"
                            // >
                            //     {(PeopleState || []).map((res: People, index: number) => {
                            //         return <Option value={res.employeeId} key={res.employeeId}>{res.employeeId + ' : ' + res.fullName}</Option>
                            //     })}
                            // </Select>
                            <AutoComplete
                                dataSource={(PeopleState || []).map((it: People) => {
                                    return {
                                        value: it.employeeId?.toString(),
                                        text: it.employeeId?.toString() + ' : ' + it.fullName
                                    } as DataSourceItemObject
                                })}
                                placeholder="Select Owner Name"
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item> */}

                    {/* <Form.Item
                        label="Asset Status"
                    >
                        {getFieldDecorator('Asset_status')(
                            <Select mode="multiple"
                                showSearch
                                loading={AssetStateState?.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                placeholder="Select Asset Status"
                            >
                                {(AssetStateState || []).map((res, index: number) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item> */}
                    <Form.Item style={{textAlign: 'end'}}>
                        <Button htmlType="button" onClick={closeForm}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onFinish} style={{marginLeft: 5}}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            ) : (
                <Form
                    name="basic"
                    layout={'vertical'}
                >
                    <Form.Item
                        label="Plan Date"
                    >
                        {getFieldDecorator('PMPlan')(
                            <RangePicker style={{width: '100%'}} format={'YYYY-MM-DD'}/>
                        )}
                    </Form.Item>

                    <Form.Item
                        label="Overall Status"
                    >
                        {getFieldDecorator('PMIDStatus')(
                            <Select placeholder="Select Overall Status" mode="multiple">
                                <Option value="Open">Open</Option>
                                <Option value="OnProcess">On Process</Option>
                                <Option value="Completed">Completed</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="PM Status"
                    >
                        {getFieldDecorator('Status')(
                            <Select placeholder="Select PM Status" mode="multiple">
                                <Option value="Open">Open</Option>
                                <Option value="OnProcess">On Process</Option>
                                <Option value="Pending">Pending</Option>
                                <Option value="WaitingForSign">Waiting for sign</Option>
                                <Option value="Ignored">Ignored</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="PM Location"
                    >
                        {getFieldDecorator('Location')(
                            <Select mode="multiple"
                                showSearch
                                loading={location.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                allowClear
                                placeholder={'Select Location'}
                                onSearch={(e) => searchFetch(e)}
                            >
                                {(dataLocation || []).map((res, index: number) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    {/* <Form.Item
                        label="Group"
                    >
                        {getFieldDecorator('Group')(
                            <Select placeholder="Select Group" mode="multiple">
                                {assetType.map((data, i) => {
                                    return <Option value={data.name} key={i}>{data.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        label="Owner Name"
                    >
                        {getFieldDecorator('User_name')(
                            // <Select mode="multiple"
                            //     showSearch
                            //     loading={PeopleState?.length === 0}
                            //     optionFilterProp="children"
                            //     filterOption={(inputValue, option: any) =>
                            //         (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            //     }
                            //     placeholder="Select Owner Name"
                            // >
                            //     {(PeopleState || []).map((res: People, index: number) => {
                            //         return <Option value={res.employeeId} key={res.employeeId}>{res.employeeId + ' : ' + res.fullName}</Option>
                            //     })}
                            // </Select>
                            <AutoComplete
                                dataSource={(PeopleState || []).map((it: People) => {
                                    return {
                                        value: it.employeeId?.toString(),
                                        text: it.employeeId?.toString() + ' : ' + it.fullName
                                    } as DataSourceItemObject
                                })}
                                placeholder="Select Owner Name"
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Asset Status"
                    >
                        {getFieldDecorator('Asset_status')(
                            <Select mode="multiple"
                                showSearch
                                loading={AssetStateState?.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                placeholder="Select Asset Status"
                            >
                                {(AssetStateState || []).map((res, index: number) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        label="Department"
                    >
                        {getFieldDecorator('Department')(
                            <Select mode="multiple"
                                // onSearch={(value) => { searchFetch(value, department || [], setDepartment!!) }}
                                showSearch
                                loading={department?.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                placeholder="Select Department"
                            >
                                {(department || []).map((res, index: number) => {
                                    return <Option value={res.departmentName} key={index}>{res.departmentName}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        label="Location"
                    >
                        {getFieldDecorator('Location')(
                            <Select mode="multiple"
                                showSearch
                                loading={location.length === 0}
                                optionFilterProp="children"
                                filterOption={(inputValue, option: any) =>
                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                allowClear
                                placeholder={'Select Location'}
                            >
                                {(location || []).map((res, index: number) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item> */}

                    <Form.Item style={{textAlign: 'end'}}>
                        <Button htmlType="button" onClick={closeForm}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onFinish} style={{marginLeft: 5}}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            )}

        </div>
        /* <div><Form
            name="basic"
            layout={'vertical'}
        >
            <Form.Item
                label="PM Plan Date"
            >
                {getFieldDecorator('PMPlan')(
                    <RangePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                )}
            </Form.Item>

            <Form.Item
                label="PM ID Status"
            >
                {getFieldDecorator('PMIDStatus')(
                    <Select placeholder="Select PM ID Status" mode="multiple" >
                        <Option value="Open">Open</Option>
                        <Option value="OnProcess">On Process</Option>
                        <Option value="Completed">Completed</Option>
                    </Select>
                )}
            </Form.Item>

            {props.isCompletePm ? (
                <Form.Item
                    label="Completed Date"
                >
                    {getFieldDecorator('CompletedDate')(
                        <RangePicker format={'YYYY-MM-DD'} />
                    )}
                </Form.Item>
            ) : (
                <Form.Item
                    label="PM Status"
                >
                    {getFieldDecorator('Status')(
                        <Select placeholder="Select Status" mode="multiple" >
                            <Option value="Open">Open</Option>
                            <Option value="OnProcess">On Process</Option>
                            <Option value="Pending">Pending</Option>
                            <Option value="Waiting for sign">Waiting for sign</Option>
                        </Select>
                    )}
                </Form.Item>
            )}

            <Form.Item
                label="Group"
            >
                {getFieldDecorator('Group')(
                    <Select placeholder="Select Group" mode="multiple">
                        <Option value="IT Asset">IT Asset</Option>
                        <Option value="Peripheral">Peripheral</Option>
                    </Select>
                )}
            </Form.Item>

            <Form.Item
                label="Owner Name"
            >
                {getFieldDecorator('Owner_name')(
                    <Select mode="multiple"
                        showSearch
                        loading={PeopleState?.length === 0}
                        optionFilterProp="children"
                        filterOption={(inputValue, option: any) =>
                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="Select Owner Name"
                    >
                        {(PeopleState || []).map((res: People, index: number) => {
                            return <Option value={res.fullName} key={index} >{res.fullName}</Option>
                        })}
                    </Select>
                )}
            </Form.Item>
            <Form.Item
                label="Asset State"
            >
                {getFieldDecorator('Asset_state')(
                    <Select mode="multiple"
                        showSearch
                        loading={AssetStateState?.length === 0}
                        optionFilterProp="children"
                        filterOption={(inputValue, option: any) =>
                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="Select Asset State"
                    >
                        {(AssetStateState || []).map((res, index: number) => {
                            return <Option value={res} key={index} >{res}</Option>
                        })}
                    </Select>
                )}
            </Form.Item>

            <Form.Item
                label="Department"
            >
                {getFieldDecorator('Department')(
                    <Select mode="multiple"
                        showSearch
                        loading={DepartmentState?.length === 0}
                        optionFilterProp="children"
                        filterOption={(inputValue, option: any) =>
                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        placeholder="Select Department"
                    >
                        {(DepartmentState || []).map((res, index: number) => {
                            return <Option value={res} key={index} >{res}</Option>
                        })}
                    </Select>
                )}
            </Form.Item>

            <Form.Item style={{ textAlign: 'end' }}>
                <Button htmlType="button" onClick={closeForm}>
                    Cancel
                </Button>
                <Button type="primary" htmlType="button" onClick={onFinish} style={{ marginLeft: 5 }}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
        </div> */
    )
}

const MyFilterDataForm = Form.create<Props>({name: 'FilterDataForm'})(FilterDataForm)
export default MyFilterDataForm
