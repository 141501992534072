/* eslint-disable react/jsx-key */
import React, { cloneElement } from 'react'
import { LocationBuildingProvider } from '../Preventive/ContextAPI/LocationBuildingContext'
import { DepartmentProvider } from '../Preventive/ContextAPI/DepartmentContext'
import { PreventiveProvider } from '../Preventive/ContextAPI/PreventiveContext'
import { AppointLocationProvider } from '../Preventive/ContextAPI/AppointLocationContext'
import { AssetStateProvider } from '../Preventive/ContextAPI/AssetStateContext'
import { BrandProvider } from '../Preventive/ContextAPI/BrandContext'
import { CommentsPicturesProvider } from '../Preventive/ContextAPI/CommentsPicturesContext'
import { PendingReasonProvider } from '../Preventive/ContextAPI/PendingReasonContext'
import { RemarkProvider } from '../Preventive/ContextAPI/RemarkContext'
import { ZoneProvider } from '../Preventive/ContextAPI/ZoneContext'
import { AssetGroupProvider } from '../Preventive/ContextAPI/AssetGroupContext'
import { PmActivityStateProvider } from '../Preventive/ContextAPI/PmActivityContext'
import { AssetNameProvider } from '../Preventive/ContextAPI/AssetTeamContext'
import { PMNameProvider } from '../Preventive/ContextAPI/PMTeamContext'
import { CompletedPMProvider } from '../Preventive/ContextAPI/CompletedPMContext'
import { PeopleProvider } from '../Preventive/ContextAPI/UserHRContext'
import { AdjustProvider } from '../ReportComponent/components/ContextAPI/AdjustContext'
import { NewAssetProvider } from '../ReportComponent/components/ContextAPI/NewAssetContext'
import { PMStatusProvider } from '../ReportComponent/components/ContextAPI/PMStatusContext'
import { PageServiceModelProvider } from '../serviceCatalog/Catalog/ContextPage/PagesContext'
import { AssetRelatedProvider } from '../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { TicketRelatedProvider } from '../Preventive/ContextAPI/TicketRelatedContext'
import { KnowledgeRelatedProvider } from '../Preventive/ContextAPI/KnowledgeRelatedContext'
import { WorkflowRelatedProvider } from '../Preventive/ContextAPI/WorkflowRelatedContext'
import { WorkflowRelatedSelectProvider } from '../Preventive/ContextAPI/WorkflowRelatedSelectContext'
import { SelectedImageAssetProvider } from '../Preventive/components/SyncDataPMToAsset/ContextAPI/SelectedImageAsset'
import { SelectedImagePMProvider } from '../Preventive/components/SyncDataPMToAsset/ContextAPI/SelectedImagePM'
import { DataModelProvider } from '../Preventive/ContextAPI/DataModelContext'
import { DataSerialNumberProvider } from '../Preventive/ContextAPI/DataSerialNumberContext'
import { DataComputerNameProvider } from '../Preventive/ContextAPI/DataComputerNameContext'
import { SetNoneAssetDetailFieldListProvider } from '../Preventive/components/SyncDataPMToAsset/ContextAPI/SetNoneAssetDetailFieldList'
import { SetNoneCustomFieldListProvider } from '../Preventive/components/SyncDataPMToAsset/ContextAPI/SetNoneCustomFieldList'
import { PmTemplateStateProvider } from '../Preventive/ContextAPI/PmTemplateCustomfieldContext'

function ProviderComposer({ contexts, children }: any) {
    return contexts.reduce(
        (kids: any, parent: React.DetailedReactHTMLElement<{ children: any }, HTMLElement>) =>
            cloneElement(parent, {
                children: kids
            }),
        children
    )
}
export default function ContextProvider({ children }: any) {
    return (
        <ProviderComposer
            // add providers to array of contexts
            contexts={[
                <LocationBuildingProvider />, <DepartmentProvider />,
                <PreventiveProvider />, <AppointLocationProvider />,
                <AssetStateProvider />, <BrandProvider />, <CommentsPicturesProvider />, <PendingReasonProvider />,
                <RemarkProvider />, <ZoneProvider />, <AssetGroupProvider/>, <PmActivityStateProvider/>,
                <AssetNameProvider />, <PMNameProvider/>, <CompletedPMProvider/>, <PeopleProvider />, <AdjustProvider />,
                <NewAssetProvider />, <PMStatusProvider />, <PageServiceModelProvider />, <AssetRelatedProvider />,
                <TicketRelatedProvider/>, <KnowledgeRelatedProvider/>, <WorkflowRelatedProvider/>, <WorkflowRelatedSelectProvider />, 
                <SelectedImageAssetProvider/>, <SelectedImagePMProvider/>, <DataModelProvider />, <DataSerialNumberProvider/>, <DataComputerNameProvider/>, 
                <SetNoneAssetDetailFieldListProvider/>, <SetNoneCustomFieldListProvider/>, <PmTemplateStateProvider/>
            ]}
        >
            {children}
        </ProviderComposer>
    )
}
