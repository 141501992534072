import { message } from 'antd'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { axiosPostDashboard } from '../restDashboard'
import { convertResponseToFile } from '../../ReportComponent/components/Services/Service'

export const getPreviewTicketReport = async (start: string, end: string, page: number, pageSize: number, selectedColumn, show :string, ticketType :string, path: string) => {
    try {
        const body: any = {
            showBy: show,
            startDate: start || '',
            endDate: end || '',
            page: page,
            size: pageSize,
            sortBy: 'createdDatetime',
            orderBy: 'desc',
            selectedColumns: selectedColumn
        }
        if (ticketType !== null && ticketType !== '') {
            body.ticketType = ticketType
        }
        const encryptData = encryptBody(JSON.stringify(body))
        // const response = await axiosPostDashboard<NewAsset[]>(`/api/ticket/report/preview/allTicket?start=${start}&end=${end}&page=${page}&size=${pageSize}&sort=createdDatetime,asc`, encryptData, herderPostPatch)
        const response = await axiosPostDashboard(`/api/tickets/report/preview/${path}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}

export const getExportTicketReport = async (start: string, end: string, reportName: string, selectedColumn :string[], show :string, ticketType :string) => {
    try {
        const body: any = {
            showBy: show,
            startDate: start || '',
            endDate: end || '',
            selectedColumns: selectedColumn
        }
        if (ticketType !== null && ticketType !== '') {
            body.ticketType = ticketType
        }
        const encryptData = encryptBody(JSON.stringify(body))
        // const response = await axiosPostDashboard<NewAsset[]>(`/api/ticket/report/preview/allTicket?start=${start}&end=${end}&page=${page}&size=${pageSize}&sort=createdDatetime,asc`, encryptData, herderPostPatch)
        const response = await axiosPostDashboard(`/api/tickets/report/download/${reportName}`, encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, `[${start} to ${end}] ${reportName}.xlsx`)
    } catch (err) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}
