import {
    GetAllSelfServiceCatalogSuccessEvent,
    GetAllSelfServiceCatagorySuccessEvent,
    GetAllSelfServiceItemSuccessEvent,
    AddSelfServiceCatalogSuccessEvent,
    AddSelfServiceCatagorySuccessEvent,
    UpdateSelfServiceCatalogSuccessEvent,
    DeleteSelfServiceCatalogSuccessEvent,
    SelfServiceStateEvent,
    DeleteSelfServiceCatagorySuccessEvent
} from './state-event'
import {SelfServiceCatalog, SelfServiceCatagory, SelfServiceItem, ResponseContentDetails, defaultResponseContentDetails} from './Model'

export const selfServiceCatalogListStateReducer = (state: ResponseContentDetails<SelfServiceCatalog[]> = defaultResponseContentDetails, event: SelfServiceStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSelfServiceCatalogSuccessEvent.type) {
        return event.payload
    } else if (event.type === AddSelfServiceCatalogSuccessEvent.type) {
        state.content.unshift(event.payload)
        return state
    } else if (event.type === UpdateSelfServiceCatalogSuccessEvent.type) {
        const newCatalogs = state.content.map((catalogs) => {
            if (Number(catalogs.id) === Number(event.payload.id)) {
                return event.payload
            } else {
                return catalogs
            }
        })
        state.content = newCatalogs
        return state
    } else if (event.type === DeleteSelfServiceCatalogSuccessEvent.type) {
        const newCatalogs = state.content.filter((catalogs) => Number(catalogs.id!!) !== Number(event.payload!!))
        state.content = newCatalogs
        return state
    } else {
        return state
    }
}

export const selfServiceCatagoryListStateReducer = (state: ResponseContentDetails<SelfServiceCatagory[]> = defaultResponseContentDetails, event: SelfServiceStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSelfServiceCatagorySuccessEvent.type) {
        return event.payload
    } else if (event.type === AddSelfServiceCatagorySuccessEvent.type) {
        state.content.unshift(event.payload)
        return state
    } else if (event.type === DeleteSelfServiceCatagorySuccessEvent.type) {
        const newCategories = state.content.filter((category) => Number(category.id!!) !== Number(event.payload!!))
        state.content = newCategories
        return state
    } else {
        return state
    }
}

export const selfServiceItemListStateReducer = (state: ResponseContentDetails<SelfServiceItem[]> = defaultResponseContentDetails, event: SelfServiceStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSelfServiceItemSuccessEvent.type) {
        return event.payload
    } else {
        return state
    }
}
