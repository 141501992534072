import React, {useContext, useEffect, useState} from 'react'
import {useParams, Link, Redirect} from 'react-router-dom'
import {Row, Col, Form, Breadcrumb, Icon, Modal, Drawer, Tabs, Input, Select, Button, message, Spin} from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import {Space} from '../../common-components/Space'
import ItemList from '../Catalog/components/Items/ItemList'
import CategoryForm from '../Catalog/components/catagory/CategoryForm'
import { SelfServiceCatagory} from '../Catalog/Model'
import { deleteCategoryCatalog, getallCategory, getCatagory, updateCategory} from '../Catalog/service'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { PageServiceModelContext } from '../Catalog/ContextPage/PagesContext'

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatagories: state.selfServiceCatagories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCategory: () => Promise<number>
    deleteCategoryCatalog: (id: number) => Promise<number>
}

type Props = FormComponentProps & StateProps & DispatchProps
const { warning } = Modal
const { confirm } = Modal
const { Option } = Select
const { TabPane } = Tabs
const CategoryView: React.FC<Props> = (props: Props) => {
    const { id }: any = useParams()
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
    const [visible, setVisible] = useState(false)
    const [dataEdit, setDataEdit] = useState<any>()
    const [dataSource, setDataSource] = useState<SelfServiceCatagory>()
    const [loading, setLoading] = useState<boolean>(false)
    const [countItem, setCountItem] = useState(0)
    const [parent, setParent] = useState<SelfServiceCatagory[]>([])
    const [catalogId, setCatalogId] = useState(0)
    const [isRedirectWithPage, setIsRedirectWithPage] = useState(false)
    const { PageServiceModelState } = useContext<any>(PageServiceModelContext)

    useEffect(() => {
        setLoading(true)
        getCatagory(id).then(categories => {
            setDataSource(categories)
        }).finally(() => {
            setLoading(false)
        })
        if (props.selfServiceCatagories.content.length === 0) {
            props.getallCategory().catch(err => {
                message.error(err)
            }).finally(() => {
                // setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.selfServiceCatagories) {
            const filterData = props.selfServiceCatagories.content.filter((category) => category.parent === null)
            setParent(props.selfServiceCatagories.content)
            // setParent(props.selfServiceCatagories.content)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selfServiceCatagories])

    useEffect(() => {
        if (dataSource) {
            if (Number(catalogId) !== Number(dataSource.catalog.id)) {
                setCatalogId(Number(dataSource.catalog.id))
                setFieldsValue({
                    catalogName: dataSource.catalog.name
                })
            } else {
                // ถ้า catalogId เปลี่ยนให้ไปดึงชื่อ catalog มาใหม่
            }
        }
        setFieldsValue({
            catalogName: dataSource?.catalog.name,
            categoryName: dataSource?.name,
            status: dataSource?.status,
            parent: dataSource?.parent?.name,
            description: dataSource?.description
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    const oppositeStatus = (status: string) => {
        if (status === 'Published') {
            return 'Draft'
        } else {
            return 'Published'
        }
    }

    const selectData = () => {
        if (dataSource?.status === 'Draft') {
            setDataEdit(dataSource)
            setVisible(true)
        } else {
            warning({
                title: 'Warning',
                content: 'Please change your status to Draft to make connections.',
                onOk() {
                    console.log('ok')
                }
            })
        }
    }

    const closeDrawer = () => {
        setVisible(false)
        // setDataEdit(undefined)
    }

    const isDeleteData = () => {
        if (dataSource?.status === 'Draft') {
            if ((countItem === 0 || !countItem ) && !parent.find((it) => it.parent?.id === Number(id))) {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Delete of this data ?',
                    onOk() {
                        props.deleteCategoryCatalog(id).catch((err) => {
                            message.error(err)
                        }).then(() => {
                            message.success('Delete data successfully.')
                            setIsRedirectWithPage(true)
                        })
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else {
                Modal.warning({
                    title: 'Warnning',
                    content: 'This action can\'t be completed. Please delete all Items related to the Category and Deselect this Category to parent .'
                })
            }
        } else {
            Modal.warning({
                title: 'Warnning',
                content: 'Please change your status to Draft to make connections.'
            })
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
            }
        })
    }

    const handleEditCategory = (categories: SelfServiceCatagory) => {
        updateCategory(categories, id).then((res) => {
            message.success('The Update has finished successfully.')
            setDataSource(res || categories)
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(true)
            getCatagory(id).then(categories => {
                setDataSource(categories)
            }).finally(() => setLoading(false))
            props.getallCategory().catch(err => {
                message.error(err)
            })
            setDataEdit(undefined)
        })
    }

    const handleChangeStatus = (status: string) => {
        confirm({
            title: 'Are you sure?',
            content: `Do you confirm Change status to ${oppositeStatus(status)} ?`,
            onOk() {
                const tempDataSource = dataSource
                if (tempDataSource) {
                    tempDataSource.status = oppositeStatus(status)
                    delete tempDataSource.createdDatetime
                    updateCategory(tempDataSource, id).then(() => {
                        setDataSource(tempDataSource)
                        message.success('The Update has finished successfully.')
                    }).catch((err) => {
                        message.error('Update Error')
                        message.error(err)
                    }).finally(() => {
                        setFieldsValue({
                            status: tempDataSource.status
                        })
                    })
                }
            },
            onCancel() {
                // console.log('Cancel')
            }
        })
    }

    const handleCountItem = (count: number) => {
        setCountItem(count)
    }

    const parentOption = () => {
        if (parent) {
            return (parent || []).map((parents) => {
                return (<Option key={parents.id} value={parents.id}>{parents.name}</Option>)
            })
        }
    }

    return (
        <Spin spinning={loading}>
            {isRedirectWithPage ? <Redirect to={`/CatalogList?path=Category&page=${PageServiceModelState.pageCategory || 1}`} /> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/CatalogList'}>Maintain Catalog</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={'/CatalogList?path=Category'}>Category</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{dataSource?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'My-card'}>
                <p className={'HerderText'}>{dataSource?.name} <Icon type="edit" style={{margin: '0px 5px'}} onClick={() => selectData()}/> <Icon type="delete" onClick={() => isDeleteData()}/> </p>
                <Form onSubmit={handleSubmit} >
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item label={'Category Name :'}>
                                {getFieldDecorator('categoryName', {
                                    rules: [{ required: true, message: 'Please input your Category Name!' }]
                                })(
                                    <Input placeholder="Category Name" disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label={'Status :'}>
                                {getFieldDecorator('status', {
                                    rules: [{ required: true, message: 'Please input your Status!' }]
                                })(
                                    <Select
                                        placeholder="Select Status"
                                        disabled={true}
                                    >
                                        <Option value="Published">Published</Option>
                                        <Option value="Draft">Draft</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Button className='btn-status' icon='sync' onClick={() => handleChangeStatus(getFieldValue('status'))} >Change to {oppositeStatus(getFieldValue('status'))}</Button>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Catalog Name :'}>
                                {getFieldDecorator('catalogName', {
                                    rules: [{ required: true, message: 'Please input your username!' }]
                                })(
                                    <Input placeholder="Catalog Name" disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Parent :'}>
                                {getFieldDecorator('parent', {
                                    rules: [{ required: false, message: 'Please input your Parent!' }]
                                })(
                                    <Select
                                        // placeholder="Select Parent"
                                        disabled={true}
                                    >
                                        {parentOption()}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={'Description :'}>
                                {getFieldDecorator('description', {
                                    rules: [{ required: false, message: 'Please input your username!' }]
                                })(
                                    <Input placeholder="description" disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Space size={10} />
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                Catalog Item ({countItem || 0})
                            </span>
                        }
                        key="1"
                    >
                        <ItemList setCountItem={handleCountItem} categories={dataSource} isViewCatalog={false}/>
                    </TabPane>
                </Tabs>
            </div>
            <div style={{display: 'none'}}><ItemList setCountItem={handleCountItem} categories={dataSource} isViewCatalog={false}/></div>
            <Drawer
                title={'Edit Category'}
                placement="right"
                onClose={() => closeDrawer()}
                visible={visible}
                width={500}
            >
                <CategoryForm catalog={dataEdit} category={dataSource} parents={parent} setVisible={setVisible} isVisible={visible} catagoryAction={handleEditCategory} id={id}/>
            </Drawer>
        </Spin>
    )
}

const MyCategoryView = Form.create<Props>({ name: 'CategoryView' })(CategoryView)
export default connect(mapStateToProps, {
    getallCategory, deleteCategoryCatalog
}
)(MyCategoryView)
