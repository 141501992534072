import {FormComponentProps} from 'antd/es/form'
import React, {useEffect, useState} from 'react'
import {Form, message, Spin} from 'antd'
import {updateConfirmUser} from '../../authorization-module/login/service'
import {useParams} from 'react-router-dom'

interface Param {
    current: number
    setCurrent: Function
    freeUser: any
    setFreeUser: Function
    setEmail: Function
}

type Props = Param & FormComponentProps
const LoadingPage: React.FC<Props> = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [, setIsValid] = useState(false)
    const { secretCode }: any = useParams()
    const params = new URLSearchParams(window.location.search)
    const email = params.get('email')
    useEffect(() => {
        console.log('secretCode', secretCode)
        updateConfirmUser(secretCode).then(() => {
            setIsValid(true)
            setIsLoading(false)
        }).catch((err) => {
            if (err?.response?.status === 409) {
                window.location.href = `/expired?email=${email}`
            } else {
                message.error('Fail to create tenant, Please try again')
            }
            setIsValid(false)
            setIsLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%'
        }}>
            <Spin tip="Waiting for create tenant..." spinning={isLoading} size="large"></Spin>
        </div>
    )
}

const TenantLoadingPage = Form.create<Props>({ name: 'Step3Free' })(LoadingPage)
export default TenantLoadingPage
