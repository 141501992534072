import {
    DashboardStateEvent,
    GetIncidentBacklogMyticketSuccessEvent,
    GetOpenbyPriorityTicketMyGroupSuccessEvent,
    GetOpenbyPriorityTicketMyticketSuccessEvent,
    GetOpenbyPriorityTicketTotalGroupSuccessEvent,
    GetOpenbyStatusTicketMyGroupSuccessEvent,
    GetOpenbyStatusTicketMyticketSuccessEvent,
    GetOpenbyStatusTicketTotalGroupSuccessEvent,
    GetOpenSLAMyGroupSuccessEvent,
    GetOpenSLAMyticketSuccessEvent,
    GetOpenSLATotalGroupSuccessEvent,
    GetOpenTicketMyGroupSuccessEvent,
    GetOpenTicketTotalGroupSuccessEvent,
    IncidentBacklogStateEvent,
    UpdateIncidentBacklogMyticketSuccessEvent,
    UpdateOpenbyPriorityTicketMyGroupSuccessEvent,
    UpdateOpenbyPriorityTicketMyticketSuccessEvent,
    UpdateOpenbyPriorityTicketTotalGroupSuccessEvent,
    UpdateOpenbyStatusTicketMyGroupSuccessEvent,
    UpdateOpenbyStatusTicketMyticketSuccessEvent,
    UpdateOpenbyStatusTicketTotalGroupSuccessEvent,
    UpdateOpenSLAMyGroupSuccessEvent,
    UpdateOpenSLAMyticketSuccessEvent,
    UpdateOpenSLATotalGroupSuccessEvent,
    UpdateOpenTicketMyGroupSuccessEvent,
    UpdateOpenTicketTotalGroupSuccessEvent,
    GetRespondedSuccessEvent,
    GetRemainderSuccessEvent,
    GetEmailSatisfactionNotDoneEvent,
    GetSatisfactionReportSuccessEvent,
    GetSatisfactionExportSuccessEvent,
    GetAllKnowledgeDahsboardSuccessEvent,
    UpdateAllKnowledgeDashboardSuccessEvent
} from './state-event'
import {GenericStateEvent} from '../common-redux'

// Total Group

export const DashboardOpenTicketTotalGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenTicketTotalGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenTicketTotalGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenbyStatusTicketTotalGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenbyStatusTicketTotalGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenbyStatusTicketTotalGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenbyPriorityTicketTotalGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenbyPriorityTicketTotalGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenbyPriorityTicketTotalGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenSLATotalGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenSLATotalGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenSLATotalGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

// End Total Group

// My Group
export const DashboardOpenTicketMyGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenTicketMyGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenTicketMyGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenbyStatusTicketMyGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenbyStatusTicketMyGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenbyStatusTicketMyGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenbyPriorityTicketMyGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenbyPriorityTicketMyGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenbyPriorityTicketMyGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenSLAMyGroupReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenSLAMyGroupSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenSLAMyGroupSuccessEvent.type) {
        return state
    } else {
        return state
    }
}
// End My Group

// My ticket
export const DashboardOpenbyStatusTicketMyticketReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenbyStatusTicketMyticketSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenbyStatusTicketMyticketSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenbyPriorityTicketMyticketReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenbyPriorityTicketMyticketSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenbyPriorityTicketMyticketSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardOpenSLAMyticketReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetOpenSLAMyticketSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateOpenSLAMyticketSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

export const DashboardIncidentBacklogMyticketReducer = (state = {}, event: IncidentBacklogStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetIncidentBacklogMyticketSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateIncidentBacklogMyticketSuccessEvent.type) {
        return state
    } else {
        return state
    }
}

// Customer satisfaction

export const DashboardRespondedReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetRespondedSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}

export const DashboardRemainderReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetRemainderSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}

export const EmailSatisfactionReducer = (state = {}, event: GenericStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetEmailSatisfactionNotDoneEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}

export const SatisfactionReportReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetSatisfactionReportSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}

export const SatisfactionExportReducer = (state = {}, event: DashboardStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetSatisfactionExportSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}

// Knowledge Dashboard
export const AllKmDashboardReducer = (state = {},event: DashboardStateEvent<any> = {type: undefined, payload: undefined}) => {
    if (event.type === GetAllKnowledgeDahsboardSuccessEvent.type) {
        return event.payload || {}
    } else if (event.type === UpdateAllKnowledgeDashboardSuccessEvent.type) {
        return state
    } else {
        return state
    }
}