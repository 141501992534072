import React, { useRef } from 'react'
import {Select} from 'antd'
import {IncidentFilter} from '../model'
import {SelectValue} from 'antd/es/select'
import {TicketStatus} from '../../incident'

interface OwnProps {
    onChanged: (value: string[]) => void
    criteria?: IncidentFilter
    statuses?: TicketStatus[]
    isLoading?: Function
}

type Props = OwnProps

const StatusFilter: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const ref = useRef<any>(null)
    const onStatusesChanged = (value: SelectValue) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Status</p>
            <Select ref={ref} mode="multiple" style={{ width: '100%' }} onChange={(e) => { onStatusesChanged(e)}} value={props.criteria?.statuses?.split(',') || []} allowClear={true} placeholder={'Select Status'}>
                {props.statuses?.map((it, index) => { return <Option key={index} value={it.status}>{it.status}</Option> })}
            </Select><br />
        </>
    )
}

export default StatusFilter
