import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Row } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { IncidentFilter } from '../model'

interface OwnProps {
    onChanged: (checkedValue: string[]) => void
    criteria?: IncidentFilter
    isLoading?: Function
}

type Props = OwnProps

export const AgentFilter: React.FC<Props> = (props: Props) => {
    const [selectedAgents, setSelectedAgents] = useState<string[]>([])

    useEffect(() => {
        setSelectedAgents(translateCriteriaToSelectedAgents())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.criteria])

    function translateCriteriaToSelectedAgents(): string[] {
        const arr: string[] = []
        if (props.criteria?.assignedToMe) {
            arr.push('assignedToMe')
        }
        if (props.criteria?.assignedToMyTeam) {
            arr.push('assignedToMyTeam')
        }
        if (props.criteria?.unassigned) {
            arr.push('unassigned')
        }
        return arr
    }

    const onSelectedAgentsChanged = (value: CheckboxValueType[]) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChanged(value.map((it) => it as string))
    }

    return (
        <>
            <p className={'topicName'}>Agents</p>
            <Checkbox.Group style={{ width: '100%' }} onChange={onSelectedAgentsChanged}
                value={selectedAgents} key={'agentFilter'}>
                <Row>
                    <Col span={24}>
                        <Checkbox value="assignedToMe">Me</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="unassigned">Unassigned</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="assignedToMyTeam">Assign to my groups</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group><br />
        </>

    )
}
