import {Order} from '../Services/model'
import {buildStateEvent, GenericStateEvent} from '../../common-redux'

export interface PackageStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllOrderSuccessEvent = {
    type: 'GetAllOrderSuccessEvent',
    build: (payload: Order[]) => buildStateEvent(GetAllOrderSuccessEvent.type, payload)
}

export const AddOrderSuccessEvent = {
    type: 'AddOrderSuccessEvent',
    build: (payload: Order) => buildStateEvent(AddOrderSuccessEvent.type, payload)
}

export const DeleteOrderSuccessEvent = {
    type: 'DeleteOrderSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteOrderSuccessEvent.type, payload)
}

export const UpdateOrderSuccessEvent = {
    type: 'UpdateOrderSuccessEvent',
    build: (payload: Order) => buildStateEvent(UpdateOrderSuccessEvent.type, payload)
}
