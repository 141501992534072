import { GetAllGeneralIncidentSettingSuccessEvent, GeneralIncidentSettingStateEvent } from './state-event'
import { GeneralIncidentSetting } from './model'

export const GeneralIncidentSettingStateReducer = (state: GeneralIncidentSetting = {}, event: GeneralIncidentSettingStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllGeneralIncidentSettingSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}
