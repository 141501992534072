import React, { DragEvent } from 'react'
import './dnd.css'
import jobIcon from '../../../../assets/images/job.png'
import { Col } from 'antd'
import approvalIcon from '../../../../assets/images/approval.png'

const onDragStart = (event: DragEvent, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
}

const Sidebar = () => {
    return (
        <aside>
            <div className="description">You can drag these nodes to the pane on the right.</div>
            {/* <div className="react-flow__node-input" onDragStart={(event: DragEvent) => onDragStart(event, 'input')} draggable>
                Input Node
            </div>
            <div className="react-flow__node-default" onDragStart={(event: DragEvent) => onDragStart(event, 'default')} draggable>
                Default Node
            </div> */}
            <div className="workflowSidebar" onDragStart={(event: DragEvent) => onDragStart(event, 'jobNode')} draggable>
                <Col span={6} className="dotForWorkflowIcon" style={{ backgroundColor: '#ED9E28' }}><img src={jobIcon} alt={'jobIcon'} className="workFlowIcon" /></Col>
                <Col span={18} style={{ paddingLeft: 10, fontSize: 16, fontWeight: 500 }}>
                    Job
                </Col>
            </div>
            {/* <div className="workflowSidebar" onDragStart={(event: DragEvent) => onDragStart(event, 'workOrderNode')} draggable>
                Work Order
            </div> */}
            <div className="workflowSidebar" onDragStart={(event: DragEvent) => onDragStart(event, 'approval')} draggable>
                <Col span={6} className="dotForWorkflowIcon" style={{ backgroundColor: '#4DC977' }}><img src={approvalIcon} alt='approvalIcon' className="workFlowIcon" /></Col>
                <Col span={18} style={{ paddingLeft: 10, fontSize: 16, fontWeight: 500 }}>
                    Approval
                </Col>
            </div>
            {/* <div className="react-flow__node-output" onDragStart={(event: DragEvent) => onDragStart(event, 'output')} draggable>
                Output Node
            </div> */}
        </aside>
    )
}

export default Sidebar
