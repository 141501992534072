import { buildStateEvent, GenericStateEvent } from '../common-redux'
import { PMActivityField } from './model'

export interface PMActivityFieldStateEvent<T> extends GenericStateEvent<T> { }

export const GetAllPMActivityFieldSuccessEvent = {
    type: 'GetAllPMActivityFieldSuccessEvent',
    build: (payload: PMActivityField[]) => buildStateEvent(GetAllPMActivityFieldSuccessEvent.type, payload)
}

export const AddPMActivityFieldSuccessEvent = {
    type: 'AddPMActivityFieldSuccessEvent',
    build: (payload: PMActivityField[]) => buildStateEvent(AddPMActivityFieldSuccessEvent.type, payload)
}

export const DeletePMActivityFieldSuccessEvent = {
    type: 'DeletePMActivityFieldSuccessEvent',
    build: (payload: string) => buildStateEvent(DeletePMActivityFieldSuccessEvent.type, payload)
}

export const UpdatePMActivityFieldSuccessEvent = {
    type: 'UpdatePMActivityFieldSuccessEvent',
    build: (payload: PMActivityField) => buildStateEvent(UpdatePMActivityFieldSuccessEvent.type, payload)
}
