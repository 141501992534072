import React from 'react'
import { Table, Collapse } from 'antd'
import {subTableOrder} from '../Services/model'

const columns = [
    {
        title: 'Service',
        dataIndex: 'Service',
        key: 'Service'
    },
    {
        title: 'User',
        dataIndex: 'User',
        key: 'User'
    },
    {
        title: 'People',
        dataIndex: 'People',
        key: 'People'
    },
    {
        title: 'Storage Size',
        dataIndex: 'StorageSize',
        key: 'StorageSize'
    },
    {
        title: 'Database Size',
        dataIndex: 'DatabaseSize',
        key: 'DatabaseSize'
    }
]
interface Param {
    title: string
    subTableOrder: subTableOrder
}

const { Panel } = Collapse
type props = Param
const SubTablePackage = (props: props) => {
    return (
        <div>
            <Collapse defaultActiveKey={['1']} >
                <Panel header={props.title} key="1">
                    <Table dataSource={[props.subTableOrder]} columns={columns} rowKey={'id'}/>
                </Panel>
            </Collapse>
        </div>
    )
}

export default SubTablePackage
