import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const DataComputerNameContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const DataComputerNameReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_DataComputerName':
        return action.payload
    default:
        return state
    }
}

export const DataComputerNameProvider = (props: props) => {
    const [DataComputerNameState, DataComputerNameDispatch] = useReducer(DataComputerNameReducer, initialState)

    const GetDataComputerName = (payload) => {
        DataComputerNameDispatch({ type: 'GET_DataComputerName', payload })
    }

    return (
        <DataComputerNameContext.Provider value={{ DataComputerNameState, GetDataComputerName }}>
            {props.children}
        </DataComputerNameContext.Provider>
    )
}
