import { ChargingGroup } from './Model'
import {CostStateEvent} from './state-event'

export const chargingStateReducer = (state: ChargingGroup[] = [], event: CostStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === 'GetChargingSuccessEvent') {
        return event.payload
    } else {
        return state
    }
}
