import React, {useEffect, useState} from 'react'
import DoughnutChart from './Chart/DoughnutChart'
import {Card, Col, DatePicker, Empty, Form, Row, Select} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import {defaults} from 'react-chartjs-2'
import TableSLAIncident from './TableSLAIncident'
import LayoutChart from './IncidentLayoutChart'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {StoreState} from '../store'
import {IncidentWebSocketChannel, SLAWebSocketChannel} from '../incident-management/incident/web-socket-channel'
import {ConvertDataForChart, ConvertDataForChartPriority, getDashboard} from './service'
import {ChartType, GroupType} from './model'
import {Frame} from 'stompjs'
import {IncidentWebSocketEvent, IncidentWebSocketEventType} from '../incident-management/incident/web-socket-model'
import { waitingGetSessions } from '../guard/route'

// https://www.chartjs.org/docs/latest/configuration/tooltip.html <-- doc
// defaults.global.legend.position = 'bottom'
defaults.global.tooltips.backgroundColor = '#FFD8BF'
defaults.global.tooltips.bodyFontColor = '#fa5414'

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardOpenByStatusTicketMyTicket: state.DashboardOpenByStatusTicketMyTicket,
        DashboardOpenByPriorityTicketMyTicket: state.DashboardOpenByPriorityTicketMyTicket,
        DashboardOpenSLAMyTicket: state.DashboardOpenSLAMyTicket,
        priority: state.priorities
    }
}

interface DispatchProps {
    getDashboard: (Group, created, Chart, startDate?, endDate?) => number
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = FormComponentProps & StateProps & DispatchProps

const { Option } = Select
const { RangePicker } = DatePicker
const IncidentDash: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [show, setShow] = useState<string>('anytime')
    const [created, setCreated] = useState<string>('last30Days')
    const [time, setTime] = useState<string[]>([])
    const [dataColor, setDataColor] = useState<string[]>([])

    useEffect(() => {
        IncidentWebSocketChannel.subscribeDashboard(onDashboardEvent)
        IncidentWebSocketChannel.subscribeTodashboard()
        SLAWebSocketChannel.subscribeDashboard(onDashboardEvent)
        SLAWebSocketChannel.subscribeTodashboard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        CheckGroupAndFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, created, time])

    useEffect(() => {
        const setColor: string[] = []
        if (props.priority) {
            props.priority.forEach((it) => {
                setColor.push(it.tagColor)
            })
        }
        setDataColor(setColor)
    }, [props.priority])

    const onDashboardEvent = (frame: Frame) => {
        const event: IncidentWebSocketEvent = JSON.parse(frame.body)
        if (event.eventType === IncidentWebSocketEventType.GroupDashboardUpdate ||
            event.eventType === IncidentWebSocketEventType.StatusDashboardUpdate ||
            event.eventType === IncidentWebSocketEventType.SlaCalculationsDashboardUpdated ||
            event.eventType === IncidentWebSocketEventType.PriorityDashboardUpdate) {
            CheckGroupAndFetchData()
        }
    }

    const CheckGroupAndFetchData = () => {
        if (created !== 'anytime' || time.length !== 0) {
            if (show === 'anytime') {
                // TotalGroup
                fetchData(GroupType.TotalGroup, ChartType.OpenTicket, time)
                fetchData(GroupType.TotalGroup, ChartType.OpenByStatus, time)
                fetchData(GroupType.TotalGroup, ChartType.OpenByPriority, time)
                fetchData(GroupType.TotalGroup, ChartType.OpenSLA, time)
                // My Group
                fetchData(GroupType.MyGroup, ChartType.OpenTicket, time)
                fetchData(GroupType.MyGroup, ChartType.OpenByStatus, time)
                fetchData(GroupType.MyGroup, ChartType.OpenByPriority, time)
                fetchData(GroupType.MyGroup, ChartType.OpenSLA, time)
                // My Ticket
                // if (sessionStorage.getItem('scrollHeightTo1000') === '1000') {
                //     console.log('fetch')
                fetchData(GroupType.MyTicket, ChartType.OpenByStatus, time)
                fetchData(GroupType.MyTicket, ChartType.OpenByPriority, time)
                fetchData(GroupType.MyTicket, ChartType.OpenSLA, time)
            } else if (show === 'MyGroup') {
                // My Group
                fetchData(GroupType.MyGroup, ChartType.OpenTicket, time)
                fetchData(GroupType.MyGroup, ChartType.OpenByStatus, time)
                fetchData(GroupType.MyGroup, ChartType.OpenByPriority, time)
                fetchData(GroupType.MyGroup, ChartType.OpenSLA, time)
            } else if (show === 'MyTicket') {
                // My Ticket
                fetchData(GroupType.MyTicket, ChartType.OpenByStatus, time)
                fetchData(GroupType.MyTicket, ChartType.OpenByPriority, time)
                fetchData(GroupType.MyTicket, ChartType.OpenSLA, time)
                // fetchData(GroupType.MyTicket, ChartType.IncidentBacklog)
            } else if (show === 'TotalGroup') {
                // TotalGroup
                fetchData(GroupType.TotalGroup, ChartType.OpenTicket, time)
                fetchData(GroupType.TotalGroup, ChartType.OpenByStatus, time)
                fetchData(GroupType.TotalGroup, ChartType.OpenByPriority, time)
                fetchData(GroupType.TotalGroup, ChartType.OpenSLA, time)
            }
        }
    }

    const fetchData = async (Group: GroupType, chart: ChartType, Time: string[]) => {
        if (Time.length !== 0) {
            await props.getDashboard(Group, created, chart, Time[0], Time[1])
        } else {
            await props.getDashboard(Group, created, chart)
        }
    }

    const changeShow = (e) => {
        setShow(e)
    }

    const changeCreated = (e) => {
        if (e !== 'anytime') {
            setTime([])
        }
        setCreated(e)
    }

    const changeTime = (value, dateString) => {
        if (dateString) {
            setTime(dateString)
        } else {
            setTime([])
        }
    }

    return (
        <div>
            <Form>
                <Row gutter={16}>
                    <Col span={18}/>
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                        <span>Show :</span>
                        <Form.Item>
                            {getFieldDecorator('Show', {
                                initialValue: show
                            })(
                                <Select onChange={(e) => changeShow(e)}>
                                    <Option value="anytime">All</Option>
                                    <Option value="MyGroup">My Group</Option>
                                    <Option value="MyTicket">My Ticket</Option>
                                    <Option value="TotalGroup">Total Group</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                        <span>Created :</span>
                        <Form.Item >
                            {getFieldDecorator('Created', {
                                initialValue: created
                            })(
                                <Select onChange={(e) => changeCreated(e)}>
                                    <Option value="last30Days">Last 30 days</Option>
                                    <Option value="today">Today</Option>
                                    <Option value="yesterday">Yesterday</Option>
                                    <Option value="thisWeek">This Week</Option>
                                    <Option value="thisMonth">This Month</Option>
                                    <Option value="anytime">Select Time Period</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={18} />
                    <Col span={6}>{created === 'anytime' ? (<>
                        <Form.Item >
                            {getFieldDecorator('TimePeriod')(
                                <RangePicker
                                    // showTime={{ format: 'HH:mm' }}
                                    onChange={changeTime}
                                    format="YYYY-MM-DD"
                                    placeholder={['Start Time', 'End Time']}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            )}
                        </Form.Item>
                    </>) : null}</Col>
                </Row>
            </Form>
            <br />

            {show === 'anytime' || show === 'TotalGroup' ? <LayoutChart Group={'Total Group'} created={created === 'anytime' ? time : created} /> : null}

            {show === 'anytime' || show === 'MyGroup' ? <LayoutChart Group={'My Group'} created={created === 'anytime' ? time : created} /> : null}

            {show === 'anytime' || show === 'MyTicket' ? <>
                {/* My Ticket */}
                <Row className="TopicDashboard">
                    <Col span={24}>
                        <span className="main-title">My Ticket</span>
                    </Col>
                </Row>
                <br />

                <Row gutter={8} style={{ marginBottom: '2%' }}>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} style={{ marginBottom: '2%' }}>
                        <Link to={`/ReportDashBoardNoneSLA?group=${show === 'anytime' ? 'MyTicket' : show}&created=${created === 'anytime' ? time : created}`} target="_blank" onClick={() => waitingGetSessions()}>
                            <Card className="Box-DashBoard">
                                <span className='sub-title'>Open by Status  ({props.DashboardOpenByStatusTicketMyTicket.total} Tickets)</span>
                                {props.DashboardOpenByStatusTicketMyTicket.total === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <DoughnutChart Height={105} showLabels={true} marginTop={'2%'} myData={ConvertDataForChart(props.DashboardOpenByStatusTicketMyTicket.data)} fixPosition='right' />}
                            </Card>
                        </Link>
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} style={{ marginBottom: '2%' }}>
                        <Link to={`/ReportDashBoardNoneSLA?group=${show === 'anytime' ? 'MyTicket' : show}&created=${created === 'anytime' ? time : created}`} target="_blank" onClick={() => waitingGetSessions()}>
                            <Card className="Box-DashBoard">
                                <span className='sub-title'>Open by Priority ({props.DashboardOpenByPriorityTicketMyTicket.total} Tickets)</span>
                                {props.DashboardOpenByPriorityTicketMyTicket.total === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <DoughnutChart Height={105} showLabels={true} marginTop={'2%'} myData={ConvertDataForChartPriority(props.DashboardOpenByPriorityTicketMyTicket.data, dataColor)} fixPosition='right' />}
                            </Card>
                        </Link>
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} style={{ marginBottom: '2%' }}>
                        <Link to={`/ReportDashBoard?group=${show === 'anytime' ? 'MyTicket' : show}&created=${created === 'anytime' ? time : created}`} target="_blank" onClick={() => waitingGetSessions()}>
                            <Card className="Box-DashBoard">
                                <span className='sub-title'>SLA  ({props.DashboardOpenSLAMyTicket.total} Tickets)</span>
                                {props.DashboardOpenSLAMyTicket.total === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <DoughnutChart Height={105} showLabels={true} marginTop={'2%'} myData={ConvertDataForChart(props.DashboardOpenSLAMyTicket.data)} fixPosition='right'/>}
                            </Card>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <Card className="Box-DashBoard" >
                        <span className='sub-title'>Ticket Backlog </span>
                        <div style={{overflow: 'auto'}}>
                            <TableSLAIncident show='MyTicket' create={created} start={time[0]} end={time[1]} />
                        </div>
                    </Card>
                </Row>
            </> : null}
        </div>
    )
}

const WrappedNormalDashboardForm = Form.create({ name: 'DashBoard' })(IncidentDash)

export default connect(mapStateToProps, {
    getDashboard: getDashboard
})(WrappedNormalDashboardForm)
