import {SupportTeamManagement, SupportTeamManagementPagination} from './model'
import {
    AddSupportTeamSuccessEvent,
    DeleteSupportTeamSuccessEvent,
    GetAllSupportTeamSuccessEvent,
    SupportTeamManagementStateEvent,
    UpdateSupportTeamCurrentPageSuccessEvent,
    UpdateSupportTeamFilterSuccessEvent,
    UpdateSupportTeamOrderBySuccessEvent,
    UpdateSupportTeamSearchFieldSuccessEvent,
    UpdateSupportTeamSearchValueSuccessEvent,
    UpdateSupportTeamSortBySuccessEvent,
    UpdateSupportTeamSuccessEvent,
    UpdateSupportTeamTotalPageSuccessEvent
} from './state-event'

export const supportTeamManagementStateReducer = (state: SupportTeamManagement[] = [], event: SupportTeamManagementStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSupportTeamSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddSupportTeamSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === UpdateSupportTeamSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else if (event.type === DeleteSupportTeamSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else {
        return state
    }
}

export const supportTeamPaginationStateReducer = (state: SupportTeamManagementPagination = {}, event: SupportTeamManagementStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateSupportTeamTotalPageSuccessEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateSupportTeamCurrentPageSuccessEvent.type) {
        state.page = event.payload
    } else if (event.type === UpdateSupportTeamOrderBySuccessEvent.type) {
        state.orderBy = event.payload
    } else if (event.type === UpdateSupportTeamFilterSuccessEvent.type) {
        state.filter = event.payload
    } else if (event.type === UpdateSupportTeamSearchFieldSuccessEvent.type) {
        state.searchField = event.payload
    } else if (event.type === UpdateSupportTeamSearchValueSuccessEvent.type) {
        state.searchValue = event.payload
    } else if (event.type === UpdateSupportTeamSortBySuccessEvent.type) {
        state.sortBy = event.payload
    } else {
        return state
    }
    return state
}
