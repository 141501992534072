import { Form, Input, Checkbox, Button, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import React, { useState } from 'react'
import { IncidentWithSlaCalculations } from './model'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { CreateTemplete } from './service'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Params {
    setVisible: Function
    incident: IncidentWithSlaCalculations | null
}

type Props = Params & FormComponentProps & StateProps
const FormCreateTemplete: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields } = props.form
    const [isCheck, setIsCheck] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields((err: any, value: any) => {
            if (!err) {
                setIsLoading(true)
                let channelId
                let TicketType
                if (props.incident) {
                    // eslint-disable-next-line no-unused-expressions
                    props.incident?.incidentSetting?.forEach(it => {
                        if (it.incGeneralSetting.settingType === 'channel') {
                            channelId = it.incGeneralSetting.id
                        }
                        if (it.incGeneralSetting.settingType === 'ticketType') {
                            TicketType = it.incGeneralSetting.id
                        }
                    })
                    const categoriesId = props.categories.find((it) => { return it.name === props.incident?.category })
                    const subCategoriesId = props.subCategories.find((it) => { return it.categoryId === categoriesId?.id && it.subCategory === props.incident?.subcategory })
                    const itemsId = props.items.find((it) => { return it.categoryId === categoriesId?.id && it.subcategoryId === subCategoriesId?.id && it.item === props.incident?.item })
                    const convertData = {
                        templateName: value.templateName,
                        ticketNumber: props.incident?.number,
                        isCopyCollaborators: isCheck,
                        requesterId: {
                            id: props.incident?.people.id
                        },
                        priorityId: {
                            id: props.incident?.priority?.id
                        },
                        channelId: {
                            id: channelId
                        },
                        ticketTypeId: {
                            id: TicketType
                        },
                        topic: props.incident?.subject,
                        description: props.incident?.description,
                        assignmentGroupId: !isCheck ? null : props.incident?.supportTeam?.id ? {
                            id: props.incident?.supportTeam?.id
                        } : null,
                        assignedToId: !isCheck ? null : props.incident?.user?.id ? {
                            id: props.incident?.user?.id
                        } : null,
                        categoryId: categoriesId?.id ? {
                            id: categoriesId?.id
                        } : null,
                        subcategoryId: subCategoriesId?.id ? {
                            id: subCategoriesId?.id
                        } : null,
                        itemId: itemsId?.id ? {
                            id: itemsId?.id
                        } : null,
                        ticketStatusId: props.incident?.ticketStatus.id ? {
                            id: props.incident?.ticketStatus.id
                        } : null,
                        active: true
                    }
                    console.log(convertData)
                    CreateTemplete(convertData).then(() => {
                        message.success('You have Successfully save the data.')
                        props.setVisible(false)
                    }).catch((error) => {
                        message.error(`You have unSuccessfully save the data. ${error}`)
                    }).finally(() => {
                        resetFields(['templateName', 'isCollaborators'])
                        // setIsCheck(false)
                        setIsLoading(false)
                    })
                }
            }
        })
    }

    const handleClose = () => {
        props.setVisible(false)
        resetFields(['templateName', 'isCollaborators'])
        setIsCheck(false)
    }

    return (
        <div>
            <Form>
                <Form.Item label="Enter a new Template Name">
                    {getFieldDecorator('templateName', {
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: 'Please input Template Name'
                            }
                        ]
                    })(<Input placeholder={'INC23000001 -'} maxLength={255} />)}
                </Form.Item>
                <span style={{ color: 'rgba(130, 130, 130, 1)' }}>Add note and related will be not be copied to the new ticket</span>
                <Form.Item>
                    {getFieldDecorator('isCollaborators', {
                    })(<Checkbox checked={isCheck} onChange={() => setIsCheck(!isCheck)}>Also copy ticket collaborators</Checkbox>)}
                </Form.Item>
                <div style={{ textAlign: 'right' }}>
                    <Button htmlType='submit' className="ml5"
                        style={{ marginBottom: 5, marginLeft: 5, marginRight: 5 }} onClick={handleClose}>
                            Cancle
                    </Button>
                    <Button type="primary" onClick={handleSubmit} className="ml5" loading={isLoading}>
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const MyFormCreateTemplete = Form.create<Props>({ name: 'FormCreateTemplete' })(FormCreateTemplete)
export default connect(mapStateToProps, {

})(MyFormCreateTemplete)
