import {Order} from '../Services/model'
import {GetAllOrderSuccessEvent, PackageStateEvent, AddOrderSuccessEvent, DeleteOrderSuccessEvent, UpdateOrderSuccessEvent} from './state-event'

export const OrderStateReducer = (state: Order[] = [], event: PackageStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllOrderSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddOrderSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteOrderSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateOrderSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
