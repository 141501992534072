import React, { useState, useEffect } from 'react'
import {Tabs, Icon, Breadcrumb, message} from 'antd'
import './CustomStyle.css'
import {Space} from '../../common-components/Space'
import CatalogList from './components/CatalogList'
import CategoryList from '../CategoryTab/CategoryList'
import ItemCatalogList from '../ItemCatalogTab/ItemCatalogList'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { getallCatalog, getallCategory } from './service'

const { TabPane } = Tabs

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs,
        selfServiceCatagories: state.selfServiceCatagories
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCatalog: () => Promise<number>
    getallCategory: () => Promise<number>
}
type Props = StateProps & DispatchProps

const CatalogHome: React.FC<Props> = (props: Props) => {
    const [name, setName] = useState('Catalog')
    const [keyName, setKeyName] = useState<string>('1')
    const params = new URLSearchParams(window.location.search)
    const path = params.get('path')

    useEffect(() => {
        // setLoading(true)
        if (props.selfServiceCatalogs.content.length === 0) {
            props.getallCatalog().catch(err => {
                message.error(err)
            }).finally(() => {
                // setLoading(false)
            })
        }
        if (props.selfServiceCatagories.content.length === 0) {
            props.getallCategory().catch(err => {
                message.error(err)
            }).finally(() => {
                // setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (path) {
            if (path === 'Catalog') {
                setKeyName('1')
            } else if (path === 'Category') {
                setKeyName('2')
            } else if (path === 'Item Catalog') {
                setKeyName('3')
            }
        }
    }, [path])

    const ChangeTab = (key) => {
        setKeyName(key)
        if (key === '1') {
            setName('Catalog')
        } else if (key === '2') {
            setName('Category')
        } else if (key === '3') {
            setName('Item Catalog')
        }
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Maintain Catalog</Breadcrumb.Item>
                <Breadcrumb.Item>{name}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <Tabs type="card" tabPosition={'left'} onChange={ChangeTab} activeKey={keyName}>
                <TabPane tab={<span>
                    <Icon type="folder-open" />
                        Catalog
                </span>} key="1" >
                    <CatalogList />
                </TabPane>
                <TabPane tab={<span>
                    <Icon type="folder-open" />
                        Category
                </span>} key="2">
                    <CategoryList nameTap={name}/>
                </TabPane>
                <TabPane tab={<span>
                    <Icon type="folder-open" />
                        Item Catalog
                </span>} key="3">
                    <ItemCatalogList nameTap={name} />
                </TabPane>
            </Tabs>
        </>
    )
}

export default connect(mapStateToProps,
    { getallCatalog, getallCategory }
)(CatalogHome)
