import { Col, Icon, message, Modal, Row, Tag } from 'antd'
import React, { useContext } from 'react'
import { TicketRelatedContext } from '../../../../Preventive/ContextAPI/TicketRelatedContext'
import { deleteRelateTicketAndProblems } from '../../service'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'

interface Param {
    it: any
    isNonApproved?: boolean
}
type Props = Param
const { confirm } = Modal
const DisplyTicket = (props: Props) => {
    const { DeleteTicketRelated } = useContext<any>(TicketRelatedContext)
    const deleteTicketRelatedTicket = (data: any, ticketId?: number) => {
        if (props.isNonApproved === false) {
            confirm({
                title: 'Are you sure ?',
                content: 'Do you confirm delete of this data ?',
                onOk() {
                    if (ticketId) {
                        DeleteTicketRelated(ticketId)
                        deleteRelateTicketAndProblems(data.relateId.toString()).then(() => {
                            message.success('Ticket deleted successfully')
                        })
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        }
    }
    console.log(props.isNonApproved)
    return (
        <div>
            <div className="TicketRelateCard">
                <Row>
                    <Col span={22} lg={22} md={20}>
                        <Row>
                            <Col span={24}>
                                <div className='text-herder-Orenge'> Ticket Number : {props.it?.number || props.it.ticketNumber}
                                    <Tag style={{ backgroundColor: props.it.priority?.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{props.it.priority?.priority?.name}</Tag>
                                </div>
                            </Col>
                            {/* <Col span={2} xl={2} lg={2} md={4} className='TicketRelateCard-DeleteButton' style={{ marginLeft: 30 }}>
                                        <Icon onClick={(e) => { deleteAssetRelatedTicket(Number(props.it.id)) }} type="delete" />
                                    </Col> */}
                        </Row>
                        <Row>
                            <Col span={12}>
                                <div className='custom-formLabel-wo'><b>Status</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.ticketStatus?.status}</p>
                            </Col>
                            <Col span={12}>
                                <div className='custom-formLabel-wo'><b>Requester</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.people?.fullName || props.it.requesterName}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <div className='custom-formLabel-wo'><b>Topic</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.subject || props.it.topic}</p>
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2} lg={2} md={4} className={props.isNonApproved === false ? 'TicketRelateCard-DeleteButton' : ''} style={{ marginLeft: 25 }} onClick={(e) => { deleteTicketRelatedTicket(props.it, props.it.id) }}>
                        {props.isNonApproved === false || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <Icon type="delete"/> : null}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DisplyTicket
