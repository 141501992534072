import React, { useEffect, useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { getUserImage } from '../authorization-module/user-role/duck/action'
import { StoreState } from '../store'
import { connect } from 'react-redux'

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']

interface Prop {
    title: string
    broken: boolean
    username?: string
    size?: number
    isMultiUser?: boolean
    isShowTooltips?: boolean
    isKmDashboardStyle?: boolean
}

const mapStateToProps = (state: StoreState) => ({
    roles: (state.roles || []),
    userRole: state.userRoles
})

type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & Prop
const AutoAvatar: React.FC<Props> = (props: Props) => {
    const [pictureAvatar, setPictureAvatar] = useState<any>()
    const [fact, setFact] = useState<boolean>(false)

    useEffect(() => {
        if (props.username !== undefined && !fact) {
            getUserImage(props.username).then((res) => {
                if (res) {
                    setFact(true)
                    setPictureAvatar(JSON.parse(res)[0].url)
                }
            }).finally(() => { setFact(false) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.username])

    let codeColorIndex = 0
    if (props.title) {
        for (let i = 0; i < props.title.length; i++) {
            codeColorIndex += props.title.charCodeAt(i)
        }
    }

    const color = colorList[codeColorIndex % 4]
    return (
        props.isShowTooltips
            ? <Tooltip title={`• ${props.title}`} placement='bottom'>
                <Avatar className={props.isMultiUser === true ? 'Avatars' : ''} style={props.isKmDashboardStyle ? {marginLeft: '-10px', marginRight: '10px', width: '45px', height: '45px', backgroundColor: color, verticalAlign: 'middle'} : { backgroundColor: color, verticalAlign: 'middle' }} size={props.size || 'large'} src={pictureAvatar || props.title}>
                    {props.title?.slice(0, 1) || ''}
                </Avatar>
            </Tooltip>
            : <Avatar className={props.isMultiUser === true ? 'Avatars' : ''} style={props.isKmDashboardStyle ? {marginLeft: '-10px', marginRight: '10px', width: '45px', height: '45px', backgroundColor: color, verticalAlign: 'middle'} : { backgroundColor: color, verticalAlign: 'middle' }} size={props.size || 'large'} src={pictureAvatar || props.title}>
                {props.title?.slice(0, 1) || ''}
            </Avatar>

    )
}

export default connect(
    mapStateToProps, {}
)(AutoAvatar)
