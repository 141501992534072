import {GetAllImpactPrioritySuccessEvent, ImpactProblemStateEvent} from './state-event'
import {PriorityLevelProblem} from '../priority'

export const impactPriorityStateReducer = (state: PriorityLevelProblem[] = [], event: ImpactProblemStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllImpactPrioritySuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
