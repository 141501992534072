import React, {useState, useEffect, useContext} from 'react'
import {Button, Col, Drawer, Icon, Input, message, Row, Table, Tag, Tooltip, Form} from 'antd'
import {Space} from '../../common-components/Space'
import {Link} from 'react-router-dom'
import {FilterItem, SelfServiceItem, ResponseContentDetails } from '../Catalog/Model'
import ReactHtmlParser from 'react-html-parser'
import MyFilterItemCatalog from './FilterItemCatalog'
import { connect } from 'react-redux'
import { searchCatalogItems } from '../Catalog/service'
import { StoreState } from '../../store'
import { getAllGeneralIncidentSetting } from '../../incident-management/general-incident-setting/service'
import {FormComponentProps} from 'antd/es/form'
import { PageServiceModelContext } from '../Catalog/ContextPage/PagesContext'
import { SearchParamsURL } from '../../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllGeneralIncidentSetting: () => Promise<number>
}

interface Param {
    nameTap: string
}

type Props = StateProps & DispatchProps & FormComponentProps & Param
const { Search } = Input
const ItemCatalogList: React.FC<Props> = (props: Props) => {
    const pageSize = 10
    const {getFieldDecorator, setFieldsValue} = props.form
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [searchFilter, setSearchFilter] = useState<FilterItem>()
    const [searchText, setSearchText] = useState<string>('')
    const [dataSource, setDataSource] = useState<ResponseContentDetails<SelfServiceItem[]>>()
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const { PageServiceModelState, GetPageServiceModel } = useContext<any>(PageServiceModelContext)

    useEffect(() => {
        const page = SearchParamsURL('page')
        if (page) {
            setPage(Number(page))
        }
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData()
        if (searchFilter) {
            if (searchFilter.CatalogId || searchFilter.CategoryId || searchFilter.Status || searchFilter.Type) {
                // setPage(1)
                if (PageServiceModelState && GetPageServiceModel) {
                    GetPageServiceModel({
                        pageCatalog: PageServiceModelState.pageCatalog,
                        pageCategory: PageServiceModelState.pageCategory,
                        pageItemCatalog: 1
                    })
                }
                setFieldsValue({
                    Search: undefined
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, searchFilter, searchText])

    useEffect(() => {
        if (props.nameTap) {
            setSearchFilter(undefined)
        }
    }, [props.nameTap])

    const getData = () => {
        setLoading(true)
        searchCatalogItems(page, pageSize, searchFilter?.CatalogId?.toString() || '', searchFilter?.CategoryId?.toString() || '', sortBy, orderBy, searchFilter?.Status?.toString(), searchFilter?.Type?.toString(), searchText).then((res) => {
            setDataSource(res)
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        <Link to={'/ItemCatalogView/' + row.id} >{data}</Link>
                        {/* <Button type="link" onClick={() => setDataEdit(row)} >{data}</Button> */}
                    </div>
                </>
            )
        },
        {
            title: 'Catalog Name',
            dataIndex: 'category.catalog.name',
            key: 'category.catalog.name',
            sorter: true
        },
        {
            title: 'Category Name',
            dataIndex: 'category.name',
            key: 'category.name',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: true
            // render: (name: string, record) => {
            //     const ticketType = props.generalIncidentSetting.ticketTypes?.find((data) => data.id === Number(name))
            //     return (
            //         <>
            //             {ticketType?.value}
            //         </>
            //     )
            // }
        }

    ]

    const searchFilterTag = (title: string, searchFilter: string[], onClose: Function, nameTag: string) => {
        let tags: JSX.Element = (<></>)
        if (nameTag !== 'Status' && nameTag !== 'Type') {
            searchFilter = JSON.parse(searchFilter[0])
        }
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string) => { return `- ${filter}` }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={onClose}>{nameTag}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        if (PageServiceModelState && GetPageServiceModel) {
            GetPageServiceModel({
                pageCatalog: PageServiceModelState.pageCatalog,
                pageCategory: PageServiceModelState.pageCategory,
                pageItemCatalog: page
            })
        }
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortBy(sorter.field)
        if (sorter.order === 'ascend') {
            setOrderBy('asc')
        } else if (sorter.order === 'descend') {
            setOrderBy('desc')
        } else {
            setOrderBy('desc')
            setSortBy('createdDatetime')
        }
    }

    const handleSearch = (search: string) => {
        setSearchFilter(undefined)
        setSearchText(search)
        setPage(1)
        // searchCatalogItems(page, pageSize, '', '', sortBy, orderBy, searchFilter?.Status?.toString(), searchFilter?.Type?.toString(), search).then((items) => {
        //     if (items) {
        //         setDataSource(items)
        //     }
        // })
    }

    const search = (value?: string, forceSearch?: boolean) => {
        getData()
    }

    return (
        <>
            <Row>
                <Col span={24} style={{textAlign: 'end'}}>
                    <Link to={'/ItemCatalog'}><Button type={'primary'} >Add Item Catalog</Button></Link>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Col span={24}>
                    <div><p className={'HerderText'}>Item Catalog ({dataSource?.totalElements || dataSource?.total || 0}) </p></div>
                    <Form >
                        <Form.Item>
                            {getFieldDecorator('Search', {})(
                                <Search
                                    placeholder="Can search Item Name"
                                    onSearch={(value) => handleSearch(value)}
                                    style={{ width: '100%' }}
                                    allowClear={true}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Col xs={24}>
                    <Button style={{ border: 0, marginTop: 10 }} onClick={() => { setOpenFilter(true) }} ><Icon type="filter" theme="filled" /> Filter</Button>
                    {searchFilter?.CatalogName ? searchFilterTag(searchFilter.CatalogName, [searchFilter.CatalogName], () => { delete searchFilter.CatalogName; setPage(1); delete searchFilter.CatalogId; search(searchText !== '' ? searchText : undefined, true) }, 'Catalog') : null}
                    {searchFilter?.CategoryName ? searchFilterTag(searchFilter.CategoryName, [searchFilter.CategoryName], () => { delete searchFilter.CategoryName; setPage(1); delete searchFilter.CategoryId; search(searchText !== '' ? searchText : undefined, true) }, 'Category') : null}
                    {searchFilter?.Status ? searchFilterTag(searchFilter.Status as string, searchFilter.Status as string[], () => { delete searchFilter.Status; setPage(1); search(searchText !== '' ? searchText : undefined, true) }, 'Status') : null}
                    {searchFilter?.Type ? searchFilterTag(searchFilter.Type as string, searchFilter.Type as string[], () => { delete searchFilter.Type; setPage(1); search(searchText !== '' ? searchText : undefined, true) }, 'Type') : null}
                </Col>
            </Row>
            <Table
                dataSource={dataSource?.content}
                rowKey={'id'}
                loading={loading}
                columns={columns}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: dataSource?.totalElements || dataSource?.total,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                }}
                onChange={handleChangeTable}
            />
            <Drawer
                title="Filter"
                placement="right"
                closable={true}
                onClose={() => { setOpenFilter(false) }}
                visible={openFilter}
                width={400}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                <MyFilterItemCatalog setOpenFilter={setOpenFilter} setSearchFilter={setSearchFilter} isVisible={openFilter} setPage={setPage} searchFilter={searchFilter} />
            </Drawer>
        </>
    )
}

const MyItemCatalogList = Form.create<Props>({name: 'ItemCatalogList'})(ItemCatalogList)
export default connect(mapStateToProps,
    { getAllGeneralIncidentSetting }
)(MyItemCatalogList)
