import { FormComponentProps } from 'antd/es/form'
import React, { useContext, useState } from 'react'
import {Button, Card, Col, Collapse, DatePicker, Icon, message, Row, Select, Spin, Form} from 'antd'
import { RoleType, checkRolesState } from '../../../authorization-module/permissions'
import { ColumnNormal, NewAsset } from '../../../ReportComponent/components/Model'
import { NewAssetContext } from '../../../ReportComponent/components/ContextAPI/NewAssetContext'
import ColumnNewAsset from '../../../ReportComponent/components/ColumnNewAsset'
import { convertCamelCaseToSentenceCase } from '../../../knowledge-management/util'
import PageService from '../../../ReportComponent/components/ContextAPI/PageService'
import moment from 'moment'
import { getExportAssetReport, getPreviewAssetReport } from './service'

interface Param {}
type Props = Param & FormComponentProps
const {Panel} = Collapse
const {RangePicker} = DatePicker
const {Option} = Select
const dateFormat = ('YYYY-MM-DD')
const ReportAsset: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue, validateFields, resetFields, getFieldValue } = props.form
    const [loadingExportAsset, setLoadingExportAsset] = useState<boolean>(false)
    const [columnsNewAssetObjList, setColumnsNewAssetObjList] = useState<ColumnNormal[]>([])
    const [columnsNewAssetList, setColumnsNewAssetList] = useState<string[]>([])
    const [totalAsset, setTotalAsset] = useState(0)
    const [rangeAsset, setRangeAsset] = useState<any[]>()
    const {NewAssetState, GetNewAsset} = useContext<any>(NewAssetContext)
    const [isFetchingFirstAllAsset, setisFetchingFirstAllAsset] = useState<boolean>(false)
    const [tempNewAssetState, setTempNewAssetState] = useState<NewAsset[]>([])
    const [loadingAsset, setLoadingAsset] = useState<boolean>(false)

    const handleAssetChange = (range: any) => {
        if (range && range.length !== 0) {
            setRangeAsset(range)
        } else {
            GetNewAsset('')
            setColumnsNewAssetList([])
            setColumnsNewAssetObjList([])
            setisFetchingFirstAllAsset(false)
            setRangeAsset(undefined)
            setTotalAsset(0)
            setTempNewAssetState([])
        }
    }

    const HandelChangeColumnsNewAssetList = (e) => {
        setColumnsNewAssetList(e)
        sessionStorage.setItem('NewAsset', e)
    }

    const handleOnClickPreviewAsset = () => {
        if (rangeAsset) {
            const startData = rangeAsset[0].format(dateFormat)
            const endDate = rangeAsset[1].format(dateFormat)
            fetchNewAsset(startData, endDate, PageService.getInstance().data.pageAsset, PageService.getInstance().data.pageSizeAsset, columnsNewAssetList)
        } else {
            GetNewAsset('')
            setColumnsNewAssetList([])
            setColumnsNewAssetObjList([])
            setisFetchingFirstAllAsset(false)
            setRangeAsset(undefined)
        }
    }

    const getTableHeader = (data) => {
        const columnsExportNewAsset: ColumnNormal[] = []
        if (data.length > 0) {
            data.map((keyName) => {
                columnsExportNewAsset.push(
                    {
                        title: convertCamelCaseToSentenceCase(keyName),
                        dataIndex: keyName
                    }
                )
            })
        }
        return columnsExportNewAsset
    }

    const fetchNewAsset = (startDate: string, endDate: string, page: number, pageSize: number, selectedColumn: string[]) => {
        setLoadingAsset(true)
        getPreviewAssetReport(startDate, endDate, page, pageSize, selectedColumn).then((res) => {
            const assetHeader = getTableHeader(res.header)
            if (res.data.length !== 0) {
                if (GetNewAsset) {
                    GetNewAsset(res.data)
                    setTotalAsset(res.total)
                    setLoadingAsset(false)
                    setTempNewAssetState(res.data)
                    if (!isFetchingFirstAllAsset || columnsNewAssetList.length === 0) {
                        setisFetchingFirstAllAsset(true)
                        if (sessionStorage.getItem('NewAsset')) {
                            const newAsset = sessionStorage.getItem('NewAsset')?.split(',')
                            setColumnsNewAssetList(newAsset || [])
                        } else {
                            setColumnsNewAssetList(assetHeader.map((keyName) => keyName.dataIndex))
                        }
                    }
                    setColumnsNewAssetObjList(assetHeader)
                    const newAssetInSesion = sessionStorage.getItem('NewAsset')?.split(',')
                    if (newAssetInSesion) {
                        if (newAssetInSesion[0] !== '') {
                            setFieldsValue({
                                ColumnAssets: sessionStorage.getItem('NewAsset')?.split(',')
                            })
                        } else {
                            setFieldsValue({
                                ColumnAssets: assetHeader.map((keyName) => keyName.dataIndex)
                            })
                        }
                    } else {
                        setFieldsValue({
                            ColumnAssets: assetHeader.map((keyName) => keyName.dataIndex)
                        })
                    }
                }
            } else {
                if (GetNewAsset) {
                    GetNewAsset(res.data)
                    setTotalAsset(res.total)
                    message.warning('Data Not Found')
                    setLoadingAsset(false)
                    if (!isFetchingFirstAllAsset || columnsNewAssetList.length === 0) {
                        setisFetchingFirstAllAsset(true)
                        if (sessionStorage.getItem('NewAsset')) {
                            const newAsset = sessionStorage.getItem('NewAsset')?.split(',')
                            setColumnsNewAssetList(newAsset || [])
                        } else {
                            setColumnsNewAssetList(assetHeader.map((keyName) => keyName.dataIndex))
                        }
                    }
                    setColumnsNewAssetObjList(assetHeader)
                }
            }
        }).catch(() => {
            setTempNewAssetState([])
        }).finally(() => setLoadingAsset(false))
    }

    const exportNewAssetData = () => {
        if (rangeAsset) {
            setLoadingExportAsset(true)
            const startData = moment(rangeAsset[0]).format(dateFormat)
            const endDate = moment(rangeAsset[1]).format(dateFormat)
            getExportAssetReport(startData, endDate, columnsNewAssetList).then((data) => {
                console.log('success')
            }).finally(() => setLoadingExportAsset(false))
        }
    }

    const disabledTime = (current) => {
        // Disable times before 10:00 AM and after 6:00 PM
        return current && current > moment().endOf('day');
    };

    return (
        <div className="main">
            <Spin spinning={loadingExportAsset} tip="Wating to Create File!!">
                <p className={'content'}>Report</p>
                <Card headStyle={{borderBottom: 'none', paddingTop: '23px'}}>
                    <Collapse defaultActiveKey={['1']} className="StyleCollapse">
                        <Panel header={'All Asset'} key="1">
                            <Row gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    <p>Created Date</p>
                                    <RangePicker onChange={handleAssetChange} placeholder={['Start Date', 'End Date']}
                                        disabled={!checkRolesState(RoleType.Asset, 'ViewAssetReport')}
                                        disabledDate={disabledTime}/>
                                </Col>
                                {
                                    columnsNewAssetObjList.length !== 0
                                        ? <>
                                            <Col md={8} sm={24}>
                                                <p>Selected Column</p>
                                                {getFieldDecorator('ColumnAssets', {
                                                    initialValue: columnsNewAssetList,
                                                    rules: [{required: false, message: 'Please input your Activity!'}]
                                                })(<Select mode="tags"
                                                    style={{width: '100%'}}
                                                    placeholder="Show"
                                                    maxTagCount={3}
                                                    // defaultValue={columnsNewAssetList}
                                                    onChange={(e) => HandelChangeColumnsNewAssetList(e)}
                                                    allowClear={true}
                                                    loading={loadingAsset}
                                                >
                                                    {(columnsNewAssetObjList || []).map((it, index) => {
                                                        return <Option key={index}
                                                            value={it.dataIndex}>{it.title}</Option>
                                                    })}
                                                </Select>)}
                                            </Col>
                                            <Col md={2} sm={24}>
                                                <Button htmlType="button" style={{marginTop: 32}}
                                                    onClick={() => handleOnClickPreviewAsset()}
                                                    loading={loadingAsset}
                                                    type='primary'
                                                ><Icon type="eye"/>Preview</Button>
                                            </Col>
                                        </>
                                        : (<>
                                            <Col md={2} sm={24}>
                                                <Button htmlType="button" style={{marginTop: 32}}
                                                    onClick={() => handleOnClickPreviewAsset()}
                                                    loading={loadingAsset}
                                                    type='primary'
                                                ><Icon type="eye"/> Preview</Button>
                                            </Col></>
                                        )
                                }
                                <Col md={8} sm={24} style={{textAlign: 'end'}}>
                                    {columnsNewAssetObjList.length > 0
                                        ? <Button htmlType="button" style={{marginTop: 32}}
                                            onClick={() => exportNewAssetData()}
                                            disabled={!checkRolesState(RoleType.Asset, 'ViewAssetReport') || columnsNewAssetList.length === 0}
                                            loading={loadingExportAsset}
                                        ><Icon type="upload"/> Export</Button> : null}
                                </Col>
                                <Col span={24}>
                                    <Spin tip="Loading..." spinning={loadingAsset}>
                                        <ColumnNewAsset dataTransfer={tempNewAssetState}
                                            columnsNewAssetList={columnsNewAssetList}
                                            columnsNewAssetObjList={columnsNewAssetObjList}
                                            total={totalAsset} handleAssetChange={handleOnClickPreviewAsset}
                                            range={rangeAsset}
                                        />
                                    </Spin>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Card>
            </Spin>
        </div>
    )
}

const ReportAssetForm = Form.create<Props>({name: 'ReportAsset'})(ReportAsset)
export default ReportAssetForm
