import { axiosPatch, axiosPost, axiosGet } from '../rest'
import { Knowledge, KnowledgeList } from '../knowledge'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { ApproveServiceRequest, ApproveWorkOrder } from './model'
const pageSize = require('../knowledge/config.json').pageSize

export const getAllKnowledgeWithApprovalStatus = async (params) => {
    try {
        const body = {
            statuses: params.listStatus,
            page: params.currentPage,
            size: pageSize
        }
        const encryptData = encryptBody(JSON.stringify(body))

        const response = await axiosPost<KnowledgeList>('/api/knowledge/awaiting-approve', encryptData, herderPostPatch)
        if (response.data.data) {
            return decryptBody(response.data.data)
        } else {
            return []
        }
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const updateKnowledgeStatus = async (knowledgeId: number, isApproved: boolean, rejectReason?: string) => {
    try {
        const body = {
            approved: isApproved,
            rejectReason: rejectReason
        }
        const response = await axiosPatch<Knowledge>(`/api/knowledge/${knowledgeId}/approve`, body, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

// export const getAllWorkOrderWithApprovalStatus = async (approver: string, status: string, page: number, pageSize: number) => {
//     try {
//         const response = await axiosGet<ApproveWorkOrder>(`/api/approval-record/search?approver=${approver}&statuses=${status}&typeName=job&page=${page}&size=${pageSize}`)
//         return decryptBody(response.data.data!!)
//     } catch (err) {
//         checkRedirect(err.response.status)
//         throw err.response
//     }
// }

export const getApproveServiceRequest = async (approved: string, status: string) => {
    try {
        const config = {
            params: {
                approver: approved,
                statuses: status,
                type: 'request'
            }
        }
        const response = await axiosGet<ApproveServiceRequest>('/api/approval-record/search', config)
        return decryptBody(response.data.data!!)
    } catch (err) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const updateNewWorkOrderWithApprovalStatus = async (action: string, Id: number, Reason: string) => {
    try {
        const config = {
            params: {
                page: Reason || ''
            }
        }
        const response = await axiosPost<ApproveWorkOrder>(`/api/approval-record/${Id}/${action}`)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return null
        }
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const updateApproveServiceRequestStatus = async (status: string, id: number, reason?: string) => {
    try {
        // api/approvalRecord/approve?id=${id}&reason=${reason}   , reason?: string
        const config = {
            params: {
                reason: reason || ''
            }
        }
        const response = await axiosPost<ApproveServiceRequest>(`/api/approval-record/${id}/${status}`)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return null
        }
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}
