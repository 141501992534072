import React, { useEffect, useState } from 'react'
import { Button, Empty, Form, Input, Timeline, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'
import { WorklogResponse, WoWorklog } from '../Job/worklog'
import { StoreState } from '../../../store'
import { addWoWorkLog, getAllWorkLogByWoNo } from '../Job/worklog/service'
import { connect } from 'react-redux'
import { Job } from '../Job'
import { RoleType, checkRolesState } from '../../../authorization-module/permissions'

interface Param {
  woNo?: string;
  itemJob: Job[];
  key: string;
  permission: boolean;
  isEdit?: boolean;
  statusJob?: string
}

const mapStateToProps = (state: StoreState) => {
    return {
        workLog: state.jobWorkLog || []
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = Param & FormComponentProps & StateProps;

const { TextArea } = Input
const Worklog: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, resetFields } = props.form
    const [woWorklog, setWoWorkLog] = useState<WorklogResponse[]>()
    const [isDisableWorkLog, setIsDisableWorkLog] = useState<boolean>(false)

    useEffect(() => {
        fetchWorkLogData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.workLog, props.itemJob])

    useEffect(() => {
        resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.key])

    useEffect(() => {
        if (props.isEdit && !checkRolesState(RoleType.WO, 'UpdateWorkOrder')) {
            setIsDisableWorkLog(true)
        } else if (props.statusJob === 'Closed' || props.statusJob === 'Cancelled') {
            setIsDisableWorkLog(true)
        } else {
            setIsDisableWorkLog(false)
        }
    }, [props.statusJob])

    const validateWorklog = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(CheckValue)) {
                if (CheckValue.length >= 3) {
                    callback()
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
        if (format.test(CheckValue)) {
                    callback(
                        'Enter more than 1 letter and can not contain any of the following characters ' +
              format
                    )
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else {
            callback()
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err && values.worklog !== undefined) {
                const worklog: WoWorklog = {
                    woNo: props.woNo,
                    messageLog: values.worklog
                }
                addWoWorkLog(worklog)
                    .then((res) => {
                        setWoWorkLog(res)
                        setFieldsValue({
                            worklog: undefined
                        })
                        message.success('Add Worklog has finished successfully.')
                    })
                    .catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    })
            } else {
                message.warning('Please input Worklog')
            }
        })
    }

    const fetchWorkLogData = () => {
        if (props.woNo) {
            getAllWorkLogByWoNo(props.woNo)
                .then((data) => {
                    setWoWorkLog(data)
                })
                .catch((err) =>
                    message.error(`Failed loading initial work log data. ${err}`)
                )
        }
    }

    const displayWorkLog = () => {
        const workLogElements: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        if (woWorklog) {
            for (const [index, workLog] of woWorklog.entries()) {
                if (workLog.worklog) {
                    workLogElements.push(
                        <Timeline.Item color={index === woWorklog.length - 1 ? 'green' : 'blue'}>
                            <p style={{ fontSize: '16px', color: '#323276', fontWeight: 'bold'}}>
                                {moment(workLog.createDatetime).format('YYYY-MM-DD HH:mm:ss')}
                            </p>
                            <p>{` ${workLog.worklog}`}</p>
                        </Timeline.Item>
                    )
                }

                // else {
                //     if (workLog.previousStatus !== null) {
                //         workLogElements.push(<Timeline.Item color={'green'}>
                //             <p>{moment(workLog.createdDatetime).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
                //             <p>{`changed Status from ${workLog.previousStatus} to ${workLog.currentStatus}`}</p>
                //         </Timeline.Item>)
                //     } else {
                //         workLogElements.push(<Timeline.Item color={'green'}>
                //             <p>{moment(workLog.createdDatetime).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
                //             <p>{`changed Status to ${workLog.currentStatus}`}</p>
                //         </Timeline.Item>)
                //     }
                // }
            }
        }
        return (
            <>
                <div
                    style={{
                        height: 700,
                        width: '100%',
                        backgroundColor: '#ffffff',
                        overflowY: 'scroll',
                        padding: 10
                    }}
                >
                    {workLogElements.length !== 0 ? (
                        <Timeline style={{ marginLeft: '40px', marginTop: '10px' }}>
                            {workLogElements.map((temps: any, i: number) => {
                                return <div key={i}>{temps}</div>
                            })}
                        </Timeline>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </>
        )
    }

    return (
        <div>
            <Form layout="vertical">
                <Form.Item label="WorkLog" className='custom-formLabel-wo'>
                    {getFieldDecorator('worklog', {
                        rules: [
                            {
                                required: false
                            },
                            { validator: validateWorklog }
                        ]
                    })(<TextArea rows={4} placeholder="WorkLog" maxLength={500} />)}
                </Form.Item>
                <Form.Item style={{ float: 'right', marginTop: 10 }}>
                    {/* <Button htmlType="button" style={{marginRight: 10}}>
                        Cancel
                    </Button> */}
                    <Button
                        onClick={handleSubmit}
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 10 }}
                        disabled={isDisableWorkLog || !props.isEdit}
                    >
            Add
                    </Button>
                </Form.Item>
            </Form>
            <br />
            <div>{displayWorkLog()}</div>
        </div>
    )
}

const WorklogForm = Form.create<Props>({ name: 'Worklog' })(Worklog)

export default connect(mapStateToProps, {})(WorklogForm)
