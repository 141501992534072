import React, {useEffect, useState} from 'react'
import {Card, Col, Empty, Progress, Row, Tooltip} from 'antd'
import DoughnutChart from './Chart/DoughnutChart'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {StoreState} from '../store'
import {Color, DataForChart} from './model'
import {ConvertDataForChart} from './service'
import {nullOrEmptyObject} from '../common-misc'
import {PriorityLevel} from '../incident-management/priority/model'
import { waitingGetSessions } from '../guard/route'

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardOpenTicketTotalGroup: state.DashboardOpenTicketTotalGroup,
        DashboardOpenbyStatusTicketTotalGroup: state.DashboardOpenbyStatusTicketTotalGroup,
        DashboardOpenbyPriorityTicketTotalGroup: state.DashboardOpenbyPriorityTicketTotalGroup,
        DashboardOpenSLATotalGroup: state.DashboardOpenSLATotalGroup,
        DashboardOpenTicketMyGroup: state.DashboardOpenTicketMyGroup,
        DashboardOpenbyStatusTicketMyGroup: state.DashboardOpenbyStatusTicketMyGroup,
        DashboardOpenbyPriorityTicketMyGroup: state.DashboardOpenbyPriorityTicketMyGroup,
        DashboardOpenSLAMyGroup: state.DashboardOpenSLAMyGroup,
        priority: state.priorities
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    Group: string
    created: string | string[]
}

type Props = Param & StateProps

const IncidentLayoutChart: React.FC<Props> = (props: Props) => {
    // total group
    const [dataDashboardOpenTicketTotalGroup, setDataDashboardOpenTicketTotalGroup] = useState<DataForChart>()
    const [dataDashboardOpenbyStatusTicketTotalGroup, setDataDashboardOpenbyStatusTicketTotalGroup] = useState<DataForChart>()
    const [dataDashboardOpenbyPriorityTicketTotalGroup, setDataDashboardOpenbyPriorityTicketTotalGroup] = useState<DataForChart>()
    const [dataDashboardOpenSLATotalGroup, setDataDashboardOpenSLATotalGroup] = useState<DataForChart>()
    // my Group
    const [dataDashboardOpenTicketMyGroup, setDataDashboardOpenTicketMyGroup] = useState<DataForChart>()
    const [dataDashboardOpenbyStatusTicketMyGroup, setDashboardOpenbyStatusTicketMyGroup] = useState<DataForChart>()
    const [dataDashboardOpenbyPriorityTicketMyGroup, setDataDashboardOpenbyPriorityTicketMyGroup] = useState<DataForChart>()
    const [dataDashboardOpenSLAMyGroup, setDataDashboardOpenSLAMyGroup] = useState<DataForChart>()

    useEffect(() => {
        if (props.Group === 'Total Group') {
            setDataDashboardOpenTicketTotalGroup(ConvertDataForChart(props.DashboardOpenTicketTotalGroup.data))
            setDataDashboardOpenbyStatusTicketTotalGroup(ConvertDataForChart(props.DashboardOpenbyStatusTicketTotalGroup.data))
            setDataDashboardOpenbyPriorityTicketTotalGroup(ConvertDataForChart(props.DashboardOpenbyPriorityTicketTotalGroup.data))
            setDataDashboardOpenSLATotalGroup(ConvertDataForChart(props.DashboardOpenSLATotalGroup.data))
        } else {
            setDataDashboardOpenTicketMyGroup(ConvertDataForChart(props.DashboardOpenTicketMyGroup.data))
            setDashboardOpenbyStatusTicketMyGroup(ConvertDataForChart(props.DashboardOpenbyStatusTicketMyGroup.data))
            setDataDashboardOpenbyPriorityTicketMyGroup(ConvertDataForChart(props.DashboardOpenbyPriorityTicketMyGroup.data))
            setDataDashboardOpenSLAMyGroup(ConvertDataForChart(props.DashboardOpenSLAMyGroup.data))
        }
    }, [props])

    const renderProgessAntd = (data: DataForChart | undefined, group: string, fixStyle: boolean) => {
        if (!nullOrEmptyObject(data?.labels)) {
            let colorCode: string = ''
            const renderColor = ['#EF701F', '#EFD658', '#1CBB9F', '#0890C2']
            return <>{
                data?.labels?.length !== 0
                    ? data?.labels.map((it, index) => {
                        props.priority.forEach((priority: PriorityLevel) => {
                            if (it === priority.name) {
                                colorCode = priority.tagColor
                            }
                        })
                        return (<div key={index} style={{marginBottom: fixStyle ? 10 : 0}}>
                            <Tooltip title={`${it} ${data.datasets[0].data[index]} Tickets`}>
                                <div>{it}</div>
                                <Progress
                                    percent={group === 'Total Group' ? Number(((data.datasets[0].data[index] / props.DashboardOpenbyPriorityTicketTotalGroup.total) * 100).toFixed()) : Number(((data.datasets[0].data[index] / props.DashboardOpenbyPriorityTicketMyGroup.total) * 100).toFixed())}
                                    style={{ width: '95%' }}
                                    format={() => data.datasets[0].data[index] + ' Tickets'}
                                    strokeColor={`${colorCode || renderColor[index]}`}
                                    strokeWidth={fixStyle ? 20 : 10}
                                />
                            </Tooltip>
                        </div>)
                    }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }</>
        }
    }

    return (
        <div>
            <Row className="TopicDashboard">
                <Col span={24}>
                    <span className="main-title">{props.Group}</span>
                </Col>
            </Row>
            <br />
            <Row style={{ height: 'auto' }} gutter={8}>
                <Col span={8} xs={24} sm={24} md={8} lg={8} style={{marginBottom: '2%'}}>
                    <Link to={`/ReportDashBoardNoneSLA?group=${props.Group}&created=${props.created}`} target="_blank" onClick={() => waitingGetSessions()}>
                        <Card className="Box-DashBoard" >
                            <span className="sub-title">Open Ticket ({props.Group === 'Total Group' ? props.DashboardOpenTicketTotalGroup.total : props.DashboardOpenbyStatusTicketMyGroup.total} Tickets)</span>
                            <DoughnutChart showLabels={false} Height={250} marginTop={'10%'} minHeight={250} myData={props.Group === 'Total Group' ? dataDashboardOpenTicketTotalGroup : dataDashboardOpenTicketMyGroup} />
                            <Row style={{ minHeight: 90, marginTop: '5%' }} gutter={8}>
                                {props.Group === 'Total Group' ? dataDashboardOpenTicketTotalGroup?.labels.map((it, index) => {
                                    return (
                                        <Col span={12} key={index}>
                                            {index < 10 ? <>
                                                <span style={{ backgroundColor: Color[index], marginRight: '5%', maxHeight: 5 }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <Tooltip title={it}>
                                                    {it.length > 20 ? it.substr(0, 20) + '...' : it}
                                                </Tooltip>

                                            </> : null}
                                        </Col>
                                    )
                                }) : dataDashboardOpenTicketMyGroup?.labels.map((it2, index) => {
                                    return (
                                        <Col span={12} key={index}>
                                            {index < 10 ? <>
                                                <span style={{ backgroundColor: Color[index], marginRight: '5%', maxHeight: 5 }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <Tooltip title={it2}>
                                                    {it2.length > 20 ? it2.substr(0, 20) + '...' : it2}
                                                </Tooltip>
                                            </> : null}
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Card>
                    </Link>
                </Col>
                <Col span={16} xs={24} sm={24} md={16} lg={16}>
                    <Row style={{ marginBottom: '2%'}}>
                        <Link to={`/ReportDashBoardNoneSLA?group=${props.Group}&created=${props.created}`} target="_blank" onClick={() => waitingGetSessions()}>
                            <Card className="Box-DashBoard" style={{minHeight: 300}}>
                                <p className="sub-title">Open by Status ({props.Group === 'Total Group' ? props.DashboardOpenbyStatusTicketTotalGroup.total : props.DashboardOpenbyStatusTicketMyGroup.total} Tickets)</p>
                                {props.Group === 'Total Group' ? (props.DashboardOpenbyStatusTicketTotalGroup.total === 0 || props.DashboardOpenbyStatusTicketTotalGroup.total === undefined) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : renderProgessAntd(dataDashboardOpenbyStatusTicketTotalGroup, 'Total Group', true) : (props.DashboardOpenbyStatusTicketMyGroup.total === 0 || props.DashboardOpenbyStatusTicketTotalGroup.total === undefined) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : renderProgessAntd(dataDashboardOpenbyStatusTicketMyGroup, 'Total Group', true)}
                            </Card>
                        </Link>
                    </Row>
                    <Row gutter={8} style={{ marginTop: '2%' }}>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} style={{ marginBottom: '2%'}}>
                            <Link to={`/ReportDashBoardNoneSLA?group=${props.Group}&created=${props.created}`} target="_blank" onClick={() => waitingGetSessions()}>
                                <Card className="Box-DashBoard" style={{ minHeight: 230 }}>
                                    <p className="sub-title">Open by Priority ({props.Group === 'Total Group' ? props.DashboardOpenbyPriorityTicketTotalGroup.total : props.DashboardOpenbyPriorityTicketMyGroup.total} Tickets)</p>
                                    {props.Group === 'Total Group' ? (props.DashboardOpenbyPriorityTicketTotalGroup.total === 0 || props.DashboardOpenbyStatusTicketTotalGroup.total === undefined) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : renderProgessAntd(dataDashboardOpenbyPriorityTicketTotalGroup, 'Total Group', false) : (props.DashboardOpenbyPriorityTicketMyGroup.total === 0 || props.DashboardOpenbyStatusTicketTotalGroup.total === undefined) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : renderProgessAntd(dataDashboardOpenbyPriorityTicketMyGroup, 'My Group', false)}
                                </Card>
                            </Link>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} style={{ marginBottom: '2%'}}>
                            <Link to={`/ReportDashBoard?group=${props.Group}&created=${props.created}`} target="_blank" onClick={() => waitingGetSessions()}>
                                <Card className="Box-DashBoard" >
                                    <span className="sub-title">SLA ({props.Group === 'Total Group' ? props.DashboardOpenSLATotalGroup.total : props.DashboardOpenSLAMyGroup.total} SLA)</span>
                                    {props.Group === 'Total Group' ? props.DashboardOpenSLATotalGroup.total !== 0 ? <DoughnutChart showLabels={(window.innerWidth >= 768)} Height={105} marginTop={'2%'} myData={props.Group === 'Total Group' ? dataDashboardOpenSLATotalGroup : dataDashboardOpenSLAMyGroup} fixPosition='right' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : props.DashboardOpenSLAMyGroup.total !== 0 ? <DoughnutChart showLabels={(window.innerWidth >= 768)} Height={105} marginTop={'2%'} myData={props.Group === 'Total Group' ? dataDashboardOpenSLATotalGroup : dataDashboardOpenSLAMyGroup} fixPosition='right' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                    <br />
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
        </div>
    )
}

export default connect(mapStateToProps, {

})(IncidentLayoutChart)
