import { cloneObject } from '../common-misc'

export interface BaseDocument {
    id?: string
    createdBy?: string
    createdByDisplay?: string
    lastModifiedBy?: string
    lastModifiedByDisplay?: string
    createdDate?: string
    lastModifiedDate?: string
    createdDatetime?: string
    lastModifiedDatetime?: string
}

export interface BaseEntity {
    id?: number
    createdBy?: string
    createdByDisplay?: string
    lastModifiedBy?: string
    lastModifiedByDisplay?: string
    createdDate?: string
    lastModifiedDate?: string
    lastModifiedDatetime?: string
    createdDatetime?: string
    createdById?: number
    lastModifiedById?: number
}

export interface BaseEntityHistory {
    id?: number
    createdBy?: string
    createdByDisplay?: string
    lastModifiedBy?: string
    lastModifiedByDisplay?: string
    createdDate?: string
    lastModifiedDate?: string
    originId?: string
    versionTimestamp?: any
    validThrough?: any
}

export interface History<T> extends BaseDocument {
    originId: string
    // origin: T
    versionTimestamp: any
    validThrough: any
}

export interface FileUpload {
    name?: string
    url?: string
    type?: string
}

export interface WithVersion<T> {
    version?: T
}

export const BaseDocumentFunc = {
    deleteFields: (baseDocument: BaseEntity, excludeId: boolean = false) => {
        if (!excludeId) { delete baseDocument.id }
        delete baseDocument.createdBy
        delete baseDocument.lastModifiedBy
        delete baseDocument.createdDate
        delete baseDocument.lastModifiedDate
    },
    cloneAndDeleteFields: (baseDocument: BaseEntity, excludeId: boolean = false) => {
        const clone = cloneObject(baseDocument)
        BaseDocumentFunc.deleteFields(clone)
        return clone
    }
}