import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { LocationBuilding } from '../Model'

export const LocationBuildingContext = createContext({})

const initialState: LocationBuilding[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const LocationBuildingReducer = (state: LocationBuilding[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_LocationBuilding':
        sessionStorage.setItem('locationBuilding', JSON.stringify(action.payload))
        return action.payload || [] as LocationBuilding[]
    case 'ADD_LocationBuilding':
        return [...state, action.payload]
    default:
        return state
    }
}

export const LocationBuildingProvider = (props: props) => {
    const [LocationBuildingState, LocationBuildingDispatch] = useReducer(LocationBuildingReducer, initialState)

    const GetLocationBuilding = (payload: LocationBuilding[]) => {
        LocationBuildingDispatch({ type: 'GET_LocationBuilding', payload })
    }

    const AddLocationBuilding = (payload: LocationBuilding) => {
        LocationBuildingDispatch({ type: 'ADD_LocationBuilding', payload })
    }

    return (
        <LocationBuildingContext.Provider value={{ LocationBuildingState, GetLocationBuilding, AddLocationBuilding }}>
            {props.children}
        </LocationBuildingContext.Provider>
    )
}
