import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { PreventiveWithAsset } from '../Model'

export const CompletedPMContext = createContext({})

const initialState: PreventiveWithAsset[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const CompletedPMReducer = (state: PreventiveWithAsset[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_Completed_PM':
        return action.payload || [] as PreventiveWithAsset[]
    case 'ADD_Preventive':
        return [...state, action.payload]
    default:
        return state
    }
}

export const CompletedPMProvider = (props: props) => {
    const [CompletedPMState, CompletedPMDispatch] = useReducer(CompletedPMReducer, initialState)

    const GetCompletedPreventive = (payload: PreventiveWithAsset[]) => {
        CompletedPMDispatch({ type: 'GET_Completed_PM', payload })
    }

    const AddPreventive = (payload: PreventiveWithAsset) => {
        CompletedPMDispatch({ type: 'ADD_Preventive', payload })
    }

    return (
        <CompletedPMContext.Provider value={{ CompletedPMState, GetCompletedPreventive, AddPreventive }}>
            {props.children}
        </CompletedPMContext.Provider>
    )
}
