import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { pmTemplateCustomfieldSelect } from '../Model'

export const PmTemplateStateContext = createContext({})

const initialState: pmTemplateCustomfieldSelect[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const PmTemplateStateReducer = (state: pmTemplateCustomfieldSelect[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_PmTemplateState':
        sessionStorage.setItem('PmTemplate', JSON.stringify(action.payload))
        return action.payload || [] as pmTemplateCustomfieldSelect[]
    case 'ADD_PmTemplateState':
        return [...state, action.payload]
    default:
        return state
    }
}

export const PmTemplateStateProvider = (props: props) => {
    const [PmTemplateState, PmTemplateStateDispatch] = useReducer(PmTemplateStateReducer, initialState)

    const GetPmTemplateState = (payload: pmTemplateCustomfieldSelect[]) => {
        PmTemplateStateDispatch({ type: 'GET_PmTemplateState', payload })
    }

    const AddPmTemplateState = (payload: pmTemplateCustomfieldSelect) => {
        PmTemplateStateDispatch({ type: 'ADD_PmTemplateState', payload })
    }

    return (
        <PmTemplateStateContext.Provider value={{ PmTemplateState, GetPmTemplateState, AddPmTemplateState }}>
            {props.children}
        </PmTemplateStateContext.Provider>
    )
}
