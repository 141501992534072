import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { People } from '../../authorization-module/people/model'

export const PeopleContext = createContext({})

const initialState: People[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const PeopleReducer = (state: People[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_People':
        return action.payload || [] as People[]
    case 'ADD_People':
        return [...state, action.payload]
    default:
        return state
    }
}

export const PeopleProvider = (props: props) => {
    const [PeopleState, PeopleDispatch] = useReducer(PeopleReducer, initialState)

    const GetPeople = (payload: People[]) => {
        PeopleDispatch({ type: 'GET_People', payload })
    }

    const AddPeople = (payload: People) => {
        PeopleDispatch({ type: 'ADD_People', payload })
    }

    return (
        <PeopleContext.Provider value={{ PeopleState, GetPeople, AddPeople }}>
            {props.children}
        </PeopleContext.Provider>
    )
}
