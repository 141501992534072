/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import { Table, Icon } from 'antd'
import { ChargingGroup } from '../../Relate/Charging/Model'
import { expenses } from '../../Relate/Job'

interface Param {
    data?: ChargingGroup
    isExpense?: boolean
    deletedata?: Function
    Data?: expenses[]
    currentStep?: number
}

type Props = Param

const TableCharging: React.FC<Props> = (props: Props) => {
    const columns = [
        {
            title: <b>No</b>,
            dataIndex: '',
            width: '10%',
            render: (text, record, index) => (
                <>
                    {index + 1}
                </>
            )
        },
        {
            title: <b>Description</b>,
            dataIndex: 'chargeDescription',
            width: '80%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Cost</b>,
            dataIndex: 'chargeCost',
            width: '10%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        }
    ]

    const columnsExpense = [
        {
            title: '',
            dataIndex: '',
            width: '10%',
            render: (text, record, index) => (
                <div>
                    {props.currentStep !== 2 ? <Icon type="delete" onClick={() => deleteDataCost(record)}/> : null}
                </div>
            )
        },
        {
            title: <span className='bold-titleWo'>Expense</span>,
            dataIndex: 'expenseDetail',
            width: '70%',
            render: (data: string) => (
                <>
                    <div style={{ marginTop: 7 }}>
                        <p style={{ maxWidth: 350 }}>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <span className='bold-titleWo'>Cost</span>,
            dataIndex: 'cost',
            width: '20%',
            render: (data: any) => (
                <>
                    <div style={{marginTop:7}}>
                        <p>{data.toFixed(2)}  THB</p>
                    </div>
                </>
            )
        }
    ]

    const deleteDataCost = (record) => {
        let totalcost = 0
        const filterDataDelete = props.Data!!.filter((data) => data.expenseDetail !== record.expenseDetail)
        if (props.currentStep !== 2) {
            if (props.deletedata) {
                filterDataDelete?.map((it) => {
                    if (it.cost) {
                        totalcost += it.cost
                    }
                })
                props.deletedata(filterDataDelete, totalcost)
            }
        }
    }

    return (
        <>
            <Table
                dataSource={props.Data}
                columns={props.isExpense ? columnsExpense : columns}
                pagination={false}
                rowKey='expenseDetail'
                style={{backgroundColor: '#FFFFFF'}}
            />
        </>
    )
}

export default TableCharging
