import {applyMiddleware, combineReducers, createStore} from 'redux'
import {KnowledgeList, knowledgeStateReducer} from '../knowledge-management/knowledge'
import {
    Category as KnowledgeCategory,
    CategoryStateReducer as knowledgeCategoryStateReducer
} from '../knowledge-management/category'

import {Comment, commentStateReducer} from '../incident-management/comment'

import {
    IncidentState,
    incidentStateReducers,
    Satisfaction,
    satisfactionStateReducer,
    TicketStatus
} from '../incident-management/incident'
import {IncidentViewState} from '../incident-management/incident-view/state-model'
import {impactStateReducer} from '../incident-management/impact'
import {urgencyStateReducer} from '../incident-management/urgency'
import {
    priorityConfigurationStateReducer,
    PriorityLevel,
    PriorityMatrix,
    priorityStateReducer
} from '../incident-management/priority'
import {
    Categories,
    SubCategory,
    Item,
    CategoryManagementPagination,
    categoryManagementPaginationReducer,
    categoryStateReducer,
    subCategoryStateReducer,
    itemsStateReducer
} from '../incident-management/category'

import {worklogStateReducer} from '../incident-management/worklog/state-reducer'
import {incidentViewStateReducer} from '../incident-management/incident-view'

import {Worklog} from '../incident-management/worklog'
import {statusStateReducer} from '../incident-management/status'
import {supportTeamStateReducer} from '../incident-management/support-team'
import {SupportTeam} from '../incident-management/incident/model'
import {
    BusinessHour,
    businessHourPaginationStateReducer,
    BusinessHoursPagination,
    businessHourStateReducer
} from '../sla-management/business-hour'
import {slaPoliciesPaginationStateReducer, slaPolicyStateReducer} from '../sla-management/sla-policy/state-reducer'
import {SlaGlobalSetting, slaGlobalSettingStateReducer} from '../sla-management/sla-global-setting'
import {SlaPoliciesPagination, SlaPolicy} from '../sla-management/sla-policy/model'
import thunk from 'redux-thunk'
import {ResponseUserRole, Roles, UserRolesPagination} from '../authorization-module/user-role/duck/model'
import {
    rolesReducer,
    userRolesPaginationStateReducer,
    userRolesReducer
} from '../authorization-module/user-role/duck/reducers'
import {composeWithDevTools} from 'redux-devtools-extension'
import {rolesStateReducer, RoleState} from '../authorization-module/permissions'
import {TablePagination, UserRolesAndPermissions} from './model'
import {tablePaginationStateReducer, userRolesAndPermissionsReducer} from './state-reducer'
import {
    SupportTeamManagement,
    SupportTeamManagementPagination
} from '../authorization-module/support-team-management/model'
import {
    supportTeamManagementStateReducer,
    supportTeamPaginationStateReducer
} from '../authorization-module/support-team-management/state-reducer'
import {People, PeopleManagementPagination} from '../authorization-module/people/model'
import {
    PeopleManagementStateReducer,
    PeoplePaginationStateReducer
} from '../incident-management/requester/state-reducer'
import {
    RoleAndPermissions,
    RoleAndPermissionsOptionsStateReducer,
    RoleAndPermissionsPaging,
    RoleAndPermissionsPagingStateReducer,
    RoleAndPermissionsStateReducer,
    RoleKMStateReducer
} from '../authorization-module/role-and-permissions'
import {
    GeneralIncidentSetting,
    GeneralIncidentSettingStateReducer
} from '../incident-management/general-incident-setting'
import {
    CustomFiledAsset,
    CustomFiledAssetStateReducer,
    CustomFileds,
    CustomFiledsStateReducer,
    CustomFiledWithWorkFlow,
    PMActivityField,
    PMActivityFieldStateReducer
} from '../configurable-fields/'
import {
    AllKmDashboardReducer,
    Dashboard,
    DashboardIncidentBacklogMyticketReducer,
    DashboardOpenbyPriorityTicketMyGroupReducer,
    DashboardOpenbyPriorityTicketMyticketReducer,
    DashboardOpenbyPriorityTicketTotalGroupReducer,
    DashboardOpenbyStatusTicketMyGroupReducer,
    DashboardOpenbyStatusTicketMyticketReducer,
    DashboardOpenbyStatusTicketTotalGroupReducer,
    DashboardOpenSLAMyGroupReducer,
    DashboardOpenSLAMyticketReducer,
    DashboardOpenSLATotalGroupReducer,
    DashboardOpenTicketMyGroupReducer,
    DashboardOpenTicketTotalGroupReducer,
    DashboardRemainderReducer,
    DashboardRespondedReducer,
    DataTotal,
    EmailSatisfactionReducer,
    KnowledgeDashboard,
    SatisfactionExportReducer,
    SatisfactionReportReducer
} from '../msp-portal'
import {Job, jobStateReducer} from '../msp-wo/Relate/Job'
import {Asset, AssetStateReducer} from '../msp-wo/Relate/Asset'
import {JobWorkLog} from '../msp-wo/Relate/Job/worklog'
import {jobWorklogStateReducer} from '../msp-wo/Relate/Job/worklog/state-reducer'
import {
    TotalWorkOrderContent,
    totalWorkOrderContentStateReducer,
    WorkOrder,
    WorkOrderList,
    workOrderListStateReducer, workOrderPaginationStateReducer,
    workOrderStateReducer, WorkOrderWithPagination
} from '../msp-wo/wo-management'
import {
    assetFilterStateReducer,
    assetOwnerLocationListStateReducer,
    AssetOwnerLocationTotalData,
    FilterAsset
} from '../msp-wo/asset-management'
import {ChargingGroup} from '../msp-wo/Relate/Charging/Model'
import {chargingStateReducer} from '../msp-wo/Relate/Charging/state-reducer'
import {NotifyStateReducer} from '../authorization-module/people/state-reducer'
import { SelfServiceCatalog, SelfServiceCatagory, SelfServiceItem, ResponseContentDetails, selfServiceCatalogListStateReducer, selfServiceCatagoryListStateReducer, selfServiceItemListStateReducer, WorkFlowPagination} from '../serviceCatalog/Catalog'
import {Order} from '../TenantSetting/Services/model'
import {OrderStateReducer} from '../TenantSetting/ManageState/state-reducer'
import { CustomFiledWithWorkFlowStateReducer } from '../configurable-fields/state-reducer-custom-workflow'
import {workFlowListStateReducer, WorkFlowPaginationStateReducer} from '../serviceCatalog/workflow/Service/state-reducer'
import { WorkFlow } from '../serviceCatalog/workflow/Service/Model'
import { supportTeamWithPermissionStateReducer } from '../incident-management/support-team/state-reducer-with-permission'
import { NotifyWebSocket } from '../common-notify/web-socket-model-notify'
import { NotifyWebSocketStateReducer } from '../common-notify/state-reducer'
import { supportTeamWithDeleteGroupReducer } from '../incident-management/support-team/state-reducer-with-delete-group'
import { impactPriorityStateReducer } from '../problem-management/impact'
import { urgencyPriortyStateReducer } from '../problem-management/urgency'
import { PriorityLevelProblem, PriorityMatrixProblem, priorityProblemConfigurationStateReducer, priorityProblemStateReducer } from '../problem-management/priority'
import { generalProblem } from '../problem-management/general/states/model'
import { GeneralProblemStateReducer } from '../problem-management/general/states/state-reducer'
import { CustomFiledProblem } from '../configurable-fields/problems/state/model'
import { CustomFiledProblemStateReducer } from '../configurable-fields/problems/state/state-reducer'

export interface StoreState {
    businessHours: BusinessHour[]
    incidentState: IncidentState
    slaPolicies: SlaPolicy[]
    categories: Categories[]
    subCategories: SubCategory[]
    items: Item[]
    categoryManagementPagination: CategoryManagementPagination
    supportTeams: SupportTeam[]
    supportTeamsWithPermission: SupportTeam[]
    impacts: PriorityLevel[]
    urgencies: PriorityLevel[]
    statuses: TicketStatus[]
    workLog: Worklog[]
    slaGlobalSettings: SlaGlobalSetting
    knowledgeCategories: KnowledgeCategory[]
    knowledgesList: KnowledgeList
    incidentViewState: IncidentViewState
    comments: Comment[]
    priorities: PriorityLevel[]
    tablePagination: TablePagination
    userRolesAndPermissions: UserRolesAndPermissions
    userRoles: ResponseUserRole[]
    userRolesPagination: UserRolesPagination
    roles: Roles[]
    currentUserRole: RoleState
    supportTeamManagement: SupportTeamManagement[]
    supportTeamManagementPagination: SupportTeamManagementPagination
    people: People[]
    peopleManagementPagination: PeopleManagementPagination
    priorityMatrix: PriorityMatrix[]
    roleAndPermissions: RoleAndPermissions[]
    roleAndPermissionsOptions: RoleAndPermissions
    generalIncidentSetting: GeneralIncidentSetting
    roleAndPermissionsPaging: RoleAndPermissionsPaging
    customFields: CustomFileds
    customFiledAsset: CustomFiledAsset[]
    customFiledWithWorkFlow: CustomFiledWithWorkFlow[]
    PMActivityField: PMActivityField[]
    DashboardOpenTicketTotalGroup: Dashboard
    DashboardOpenbyStatusTicketTotalGroup: Dashboard
    DashboardOpenbyPriorityTicketTotalGroup: Dashboard
    DashboardOpenSLATotalGroup: Dashboard
    DashboardOpenTicketMyGroup: Dashboard
    DashboardOpenbyStatusTicketMyGroup: Dashboard
    DashboardOpenbyPriorityTicketMyGroup: Dashboard
    DashboardOpenSLAMyGroup: Dashboard
    DashboardOpenByStatusTicketMyTicket: Dashboard
    DashboardOpenByPriorityTicketMyTicket: Dashboard
    DashboardOpenSLAMyTicket: Dashboard
    DashboardIncidentBacklogMyTicket: Dashboard
    DashboardResponded: Dashboard
    DashboardRemainder: Dashboard
    job: Job[]
    asset: Asset[],
    filterAsset: FilterAsset,
    workOrder: WorkOrder[]
    workOrderList: WorkOrderList[]
    assetOwnerLocationList: AssetOwnerLocationTotalData
    totalWorkOrderContent: TotalWorkOrderContent
    jobWorkLog: JobWorkLog[],
    chargingGroup: ChargingGroup[],
    kmMapRole: string[],
    satisfactionState: Satisfaction[],
    EmailSatNotDoneState: DataTotal,
    satisfactionReport: Dashboard,
    SatisfactionExport: Dashboard,
    notify: number,
    businessHourPagination: BusinessHoursPagination,
    slaPoliciesPagination: SlaPoliciesPagination,
    workOrdersPagination: WorkOrderWithPagination,
    selfServiceCatalogs: ResponseContentDetails<SelfServiceCatalog[]>,
    selfServiceCatagories: ResponseContentDetails<SelfServiceCatagory[]>,
    selfServiceItems: ResponseContentDetails<SelfServiceItem[]>,
    orders: Order[],
    workFlow: WorkFlow[],
    workFlowPagination: WorkFlowPagination
    AllKmDashboard: KnowledgeDashboard,
    NotifyWebSocket: NotifyWebSocket[],
    supportTeamsWithDeleteGroup: SupportTeam[],
    impactProblem: PriorityLevelProblem[],
    urgencieProblem: PriorityLevelProblem[],
    priorityProblem: PriorityLevelProblem[],
    priorityMatrixProblems: PriorityMatrixProblem[],
    generalProblems: generalProblem,
    customFieldProblems: CustomFiledProblem[]
}

export const reducers = combineReducers<StoreState>({
    businessHours: businessHourStateReducer,
    incidentState: incidentStateReducers,
    slaPolicies: slaPolicyStateReducer,
    categories: categoryStateReducer,
    subCategories: subCategoryStateReducer,
    items: itemsStateReducer,
    categoryManagementPagination: categoryManagementPaginationReducer,
    supportTeams: supportTeamStateReducer,
    supportTeamsWithPermission: supportTeamWithPermissionStateReducer,
    impacts: impactStateReducer,
    urgencies: urgencyStateReducer,
    statuses: statusStateReducer,
    workLog: worklogStateReducer,
    slaGlobalSettings: slaGlobalSettingStateReducer,
    knowledgeCategories: knowledgeCategoryStateReducer,
    knowledgesList: knowledgeStateReducer,
    incidentViewState: incidentViewStateReducer,
    comments: commentStateReducer,
    priorities: priorityStateReducer,
    tablePagination: tablePaginationStateReducer,
    userRolesAndPermissions: userRolesAndPermissionsReducer,
    userRoles: userRolesReducer,
    userRolesPagination: userRolesPaginationStateReducer,
    roles: rolesReducer,
    currentUserRole: rolesStateReducer,
    supportTeamManagement: supportTeamManagementStateReducer,
    supportTeamManagementPagination: supportTeamPaginationStateReducer,
    people: PeopleManagementStateReducer,
    peopleManagementPagination: PeoplePaginationStateReducer,
    priorityMatrix: priorityConfigurationStateReducer,
    roleAndPermissions: RoleAndPermissionsStateReducer,
    roleAndPermissionsOptions: RoleAndPermissionsOptionsStateReducer,
    roleAndPermissionsPaging: RoleAndPermissionsPagingStateReducer,
    generalIncidentSetting: GeneralIncidentSettingStateReducer,
    customFields: CustomFiledsStateReducer,
    customFiledAsset: CustomFiledAssetStateReducer,
    customFiledWithWorkFlow: CustomFiledWithWorkFlowStateReducer,
    PMActivityField: PMActivityFieldStateReducer,
    DashboardOpenTicketTotalGroup: DashboardOpenTicketTotalGroupReducer,
    DashboardOpenbyStatusTicketTotalGroup: DashboardOpenbyStatusTicketTotalGroupReducer,
    DashboardOpenbyPriorityTicketTotalGroup: DashboardOpenbyPriorityTicketTotalGroupReducer,
    DashboardOpenSLATotalGroup: DashboardOpenSLATotalGroupReducer,
    DashboardOpenTicketMyGroup: DashboardOpenTicketMyGroupReducer,
    DashboardOpenbyStatusTicketMyGroup: DashboardOpenbyStatusTicketMyGroupReducer,
    DashboardOpenbyPriorityTicketMyGroup: DashboardOpenbyPriorityTicketMyGroupReducer,
    DashboardOpenSLAMyGroup: DashboardOpenSLAMyGroupReducer,
    DashboardOpenByStatusTicketMyTicket: DashboardOpenbyStatusTicketMyticketReducer,
    DashboardOpenByPriorityTicketMyTicket: DashboardOpenbyPriorityTicketMyticketReducer,
    DashboardOpenSLAMyTicket: DashboardOpenSLAMyticketReducer,
    DashboardIncidentBacklogMyTicket: DashboardIncidentBacklogMyticketReducer,
    DashboardResponded: DashboardRespondedReducer,
    DashboardRemainder: DashboardRemainderReducer,
    job: jobStateReducer,
    asset: AssetStateReducer,
    filterAsset: assetFilterStateReducer,
    workOrder: workOrderStateReducer,
    workOrderList: workOrderListStateReducer,
    assetOwnerLocationList: assetOwnerLocationListStateReducer,
    totalWorkOrderContent: totalWorkOrderContentStateReducer,
    jobWorkLog: jobWorklogStateReducer,
    chargingGroup: chargingStateReducer,
    kmMapRole: RoleKMStateReducer,
    satisfactionState: satisfactionStateReducer,
    EmailSatNotDoneState: EmailSatisfactionReducer,
    satisfactionReport: SatisfactionReportReducer,
    SatisfactionExport: SatisfactionExportReducer,
    notify: NotifyStateReducer,
    businessHourPagination: businessHourPaginationStateReducer,
    slaPoliciesPagination: slaPoliciesPaginationStateReducer,
    workOrdersPagination: workOrderPaginationStateReducer,
    selfServiceCatalogs: selfServiceCatalogListStateReducer,
    selfServiceCatagories: selfServiceCatagoryListStateReducer,
    selfServiceItems: selfServiceItemListStateReducer,
    orders: OrderStateReducer,
    workFlow: workFlowListStateReducer,
    workFlowPagination: WorkFlowPaginationStateReducer,
    AllKmDashboard: AllKmDashboardReducer,
    NotifyWebSocket: NotifyWebSocketStateReducer,
    supportTeamsWithDeleteGroup: supportTeamWithDeleteGroupReducer,
    impactProblem: impactPriorityStateReducer,
    urgencieProblem: urgencyPriortyStateReducer,
    priorityProblem: priorityProblemStateReducer,
    priorityMatrixProblems: priorityProblemConfigurationStateReducer,
    generalProblems: GeneralProblemStateReducer,
    customFieldProblems: CustomFiledProblemStateReducer
})

export default createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))
