import { KnowledgeStateEvent, GetAllKnowledgeSuccessEvent, DeleteKnowledgeSuccessEvent } from './state-event'
import { KnowledgeList } from './model'

export const knowledgeStateReducer = (state: KnowledgeList = {}, event: KnowledgeStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllKnowledgeSuccessEvent.type) {
        return event.payload
    } else if (event.type === DeleteKnowledgeSuccessEvent.type) {
        state.content = state.content?.filter((it) => it.ticketNumber !== event.payload)
        return {...state}
    } else {
        return state
    }
}
