import {
    AddAssetSuccessEvent,
    AssetStateEvent,
    DeleteAssetSuccessEvent,
    GetAllAssetSuccessEvent,
    UpdateAssetSuccessEvent
} from './state-event'
import { Asset } from './model'

export const AssetStateReducer = (state: Asset[] = [], event: AssetStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllAssetSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddAssetSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteAssetSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateAssetSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
