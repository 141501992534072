import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { NewAsset } from '../Model'

export const NewAssetContext = createContext({})

const initialState: NewAsset[] = []

interface Param{}

type props = PropsWithChildren<Param>
const NewAssetReducer = (state: NewAsset[], action: {type: string; payload: any}) => {
    switch (action.type) {
    case 'GET_NewAsset':
        return action.payload || [] as NewAsset[]
    default:
        return state
    }
}

export const NewAssetProvider = (props: props) => {
    const [NewAssetState, NewAssetDispatch] = useReducer(NewAssetReducer, initialState)
    const GetNewAsset = (payload: NewAsset[]) => {
        NewAssetDispatch({type: 'GET_NewAsset', payload})
    }
    return (
        <NewAssetContext.Provider value = {{ NewAssetState, GetNewAsset}} >
            {props.children}
        </NewAssetContext.Provider>
    )
}
