import {
    CreatePriorityMatrixNewVersionSuccessEvent,
    GetAllPrioritySuccessEvent,
    GetPriorityMatrixSuccessEvent,
    PriorityStateEvent
} from './state-event'
import {PriorityLevel, PriorityMatrix} from './model'

export const priorityStateReducer = (state: PriorityLevel[] = [], event: PriorityStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllPrioritySuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}

export const priorityConfigurationStateReducer = (state: PriorityMatrix[] = [], event: PriorityStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetPriorityMatrixSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === CreatePriorityMatrixNewVersionSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
