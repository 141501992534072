import {GetAllUrgencyPriortySuccessEvent, UrgencyProblemStateEvent} from './state-event'
import {PriorityLevelProblem} from '../priority'

export const urgencyPriortyStateReducer = (state: PriorityLevelProblem[] = [], event: UrgencyProblemStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllUrgencyPriortySuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
