/* eslint-disable array-callback-return */
import React, {useContext, useEffect, useState} from 'react'
import {Button, Card, Checkbox, Col, Form, Input, message, Row, Select, Spin} from 'antd'
import {PmActivityCustomField, pmAdjustData, PreventiveWithAsset} from '../Model'
import {RemarkContext} from '../ContextAPI/RemarkContext'
import moment from 'moment'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'
import {FormComponentProps} from 'antd/es/form'
import {getDataItems, updatePreventiveById} from '../services'
import './stylePM.css'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {PMActivityField} from '../../configurable-fields'
import { replaceName } from '../../common-file'

interface Param {
    current: number
    setCurrent: Function
    dataPM?: PreventiveWithAsset
    isOnline: boolean
    pmAdjusted?: pmAdjustData
    Group?: string
    getPmActivity: Function
    OptionRemark?: string[]
}

type Props = Param & FormComponentProps
const {Option} = Select
const Step3Form: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue, validateFields, resetFields, getFieldValue } = props.form
    const [isNext, setISNext] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isPMActivity, setIsPMActivity] = useState<boolean>(true)
    const [optionRemark, setOptionRemark] = useState<any[]>([])
    const [pmActivity, setPmActivity] = useState<PMActivityField[]>([])
    const [dataPMActivity, setDataPMActivity] = useState<PmActivityCustomField[] | undefined>([])
    const [checkAll, setCheckAll] = useState<boolean>(false)
    const {RemarkState, GetRemark} = useContext<any>(RemarkContext)
    const [isMobileSize, setIsMobileSize] = useState<boolean>(false)
    const [checkRequired, setCheckRequired] = useState<boolean>(false)

    useEffect(() => {
        getDataItems('remark').then((res) => {
            if (RemarkState.length === 0) {
                // if (props.OptionRemark) {
                //     res.push(...props.OptionRemark)
                // }
                GetRemark(res)
            }
        })
        // let data = props?.dataPM?.preventiveMaintenance?.remark?.split(',') || []
        // if (data && props.OptionRemark) {
        //     data.push(...props.OptionRemark)
        //     data = data.filter((it) => it !== '')
        // }
        console.log(props?.dataPM?.preventiveMaintenance?.remark?.split(','))
        setFieldsValue({
            Activity: props?.dataPM?.preventiveMaintenance.pmActivityCustomField,
            Remark: props?.dataPM?.preventiveMaintenance?.remark === '' ? undefined : props?.dataPM?.preventiveMaintenance?.remark?.split(',') || []
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const remark = sessionStorage.getItem('remark')
        if (remark) {
            setOptionRemark(JSON.parse(remark))
        } else {
            // if (props.OptionRemark) {
            //     RemarkState.push(...props.OptionRemark)
            // }
            setOptionRemark(RemarkState)
        }
        if (props.Group) {
            if (props.dataPM) {
                const tempCheck: string[] = []
                const customField = props.dataPM?.preventiveMaintenance?.pmActivityCustomField?.map((value) => value.customField!!)
                setDataPMActivity(props.dataPM?.preventiveMaintenance?.pmActivityCustomField)
                const pmActivity = (customField || []).map((data) => {
                    return data
                })
                if (pmActivity) {
                    setPmActivity(pmActivity)
                }

                if (props.dataPM.preventiveMaintenance.pmActivityCustomField) {
                    props.dataPM.preventiveMaintenance.pmActivityCustomField.filter((ele) => ele.value === true).forEach((pmActivity) => {
                        tempCheck.push(pmActivity.customField?.activityLabel!!)
                    })
                }
                CheckFullActivity(tempCheck, pmActivity.length)

                // let data = props?.dataPM?.preventiveMaintenance?.remark?.split(',') || []
                // if (data && props.OptionRemark) {
                //     data.push(...props.OptionRemark)
                //     data = data.filter((it) => it !== '')
                // }
                console.log(tempCheck)
                setFieldsValue({
                    Activity: tempCheck,
                    Remark: props?.dataPM?.preventiveMaintenance?.remark === '' ? undefined : props?.dataPM?.preventiveMaintenance?.remark?.split(',') || []
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Group, props.dataPM])

    useEffect(() => {
        if (dataPMActivity?.length !== 0) {
            // eslint-disable-next-line no-unused-expressions
            dataPMActivity?.forEach((data, index) => {
                const obj = {}
                obj[replaceName('Remark' + (index + 1))] = data.remark
                setFieldsValue(obj)
            })
        }
    }, [dataPMActivity])

    useEffect(() => {
        if (window.innerWidth < 576) {
            setIsMobileSize(true)
        } else {
            setIsMobileSize(false)
        }
    }, [isMobileSize])

    const onFinish = (e: any) => {
        e.preventDefault()
        validateFields(async (_err, values) => {
            if (!_err) {
                const convertData = props.dataPM!!
                setIsLoading(true)
                const filterPmAdjusted = props.dataPM?.preventiveMaintenance?.pmAdjust
                // console.log(values.Activity)
                const mapValue: PmActivityCustomField[] = [];
                (dataPMActivity || []).forEach((data, index) => {
                    // if ((values.Activity || []).includes(data.customField?.activityLabel)) {
                    // console.log((values.Activity || []).includes(data.customField?.activityLabel))
                    if ((values.Activity || []).includes(data.customField?.activityLabel)) {
                        data.value = true
                        data.remark = props.form.getFieldValue('Remark' + (index + 1)) ? props.form.getFieldValue('Remark' + (index + 1)) : undefined
                    } else {
                        data.value = false
                    }
                    mapValue.push(data)
                })

                if (convertData.preventiveMaintenance.pmAdjust) {
                    if (!props.pmAdjusted && !filterPmAdjusted?.pmAdjustData?.id && props.isOnline) {
                        convertData.preventiveMaintenance.pmAdjust = undefined
                    } else {
                        if (!checkValuePMAdjusted(filterPmAdjusted!!)) {
                            convertData.preventiveMaintenance.pmAdjust = undefined
                        } else {
                            convertData.preventiveMaintenance.pmAdjust = props.pmAdjusted!! || convertData.preventiveMaintenance.pmAdjust!!
                        }
                    }
                }
                convertData.preventiveMaintenance.asset = props.dataPM?.asset!!
                convertData.preventiveMaintenance.pmActivityCustomField = mapValue
                // convertData.preventiveMaintenance.remark = (values.Remark || [])?.join(',')

                if (!isPMActivity) {
                    convertData.preventiveMaintenance.actualDatetime = moment(values.PM_Plan).format('YYYY-MM-DD HH:mm:ss')
                }
                if (isNext) {
                    if (convertData.preventiveMaintenance.currentStep === 2) {
                        convertData.preventiveMaintenance.pmStatus = 'WaitingForSign'
                        convertData.preventiveMaintenance.currentStep = convertData.preventiveMaintenance.currentStep + 1
                    }
                }
                convertData.preventiveMaintenance.actionInStep = 3
                if (props.isOnline) {
                    updatePreventiveById(convertData.preventiveMaintenance.id!!, convertData.preventiveMaintenance).then((res) => {
                        if (res) {
                            console.log('res', res)
                            props.getPmActivity(values.Activity)
                            message.success('You have Successfully save the data.')
                            if (props.setCurrent && isNext && checkAll) {
                                if (props.current) {
                                    props.setCurrent(props.current + 1)
                                }
                            }
                            setIsLoading(false)
                        }
                    }).catch((err) => {
                        message.error('Update Ticket Fail' + err)
                        setIsLoading(false)
                    })
                }
            } else {
                setISNext(false)
            }
        })
    }

    const BackStep = () => {
        props.setCurrent(props.current - 1)
    }

    const CheckFullActivity = (value: CheckboxValueType[], pmActivity: number) => {
        if (pmActivity !== 0) {
            if (value.length === pmActivity) {
                setCheckAll(true)
                setIsPMActivity(false)
            } else {
                setCheckAll(false)
                setIsPMActivity(true)
            }
        }
    }

    const checkValuePMAdjusted = (value: pmAdjustData): boolean => {
        let check = false
        if (value.id || value?.pmAdjustData?.assetLoanId || value?.pmAdjustData?.status || value?.pmAdjustData?.assetGroupType || value?.pmAdjustData?.brand ||
            value?.pmAdjustData?.building || value?.pmAdjustData?.floor || value?.pmAdjustData?.location || value?.pmAdjustData?.assetName ||
            value?.pmAdjustData?.manufacturer || value?.pmAdjustData?.model || value?.pmAdjustData?.pairedItAsset || value?.pmAdjustData?.peripheralSet ||
            value?.pmAdjustData?.site || value?.pmAdjustData?.people?.fullName || value?.pmAdjustData?.zone) {
            check = true
        }
        return check
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setFieldsValue({
                Activity: pmActivity.map(res => res.activityLabel)
            })
            setIsPMActivity(false)
        } else {
            setFieldsValue({
                Activity: []
            })
            setIsPMActivity(true)
        }
        setCheckAll(e.target.checked)
    }

    const checkDisable = (data, key) => {
        let result = true
        if (data) {
            const checkIsData = data.find((it) => {
                return it.trim() === key.trim()
            })
            if (checkIsData) {
                result = false
            }
        }
        return result
    }

    const handleChange = (e, inputName) => {
        if (!e.target.checked && getFieldValue(inputName)) {
            resetFields(inputName)
        }
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <Form
                    name="Step3"
                    layout={'vertical'}
                    onSubmit={onFinish}
                >
                    <Card title="PM Activity" className="card-back">
                        {/* <Col span={24} style={{padding: 10}}>
                            <Checkbox style={{width: '100%'}} onChange={e => onCheckAllChange(e)} checked={checkAll}
                                disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3 || pmActivity.length === 0}>
                            Check all
                            </Checkbox>
                        </Col> */}

                        <Form.Item
                            label=""
                        >
                            {getFieldDecorator('Activity', {
                                rules: [{required: false, message: 'Please input your Activity!'}]
                            })(
                                <Checkbox.Group style={{width: '100%'}}
                                    onChange={(e) => CheckFullActivity(e, pmActivity.length)}>
                                    <Row>
                                        {(pmActivity || []).map((res, index: number) => {
                                            return <Col span={24} className="striped" key={index}>
                                                <Checkbox
                                                    value={res.activityLabel}
                                                    disabled={props.dataPM?.preventiveMaintenance?.currentStep === 3}
                                                    onChange={(e) => handleChange(e, replaceName('Remark' + (index + 1)))}
                                                >
                                                    {index + 1}. {res.activityLabel!!.trim()}
                                                </Checkbox>
                                                <br />
                                                {res.description ? <div style={{ paddingLeft: '2em', color: '#828282'}} >{res.description}</div> : null }
                                                <br />
                                                <Col xs={24} sm={7} md={4} lg={3} xl={3} style={{marginTop: 4, paddingLeft: '2em'}} className= { !checkDisable(props.form.getFieldValue('Activity'), res.activityLabel) && res.isRequireRemark ? 'ant-form-item-required' : ''}>
                                                    Remark :
                                                </Col>
                                                <Col xs={24} sm={17} md={20} lg={21} xl={21} style={{paddingLeft: isMobileSize ? '2em' : undefined, paddingRight: '2em'}}>
                                                    <Form.Item>
                                                        {getFieldDecorator(replaceName('Remark' + (index + 1)), {
                                                            rules: [
                                                                {
                                                                    required: !checkDisable(props.form.getFieldValue('Activity'), res.activityLabel) && res.isRequireRemark,
                                                                    message: 'Remark is Required!, Please Enter Remark.'
                                                                },
                                                                {
                                                                    whitespace: !checkDisable(props.form.getFieldValue('Activity'), res.activityLabel) && res.isRequireRemark,
                                                                    message: 'Please enter more than 1 letters remark.'
                                                                }
                                                            ]
                                                        })(
                                                            <Input
                                                                placeholder="Enter Remark"
                                                                disabled={checkDisable(props.form.getFieldValue('Activity'), res.activityLabel)}
                                                                value={props.form.getFieldValue('Remark' + (index + 1))}
                                                                maxLength={255}
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Col>
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                    </Card>
                    <br/>
                    {/* <Form.Item
                        label="Remark"
                    >
                        {getFieldDecorator('Remark')(
                            <Select mode="tags" placeholder={'Select Remark'}>
                                {props.isOnline ? ([...new Set(RemarkState)] || []).map((res: any, i: number) => {
                                    return <Option value={res} key={i}>{res}</Option>
                                }) : ([...new Set(optionRemark)] || [])?.map((res, index) => {
                                    return <Option value={res} key={index}>{res}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item> */}

                    <Form.Item style={{textAlign: 'end'}}>
                        <Button htmlType="button" onClick={BackStep}>
                        Back
                        </Button>
                        <Button type="primary" htmlType="submit" className="ml5" disabled={!checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}
                            style={{marginLeft: 5, marginRight: 5}}>
                        Submit
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={() => setISNext(true)} className="ml5"
                            disabled={isPMActivity || pmActivity.length === 0 || !checkRolesState(RoleType.PM, 'CreateUpdateDeletePMPlan')}>
                        Next
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

const MyStep3Form = Form.create<Props>({name: 'Step3Form'})(Step3Form)
export default MyStep3Form
