import React from 'react'
import {Card, Col, Modal, Row} from 'antd'

interface Param {
    isVisible: boolean
    setIsVisible: Function
}

type Props = Param
const PrivacyPolicy: React.FC<Props> = (props: Props) => {

    return (
        <div style={{wordWrap: 'break-word'}}>
            <Modal
                title="การยิมยอมให้ข้อมูลส่วนบุคคล"
                visible={props.isVisible}
                footer={false}
                onCancel={() => props.setIsVisible(false)}
                width={'60%'}
            >
                <Row className={'text-align-center'}>
                    <b>หนังสือให้ความยินยอมสำหรับการรักษาความปลอดภัยข้อมูลส่วนบุคคล</b>
                </Row>
                <br/>
                <Row className={'text-align-center'}>
                    <b>กลุ่มบริษัท จีเอเบิล จำกัด</b>
                </Row>
                <br/>
                <Row className={'text-align-start'}>
                    <b>การเคารพสิทธิในความเป็นส่วนตัวของท่าน</b>
                </Row>
                <Row className={'text-align-justify'}>
                    <p>กลุ่มบริษัทฯ จีเอเบิล จำกัด
                        ความตระหนักดีว่าท่านมีความประสงค์ที่จะได้รับความปลอดภัยสูงสุดในสิทธิความเป็นส่วนตัวของท่าน
                        กลุ่มบริษัทฯ จึงให้ความสำคัญ และ เคารพสิทธิในความเป็นส่วนตัวของท่าน ในการดำเนินการ
                        เก็บรวมรวม ใช้ ประมวลผลและเปิดเผยข้อมูลส่วนบุคคลของท่าน ที่สามารถระบุตัวตนของท่านได้
                        หรือข้อมูลอื่นใดที่เป็นข้อมูลส่วนบุคคลภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล โดยกลุ่มบริษัทฯ
                        จะดำเนินการโดยใช้มาตรการ
                        ที่เข้มงวดในการรักษาความปลอดภัยตามวัตถุประสงค์ที่ท่านได้ให้ความยินยอมไว้เท่านั้น
                        ตลอดจนป้องกันมิให้มีการนำข้อมูลส่วนบุคคลของท่านไปใช้โดยมิได้รับการอนุญาตจากท่านก่อน</p>
                </Row>
                <Row className={'text-align-start'}>
                    <b>การเก็บรวบรวม ใช้ และประมวลผลข้อมูลส่วนบุคคล</b>
                </Row>
                <Row className={'text-align-justify'}>
                    <p>
                        กลุ่มบริษัทฯ จะใช้ข้อมูลส่วนบุคคลของท่านเพื่อประกอบการดำเนินธุรกิจของกลุ่มบริษัทฯ
                        เพื่อประโยชน์ของท่านเพื่อวัตถุประสงค์ที่เกี่ยวข้องกับท่านในฐานะ พนักงาน
                        และเพื่อการประมวลผลตามวัตถุประสงค์ที่เกี่ยวข้องดังที่ปรากฎในตาราง
                        ซึ่งบางครั้งอาจเป็นการปฏิบัติตามสัญญา กฎหมายหรือกฎระเบียบที่เกี่ยวข้องด้วย
                    </p>
                </Row>
                <Row>
                    <Col span={8}>
                        <Card title="วัตถุประสงค์ในการใช้"
                            style={{height: 200, textAlign: 'center'}} bordered={true}>
                            เพื่อใช้ในการบริหารงานบุคคลของกลุ่มบริษัทฯ, สิทธิประโยชน์และสวัสดิการของพนักงานและ
                            ครอบครัว, เพื่อสิทธิประโยชน์และสวัสดิการของพนักงานและครอบครัว, เพื่อ CSR การประชาสัมพันธ์
                            และเผยแพร่ข่าวสารองค์กรไปสู่สาธารณะ (CSR ภายในและภายนอก), ยื่นรับรองหลักสูตรกรมพัฒนา
                            ฝีมือแรงงาน
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="ประเภทข้อมูลส่วนบุคคล"
                            style={{height: 200, textAlign: 'center'}} bordered={true}>
                            ชื่อ-สกุล, อีเมล, ตำแหน่ง, ที่อยู่, หมายเลขโทรศัพท์ , รูปถ่าย, สื่อวีดีโอ หรือคลิปเสียง ,
                            บัญชีsocial, ชื่อบริษัท,หน่วยงานหรือสังกัด
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="ระยะเวลาจัดเก็บ"
                            style={{height: 200, textAlign: 'center'}} bordered={true}>
                            ตราบเท่าที่ท่านยังให้ความยินยอม
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row className={'text-align-start'}>
                    <b>การเปิดเผยข้อมูลส่วนบุคคล</b>
                </Row>
                <Row className={'text-align-justify'}>
                    <p>กลุ่มบริษัทฯ จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่าน
                        ตลอดจนจะป้องกันมิให้มีการนำข้อมูลส่วนบุคคลของท่านไปใช้โดยพลการ
                        นอกเหนือจากที่ท่านได้ให้ความยินยอมไว้ ทั้งนี้ กลุ่มบริษัทฯ จะเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่
                        กลุ่มบริษัทฯ ในเครือกิจการ หรือ เครือธุรกิจเดียวกัน หรือ พันธมิตรทางธุรกิจ
                        เพื่อการดำเนินธุรกิจของกลุ่มบริษัทฯ รวมทั้งอาจใช้เพื่อเผยแพร่ข่าวสาร
                        กิจกรรมไปสู่สาธารณะเพื่อสนับสนุนด้านการตลาด หรือ การเปิดเผยข้อมูลนั้นๆ เป็นไปโดยถูกต้องตามกฎหมาย
                        หรือตามคำสั่งของหน่วยงานรัฐที่เกี่ยวข้อง</p>
                </Row>
                <br/>
                <Row className={'text-align-start'}>
                    <b>สิทธิที่เกี่ยวกับข้อมูลของท่าน </b>
                </Row>
                <br/>
                <Row className={'text-align-justify'}>
                    <p>ท่านสามารถใช้สิทธิที่เกี่ยวกับข้อมูลของท่านโดยใช้ความเป็นเจ้าของข้อมูลส่วนบุคคลของท่านตามที่กลุ่มบริษัทฯ
                        แจ้งท่านไว้ในหนังสือฉบับนี้</p>
                </Row>
                <Row className={'text-align-justify'}>
                    <p>&emsp;1.
                        สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวข้องกับตนซึ่งอยู่ในความรับผิดชอบของผู้ควบคุมข้อมูลส่วนบุคคล
                        หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอม
                    </p>
                    <p>
                        &emsp;2. สิทธิขอรับข้อมูลส่วนบุคคลที่เกี่ยวข้องกับตนจากกลุ่มบริษัทฯได้
                        ในกรณีที่กลุ่มบริษัทฯได้ทำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและ
                        สามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิ
                        ขอให้กลุ่มบริษัทฯส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ
                        หรือ
                        ไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่โดยสภาพทางเทคนิคไม่สามารถทำได้
                    </p>
                    <p>
                        &emsp;3. สิทธิคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตน
                    </p>
                    <p>
                        &emsp;4. สิทธิขอให้กลุ่มบริษัทฯดำเนินการลบ หรือทำลาย
                        หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้
                    </p>
                    <p>
                        &emsp;5. สิทธิขอให้กลุ่มบริษัทฯระงับการใช้ข้อมูลส่วนบุคคล
                    </p>
                    <p>
                        &emsp;6. สิทธิขอให้ดำเนินการแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์ และทำให้ข้อมูลเป็นปัจจุบัน
                    </p>
                    <p>
                        &emsp;7. สิทธิในการขอถอนความยินยอม
                        ซึ่งท่านสามารถใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลจะถอนความยินยอมเมื่อใดก็ได้
                        โดยท่านสามารถแจ้งมายังสถานที่ติดต่อ หรือวิธีการติดต่อที่กลุ่มบริษัทฯ แจ้งท่านไว้ในหนังสือฉบับนี้
                    </p>
                </Row>
                <br/>
                <Row className={'text-align-start'}>
                    <b>สถานที่ติดต่อ และวิธีการติดต่อ (กรณีติดต่อจากภายนอก)</b>
                </Row>
                <br/>
                <Row className={'text-align-justify'}>
                    <p>ในกรณีที่ท่านต้องการความช่วยเหลือจากบริษัทฯ หรือ หากมีข้อสงสัยใดๆ ที่เกี่ยวข้องกับสิทธิของท่าน
                        สามารถติดต่อสอบถามผ่านทางช่องทางที่กำหนดไว้ ดังนี้
                        บริษัท จีเอเบิล จำกัด 127/20, 22, 29-31 อาคารปัญจธานี ถนน นนทรี
                        แขวง ช่องนนทรี เขต ยานนาวา กรุงเทพฯ 10120
                        โทรศัพท์ 02-7819333
                        Email: ccd@g-able.com
                        หรือ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO)
                        Email: dpo@g-able.com
                    </p>
                </Row>
            </Modal>
        </div>
    )
}

export default PrivacyPolicy
