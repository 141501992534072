import React, { useEffect, useState } from 'react'
import { Button, Col, Checkbox, Input, Row, Spin, Tooltip, Avatar, Modal, Icon, message } from 'antd'
import AutoAvatar from '../../common-components/autoAvatar'
import { Configuration, OpenAIApi } from 'openai'
import ChatArrow from '../../assets/icons/ChatArrow.png'
import { authorType, ChatInterface } from './model'
import { tokenDecode } from '../../common-misc'
import GhatGPT from '../../assets/images/IconGPT.png'
import ReactHtmlParser from 'react-html-parser'
import ChatGPTShareData from './ChatGPTShareData'
import { Redirect } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'
import { decryptBodyToken } from '../../common-misc/encryptBody'
import { getTenantConfigByApiKeyType } from '../../authorization-module/user-role/duck/service'
import { tenantConfig } from '../../authorization-module/people/model'
import EmptyChat from '../../assets/images/EmptyChat.png'
import moment from 'moment'
const axios = require('axios')

interface Params {
    setOpen: Function
    initText?: string
}

type Props = Params
const { confirm } = Modal
let i = 0
const ChatUI: React.FC<Props> = (props: Props) => {
    const [input, setInput] = useState(props.initText || '')
    const [listChat, setListChat] = useState<ChatInterface[]>([])
    const [modeSelect, setModeSelect] = useState(false)
    const [selectChat, setSelectChat] = useState<ChatInterface[]>([])
    const [loadingChat, setLoadingChat] = useState(false)
    const [tempChatUser, setTempChatUser] = useState('')
    const [checkAll, setCheckAll] = useState(false)
    const [isRedirect, setIsRedirect] = useState(false)
    const [dataTenantConfig, setDataTenantConfig] = useState<tenantConfig[]>([])
    const [indeterminate, setIndeterminate] = useState(false)
    const ChatGPTKey = tokenDecode()?.chatGptKey || ''
    const now = moment()

    useEffect(() => {
        getTenantConfigByApiKeyType('chatGPT').then((res) => {
            if (res.length !== 0) {
                setDataTenantConfig(res)
            }
        })
        if (ChatGPTKey !== '') {
            // getUsage(decryptBodyToken(ChatGPTKey))
        }
    }, [])

    useEffect(() => {
        setInput(props.initText || '')
    }, [props.initText])

    const getUsage = (OPENAI_API_KEY) => {
        axios({
            method: 'get',
            url: 'https://api.openai.com/dashboard/billing/credit_grants',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`
            }
        })
            .then(response => {
                console.log(response)
                if (response) {
                    const getExpireList = response.grants.data?.map((it) => { return it.expires_at })
                    const getMaxDate = Math.max(...getExpireList)
                    if (now.diff(moment(getMaxDate * 1000), 'days') > 0) {
                        message.error('Token Chat GPT are expired!')
                    }
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const sentChat = (OPENAI_API_KEY, text) => {
        axios.post('https://api.openai.com/v1/chat/completions', {
            model: dataTenantConfig.find((it) => { return it.keyName === 'model' })?.value || 'gpt-3.5-turbo',
            messages: [{role: 'user', content: text}],
            temperature: Number(dataTenantConfig.find((it) => { return it.keyName === 'temperature' })?.value) || 0.7
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`
            }
        })
            .then((response) => {
                console.log(response)
                const chatResponse: ChatInterface = {
                    author: authorType.ChatGPT,
                    text: response.data.choices[0].message.content,
                    order: i++
                }
                setListChat(prevList => [...prevList, chatResponse])
                setLoadingChat(false)
            }, (error) => {
                console.log(error)
                setLoadingChat(false)
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (input.trim() !== '') {
            const UserInput: ChatInterface = {
                author: authorType.User,
                text: input.trim(),
                order: i++
            }
            setListChat(prevList => [...prevList, UserInput])
            setTempChatUser(prevList => prevList + '\n' + input)
            setLoadingChat(true)
            setInput('')
            // const configuration = new Configuration({
            //     apiKey: decryptBodyToken(ChatGPTKey)
            // })
            // const openai = new OpenAIApi(configuration)

            // const response = await openai.createCompletion({
            //     model: dataTenantConfig.find((it) => { return it.keyName === 'model' })?.value || 'text-davinci-003',
            //     prompt: tempChatUser + '\n' + input,
            //     temperature: Number(dataTenantConfig.find((it) => { return it.keyName === 'temperature' })?.value) || 0.7,
            //     max_tokens: Number(dataTenantConfig.find((it) => { return it.keyName === 'maxTokens' })?.value) || 400
            // }).finally(() => {
            //     setLoadingChat(false)
            // })
            // // console.log('response : ', response.data)
            // if (response.data.choices[0].text) {
            //     const chatResponse: ChatInterface = {
            //         author: authorType.ChatGPT,
            //         text: response.data.choices[0].text,
            //         order: i++
            //     }
            //     setListChat(prevList => [...prevList, chatResponse])
            //     setLoadingChat(false)
            // }
            sentChat(decryptBodyToken(ChatGPTKey), tempChatUser + '\n' + input)
        }
    }

    const onChange = (Chat) => {
        console.log(Chat)
        setSelectChat(Chat)
        if (Chat.length === 0) {
            // setIndeterminate(false)
            setCheckAll(false)
        } else {
            if (Chat.length === listChat.length) {
                // setIndeterminate(false)
                setCheckAll(true)
            } else {
                setCheckAll(false)
                // setIndeterminate(true)
            }
        }
    }

    const SelectAll = (e) => {
        setCheckAll(!checkAll)
        if (e) {
            setSelectChat(listChat)
        } else {
            setSelectChat([])
        }
    }

    const sendToMyKnowledge = () => {
        props.setOpen(false)
        const AllData: string[] = []
        const sortSelectChat = selectChat.sort((a, b) => a.order - b.order)
        sortSelectChat.forEach((it) => {
            AllData.push(ReactDOMServer.renderToString(CheckChat(it.text, it.author === authorType.User)))
        })
        console.log(AllData)
        ChatGPTShareData.getInstance().data = AllData
        setIsRedirect(true)
    }

    const CheckChat = (chat: string, isUserTagB?: boolean): JSX.Element => {
        const pattern = /(https?:\/\/[^\s]+)/g
        // let text = chat
        const match = chat.match(pattern)
        if (chat.startsWith('\n\n')) {
            chat = chat.substring(2)
        }
        chat = chat.replaceAll('\n', '<br/>')
        if (match) {
            const text2 = match.map((it) => {
                return chat.replaceAll(it, `<img src='${it}' alt='data' />`)
            })
            return <div><code>{isUserTagB ? <b>{ReactHtmlParser(text2[0])}</b> : ReactHtmlParser(text2[0])}</code></div>
        } else {
            return <div><code>{isUserTagB ? <b>{ReactHtmlParser(chat)}</b> : ReactHtmlParser(chat)}</code></div>
        }
    }

    const handleCancel = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            onOk() {
                setInput(props.initText || '')
                setListChat([])
                setSelectChat([])
                setModeSelect(false)
                setLoadingChat(false)
                props.setOpen(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            {isRedirect ? <Redirect to={'/KnowledgeForm'}/> : null}
            {!modeSelect && listChat.length !== 0 ? <div className='flexEndData' style={{ paddingBottom: 5 }}>
                <div className='feck-link' onClick={(e) => { setModeSelect(!modeSelect) }}>Select My Knowledge</div>
            </div> : null}
            {modeSelect ? <div className='flexEndData' style={{ paddingBottom: 5 }}>
                <Checkbox
                    // indeterminate={indeterminate}
                    onChange={(e) => SelectAll(e.target.checked)}
                    checked={checkAll}
                >
                    Select All
                </Checkbox>
            </div> : null}
            <div className='Box-Chat' style={listChat.length === 0 ? { background: `url(${EmptyChat}) no-repeat center`, backgroundSize: '50%' } : {}}>
                <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={selectChat as any}>
                    {listChat.map((Chat, i) => {
                        if (Chat.author === authorType.User) {
                            return <div className='flexEndData' key={i}>
                                <Row style={{ width: '-webkit-fill-available', display: 'flex', justifyContent: 'end', padding: 5}}>
                                    <Col span={!modeSelect ? 22 : 20} className='Chat-User'>
                                        {Chat.text}
                                    </Col>
                                    <Col span={2} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <AutoAvatar title={tokenDecode()?.sub || ''} broken={false} username={sessionStorage.getItem('username')?.toString()!!} size={35}/>
                                    </Col>
                                    {modeSelect ? <Col className='flexEndData' span={2} style={{ paddingTop: 10, paddingRight: 8 }}>
                                        <Checkbox value={Chat} />
                                    </Col> : null}
                                </Row>
                            </div>
                        } else {
                            return <div>
                                <Row style={{ padding: 5}}>
                                    <Col span={2} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <Avatar src={GhatGPT} alt='Chat GPT' />
                                    </Col>
                                    <Col span={!modeSelect ? 22 : 17} className='Chat-GPT'>
                                        {CheckChat(Chat.text)}
                                    </Col>
                                    {modeSelect ? <Col className='flexEndData' style={{ paddingTop: 10, marginRight: 8 }}>
                                        <Checkbox value={Chat} />
                                    </Col> : null}
                                </Row>
                            </div>
                        }
                    })}
                </Checkbox.Group>
                {loadingChat ? <Spin style={{paddingLeft: 10}}/> : null}
            </div>
            <br/>
            <Row gutter={[16, 16]}>
                <Col span={22}>
                    <Input suffix={
                        <Tooltip title="Send">
                            <img src={ChatArrow} alt='Send' width={20} height={20} onClick={handleSubmit} style={{ cursor: 'pointer' }}/>
                        </Tooltip>
                    }
                    prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    value={input} placeholder={'Search by ChatGPT'} autoFocus={true} onChange={(e) => { setInput(e.target.value) }} onPressEnter={handleSubmit}/>
                </Col>
                <Col span={2}>
                    <AutoAvatar title={tokenDecode()?.sub || ''} broken={false} username={sessionStorage.getItem('username')?.toString()!!} size={35}/>
                </Col>
            </Row>
            <br/>
            <Row>
                <Button type="primary" htmlType="button" style={{ float: 'right' }} disabled={selectChat.length === 0} onClick={sendToMyKnowledge}>
                    Send to My Knowledge
                </Button>
                <Button type="default" htmlType="button" style={{ float: 'right', marginRight: 10 }} onClick={() => handleCancel()}>
                    Cancel
                </Button>
            </Row>
        </div>
    )
}

export default ChatUI
