import {
    GetAllWorkFlowSuccessEvent,
    AddWorkFlowSuccessEvent,
    UpdateWorkFlowSuccessEvent,
    DeleteWorkFlowSuccessEvent,
    WorkFlowStateEvent,
    UpdateWorkFlowTotalPageSuccessEvent,
    UpdateWorkFlowCurrentPageSuccessEvent
} from './state-event'
import {WorkFlow} from './Model'
import { WorkFlowPagination } from '../../Catalog'

export const workFlowListStateReducer = (state: WorkFlow[] = [], event: WorkFlowStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllWorkFlowSuccessEvent.type) {
        return event.payload
    } else if (event.type === AddWorkFlowSuccessEvent.type) {
        if (state) {
            return [...state, event.payload]
        } else {
            return [event.payload, ...state!!]
        }
    } else if (event.type === UpdateWorkFlowSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else if (event.type === DeleteWorkFlowSuccessEvent.type) {
        return state.filter((role) => role.id !== event.payload)
    } else {
        return state
    }
}

export const WorkFlowPaginationStateReducer = (state: WorkFlowPagination = {}, event: WorkFlowStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateWorkFlowTotalPageSuccessEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateWorkFlowCurrentPageSuccessEvent.type) {
        state.page = event.payload
    } else {
        return state
    }
    return state
}
