import React, { useEffect, useState } from 'react'
import { Badge, Col, Dropdown, Empty, Icon, Menu, Modal, Row, Tooltip } from 'antd'
import AutoAvatar from '../common-components/autoAvatar'
import NotifiesShareData from './NotifiesShareData'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../store'
import { UpdateNotifySuccessEvent } from '../authorization-module/people/state-event'
import Profile from './Profile'
import { tokenDecode } from '../common-misc'
import {UserProfile} from '../authorization-module/user-role/duck/model'
import { getNotificationAuthByUserID, getUserProfile } from '../authorization-module/user-role/duck/action'
import { NotifyWebSocketChannel } from '../common-notify/web-socket-channel-notify'
import { NotifyWebSocket } from '../common-notify/web-socket-model-notify'
import errorIcon from '../assets/icons/error-icon.png'
import successIcon from '../assets/icons/succress-icon.png'
import { getAllNotify } from '../msp-wo/asset-management/service'
import moment from 'moment'
import { UserWebSocketChannel } from '../common-notify/web-socket-auth'
import { GetAllNotifyWebSocketSuccessEvent } from '../common-notify/state-event'
import { CategoryWebSocketChannel } from '../common-notify/web-socket-category'

interface Params {
    // auth: Auth;
    broken: boolean
}

const mapStateToProps = (state: StoreState) => {
    return {
        notify: state.notify,
        NotifyWebSocket: state.NotifyWebSocket
    }
}

interface DispatchProps {
    getAllNotify: (id: string) => Promise<number>;
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & Params & DispatchProps

const UserDetails: React.FC<Props> = (props: Props) => {
    const [count, setCount] = useState<number>(0)
    const [notifyData, setNotifyData] = useState<NotifyWebSocket[]>([])
    const [isOpenNotify, setIsOpenNotify] = useState<boolean>(false)
    // const [fileName, setFilename] = useState<string>('')
    const dispatch = useDispatch()
    const [dataUser, setDataUser] = useState<UserProfile>()

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        getUserProfile().then((res) => {
            if (res) {
                setDataUser(res)
            }
        })
        // props.getAllNotify(tokenDecode()?.userId?.toString() || '')
        CategoryWebSocketChannel.subscribe(tokenDecode()?.sub || '')
    }, [])

    useEffect(() => {
        UserWebSocketChannel.subscribe(tokenDecode()?.sub || '')
    }, [])

    useEffect(() => {
        NotifyWebSocketChannel.subscribe(tokenDecode()?.sub || '')
    }, [])

    useEffect(() => {
        if (props.notify) {
            setCount(props.notify)
            // const getFileName = sessionStorage.getItem('fileName')
            // if (getFileName) {
            //     setFilename(getFileName)
            // }
        }
    }, [props.notify])

    useEffect(() => {
        setNotifyData(props.NotifyWebSocket)
    }, [props.NotifyWebSocket])

    useEffect(() => {
        console.log(NotifiesShareData.getInstance().notify)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [NotifiesShareData.getInstance().notify])

    const myMenu = (
        <Menu>
            <Profile dataUser={dataUser!!} />
        </Menu>
    )

    const CheckShowMessageError = (it: NotifyWebSocket):string => {
        if (it?.responseData) {
            const data = JSON.parse(JSON.stringify(it.responseData))
            if (data.messageFail) {
                return data.messageFail || 'Failed to import'
            } else {
                return 'Please check the file being imported.'
            }
        } else {
            return ''
        }
    }

    const myNotify = (
        <Menu className='Notify'>
            {notifyData.length === 0 ? <Empty /> : <div >
                <div style={{ textAlign: 'center' }}><h2 style={{ color: '#323276' }}>Notification</h2></div>
                <div>Today</div>
                <br/>
            </div>
            }
            {notifyData.length > 0 && notifyData ? props.NotifyWebSocket.map((response, index) => {
                return <Menu.Item key={index} className='Item-Notify' onClick={() => showDetails(response)} >
                    <Row gutter={[16, 16]}>
                        <Col span={4}>{response.httpStatus === '200' ? <img src={successIcon} alt='success' style={{ padding: 5 }} /> : <img src={errorIcon} alt='error' style={{ padding: 5 }}/>}</Col>
                        <Col span={20} style={{ paddingBottom: 0 }}>{response.httpStatus === '200' ? <span style={{ fontSize: 18, color: '#323276', fontWeight: 500 }}>Successfully imported.</span> : <span style={{ fontSize: 18, color: '#323276', fontWeight: 500 }}>Failed to import data.</span>}</Col>
                        <Col span={20} style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {response.httpStatus === '200' ? <div style={{ fontSize: 14, marginBottom: 10 }}>Your file has successfully imported.</div> : <div style={{ fontSize: 14, marginBottom: 10, wordBreak: 'break-word' }}><div className='force-break-word'>{CheckShowMessageError(response)}</div></div>}
                            <div style={{ color: '#8C8C8C', textAlign: 'end', marginBottom: 10, marginRight: 10 }}>{moment(response.createdDatetime).fromNow()}</div>
                        </Col>
                    </Row>
                </Menu.Item>
            }) : null}
        </Menu>
    )

    const showDetails = (response: NotifyWebSocket) => {
        setIsOpenNotify(false)
        const data = JSON.parse(response.responseData)
        console.log(data)
        let textDataNull = ''
        let textDataLimit = ''
        let textRowInvalid = ''
        let textRowDuplicate = ''
        let textRowLimit = ''
        let textpmDuplicate = ''
        let textMessageError = ''

        let numberOfRowsNull = ''
        let numberOfRowsLimit = ''
        let numberOfRowsInvalid = ''
        let numberOfRowsDuplicate = ''
        let numberOfLimit = ''
        let numberTextPMDuplicate = ''

        let objmessageAuth = {}
        let objmessageCategory = {}

        if (data.rowPmPlanDateIsNull?.length > 0 || data.rowPmPlanApproveIsNull?.length > 0 || data.rowSerialNumberIsNull?.length > 0) {
            textDataNull = 'Please Input Plan Date, PM Plan Approve and Serial Number.'
            numberOfRowsNull = 'Excel Row : ' + [...new Set([...data.rowPmPlanDateIsNull, ...data.rowPmPlanApproveIsNull, ...data.rowSerialNumberIsNull])].join(', ')
        }

        if (data.rowPmPlanDateIncorrectFormat?.length > 0 || data.rowPmPlanApproveIncorrectFormat?.length > 0) {
            textDataLimit = 'Please Input Plan date and PM Plan Approve in accordance with the policy requirements.'
            numberOfRowsLimit = 'Excel Row : ' + [...new Set([...data.rowPmPlanDateIncorrectFormat, ...data.rowPmPlanApproveIncorrectFormat])].join(', ')
        }

        if (data.rowDescriptionExceedLimit?.length > 0 || data.rowPmLocationExceedLimit?.length > 0 || data.rowSerialNumberExceedLimit?.length > 0) {
            textRowLimit = 'Some values in these field may cause a problem, please check Plan Date, Description, PM Location PM Plan Approve and Serial Number in accordance with the policy.'
            numberOfLimit = 'Excel Row : ' + [...new Set([...data.rowDescriptionExceedLimit, ...data.rowPmLocationExceedLimit, ...data.rowSerialNumberExceedLimit])].join(', ')
        }

        if (data.rowSerialNumberDup?.length > 0) {
            textRowDuplicate = 'Please do not use the same machine Serial Number in the same PM Plan.'
            numberOfRowsDuplicate = 'Excel Row : ' + [...new Set([...data.rowSerialNumberDup])].join(', ')
        }

        if (data.rowPmLocationDup?.length > 0) {
            textpmDuplicate = 'Please do not use the same machine PM location in the same work order'
            numberTextPMDuplicate = 'Excel Row : ' + [...new Set([...data.rowPmLocationDup])].join(', ')
        }

        if (data.assetNotFound?.length > 0 || data.pmLocationNotFound?.length > 0) {
            const temp: string[] = []
            if (data.assetNotFound?.length > 0) {
                data.assetNotFound.forEach((item) => { temp.push(item.row) })
            }
            if (data.pmLocationNotFound?.length > 0) {
                data.pmLocationNotFound.forEach((item) => { temp.push(item.row) })
            }
            textRowInvalid = 'Serial Number and PM Location was wrong, there is no match of this value in your configuration.'
            numberOfRowsInvalid = 'Excel Row : ' + [...new Set(temp)]?.join(', ')
        }

        if (data.messageFail) {
            textMessageError = data.messageFail
        }

        if (response.action === 'ImportUser') {
            objmessageAuth = showDetailsAuth(data)
        }

        if (response.action === 'ImportCategory') {
            objmessageCategory = showDetailsCategory(data)
        }

        Modal.error({
            title: <b style={{ color: '#323276' }}>Error</b>,
            content: renderResultImportError(textDataNull, numberOfRowsNull, textDataLimit, numberOfRowsLimit, textRowInvalid, numberOfRowsInvalid, textRowDuplicate, numberOfRowsDuplicate, textRowLimit, numberOfLimit
                , textpmDuplicate, numberTextPMDuplicate, textMessageError, objmessageAuth, objmessageCategory),
            okText: 'OK',
            okType: 'danger',
            onOk: () => {
                // window.location.reload()
            },
            onCancel: () => {
                // window.location.reload()
            },
            className: 'modal-not-show-close'
        })
    }

    const renderResultImportError = (
        textDataNull?: string, numberOfRowsNull?: string, textDataLimit?: string, numberOfRowsLimit?: string, textRowInvalid?: string, numberOfRowsInvalid?: string,
        textRowDuplicate?: string, numberOfRowsDuplicate?: string, textRowLimit?: string, numberOfLimit?: string, textpmDuplicate?: string, numberTextPMDuplicate?: string,
        textMessageError?: string, objmessageAuth?: any, objmessageCategory?: any
    ) => {
        const text = /[!@#$%&*_+\-=[\]{};':"\\|,.<>?]/
        return <div>
            {textDataNull ? RenderText(textDataNull, numberOfRowsNull) : null}
            {textDataLimit ? RenderText(textDataLimit, numberOfRowsLimit, <span>
                <Row className='custom-tooltip'>Plan Date : Input Date only. (YYYY-MM-DD)</Row>
                <Row className='custom-tooltip'>PM Plan Approve : Please input only yes or no.</Row>
            </span>) : null}
            {textRowInvalid ? RenderText(textRowInvalid, numberOfRowsInvalid) : null}
            {textRowDuplicate ? RenderText(textRowDuplicate, numberOfRowsDuplicate) : null}
            {textpmDuplicate ? RenderText(textpmDuplicate, numberTextPMDuplicate) : null}
            {textRowLimit ? RenderText(textRowLimit, numberOfLimit, <span className='custom-tooltip'>
                <Row className='custom-tooltip'>Description : Input limit than 255 characters</Row>
                <Row className='custom-tooltip'>PM Location : Input limit than 255 characters and separate text with symbols. (,)</Row>
                <Row className='custom-tooltip'> Serial Number : Input limit than 60 characters and separate text with symbols. (,)</Row>
            </span>) : null}
            {textMessageError ? <div>{textMessageError}</div> : null}
            {Object.keys(objmessageAuth).map((key) => {
                if (JSON.stringify(objmessageAuth[key]) !== '{}') {
                    if (key === 'rowInvalidDefaultPassword' || key === 'rowDefaultPasswordIsNull') {
                        return objmessageAuth[key] ? RenderText(objmessageAuth[key].text, objmessageAuth[key].number, <span className='custom-tooltip'>
                            <Row className='custom-tooltip'>At least one upper case character (A-Z)</Row>
                            <Row className='custom-tooltip'>At least one lower case character (a-z)</Row>
                            <Row className='custom-tooltip'>At least one numeric case character (1-9)</Row>
                            <Row className='custom-tooltip'>At least one symbol case character(/[!@#$%&*_+\-=[\]{};&quot;:&ldquo;\\|,.&lt;&gt;?]/)</Row>
                            <Row className='custom-tooltip'>A minimum of 8 characters</Row>
                        </span>) : null
                    } else {
                        return objmessageAuth[key] ? RenderText(objmessageAuth[key].text, objmessageAuth[key].number) : null
                    }
                } else {
                    return null
                }
            })}
            {Object.keys(objmessageCategory).map((key) => {
                if (JSON.stringify(objmessageCategory[key]) !== '{}') {
                    return objmessageCategory[key] ? RenderText(objmessageCategory[key].text, objmessageCategory[key].number) : null
                } else {
                    return null
                }
            })}
        </div>
    }

    const showDetailsAuth = (data) => {
        const message = {
            rowCompanyAddressIsNull: {},
            rowEmailDup: {},
            rowEmailExceedLimit: {},
            rowEmailIsNull: {},
            rowEmployeeIdDup: {},
            rowEmployeeIdExceedLimit: {},
            rowEmployeeIdIsNull: {},
            rowFirstNameExceedLimit: {},
            rowFirstNameIsNull: {},
            rowInvalidCompany: {},
            rowInvalidDefaultPassword: {},
            rowInvalidDepartment: {},
            rowInvalidEmailFormat: {},
            rowInvalidFirstNameFormat: {},
            rowInvalidLastNameFormat: {},
            rowLastNameExceedLimit: {},
            rowLastNameIsNull: {},
            rowTelExceedLimit: {},
            rowTelIsNull: {},
            rowEmailDupInFile: {},
            rowEmployeeIdDupInFile: {},
            rowDefaultPasswordIsNull: {},
            rowEmailDupAnotherTenant: {}
        }
        if (data.rowCompanyAddressIsNull.length > 0) {
            message.rowCompanyAddressIsNull = {
                text: 'The company name is blank, Please give a value in the company sheet.',
                number: 'Excel Row : ' + [...new Set([...data.rowCompanyAddressIsNull])].join(', ')
            }
        }
        if (data.rowEmailDup?.length > 0) {
            message.rowEmailDup = {
                text: 'This email already exists in the system. It may be used by some person in another tenant.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmailDup])].join(', ')
            }
        }
        if (data.rowEmailExceedLimit.length > 0) {
            message.rowEmailExceedLimit = {
                text: 'Some values in these field may cause a problem, Please check your email do not enter no more than 60 characters.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmailExceedLimit])].join(', ')
            }
        }
        if (data.rowEmailIsNull.length > 0) {
            message.rowEmailIsNull = {
                text: 'Please input email.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmailIsNull])].join(', ')
            }
        }
        if (data.rowEmployeeIdDup.length > 0) {
            message.rowEmployeeIdDup = {
                text: 'Please do not use duplicate employee id.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmployeeIdDup])].join(', ')
            }
        }
        if (data.rowEmployeeIdExceedLimit?.length > 0) {
            message.rowEmployeeIdExceedLimit = {
                text: 'Some values in these field may cause a problem, Please check your employee id do not enter no more than 60 characters.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmployeeIdExceedLimit])].join(', ')
            }
        }
        if (data.rowEmployeeIdIsNull.length > 0) {
            message.rowEmployeeIdIsNull = {
                text: 'Please input employee id.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmployeeIdIsNull])].join(', ')
            }
        }
        if (data.rowFirstNameExceedLimit.length > 0) {
            message.rowFirstNameExceedLimit = {
                text: 'Some values in these field may cause a problem, Please check your first name do not enter no more than 60 characters.',
                number: 'Excel Row : ' + [...new Set([...data.rowFirstNameExceedLimit])].join(', ')
            }
        }
        if (data.rowFirstNameIsNull.length > 0) {
            message.rowFirstNameIsNull = {
                text: 'Please input firstname.',
                number: 'Excel Row : ' + [...new Set([...data.rowFirstNameIsNull])].join(', ')
            }
        }
        if (data.rowInvalidCompany.length > 0) {
            message.rowInvalidCompany = {
                text: 'Company name was wrong, There is no match of this value in your tenant.',
                number: 'Excel Row : ' + [...new Set([...data.rowInvalidCompany])].join(', ')
            }
        }
        if (data.rowInvalidDefaultPassword.length > 0) {
            message.rowInvalidDefaultPassword = {
                text: 'Please input your password in accordance with the password policy requirements.',
                number: 'Excel Row : ' + [...new Set([...data.rowInvalidDefaultPassword])].join(', ')
            }
        }
        if (data.rowInvalidDepartment.length > 0) {
            message.rowInvalidDepartment = {
                text: 'Department was wrong, There is no match of this value in your tenant.',
                number: 'Excel Row : ' + [...new Set([...data.rowInvalidDepartment])].join(', ')
            }
        }
        if (data.rowInvalidEmailFormat.length > 0) {
            message.rowInvalidEmailFormat = {
                text: 'Please input provide a valid email address format (e.g., example@example.com).',
                number: 'Excel Row : ' + [...new Set([...data.rowInvalidEmailFormat])].join(', ')
            }
        }
        if (data.rowInvalidFirstNameFormat.length > 0) {
            message.rowInvalidFirstNameFormat = {
                text: 'Please input firstname the first using letter only and enter more than 1 letters.',
                number: 'Excel Row : ' + [...new Set([...data.rowInvalidFirstNameFormat])].join(', ')
            }
        }
        if (data.rowInvalidLastNameFormat.length > 0) {
            message.rowInvalidLastNameFormat = {
                text: 'Please input lastname the first using letter only and enter more than 1 letters.',
                number: 'Excel Row : ' + [...new Set([...data.rowInvalidLastNameFormat])].join(', ')
            }
        }
        if (data.rowLastNameExceedLimit.length > 0) {
            message.rowLastNameExceedLimit = {
                text: 'Some values in these field may cause a problem, Please check your last name do not enter no more than 60 characters.',
                number: 'Excel Row : ' + [...new Set([...data.rowLastNameExceedLimit])].join(', ')
            }
        }
        if (data.rowLastNameIsNull.length > 0) {
            message.rowLastNameIsNull = {
                text: 'Please input lastname.',
                number: 'Excel Row : ' + [...new Set([...data.rowLastNameIsNull])].join(', ')
            }
        }
        if (data.rowTelExceedLimit.length > 0) {
            message.rowTelExceedLimit = {
                text: 'Some values in these field may cause a problem, Please check your phone number do not enter no more than 50 characters.',
                number: 'Excel Row : ' + [...new Set([...data.rowTelExceedLimit])].join(', ')
            }
        }
        if (data.rowTelIsNull.length > 0) {
            message.rowTelIsNull = {
                text: 'Please input phone number.',
                number: 'Excel Row : ' + [...new Set([...data.rowTelIsNull])].join(', ')
            }
        }
        if (data.rowEmailDupInFile.length > 0) {
            message.rowEmailDupInFile = {
                text: 'Please do not use duplicate email in file.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmailDupInFile])].join(', ')
            }
        }

        if (data.rowEmployeeIdDupInFile.length > 0) {
            message.rowEmployeeIdDupInFile = {
                text: 'Please do not use duplicate employee id in file.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmployeeIdDupInFile])].join(', ')
            }
        }

        if (data.rowDefaultPasswordIsNull.length > 0) {
            message.rowDefaultPasswordIsNull = {
                text: 'Please input default password.',
                number: 'Excel Row : ' + [...new Set([...data.rowDefaultPasswordIsNull])].join(', ')
            }
        }
        if (data.rowEmailDupAnotherTenant.length > 0) {
            message.rowEmailDupAnotherTenant = {
                text: 'This email already exists in the system. It may be used by some person in another tenant.',
                number: 'Excel Row : ' + [...new Set([...data.rowEmailDupAnotherTenant])].join(', ')
            }
        }
        return message
    }

    const showDetailsCategory = (data) => {
        const message = {
            dataCategoryNotImport: {},
            dataSubcategoryNotImport: {},
            dataItemsNotImport: {}
        }
        if (data.dataCategoryNotImport.length > 0) {
            message.dataCategoryNotImport = {
                text: 'Please input category.',
                number: 'Excel Row : ' + [...new Set([...data.dataCategoryNotImport])].join(', ')
            }
        }
        if (data.dataSubcategoryNotImport.length > 0) {
            message.dataSubcategoryNotImport = {
                text: 'Please input category.',
                number: 'Excel Row : ' + [...new Set([...data.dataSubcategoryNotImport])].join(', ')
            }
        }
        if (data.dataItemsNotImport.length > 0) {
            message.dataItemsNotImport = {
                text: 'Please input category and subcategory.',
                number: 'Excel Row : ' + [...new Set([...data.dataItemsNotImport])].join(', ')
            }
        }
        return message
    }

    const RenderText = (Text: string, rowNumber?: string, info?: JSX.Element) => {
        return <div>
            <p>
                <b style={{ color: '#323276', fontWeight: '600' }}>{Text}</b>
                {info ? <Tooltip placement="bottom" title={info} overlayStyle={{minWidth: 'fit-content'}} > <Icon type="info-circle" className='notify-icon' theme="filled"/></Tooltip> : null}
                <br></br>
                <b style={{ color: '#FA541C', fontWeight: '600' }}>{rowNumber}</b>
            </p>
        </div>
    }

    // const DownloadSuccess = (url) => {
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', fileName + '.csv') // or any other extension
    //     document.body.appendChild(link)
    //     link.click()
    //     document.body.removeChild(link)
    //     setFilename('')
    //     setCount(count - 1)
    //     if (props.notify) {
    //         dispatch(UpdateNotifySuccessEvent.build(count - 1))
    //         sessionStorage.removeItem('fileName')
    //     }
    // }

    const onVisibleChange = (e) => {
        setIsOpenNotify(e)
        if (e) {
            // props.getAllNotify(tokenDecode()?.userId?.toString() || '')
            getAllnotiAuth()
            dispatch(UpdateNotifySuccessEvent.build(0))
            setCount(0)
        }
    }

    const getAllnotiAuth = () => {
        getNotificationAuthByUserID(tokenDecode()?.userId?.toString() || '').then((notification) => {
            console.log(notification)
            dispatch(GetAllNotifyWebSocketSuccessEvent.build(notification))
        })
    }

    return (
        <>
            <span style={{ marginTop: 5, width: 50 }}>
                <Dropdown overlay={myNotify} trigger={['click']} placement="bottomRight" visible={isOpenNotify} onVisibleChange={onVisibleChange} >
                    <Badge count={count}>
                        <Icon type="bell" theme="filled" style={{ marginRight: 10, fontSize: 24, color: '#323276', cursor: 'pointer' }} />
                    </Badge>
                </Dropdown>
            </span>
            <span style={{ color: '#8c8c8c', fontSize: 20, marginRight: 15 }}>|</span>
            {
                sessionStorage.getItem('username') ? (
                    <Dropdown overlay={myMenu} trigger={['click']} >
                        <div style={{cursor: 'pointer'}}>
                            {props.broken
                                ? <span className="ant-dropdown-circle" style={{ marginLeft: 5 }} ><AutoAvatar title={dataUser?.pictureProfile ? JSON.parse(dataUser?.pictureProfile)[0].url : tokenDecode()?.sub || ''} broken={props.broken} username={tokenDecode()?.sub || ''} /></span>
                                : <AutoAvatar title={dataUser?.pictureProfile ? JSON.parse(dataUser?.pictureProfile)[0].url : tokenDecode()?.sub || ''} broken={props.broken} username={tokenDecode()?.sub || ''} />}

                            {props.broken ? null
                                : <span className="ant-dropdown-link" style={{ marginLeft: 5 }} >{tokenDecode()?.sub || ''} <Icon type="down" style={{ marginBottom: '5px' }} /></span>
                            }
                        </div>
                    </Dropdown>
                ) : null
            }
        </>
    )
}

export default connect(mapStateToProps, {
    getAllNotify
})(UserDetails)
