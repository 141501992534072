import React, {useEffect, useRef} from 'react'
import {Select} from 'antd'
import {SelectValue} from 'antd/es/select'
import {IncidentFilter} from '../model'
import {Categories} from '../../category'

interface Props {
    categories: Categories[]
    onChanged: (value: string[]) => void
    criteria?: IncidentFilter
    isLoading?: Function
}

export const CategoryFilter: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const myCategory: string[] = props.categories.filter((it: Categories) => { return it.active }).map(it => it.name)
    const ref = useRef<any>(null)

    useEffect(() => {
        if (props.categories) {
            props.categories.forEach((category: Categories) => {
                if (category.active === true) {
                    myCategory.push(category.name)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    const onRequesterChanged = (value: SelectValue) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Category</p>
            <Select ref={ref} mode="multiple" style={{ width: '100%' }} onChange={(e) => { onRequesterChanged(e) }} value={props.criteria?.categories?.split(',') || []} placeholder={'Select a category'} allowClear>
                {[...new Set(myCategory)].map((it, index) => {
                    return <Option key={index} value={it}>{it}</Option>
                })}
            </Select><br />
        </>
    )
}
