import {buildStateEvent, GenericStateEvent} from '../common-redux'
import { NotifyWebSocket } from './web-socket-model-notify'

export interface NotifyWebSocketStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllNotifyWebSocketSuccessEvent = {
    type: 'GetAllNotifyWebSocketSuccessEvent',
    build: (payload: NotifyWebSocket[]) => buildStateEvent(GetAllNotifyWebSocketSuccessEvent.type, payload)
}

export const AddNotifyWebSocketSuccessEvent = {
    type: 'AddNotifyWebSocketSuccessEvent',
    build: (payload: NotifyWebSocket) => buildStateEvent(AddNotifyWebSocketSuccessEvent.type, payload)
}

export const DeleteNotifyWebSocketSuccessEvent = {
    type: 'DeleteNotifyWebSocketSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteNotifyWebSocketSuccessEvent.type, payload)
}

export const UpdateNotifyWebSocketSuccessEvent = {
    type: 'UpdateNotifyWebSocketSuccessEvent',
    build: (payload: NotifyWebSocket) => buildStateEvent(UpdateNotifyWebSocketSuccessEvent.type, payload)
}
