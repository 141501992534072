import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {PriorityLevelProblem, PriorityMatrixProblem} from './model'

export interface PriorityProblemStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllPriorityProblemSuccessEvent = {
    type: 'GetAllPriorityProblemSuccessEvent',
    build: (payload: PriorityLevelProblem[]) => buildStateEvent(GetAllPriorityProblemSuccessEvent.type, payload)
}

export const GetPriorityMatrixProblemSuccessEvent = {
    type: 'GetPriorityMatrixProblemSuccessEvent',
    build: (payload: PriorityMatrixProblem) => buildStateEvent(GetPriorityMatrixProblemSuccessEvent.type, payload)
}

export const CreatePriorityMatrixNewProblemVersionSuccessEvent = {
    type: 'CreatePriorityMatrixNewProblemVersionSuccessEvent',
    build: (payload: PriorityMatrixProblem[]) => buildStateEvent(CreatePriorityMatrixNewProblemVersionSuccessEvent.type, payload)
}
