import React, {useEffect, useState} from 'react'
import {Breadcrumb, Row, Col, Card, Icon, Button, Form, Input, DatePicker, Upload, message} from 'antd'
import { Space } from '../../common-components/Space'
import '../CustomStyle.css'
import {FormComponentProps} from 'antd/lib/form'
import moment from 'moment'
import exportIcon from '../../common-file/icon-file'
import {UploadFile} from 'antd/lib/upload/interface'
import {useParams} from 'react-router'
import {getOrderById, addPayment} from '../Services/Service'
import {Order, payment} from '../Services/model'
import FSS from '../../file-server-storage'
import {UploadLink} from '../../knowledge-management/knowledge/uploads'
import {Link} from 'react-router-dom'

const { MonthPicker } = DatePicker

type Props = FormComponentProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const PaymentConfirmation = (props: Props) => {
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const { getFieldDecorator } = props.form
    const [getOrder, setOrder] = useState<Order>()
    const { id }: any = useParams()

    useEffect(() => {
        getOrderById(id).then((res) => {
            if (res) {
                setOrder(res)
            }
        })
    }, [id])

    const HerderOrder = () => {
        return <div style={{textAlign: 'center', backgroundColor: '#F2F2F2', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
            <div style={{padding: 5}}>Payment Confirmation</div>
        </div>
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                const convertData: payment = {
                    billingId: getOrder?.billing.id,
                    name: values.Name,
                    bank: values.Name,
                    phone: values.Phone,
                    transferAmount: values.TransferAmount,
                    paymentDatetime: moment(values.date).format('YYYY-MM-DD') + moment(values.time).format('HH:mm')
                }
                if (fileList.length > 0) {
                    FSS.putFile(fileList, '/knowledge/', (uploadLink: UploadLink[]) => {
                        convertData.slip = JSON.stringify(uploadLink)
                    })
                    addPayment(convertData).then((res) => {
                        if (res) {
                            message.success('Update Success')
                        }
                    }).catch((err) => {
                        message.error(err?.response?.message || 'Error Update Payment')
                    })
                } else {
                    addPayment(convertData).then((res) => {
                        if (res) {
                            message.success('Update Success')
                        }
                    }).catch((err) => {
                        message.error(err?.response?.message || 'Error Update Payment')
                    })
                }
            }
        })
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                if (fillOffFileList.length > 1) {
                    fillOffFileList.shift()
                    setFileList(fillOffFileList)
                } else {
                    setFileList(fillOffFileList)
                }
            }
            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to="/Setting">Setting</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Logo & Display Company Name</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'Box'} style={{display: 'flex', justifyContent: 'center'}}>
                <Card title={HerderOrder()} className={'mycardtest'} style={{width: 750}}>
                    <div><p>Please fill out the information below completely in order to notify the money transfer.</p></div>
                    <Form onSubmit={handleSubmit} >
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Full name</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('Name', {
                                        rules: [{ required: true, message: 'Please input your Full name!' }]
                                    })(
                                        <Input placeholder="Full name" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Phone Number</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('Phone', {
                                        rules: [{ required: true, message: 'Please input your Phone Number!' }]
                                    })(
                                        <Input placeholder="Phone Number" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Payment bank</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('bank', {
                                        rules: [{ required: true, message: 'Please input your Payment bank!' }]
                                    })(
                                        <Input placeholder="Payment bank" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Payment date</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('date', {
                                        rules: [{ required: true, message: 'Please input your Payment date!' }]
                                    })(
                                        <DatePicker placeholder="Select Date" style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Payment Time</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('time', {
                                        rules: [{ required: true, message: 'Please input your Payment Time!' }]
                                    })(
                                        <MonthPicker defaultValue={moment('2015/01', 'YYYY/MM')} format={'YYYY/MM'} style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Transfer Amount</div>
                            </Col>
                            <Col span={20}>
                                <Form.Item >
                                    {getFieldDecorator('TransferAmount', {
                                        rules: [{ required: true, message: 'Please input your Transfer Amount!' }]
                                    })(
                                        <Input placeholder="Transfer Amount" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <div className={'mart5'}><span className={'TextRed'}>* </span> Payment Slip</div>
                            </Col>
                            <Col span={20} >
                                <Form.Item style={{width: '100%'}}>
                                    {getFieldDecorator('Slip', {
                                        rules: [{ required: true, message: 'Please input your Slip!' }]
                                    })(
                                        <Upload {...uploadProps} style={{width: '100%'}}>
                                            <Button style={{width: '100%'}}>
                                                <Icon type="upload" /> Click to Upload
                                            </Button>
                                        </Upload>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{textAlign: 'end'}}>
                            <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} > <Icon type="lock" /> Submit Payment</Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

const MyPaymentConfirmation = Form.create<Props>({ name: 'PaymentConfirmation' })(PaymentConfirmation)
export default MyPaymentConfirmation
