import {
    RoleAndPermissionsStateEvent, GetAllRoleAndPermissionsSuccessEvent
    , GetAllRoleAndPermissionsOptionsSuccessEvent, DeleteRoleAndPermissionsEvent
    , UpdateRoleAndPermissionsEvent, UpdatePageRoleAndPermissionsEvent, UpdateTotalContentRoleAndPermissionsEvent,
    GetAllRoleKMSuccessEvent,
    RoleKMStateEvent
} from './state-event'
import { RoleAndPermissions, RoleAndPermissionsPaging } from './model'

export const RoleAndPermissionsStateReducer = (state: RoleAndPermissions[] = [], event: RoleAndPermissionsStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllRoleAndPermissionsSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === DeleteRoleAndPermissionsEvent.type) {
        return state.filter((role) => role.id !== event.payload)
    } else if (event.type === UpdateRoleAndPermissionsEvent.type) {
        return state.map((role) => role.id === event.payload.id ? event.payload : role)
    } else {
        return state
    }
}

export const RoleAndPermissionsOptionsStateReducer = (state: RoleAndPermissions = {}, event: RoleAndPermissionsStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllRoleAndPermissionsOptionsSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}

export const RoleAndPermissionsPagingStateReducer = (state: RoleAndPermissionsPaging = {}, event: RoleAndPermissionsStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdatePageRoleAndPermissionsEvent.type) {
        state.page = event.payload
    } else if (event.type === UpdateTotalContentRoleAndPermissionsEvent.type) {
        state.totalContent = event.payload
    } else {
        return state
    }
    return state
}

export const RoleKMStateReducer = (state: string[] = [], event: RoleKMStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllRoleKMSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
