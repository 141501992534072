import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const SelectedImageAssetContext = createContext({})

const initialState: string[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const SelectedImageAssetReducer = (state: string[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_SelectedImageAsset':
        return action.payload || [] as string[]
    default:
        return state
    }
}

export const SelectedImageAssetProvider = (props: props) => {
    const [SelectedImageAssetState, SelectedImageAssetDispatch] = useReducer(SelectedImageAssetReducer, initialState)

    const GetSelectedImageAsset = (payload: string[]) => {
        SelectedImageAssetDispatch({ type: 'GET_SelectedImageAsset', payload })
    }

    return (
        <SelectedImageAssetContext.Provider value={{ SelectedImageAssetState, GetSelectedImageAsset }}>
            {props.children}
        </SelectedImageAssetContext.Provider>
    )
}
