import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import RelateJob from '../Relate/Job/RelateJob'
import RelateAsset from '../Relate/Asset/RelateAsset'
import RelateTicket from '../Relate/Ticket/RelateTicket'
import { Asset, getAssetRelatedWo } from '../Relate/Asset'
import { AssigneesPeople, WorkOrder } from '.'
import Worklog from '../Relate/Worklog/Worklog'
import { WorkFlow } from '../../serviceCatalog/workflow/Service/Model'

interface Param {
    getJob: Function
    woId: string
    getCharging: Function
    getAsset: Function
    permission: boolean,
    ticketId: string,
    roleId?: number,
    assignedTo: AssigneesPeople[],
    vSpaceIncidentNo: string,
    woNo?: string
    selectWorkFlow?: WorkFlow
    isEdit?: boolean
    statusJob?: string
    wo?: WorkOrder
}

type Props = Param
const { TabPane } = Tabs
const RelateView: React.FC<Props> = (props: Props) => {
    const [itemJob, setItemJob] = useState<any>()
    const [key, setKey] = useState<string>('')
    const [dataAsset, setDataAsset] = useState<Asset[]>([])

    useEffect(() => {
        if (props.ticketId) {
            getAssetRelatedWo(props.ticketId).then((res) => {
                getItemAsset(res)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ticketId])

    const getItemJob = (item) => {
        props.getJob(item)
        setItemJob(item)
    }
    
    const getItemAsset = (item) => {
        setDataAsset(item)
        props.getAsset(item)
    }
    
    return (
        <>
            <br /><br /><br /><br />
            <div className="TextFrontDesc">Related</div>
            <Tabs type="card" onChange={(e) => setKey(e)}>
                <TabPane tab="Job" key="1" >
                    <RelateJob
                        getItemJob={getItemJob}
                        woId={props.woId}
                        permission={props.permission}
                        assignedTo={props.assignedTo}
                        vSpaceIncidentNo={props.vSpaceIncidentNo}
                        selectWorkFlow={props.selectWorkFlow}
                        dataAsset={dataAsset}
                        wo={props.wo}
                    />
                </TabPane>
                <TabPane tab="Asset" key="2" disabled={props.permission}>
                    <RelateAsset getItemAsset={getItemAsset} woId={props.woId} isEdit={props.isEdit} statusJob={props.statusJob} dataAsset={dataAsset} wo={props.wo}/>
                </TabPane>
                <TabPane tab="Ticket" key="3" disabled={props.permission}>
                    <RelateTicket />
                </TabPane>
                <TabPane tab="WorkLog" key="4" disabled={!props.isEdit}>
                    <Worklog woNo={props.woNo} itemJob={itemJob} key={key} permission={props.permission} isEdit={props.isEdit} statusJob={props.statusJob}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default RelateView
