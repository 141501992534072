import {Dispatch} from 'redux'
import {ResponseDetails} from '../../common-rest'
import {
    AddCategorySuccessEvent,
    AddItemsSuccessEvent,
    AddSubCategorySuccessEvent,
    DeleteCategorySuccessEvent,
    DeleteItemsSuccessEvent,
    DeleteSubCategorySuccessEvent,
    GetAllCategorySuccessEvent,
    GetAllItemsSuccessEvent,
    GetAllSubCategorySuccessEvent,
    UpdateCategorySuccessEvent,
    UpdateItemsSuccessEvent,
    UpdateSubCategorySuccessEvent
} from './state-event'
import {Categories, Category, InitSubCategory, Item, SubCategory} from './model'
import {axiosDelete, axiosFactory, axiosGet, axiosPatch, axiosPost} from '../rest'
import {checkRedirect, herderPostPatch} from '../../common-misc'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'
import {message} from 'antd'
import {convertResponseToFile} from '../../ReportComponent/components/Services/Service'

export const getAllCategoryNoRedux = async () => {
    try {
        const res = await axiosFactory().get<ResponseDetails<Category[]>>('/api/categories')
        // const res = await axiosFactory().get<ResponseDetails<SubCategory>>(`/api/subcategories/getSubcategory/${id}`)
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
    }
}

export const getAllCategory = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosFactory().get<ResponseDetails<Categories[]>>('/api/categories')
            if (response.data.data) {
                dispatch(GetAllCategorySuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err) {
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function getCategoryById(id: string): Promise<Categories> {
    try {
        const res = await axiosFactory().get<ResponseDetails<Categories>>(`/api/categories/${id}`)
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const addCategory = (category: Categories) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(category))
            const response = await axiosPost<Categories>('/api/categories', encryptData, herderPostPatch)
            console.log(response.data)
            dispatch(AddCategorySuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response?.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const updateCategory = (patch: Categories) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPatch<Categories>(`/api/categories/${id}`, encryptData, herderPostPatch)
            dispatch(UpdateCategorySuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteCategory = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/categories/${id}`)
            dispatch(DeleteCategorySuccessEvent.build(id))
            if (response.status === 200) {
                message.success('Delete Category successfully.')
            }
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// export const importExcel = (category : any) => {
//     return async () => {
//         try {
//             const encryptData = encryptBody(JSON.stringify(category))
//             const response = await axiosPost<any>('/api/categories/importCategory', encryptData, herderPostPatch)
//             // dispatch(AddCategorySuccessEvent.build(decryptBody(encryptData)))
//             return response.status
//         } catch (err) {
//             console.log(err)
//             checkRedirect(err.response.status)
//             throw err
//         }
//     }
// }

export const importExcelCategoryFromBackend = async (category : any) => {
    try {
        const encryptData = encryptBody(JSON.stringify(category))
        const response = await axiosPost<any>('/api/categories/import', encryptData, herderPostPatch)
        return response.status
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

// export const exportExcel = async () => {
//     try {
//         const response = await axiosGet<any>('/api/categories/exportCategory', herderPostPatch)
//         const exportData = decryptBody(response.data.data)
//         return exportData
//     } catch (err) {
//         console.log(err)
//         checkRedirect(err.response.status)
//         throw err
//     }
// }

export const exportTempleteCategory = async () => {
    try {
        const response = await axiosGet<any>('/api/categories/template', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, 'Templete_Import_Category.xlsx')
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

// ============================================================================================
// ===================================== SubCategory ==========================================
// ============================================================================================

export const getAllSubCategory = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosFactory().get<ResponseDetails<SubCategory[]>>('/api/subcategories')
            if (response.data.data) {
                // console.log(decryptBody(response.data.data!!))
                dispatch(GetAllSubCategorySuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err) {            
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// export async function getSubCategoryById(id: string): Promise<SubCategory> {
//     try {
//         const res = await axiosFactory().get<ResponseDetails<SubCategory>>(`/api/subcategories/getSubcategory/${id}`)
//         return decryptBody(res.data.data!!)
//     } catch (error) {
//         console.log(error)
//         checkRedirect(error.response.status)
//         throw error
//     }
// }

export const addSubCategory = (subCategories: InitSubCategory) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(subCategories))
            const response = await axiosPost('/api/subcategories', encryptData, herderPostPatch)
            const body: InitSubCategory = decryptBody(response.data.data)
            const bodySubCategories: SubCategory = {
                id: body.id,
                subCategory: body.name,
                categoryId: body.category.id,
                category: body.category.name,
                lastModifiedDatetime: body.lastModifiedDatetime,
                active: body.active
            }
            dispatch(AddSubCategorySuccessEvent.build(bodySubCategories))
            return response?.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const updateSubCategory = (patch: InitSubCategory) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPatch(`/api/subcategories/${id}`, encryptData, herderPostPatch)
            const body = decryptBody(response.data.data)
            const bodySubCategories: SubCategory = {
                id: body.id,
                subCategory: body.name,
                categoryId: body.category.id,
                category: body.category.name,
                lastModifiedDatetime: body.lastModifiedDatetime,
                active: body.active
            }
            dispatch(UpdateSubCategorySuccessEvent.build(bodySubCategories))
            return response.status
        } catch (err) {
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteSubCategory = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete(`/api/subcategories/${id}`)
            dispatch(DeleteSubCategorySuccessEvent.build(id))
            if (response.status === 200) {
                message.success('Delete Subcategory successfully.')
            }
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// ============================================================================================
// ======================================== Items =============================================
// ============================================================================================

export const getAllItems = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosFactory().get<ResponseDetails<Item[]>>('/api/subcategories/items')
            if (response.data.data) {
                dispatch(GetAllItemsSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err) {
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// export async function getItemsById(id: string): Promise<Item> {
//     try {
//         const res = await axiosFactory().get<ResponseDetails<Item>>(`/api/subcategories/items/${id}`)
//         return decryptBody(res.data.data!!)
//     } catch (error) {
//         console.log(error)
//         checkRedirect(error.response.status)
//         throw error
//     }
// }

export const addItems = (item: InitSubCategory) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(item))
            const response = await axiosPost('/api/subcategories', encryptData, herderPostPatch)
            const body: InitSubCategory = decryptBody(response.data.data)
            const bodyItems: Item = {
                id: body.id,
                item: body.name,
                categoryId: body.category.id,
                category: body.category.name,
                lastModifiedDatetime: body.lastModifiedDatetime,
                active: body.active,
                subCategory: body.subcategory?.name || '',
                subcategoryId: body.subcategory?.id || 0
            }
            dispatch(AddItemsSuccessEvent.build(bodyItems))
            return response?.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const updateItems = (patch: InitSubCategory) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            const encryptData = encryptBody(JSON.stringify(patch))
            console.log(encryptData)
            const response = await axiosPatch(`/api/subcategories/${id}`, encryptData, herderPostPatch)
            const body: InitSubCategory = decryptBody(response.data.data)
            const bodyItems: Item = {
                id: body.id,
                item: body.name,
                categoryId: body.category.id,
                category: body.category.name,
                lastModifiedDatetime: body.lastModifiedDatetime,
                active: body.active,
                subCategory: body.subcategory?.name || '',
                subcategoryId: body.subcategory?.id || 0
            }
            dispatch(UpdateItemsSuccessEvent.build(bodyItems))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteItems = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/subcategories/${id}`)
            dispatch(DeleteItemsSuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// ============================================================================================

// export const addMultipleCategory = (category: Category[]) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const response = await axiosPost<Category[]>('/api/categories/saveAll', category)
//             dispatch(AddCategoryByExcelSuccessEvent.build(decryptBody(response.data.data!!)))
//             return response.status
//         } catch (err) {
//             console.log(err)
//             checkRedirect(err.response.status)
//             throw err
//         }
//     }
// }

// export const deleteMultipleCategory = (category: Category[]) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const response = await axiosPost<Category[]>('/api/categories/deleteAll', category)
//             // dispatch(DeleteAllCategorySuccessEvent.build(category))
//             return response.status
//         } catch (err) {
//             console.log(err)
//             checkRedirect(err.response.status)
//             throw err
//         }
//     }
// }

// export const getAllCategoriesWithPagination = (page: number, pageSize: number, searchField?: string, searchValue?: string, active?: boolean[], sortBy?: string, orderBy?: string) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const response = await axiosGetWithPagination<Category[]>(`/api/categories/getAllCategories?page=${page}&pageSize=${pageSize}&searchField=${searchField}&searchValue=${searchValue}&active=${active}&sortBy=${sortBy}&orderBy=${orderBy}`)
//             const decryptResponse = decryptBody(response.data.data!!)
//             dispatch(GetAllCategorySuccessEvent.build(decryptResponse.content || []))
//             dispatch(UpdateCategoryTotalPageSuccessEvent.build(decryptResponse.totalElements || decryptResponse.total))
//             dispatch(UpdateCategoryCurrentPageSuccessEvent.build(decryptResponse.number || decryptResponse?.pageable?.page))
//             return response.status
//         } catch (err) {
//             console.log(err.response)
//             checkRedirect(err.response.status)
//             throw err
//         }
//     }
// }

const convertArrayStringToCategory = (value: string[]): Category => {
    const tempCat: Category = {
        id: Number(value[0]),
        createdBy: value[1],
        createdDatetime: value[2],
        lastModifiedBy: value[3],
        lastModifiedDatetime: value[4],
        category: value[5],
        subCategory: value[6],
        item: value[7],
        active: Boolean(value[8])
    }
    return tempCat
}

export const RollbackDataFromFileToCategoryForExcel = (data: any[]): Category[] => {
    data.shift()
    const allCat: Category[] = []
    data.forEach((it) => {
        if (it) {
            const tempCat = convertArrayStringToCategory(it)
            const check = allCat.some(it => it.category === tempCat.category && it.subCategory === tempCat.subCategory && it.item === tempCat.item)
            if (!check) {
                allCat.push(tempCat)
            }
        }
    })
    return allCat
}
