import { Checkbox, Modal, Icon } from 'antd'
import React, { useContext, useState } from 'react'
import { pmUploadLink } from '../../Model'
import { PhotoView } from 'react-photo-view'
import { SelectedImageAssetContext } from './ContextAPI/SelectedImageAsset'
import { SelectedImagePMContext } from './ContextAPI/SelectedImagePM'
interface Param {
    imageDetail: pmUploadLink
}

const CheckboxImage = (props: Param) => {
    const { SelectedImageAssetState } = useContext<any>(SelectedImageAssetContext)
    const { SelectedImagePMState } = useContext<any>(SelectedImagePMContext)
    
    return (
        <> 
            <div className={SelectedImageAssetState.concat(...SelectedImagePMState).includes(props.imageDetail.url) ? 'sync-pm-picture-frame-selected' : 'sync-pm-picture-frame'}>
                <Checkbox className='sync-pm-checkbox' value={props.imageDetail.url} key={props.imageDetail.url} />
                <PhotoView src={props.imageDetail.url!!}>
                    <div className={'sync-pm-img-div'}>
                        <img src={props.imageDetail.url!!} className={'sync-pm-img'} />
                    </div>
                </PhotoView>
            </div>
            {/* <Modal visible={previewImage !== undefined} footer={null} onCancel={() => setPreviewImage(undefined)}>
                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
        </>
    )
}

export default CheckboxImage