import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const PendingReasonContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const PendingReasonReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_PendingReason':
        sessionStorage.setItem('pending_reason', JSON.stringify(action.payload))
        return action.payload
    case 'ADD_PendingReason':
        return [...state, action.payload]
    default:
        return state
    }
}

export const PendingReasonProvider = (props: props) => {
    const [PendingReasonState, PendingReasonDispatch] = useReducer(PendingReasonReducer, initialState)

    const GetPendingReason = (payload) => {
        PendingReasonDispatch({ type: 'GET_PendingReason', payload })
    }

    const AddPendingReason = (payload) => {
        PendingReasonDispatch({ type: 'ADD_PendingReason', payload })
    }

    return (
        <PendingReasonContext.Provider value={{ PendingReasonState, GetPendingReason, AddPendingReason }}>
            {props.children}
        </PendingReasonContext.Provider>
    )
}
