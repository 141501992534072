import React from 'react'
import {Col, Collapse, Icon} from 'antd'
import Menu from 'antd/lib/menu'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'

interface Props {
    selectFolder: Function
    getKeyView: Function
    countKnowledge? : number
    countServiceRequest? : number
    countWorkOrder? : number
    countProblems? : number
}

const {Panel} = Collapse

export const ApprovalCategories: React.FC<Props> = (props: Props) => {
    const onClickMenu = (e) => {
        props.getKeyView(e.key)
    }

    return (
        <Col span={6} xs={24} sm={24} md={24} lg={24} xl={6}>
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Approval Category" key="1">
                    <Menu mode="inline" onClick={(e) => onClickMenu(e)}>
                        {checkRolesState(RoleType.Knowledge, 'ApproveOrRejectKnowledge')
                            ? <Menu.Item key='1'>
                                <span style={{ color: sessionStorage.getItem('vap') === '1' ? '#FA541C' : 'rgba(0, 0, 0, 0.65)'}}><Icon type="bulb" /> Knowledge ({props.countKnowledge || 0})</span>
                            </Menu.Item>
                            : null}
                        {checkRolesState(RoleType.WO, 'ApproveOrRejectWorkOrder')
                            ? <Menu.Item key='2' >
                                <span style={{ color: sessionStorage.getItem('vap') === '2' ? '#FA541C' : 'rgba(0, 0, 0, 0.65)'}}><Icon type="hdd" /> Service Request ({props.countServiceRequest || 0})</span>
                            </Menu.Item>
                            : null}
                        {checkRolesState(RoleType.WO, 'ApproveOrRejectJob')
                            ? <Menu.Item key='3' >
                                <span style={{ color: sessionStorage.getItem('vap') === '3' ? '#FA541C' : 'rgba(0, 0, 0, 0.65)'}}><Icon type="profile" /> Job ({props.countWorkOrder || 0})</span>
                            </Menu.Item>
                            : null}
                        <Menu.Item key='4' >
                            <span style={{ color: sessionStorage.getItem('vap') === '4' ? '#FA541C' : 'rgba(0, 0, 0, 0.65)'}}><Icon type="laptop" /> Problems ({props.countProblems || 0})</span>
                        </Menu.Item>
                        {/* <Menu.Item key='5' >
                            <span style={{ color: sessionStorage.getItem('vap') === '5' ? '#FA541C' : 'rgba(0, 0, 0, 0.65)'}}><Icon type="laptop" /> Change ({props.countWorkOrder || 0})</span>
                        </Menu.Item> */}
                    </Menu>
                </Panel>
            </Collapse>
        </Col>
    )
}
