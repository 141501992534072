import { axiosGet, axiosPost } from '../../../problem-management/rest'
import {
    GetAllCustomFiledProblemSuccessEvent
} from './state-event'
import {Dispatch} from 'redux'
import {CustomFiledProblem} from './model'
import { decryptBody, encryptBody } from '../../../common-misc/encryptBody'
import { herderPostPatch } from '../../../common-misc'

export const getAllCustomFieldProblem = (value: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<CustomFiledProblem>('/api/custom-fields/' + value + '/lists')
            dispatch(GetAllCustomFiledProblemSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addOrUpdateCustomFieldsProblem = async (customField: CustomFiledProblem[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(customField))
        const response = await axiosPost<CustomFiledProblem>('/api/custom-fields/setting', encryptData, herderPostPatch)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}
