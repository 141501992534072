import { CategoryStateEvent, GetAllCategorySuccessEvent } from './state-event'
import { Category } from './model'

export const CategoryStateReducer = (state: Category[] = [], event: CategoryStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllCategorySuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
