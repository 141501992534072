import { WithVersion } from '../common-model'
import { SelectValue } from 'antd/es/select'
import moment from 'moment'
import 'moment-duration-format'
import crypto from 'crypto'
import {LoginInfo} from '../authorization-module/vSpace-login/model'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import UserShareData from '../authorization-module/login/UserShareData'
import XLSX from 'sheetjs-style'
import { Modal, message } from 'antd'
import {deleteCookie, getCookie} from '../guard/route'
import { decryptBodyToken } from './encryptBody'
import {axiosDelete} from "../authorization-module/rest";

export interface accessToken {
    sub: string
    service: {
        packageId: number
        serviceId: number[]
        expireDate: number
    }
    tenantID: string
    permission: permissionToken[]
    roleLevel: number
    chatGptKey: string
    exp: number
    iat: number
    isGenQRCode?: boolean
    userId: number
    groups: string[]
}

export interface permissionToken {
    security: permissionDetail
    incident: permissionDetail
    knowledge: permissionDetail
    asset: permissionDetail
    pm: permissionDetail
    serviceCatalog: permissionDetail
    selectedGroup: string[]
}

export interface permissionDetail {
    permissions: string[]
    scope: string
}

export interface groupDataStorage {
    userId: string,
    name: string,
    userRoles: string,
    role: string,
    time: string,
    service: string
}

export const herderPostPatch = {
    headers: {
        'Content-Type': 'text/plain'
    }
}

export const tokenDecode = (): accessToken | null => {
    if (getCookie('access_token')) {
        const decoded: any = jwt_decode(UserShareData.getInstance().token || getCookie('access_token')!!)
        return decoded as accessToken
    } else {
        return null
    }
}

// export const getDataMSPPlaybackState = (): groupDataStorage | null => {
//     const data = sessionStorage.getItem('msp_playback_state')
//     if (data) {
//         const decoded: any = decrypt(JSON.parse(data))
//         console.log(decoded)
//         return JSON.parse(decoded) as groupDataStorage
//     } else {
//         return null
//     }
// }

export const resolveOrderDirectionLabel = (orderDirection: string) => ({
    asc: 'Ascending',
    desc: 'Descending',
    DESC: 'Descending',
    ASC: 'Ascending'
})[orderDirection]

export function toDistinctArray<T>(array: T[]): T[] {
    return [...new Set(array)]
}

export function getVersion<T>(obj: any): T | undefined {
    return (obj as WithVersion<T>).version
}

export function nullOrEmpty(array: Array<any> | null | undefined): boolean {
    return (array === null || array === undefined || array.length === 0)
}

export function notEmpty(array: Array<any> | null | undefined): boolean {
    return !nullOrEmpty(array)
}

export function nullOrEmptyMap(map: Map<any, any> | null | undefined): boolean {
    return (map === null || map === undefined || map.size === 0)
}

export function notEmptyMap(map: Map<any, any> | null | undefined): boolean {
    return !nullOrEmptyMap(map)
}

export function nullOrEmptyObject(map: {} | null | undefined): boolean {
    return (map === null || map === undefined || Object.keys(map).length === 0)
}

export function notEmptyObject(map: Map<any, any> | null | undefined): boolean {
    return !nullOrEmptyObject(map)
}

export const SelectValueFunc = {
    toStringArray: (value: SelectValue): string[] => {
        if (value === undefined) {
            return []
        } else if (typeof value === 'string') {
            return [value]
        } else {
            return value as string[]
        }
    }
}

export function cloneObject<T>(object: T) {
    return JSON.parse(JSON.stringify(object)) as T
}

export function parseJavaDuration(s: string) {
    // @ts-ignore
    console.log(moment.duration(s).format({
        template: 'd [days] h [hours] m [mins] s [secs]',
        trim: 'left'
    }))
}

function CheckProperty(a: any, b: any, property: any) {
    if (a[property] > b[property]) {
        return 1
    } else {
        return 0
    }
}

export function SortArrayObjectByKey(property) {
    let sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        const result = (a[property] < b[property]) ? -1 : CheckProperty(a, b, property)
        return result * sortOrder
    }
}

export function countLines(ElementId: string): number {
    const el = document.getElementById(ElementId) as HTMLInputElement
    if (el) {
        const lines = (el.innerText).split('\n')
        return lines.length
    } else {
        return 0
    }
}

export function CutTextToReadMore(lineNumber:number): Object {
    return {overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: lineNumber, WebkitBoxOrient: 'vertical'}
}

const algorithm = 'aes-256-ctr'
const secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
const iv = crypto.randomBytes(16)

export const encrypt = (text) => {
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv)

    const encrypted = Buffer.concat([cipher.update(text), cipher.final()])

    return {
        iv: iv.toString('hex'),
        content: encrypted.toString('hex')
    }
}

export const decrypt = (hash) => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'))

    const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()])

    return decrpyted.toString()
}

export const decryptDataVspace = () => {
    const rawData = sessionStorage.getItem('dataVspace')
    if (rawData) {
        return JSON.parse(decrypt(JSON.parse(rawData))) as LoginInfo
    }
}

export const encodeSpecialCharacter = (c: string) => {
    let newStr = ''
    for (let i = 0; i < c.length; i++) {
        if (c[i] === '&') {
            newStr += '%26'
        } else if (c[i] === '+') {
            newStr += '%2B'
        } else if (c[i] === '#') {
            newStr += '%23'
        } else if (c[i] === '%') {
            newStr += '%25'
        } else if (c[i] === '{') {
            newStr += '%7B'
        } else if (c[i] === '}') {
            newStr += '%7D'
        } else if (c[i] === '|') {
            newStr += '%7C'
        } else if (c[i] === '^') {
            newStr += '%5E'
        } else if (c[i] === '[') {
            newStr += '%5B'
        } else if (c[i] === ']') {
            newStr += '%5D'
        } else if (c[i] === '`') {
            newStr += '%60'
        } else if (c[i] === '\\') {
            newStr += '%5C'
        } else {
            newStr += c[i]
        }
    }
    return newStr
}

export const disableByStatus = (currentStatus, setDisabledState: Function, assignee, supportTeam) => {
    if ((currentStatus === 'Resolved' || currentStatus === 'Closed') && !(assignee && supportTeam)) {
        setDisabledState(false)
    } else if (currentStatus === 'Resolved' || currentStatus === 'Closed' || currentStatus === 'Cancelled' || currentStatus === 'Pending') {
        setDisabledState(true)
    } else {
        setDisabledState(false)
    }
}

export const disableByStatusForIncidentDetailView = (currentStatus, setDisabledState: Function, assignee, supportTeam, setDisableAddResolution: Function) => {
    if ((currentStatus === 'Resolved' || currentStatus === 'Closed') && !(assignee && supportTeam)) {
        setDisabledState(false)
        setDisableAddResolution(false)
    } else if (currentStatus === 'Resolved' && (assignee && supportTeam)) {
        setDisableAddResolution(false)
    } else if (currentStatus === 'Resolved' || currentStatus === 'Closed' || currentStatus === 'Cancelled' || currentStatus === 'Pending') {
        setDisabledState(true)
        setDisableAddResolution(true)
    } else {
        setDisabledState(false)
        setDisableAddResolution(false)
    }
}

function error() {
    Modal.error({
        title: 'This is an error message',
        content: 'Token is Expired!!',
        onOk: async () => {
            await axiosDelete('/api/security/deleteToken').finally(() => {
                deleteCookie('access_token')
                sessionStorage.clear()
                window.location.href = '/'
            })
            Modal.destroyAll()
        }
    })
}

export const checkRedirect = (status: number) => {
    if (status === 401 || status === 403) {
        const getClass = document.getElementsByClassName('ant-modal-content')
        if ((!getClass || getClass?.length === 0) && document.hasFocus()) {
            error()
            setTimeout(() => {
                deleteCookie('access_token')
                sessionStorage.clear()
                window.location.href = '/'
            }, 5000)
        }
    }
}

export function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
}

export const replaceSpae = (name: string) => {
    return name.replace(/ /g, '')
}

export const DownloadAnyFile = (file: any) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = file.url || file.preview
    downloadLink.download = file.name || ''

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
}

export const SearchParamsURL = (key: string): string | null => {
    const params = new URLSearchParams(window.location.search)
    const search = params.get(key)
    return search
}

export const fixWidthExcel = (data: any[], worksheet: XLSX.WorkSheet): XLSX.ColInfo[] => {
    const colLengths = Object.keys(data[0]).map((k) => k?.toString().length)
    for (const d of data) {
        Object.values(d).forEach((element: any, index) => {
            const length = element?.toString().length
            if (colLengths[index] < length) {
                colLengths[index] = length
            }
        })
    }
    const ColInfoData = worksheet['!cols'] = colLengths.map((l) => {
        return {
            wch: l + 2
        }
    })
    return ColInfoData
}

export function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
}
