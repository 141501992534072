export default class TenantShareData {
    private static instance: TenantShareData
    tenant?: string = undefined

    static getInstance(): TenantShareData {
        if (!TenantShareData.instance) {
            TenantShareData.instance = new TenantShareData()
        }
        return TenantShareData.instance
    }
}
