import React, {useEffect, useState} from 'react'
import {Row, Col, Breadcrumb, Card, Icon, Checkbox, InputNumber, Form, message, Button, Carousel} from 'antd'
import {Space} from '../../common-components/Space'
import '../CustomStyle.css'
import {FormComponentProps} from 'antd/lib/form'
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router'
import {
    createOrder, getAllOrderNoRedux,
    getAllPackageMaster,
    getTenantProfileByTenantId
} from '../Services/Service'
import {Order, packages, tenantProfile, userPackage} from '../Services/model'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import { tokenDecode } from '../../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        orders: state.orders
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

type props = FormComponentProps & StateProps
const choosePackagesUser: userPackage[] = []
const choosePackagesPeople: userPackage[] = []
const choosePackagesDatabase: userPackage[] = []
const choosePackagesStorage: userPackage[] = []
const PackageDetail = (props: props) => {
    const { getFieldDecorator, getFieldValue } = props.form
    const [selectPackage, setSelectPackage] = useState<string>()
    const [selectPrice, setSelectPrice] = useState<number>(0)
    const [isDisable, setIsDisable] = useState(true)
    const [packageMaster, setPackageMaster] = useState<packages[]>([])
    const [packageUser, setPackageUser] = useState<packages[]>([])
    const [packagePeople, setPackagePeople] = useState<packages[]>([])
    const [packageDatabase, setPackageDatabase] = useState<packages[]>([])
    const [packageStorage, setPackageStorage] = useState<packages[]>([])
    const [choosePackages, setChoosePackages] = useState<userPackage[]>([])
    const [total, setTotal] = useState<number>(0)
    const [isRedirect, setIsRedirect] = useState(false)
    const [dataTenant, setDataTenant] = useState<tenantProfile>()
    const [dataIdCreate, setDataIdCreate] = useState<number>()
    const [, setOrder] = useState<Order>()
    const [isEdit, setIsEdit] = useState(false)
    const TenantId = tokenDecode()?.tenantID || '0'

    useEffect(() => {
        getAllPackageMaster().then((res) => {
            if (res) {
                const filterMainPackage = res.filter((it) => it.packageType === 'm')
                const filterUserPackage = res.filter((it) => it.packageType === 'u')
                const filterPeoplePackage = res.filter((it) => it.packageType === 'p')
                const filterDatabasePackage = res.filter((it) => it.packageType === 'd')
                const filterStoragePackage = res.filter((it) => it.packageType === 's')
                setPackageMaster(filterMainPackage)
                setPackageUser(filterUserPackage)
                setPackagePeople(filterPeoplePackage)
                setPackageDatabase(filterDatabasePackage)
                setPackageStorage(filterStoragePackage)
            }
        }).catch((err) => {
            message.error(err?.response?.message || 'Error get Packages Master')
        })

        getTenantProfileByTenantId().then((res) => {
            if (res) {
                setDataTenant(res)
            }
        }).catch((err) => {
            message.error(err?.response?.message || 'error get profile')
        })

        if (props.orders.length !== 0) {
            props.orders.forEach((order) => {
                if (order) {
                    const filterMainPackage = order.packageSubscription.find((it) => it.packages.packageType === 'm' && it.isEnable === true)
                    if (filterMainPackage) {
                        setSelectPackage(filterMainPackage.packages.packageName)
                        setSelectPrice(filterMainPackage.packages.price)
                        setIsDisable(false)
                        setOrder(order)
                        setTotal(order.billing.amount)
                        setIsEdit(true)
                    }
                }
            })
        } else {
            getAllOrderNoRedux().then((res) => {
                if (res) {
                    res.forEach((order) => {
                        if (order) {
                            const filterMainPackage = order.packageSubscription.find((it) => it.packages.packageType === 'm' && it.isEnable === true)
                            if (filterMainPackage) {
                                setSelectPackage(filterMainPackage.packages.packageName)
                                setSelectPrice(filterMainPackage.packages.price)
                                setIsDisable(false)
                                setOrder(order)
                                setTotal(order.billing.amount)
                                setIsEdit(true)
                            }
                        }
                    })
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let price: number = selectPrice
        if (packageStorage.length !== 0) {
            let convertPackageStorage: userPackage
            packageStorage.forEach((ps, index) => {
                if (getFieldValue('Storage' + index)) {
                    price += getFieldValue('Storage' + index) * ps.price
                    convertPackageStorage = {
                        packages: ps,
                        tenantId: Number(TenantId),
                        startDate: ps.startDate,
                        endDate: ps.endDate,
                        durationDay: ps.durationDay,
                        subscriptionTotal: getFieldValue('Storage' + index),
                        price: getFieldValue('Storage' + index) * ps.price,
                        isEnable: true
                    }
                    choosePackagesStorage.push(convertPackageStorage)
                }
            })
        }
        if (packageDatabase.length !== 0) {
            let convertPackageDatabase: userPackage
            packageDatabase.forEach((pd, index) => {
                if (getFieldValue('Database' + index)) {
                    price += getFieldValue('Database' + index) * pd.price
                    convertPackageDatabase = {
                        packages: pd,
                        tenantId: Number(TenantId),
                        startDate: pd.startDate,
                        endDate: pd.endDate,
                        durationDay: pd.durationDay,
                        subscriptionTotal: getFieldValue('Storage' + index),
                        price: getFieldValue('Storage' + index) * pd.price,
                        isEnable: true
                    }
                    choosePackagesDatabase.push(convertPackageDatabase)
                }
            })
        }
        if (packageUser.length !== 0) {
            let convertPackageUser: userPackage
            packageUser.forEach((pu, index) => {
                if (getFieldValue('User' + index)) {
                    price += getFieldValue('User' + index) * pu.price
                    convertPackageUser = {
                        packages: pu,
                        tenantId: Number(TenantId),
                        startDate: pu.startDate,
                        endDate: pu.endDate,
                        durationDay: pu.durationDay,
                        subscriptionTotal: getFieldValue('Storage' + index),
                        price: getFieldValue('Storage' + index) * pu.price,
                        isEnable: true
                    }
                    choosePackagesUser.push(convertPackageUser)
                }
            })
        }
        if (packagePeople.length !== 0) {
            let convertPackagePeople: userPackage
            packagePeople.forEach((pp, index) => {
                if (getFieldValue('People' + index)) {
                    price += getFieldValue('People' + index) * pp.price
                    convertPackagePeople = {
                        packages: pp,
                        tenantId: Number(TenantId),
                        startDate: pp.startDate,
                        endDate: pp.endDate,
                        durationDay: pp.durationDay,
                        subscriptionTotal: getFieldValue('Storage' + index),
                        price: getFieldValue('Storage' + index) * pp.price,
                        isEnable: true
                    }
                    choosePackagesPeople.push(convertPackagePeople)
                }
            })
        }
        setTotal(price)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form, selectPrice])

    const HerderCard = (Package, price) => {
        return <div style={{textAlign: 'center', backgroundColor: RenderColor(Package), borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
            <p style={{paddingTop: 5}}><h2 style={{color: '#ffffff'}}>{Package}</h2></p>
            <div style={{color: '#ffffff', paddingBottom: 5}}>
                <span style={{fontSize: 16}}>
                    ฿ {price}
                </span>
                <span style={{fontSize: 16}}>
                    /month
                </span>
            </div>
        </div>
    }

    const HerderOrder = () => {
        return <div style={{backgroundColor: '#F2F2F2', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
            <div style={{padding: 5}}>Order</div>
        </div>
    }

    const RenderColor = (Package): string => {
        let colorCode = '#323276'
        if (isEdit) {
            if (selectPackage) {
                if (Package === selectPackage) {
                    colorCode = '#f89576'
                } else {
                    colorCode = '#afadad'
                }
            }
        } else {
            if (selectPackage) {
                if (Package === selectPackage) {
                    colorCode = '#FA541C'
                } else {
                    colorCode = '#767676'
                }
            }
        }
        return colorCode
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                const packageSubscription: userPackage[] = choosePackages.concat(choosePackagesStorage[choosePackagesStorage.length - 1], choosePackagesDatabase[choosePackagesDatabase.length - 1],
                    choosePackagesUser[choosePackagesUser.length - 1], choosePackagesPeople[choosePackagesPeople.length - 1])
                const packageSubscriptionFilter = packageSubscription.filter((it) => it !== undefined)
                const order: Order = {
                    billing: {
                        billingName: dataTenant?.firstname + ' ' + dataTenant?.lastname || '',
                        billingAddress: dataTenant?.billingAddress || '',
                        billingPhone: dataTenant?.phone || '',
                        amount: total,
                        paymentMethod: 'mobile banking',
                        billingCompany: dataTenant?.companyName || ''
                    },
                    status: 'PendingPayment',
                    packageSubscription: packageSubscriptionFilter
                }

                createOrder(order).then((res) => {
                    if (res) {
                        setDataIdCreate(res.id)
                        setIsRedirect(true)
                    }
                }).catch((err) => {
                    message.error(err?.response?.message || 'Error Create Order')
                })

                // if (isEdit) {
                //     updateOrder(getOrder?.id!!, order).then((res) => {
                //         if (res) {
                //             setDataIdCreate(res.id)
                //             setIsRedirect(true)
                //         }
                //     }).catch((err) => {
                //         message.error(err?.response?.message || 'Error Update Order')
                //     })
                // } else {
                //
                // }
            }
        })
    }

    const getPackage = (Package: packages) => {
        if (isDisable) {
            const convertData: userPackage = {
                packages: Package,
                tenantId: Number(TenantId),
                startDate: Package.startDate,
                endDate: Package.endDate,
                durationDay: Package.durationDay,
                subscriptionTotal: 1,
                price: Package.price,
                isEnable: true
            }
            setChoosePackages([convertData])
            setSelectPackage(Package.packageName)
            setSelectPrice(Package.price)
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to="/Setting">Setting</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Subscription</Breadcrumb.Item>
            </Breadcrumb>
            {isRedirect ? <Redirect to={'/OrderDetail/' + dataIdCreate}/> : null}
            <Space size={10} />
            <div className={'Box'}>
                <div><p className={'HerderText'}>My Package</p></div>
                <div><p className={'DescText'}>Choose the plan that’s right for you</p></div>
                <Space size={10} />
                <Carousel autoplay={true} dotPosition={'bottom'}>
                    <Row style={{width: '80%', margin: 'auto'}}>
                        {packageMaster.map((pm, index) => {
                            return <Col span={8} className={'position-card'} key={index}>
                                <Card title={HerderCard(pm.packageName, pm.price)} style={{ width: 250 }} hoverable={isDisable} className={'mycardtest'} onClick={() => getPackage(pm)} >
                                    <div className={'my-body-card'}>
                                        <div>{pm.quotaMaster.limitGoServiceUser}</div>
                                        <p>User</p>
                                        <div>{pm.quotaMaster.limitServicePortal}</div>
                                        <p>People</p>
                                        <hr/>
                                        <Row gutter={[8, 8]}>
                                            <Col span={12}><Icon type="check" style={{color: '#52C41A'}} /> Storage {pm.quotaMaster.limitStorageSize} G</Col>
                                            <Col span={12}><Icon type="check" style={{color: '#52C41A'}} /> DB {pm.quotaMaster.limitDbSize} G</Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        })}
                    </Row>
                </Carousel>
                <Space size={20} />
                <div style={{color: '#323276', fontWeight: 500}}>Get add-ons that help you do more with GO-Service </div>
                <Space size={10} />
                <Form onSubmit={handleSubmit} >
                    <Row>
                        <Col span={14}>
                            <p style={{color: '#323276', fontWeight: 400}}>· Storage</p>
                            {packageStorage.map((ps, index) => {
                                return <div className={!isDisable ? 'card-service-package-active' : getFieldValue('checkStorage' + index) ? 'card-service-package-active' : 'card-service-package-InActive'} key={index}>
                                    <Row>
                                        <Col span={12} >
                                            {isDisable ? <Form.Item >
                                                {getFieldDecorator('checkStorage' + index)(
                                                    <Checkbox style={{marginLeft: 10}} >Storage size ({ps.quotaMaster.limitStorageSize} Gb per unit)</Checkbox>
                                                )}
                                            </Form.Item> : <div style={{margin: '10px 5px'}}>Storage size ({ps.quotaMaster.limitStorageSize} Gb per unit)</div>}
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item >
                                                {getFieldDecorator('Storage' + index, {
                                                    initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 's')?.subscriptionTotal
                                                })(
                                                    <InputNumber min={0} max={1000} disabled={!isDisable ? false : !getFieldValue('checkStorage' + index)}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={9} style={{textAlign: 'center', marginTop: 10}}>
                                            <div>Unit ({ps.price} Baht per unit)</div>
                                        </Col>
                                    </Row>
                                </div>
                            })}
                            <Space size={10} />
                            {packageDatabase.map((pd, index) => {
                                return <div className={!isDisable ? 'card-service-package-active' : getFieldValue('checkDatabase' + index) ? 'card-service-package-active' : 'card-service-package-InActive'} key={index}>
                                    <Row>
                                        <Col span={12} >
                                            {isDisable ? <Form.Item >
                                                {getFieldDecorator('checkDatabase' + index)(
                                                    <Checkbox style={{marginLeft: 10}}>Database size ({pd.quotaMaster.limitDbSize} Gb per unit)</Checkbox>
                                                )}
                                            </Form.Item> : <div style={{margin: '10px 5px'}}>Database size ({pd.quotaMaster.limitStorageSize} Gb per unit)</div>}
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item >
                                                {getFieldDecorator('Database' + index, {
                                                    initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 'd')?.subscriptionTotal
                                                })(
                                                    <InputNumber min={0} max={1000} disabled={!isDisable ? false : !getFieldValue('checkDatabase' + index)}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={9} style={{textAlign: 'center', marginTop: 10}}>
                                            <div>Unit ({pd.price} Baht per unit)</div>
                                        </Col>
                                    </Row>
                                </div>
                            })}
                            <Space size={10} />
                            <p style={{color: '#323276', fontWeight: 400}}>· User</p>
                            {packageUser.map((pu, index) => {
                                return <div className={!isDisable ? 'card-service-package-active' : getFieldValue('checkUser' + index) ? 'card-service-package-active' : 'card-service-package-InActive'} key={index}>
                                    <Row>
                                        <Col span={12} >
                                            {isDisable ? <Form.Item >
                                                {getFieldDecorator('checkUser' + index)(
                                                    <Checkbox style={{marginLeft: 10}}>User</Checkbox>
                                                )}
                                            </Form.Item> : <div style={{margin: '10px 5px'}}>User</div>}
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item >
                                                {getFieldDecorator('User' + index, {
                                                    initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 'u')?.subscriptionTotal
                                                })(
                                                    <InputNumber min={0} max={1000} disabled={!isDisable ? false : !getFieldValue('checkUser' + index)}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={9} style={{textAlign: 'center', marginTop: 10}}>
                                            <div>User ({pu.price} Baht per user)</div>
                                        </Col>
                                    </Row>
                                </div>
                            })}
                            <Space size={10} />
                            {packagePeople.map((pp, index) => {
                                return <div className={!isDisable ? 'card-service-package-active' : getFieldValue('checkPeople' + index) ? 'card-service-package-active' : 'card-service-package-InActive'} key={index}>
                                    <Row>
                                        <Col span={12} >
                                            {isDisable ? <Form.Item >
                                                {getFieldDecorator('checkPeople' + index)(
                                                    <Checkbox style={{marginLeft: 10}}>People</Checkbox>
                                                )}
                                            </Form.Item> : <div style={{margin: '10px 5px'}}>People</div>}
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item >
                                                {getFieldDecorator('People' + index, {
                                                    initialValue: 0 // getOrder?.packageSubscription.find((it) => it.packages.packageType === 'p')?.subscriptionTotal
                                                })(
                                                    <InputNumber min={0} max={1000} disabled={!isDisable ? false : !getFieldValue('checkPeople' + index)}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={9} style={{textAlign: 'center', marginTop: 10}}>
                                            <div>People ({pp.price} Baht per people)</div>
                                        </Col>
                                    </Row>
                                </div>
                            })}
                            <Space size={10} />
                        </Col>
                        <Col span={2}></Col>
                        <Col span={8}>
                            <p style={{color: '#323276', fontWeight: 400}}>Billing Address</p>
                            <div style={{color: '#828282', fontWeight: 300}}> &emsp; &emsp; บริษัท จีเอเบิล จำกัด  127/20, 22, 29-31 ถนน นนทรี แขวง ช่องนนทรี เขต ยานนาวา กรุงเทพมหานคร 10120</div>
                            <Space size={10} />
                            <Card title={HerderOrder()} className={'mycardtest'} >
                                <div className={'my-body-card'}>
                                    <Row gutter={[8, 8]} >
                                        <Col span={12}>You choose Package</Col>
                                        <Col span={6}>{selectPackage || '-'}</Col>
                                        <Col span={6}>{selectPrice} &emsp; Baht</Col>
                                    </Row>
                                    {packageStorage.map((ps, index) => {
                                        return <Row gutter={[8, 8]} key={index}>
                                            <Col span={12}>You get Storage size</Col>
                                            <Col span={6}>{getFieldValue('Storage' + index) ? (getFieldValue('Storage' + index) * ps.quotaMaster.limitStorageSize) : 0} &emsp; GB</Col>
                                            <Col span={6}>{getFieldValue('Storage' + index) ? (getFieldValue('Storage' + index) * ps.price) : 0} &emsp; Baht</Col>
                                        </Row>
                                    })}
                                    {packageDatabase.map((pd, index) => {
                                        return <Row gutter={[8, 8]} key={index}>
                                            <Col span={12}>You get Database size</Col>
                                            <Col span={6}>{getFieldValue('Database' + index) ? (getFieldValue('Database' + index) * pd.quotaMaster.limitDbSize) : 0} &emsp; GB</Col>
                                            <Col span={6}>{getFieldValue('Database' + index) ? (getFieldValue('Database' + index) * pd.price) : 0} &emsp; Baht</Col>
                                        </Row>
                                    })}
                                    {packageUser.map((pu, index) => {
                                        return <Row gutter={[8, 8]} key={index}>
                                            <Col span={12}>You get User</Col>
                                            <Col span={6}>{getFieldValue('User' + index) ? (getFieldValue('User' + index)) : 0} &emsp; GB</Col>
                                            <Col span={6}>{getFieldValue('User' + index) ? (getFieldValue('User' + index) * pu.price) : 0} &emsp; Baht</Col>
                                        </Row>
                                    })}
                                    {packagePeople.map((pp, index) => {
                                        return <Row gutter={[8, 8]} key={index}>
                                            <Col span={12}>You get People</Col>
                                            <Col span={6}>{getFieldValue('People' + index) ? (getFieldValue('People' + index)) : 0} &emsp; GB</Col>
                                            <Col span={6}>{getFieldValue('People' + index) ? (getFieldValue('People' + index) * pp.price) : 0} &emsp; Baht</Col>
                                        </Row>
                                    })}
                                </div>
                            </Card>
                            <Row>
                                <Col span={24} style={{padding: 10, textAlign: 'end'}}>
                                    <span>Total</span>&emsp;
                                    <span style={{backgroundColor: '#F2F2F2', padding: 5, marginRight: 25}}>{total} &emsp; Baht</span>
                                </Col>
                            </Row>
                            <Row>
                                {selectPackage ? <Col span={24} style={{ marginTop: 10 }}>
                                    {/* <Link to={'/OrderDetail'}> */}
                                    <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                                    {/* </Link> */}
                                    <Link to={'/Setting'}><Button style={{ float: 'right' }}>Cancel</Button></Link>
                                </Col> : null}
                            </Row>
                        </Col>
                    </Row>
                </Form>

            </div>
        </div>
    )
}

const MyPackageDetail = Form.create<props>({ name: 'PackageDetail' })(PackageDetail)
export default connect(mapStateToProps)(MyPackageDetail)
