import { RoleState } from './model'

export class Permissions {
    private static instance: Permissions
    roleState: RoleState = {}

    static getInstance(): Permissions {
        if (!Permissions.instance) {
            Permissions.instance = new Permissions()
        }
        return Permissions.instance
    }
}
