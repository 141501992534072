import { generalProblem } from './model'
import {GetAllGeneralProblemSuccessEvent, generalProblemStateEvent} from './state-event'

export const GeneralProblemStateReducer = (state: generalProblem = { pbTypeId: undefined, problemCloseReasonStatus: [], reAnalysis: false },
    event: generalProblemStateEvent<any> = { type: undefined, payload: undefined }) => {
    if (event.type === GetAllGeneralProblemSuccessEvent.type) {
        console.log(event.payload)
        return event.payload || {}
    } else {
        return state
    }
}
