import React, {useState} from 'react'
import {Button, message, Result} from 'antd'
import {resentMail} from '../authorization-module/login/service'

const PageExpiredMail = () => {
    const [isLoading, setIsLoading] = useState(false)
    const params = new URLSearchParams(window.location.search)
    const email = params.get('email')

    const onClickToResentMail = () => {
        console.log(email)
        setIsLoading(true)
        resentMail(email).then(() => {
            message.success('Resend mail success.')
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
            message.error('Fail to resend mail, Please try again.')
        })
    }

    return (
        <Result
            status="warning"
            title="Your verification link has expired."
            extra={
                <Button type="primary" key="console" loading={isLoading} onClick={() => onClickToResentMail()}>
                    Resend
                </Button>
            }
        />
    )
}

export default PageExpiredMail
