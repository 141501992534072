import React, { useEffect, useState } from 'react'
import { Icon, message, Modal, Upload, Tooltip } from 'antd'
import exportIcon from '../../common-file/icon-file'
import { pmUploadLink, PreventiveWithAsset } from '../Model'
import Compress from 'compress.js'
import './stylePM.css'
import { DownloadAnyFile } from '../../common-misc'

interface Param {
    customPicturName: string
    keyPicture?: number
    getDataCustomPicture: Function
    dataPM?: PreventiveWithAsset
    checkDataDelete: Function
    requestField: boolean
    isCheckField: boolean
}

type Props = Param
const compress = new Compress()

const CustomPicture: React.FC<Props> = (props: Props) => {
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [customPicture, setCustomPicture] = useState<any[]>([])

    useEffect(() => {
        if (props.dataPM) {
            if (props.dataPM.preventiveMaintenance.pmUploadLink) {
                const tempFileList = props.dataPM.preventiveMaintenance?.pmUploadLink?.find((file: pmUploadLink) => {
                    return props?.keyPicture === file?.customPictureId && file?.url !== null
                })
                if (tempFileList) {
                    const myUploadFile: any = {
                        uid: tempFileList?.name || null,
                        name: tempFileList?.name || null,
                        status: 'done',
                        url: tempFileList?.url || null,
                        type: tempFileList.type,
                        thumbUrl: exportIcon(tempFileList?.type || '') || null,
                        customPictureId: tempFileList.customPictureId,
                        size: 0
                    }
                    // console.log(tempFileList)
                    // console.log(myUploadFile)
                    setCustomPicture([myUploadFile])
                } else {
                    setCustomPicture([])
                }
            }
        }
    }, [props.dataPM])

    const uploadCustomPicture = {
        multiple: false,
        onRemove: (file: any) => {
            setCustomPicture(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                props.getDataCustomPicture(newFileList, state[0])
                props.checkDataDelete(file)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }
            setLoading(true)
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                // console.log(e)
                let fillOffFileList = customPicture
                const temp: any[] = []
                const promiseAll: Promise<void>[] = []
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                // console.log(fillOffFileList)
                for (const file of fillOffFileList) {
                    if (file.url?.includes('data:image/png;base64') || file.url?.includes('data:image/jpeg;base64')) {
                        const originalFile = dataURLtoFile(file.url, file.fileName)
                        promiseAll.push(compress.compress([originalFile], {
                            size: 1, // the max size in MB, defaults to 2MB
                            quality: 0.75, // the quality of the image, max is 1,
                            maxWidth: 1280, // the max width of the output image, defaults to 1920px
                            maxHeight: 1280, // the max height of the output image, defaults to 1920px
                            resize: true // defaults to true, set false if you do not want to resize the image width and height
                        }).then((data) => {
                            temp.push({
                                uid: file.uid,
                                name: file.name,
                                status: 'done',
                                url: data[0].prefix + data[0].data,
                                type: data[0].ext,
                                size: e.total,
                                customPictureId: props.keyPicture,
                                requestField: props.requestField

                            })
                        }))
                    } else {
                        temp.push(file)
                    }
                }
                Promise.all(promiseAll).finally(() => {
                    setCustomPicture(temp)
                    props.getDataCustomPicture(temp)
                    // setIsFile(true)
                    setLoading(false)
                })
            }
            return false
        },
        showUploadList: { showDownloadIcon: true },
        onDownload: (file) => {
            console.log(file)
            if (file.url.startsWith('https://')) {
                window.open(file.url)
            } else {
                message.warning("Can't Download Files")
            }
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const dataURLtoFile = (dataUrl: any, filename: any) => {
        const arr = dataUrl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: mime })
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    return (
        <>
            <div className={customPicture && customPicture?.length >= 1 ? 'picture-upload' : ''} >
                <Upload
                    className={props.isCheckField === true && props.requestField === true && customPicture?.length === 0 ? 'custompicturetest' : 'custompicture'}
                    key={props.keyPicture}
                    {...uploadCustomPicture}
                    fileList={customPicture}
                    listType="picture-card"
                    onPreview={(e: any) => handlePreview(e)}
                >
                    {customPicture && customPicture?.length >= 1 ? null : (
                        <>
                            <Tooltip placement="top" title={props.customPicturName}>
                                {loading ? <Icon type="loading" /> : <Icon type="plus" />}
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <div className="ant-upload-text truncate-text1">
                                        {props.requestField === true ? <p style={{ color: 'red', fontWeight: 'bold', display: 'inline' }}>*</p> : null}
                                       &nbsp;{props.customPicturName}
                                    </div>
                                </div>
                            </Tooltip>
                        </>
                    )}
                </Upload>

                {props.isCheckField === true && props.requestField === true && customPicture?.length === 0 ? <p style={{ color: 'red' }}>Please Input Photo</p> : null}
                {customPicture && customPicture?.length >= 1 ? (
                    <Tooltip placement="bottom" title={props.customPicturName}>
                        <p className="truncate-text"><Icon type="paper-clip" /> {props.customPicturName}</p>
                    </Tooltip>
                ) : null}
            </div>
            <Modal visible={previewVisible} onCancel={() => setPreviewVisible(false)} footer={null}>
                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}

export default CustomPicture
