import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { PMNameAndAssetName } from '../Model'

export const PMNameContext = createContext({})

const initialState: PMNameAndAssetName[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const PMNameReducer = (state: PMNameAndAssetName[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_PMName':
        sessionStorage.setItem('pm_name', JSON.stringify(action.payload || []))
        return action.payload || [] as PMNameAndAssetName[]
    case 'ADD_PMName':
        return [...state, action.payload]
    default:
        return state
    }
}

export const PMNameProvider = (props: props) => {
    const [PMNameState, PMNameDispatch] = useReducer(PMNameReducer, initialState)

    const GetPMName = (payload: PMNameAndAssetName[]) => {
        PMNameDispatch({ type: 'GET_PMName', payload })
    }

    const AddPMName = (payload: PMNameAndAssetName) => {
        PMNameDispatch({ type: 'ADD_PMName', payload })
    }

    return (
        <PMNameContext.Provider value={{ PMNameState, GetPMName, AddPMName }}>
            {props.children}
        </PMNameContext.Provider>
    )
}
