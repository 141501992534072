import React, {useEffect, useState} from 'react'
import {
    AutoComplete,
    Avatar,
    Breadcrumb,
    Button,
    Card,
    Col,
    Form,
    Icon,
    Input,
    message,
    Modal,
    Row,
    Select,
    Upload
} from 'antd'
import './style.css'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {
    addUserRole,
    editUser,
    resend,
    searchAllPeople,
    searchPeopleNotExistInUser,
    showActiveRoleList
} from './duck/action'
import {ResponseUserRole, Roles} from './duck/model'
import {FormComponentProps} from 'antd/lib/form'
import {DataSourceItemObject} from 'antd/lib/auto-complete'
import {Link, Redirect, useParams} from 'react-router-dom'
import exportIcon from '../../common-file/icon-file'
import {UploadFile} from 'antd/lib/upload/interface'
import FSS from '../../file-server-storage'
import {UploadLink} from '../../file-server-storage/model'
import { DownloadAnyFile } from '../../common-misc'

const mapStateToProps = (state: StoreState) => ({
    roles: (state.roles || [])
})

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    showUserList: (roles: string[]) => Promise<number>
    showActiveRoleList: () => Promise<number>
    addUserRole: (userRoles: ResponseUserRole) => Promise<number>
    editUser: (userRoles: ResponseUserRole, id: string) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const {confirm} = Modal

const UserRoleFrom: React.FC<Props> = (props: Props) => {
    // img upload
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()

    // Picture Avatar
    const [pictureAvatar, setPictureAvatar] = useState<string>()

    const {Option} = Select

    const {id, peopleIdParam, usernameParam, role}: any = useParams()

    const [valueRoles, setValueRoles] = useState<Array<Roles>>([])
    const [isRedirect, setIsRedirect] = useState(false)
    const [firstName, setFirstName] = useState<string>('-')
    const [lastName, setLastName] = useState<string>('-')
    const [fullName, setFullName] = useState<string>('-')
    const [phoneNumber, setPhoneNumber] = useState<string>('-')
    const [companyName, setCompanyName] = useState<string>('-')
    const [companyAddress, setCompanyAddress] = useState<string>('-')
    const [siteName, setSiteName] = useState<string>('-')
    const [siteAddress, setSiteAddress] = useState<string>('-')
    const [dataSource, setDatasource] = useState<DataSourceItemObject[]>([])
    const [email, setEmail] = useState<string>('')
    const [peopleId, setPeopleId] = useState<any>('')
    const [editMode, setEditMode] = useState<boolean>(false)
    const [preview, setPreview] = useState<boolean>(false)
    const [loading, setLoadind] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [existEmail, setExistEmail] = useState<boolean>(false)
    const [selectedRoles, setSelectedRoles] = useState<Array<number>>([])
    const [usernameRoleEnable, setUsernameRoleEnable] = useState<boolean>(true)
    const [searchEditRole, setSearchEditRole] = useState<boolean>(true)
    // img upload
    // const [fileList, setFileList] = useState<UploadFile[]>([])
    // const [previewVisible, setPreviewVisible] = useState<boolean>()
    // const [previewImage, setPreviewImage] = useState<string>()

    // // Picture Avatar
    // const [pictureAvatar, setPictureAvatar] = useState<string>()

    useEffect(() => {
        const pathname = window.location.pathname
        if (pathname.includes('/UserSettingPreview')) {
            setPreview(true)
            setEditMode(false)
        }
        props.showActiveRoleList().then(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setValueRoles(props.roles)
    }, [props.roles])

    useEffect(() => {
        if (id) {
            onSelect(peopleIdParam)
            setEditMode(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peopleIdParam])

    useEffect(() => {
        if (role) {
            setSelectedRoles(role?.split(','))
        }
    }, [role])

    const {getFieldDecorator} = props.form

    const convertSelectedRole = (selectedRole): number[] => {
        const arr: number[] = []
        let roleId: number | undefined = 0
        selectedRole.forEach((item) => {
            if (typeof item === 'string') {
                roleId = props.roles.find(role => role.name === item)?.id
                if (roleId != null) {
                    arr.push(roleId)
                }
            } else {
                arr.push(item)
            }
        })
        return arr
    }

    const handleSubmit = (e) => {
        setLoadind(true)
        e.preventDefault()
        props.form.validateFieldsAndScroll((error, value) => {
            if (!error) {
                const packData = {
                    username: value.username,
                    fullName: fullName,
                    roles: convertSelectedRole(value.roles).map((roleId) => {
                        return {
                            id: roleId
                        }
                    }
                    ),
                    peopleId: peopleId,
                    active: true
                } as ResponseUserRole

                console.log(packData)
                if (id) {
                    if (fileList.length !== 0) {
                        FSS.putFile(fileList, '/user/', (uploadLink: UploadLink[]) => {
                            packData.pictureProfile = JSON.stringify(uploadLink)
                            props.editUser(packData, id).then(() => {
                                setExistEmail(false)
                                message.success('The Update has finished successfully.')
                                setIsRedirect(true)
                            }).catch((error) => {
                                setLoadind(false)
                                message.error(error.message)
                            })
                        })
                    } else {
                        props.editUser(packData, id).then(() => {
                            setExistEmail(false)
                            message.success('The Update has finished successfully.')
                            setIsRedirect(true)
                        }).catch((error) => {
                            setLoadind(false)
                            message.error(error.message)
                        })
                    }
                } else {
                    if (fileList.length !== 0) {
                        FSS.putFile(fileList, '/user/', (uploadLink: UploadLink[]) => {
                            packData.pictureProfile = JSON.stringify(uploadLink)
                            props.addUserRole(packData).then(() => {
                                setExistEmail(false)
                                message.success('You have Successfully save the data.')
                                props.form.setFieldsValue({
                                    username: undefined,
                                    fullName: undefined,
                                    roles: undefined
                                })
                                setIsRedirect(true)
                            }).catch((error) => {
                                if (error.response.status === 409) {
                                    setLoadind(false)
                                    setExistEmail(true)
                                    // message.error('This email is already in the system.')
                                } else {
                                    setLoadind(false)
                                    message.error(error.message)
                                }
                            })
                        })
                    } else {
                        props.addUserRole(packData).then(() => {
                            setExistEmail(false)
                            message.success('You have Successfully save the data.')
                            props.form.setFieldsValue({
                                username: undefined,
                                fullName: undefined,
                                roles: undefined
                            })
                            setIsRedirect(true)
                        }).catch((error) => {
                            if (error.response.status === 409) {
                                setLoadind(false)
                                setExistEmail(true)
                                // message.error('This email is already in the system.')
                            } else {
                                setLoadind(false)
                                message.error(error.message)
                            }
                        })
                    }
                }
            } else {
                setLoadind(false)
            }
        })
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const changeToEdit = () => {
        setEditMode(true)
        setPreview(false)
    }

    const onSelect = (value) => {
        props.form.resetFields()
        setSearchEditRole(false)
        setUsernameRoleEnable(true)
        if (id) {
            searchAllPeople('').then((raw) => {
                // eslint-disable-next-line no-unused-expressions
                raw?.map((temp) => {
                    if (temp.id?.toString() === value.toString()) {
                        console.log(temp)
                        // Picture Avatar
                        setPictureAvatar(temp.pictureProfile)
                        setFirstName(temp.firstName)
                        setLastName(temp.lastName)
                        setPhoneNumber(temp.tel)
                        setCompanyName(temp.company)
                        setEmail(temp.email)
                        setFullName(temp.fullName)
                        setCompanyAddress(temp.companyAddress)
                        setSiteAddress(temp.siteAddress)
                        setSiteName(temp.site)
                        setPeopleId(value)
                    }
                })
                if (id) {
                    setEmail(usernameParam)
                }
            })
        } else {
            searchPeopleNotExistInUser('').then((raw) => {
                // eslint-disable-next-line no-unused-expressions
                raw?.map((temp) => {
                    if (temp.id?.toString() === value.toString()) {
                        setPictureAvatar(temp.pictureProfile)
                        setFirstName(temp.firstName)
                        setLastName(temp.lastName)
                        setPhoneNumber(temp.tel)
                        setCompanyName(temp.company)
                        setEmail(temp.email)
                        setFullName(temp.fullName)
                        setCompanyAddress(temp.companyAddress)
                        setSiteAddress(temp.siteAddress)
                        setSiteName(temp.site)
                        setPeopleId(value)
                    }
                })
                if (id) {
                    setEmail(usernameParam)
                }
            })
        }
    }

    const onSearch = (searchText) => {
        if (searchText.length >= 3) {
            if (id) {
                searchAllPeople(searchText).then((data) => {
                    if (data) {
                        const result = data.map((people) => {
                            return {
                                value: people.id?.toString(),
                                text: people.fullName
                            } as DataSourceItemObject
                        })
                        setDatasource(result)
                    } else {
                        setDatasource([])
                    }
                })
            } else {
                searchPeopleNotExistInUser(searchText).then((data) => {
                    if (data) {
                        const result = data.map((people) => {
                            return {
                                value: people.id?.toString(),
                                text: people.fullName
                            } as DataSourceItemObject
                        })
                        setDatasource(result)
                    } else {
                        setDatasource([])
                    }
                })
            }
        }
    }

    const handleResend = () => {
        setIsLoading(true)
        const username = props.form.getFieldValue('username')
        resend(username, 'resend')
            .then(() => {
                setIsLoading(false)
                message.success('Account Verification Link has been send')
            }).catch(error => {
                setIsLoading(false)
                if (error.response.status === 404) {
                    message.error('User already confirmed account.')
                } else {
                    message.error('You have Unsuccessfully save the data.')
                }
            }
            )
    }
    // img upload
    const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                if (fillOffFileList.length > 1) {
                    fillOffFileList.shift()
                    setFileList(fillOffFileList)
                } else {
                    setFileList(fillOffFileList)
                }
            }
            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    return (
        <>
            {isRedirect ? (<Redirect to="/UserSetting"/>) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/UserSetting">User</Link>
                </Breadcrumb.Item>
                { preview ? <Breadcrumb.Item>{firstName}</Breadcrumb.Item> : (id) ? <Breadcrumb.Item>Edit User</Breadcrumb.Item> : <Breadcrumb.Item>New User</Breadcrumb.Item> }
            </Breadcrumb>
            <br/>
            <Card className={'custom-card'}>
                <Row>
                    <Col style={{textAlign: 'right'}}>{preview
                        ? <Icon className="edit_icon" type="edit" onClick={changeToEdit}/> : null}</Col>
                    <Col>
                        { preview ? <h2 className={'main-title'}>User</h2> : id ? <h2 className={'main-title'}>Edit User</h2> : <h2 className={'main-title'}>New User</h2>}
                    </Col>
                </Row>

                <Form onSubmit={handleSubmit}>
                    {/* Left Part */}
                    <Row>
                        <Col span={5} lg={5} md={5} sm={24} xs={24}>
                            <Row>
                                <AutoComplete
                                    disabled={editMode}
                                    dataSource={dataSource}
                                    onSelect={onSelect}
                                    onSearch={onSearch}
                                    placeholder="Can search name"
                                >
                                    <Input suffix={<Icon type="search" className="certain-category-icon"/>}/>
                                </AutoComplete>
                            </Row>
                            <Row>
                                <Col span={24} style={{textAlign: 'center', paddingTop: 24, maxWidth: 'min-content', paddingLeft: 50}}>
                                    {/* Picture Avatar */}
                                    <Avatar shape="square" size={170} icon="user" src={fileList?.length > 0 ? fileList[0]?.url : pictureAvatar ? JSON.parse(pictureAvatar)[0].url : undefined}/>
                                    <Upload {...uploadProps}
                                        fileList={fileList}
                                        onPreview={(e: any) => handlePreview(e)}
                                    >
                                        <Button disabled={preview} style={{marginTop: 10}} ><Icon type="upload"/>Upload New Profile</Button>
                                    </Upload>
                                    <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} >
                                        <img alt={previewImage} style={{ width: '170' }} src={previewImage} />
                                    </Modal>
                                </Col>
                            </Row>
                        </Col>

                        {/* Right Part */}
                        <Col span={18} style={{marginLeft: '3%'}} lg={18} md={18} sm={24} xs={24}>
                            <Row style={{marginTop: '8%'}}>
                                <h4 style={{color: '#323276', fontSize: 14}}>Personal information</h4>
                                <Row style={{marginTop: '2%'}}>
                                    <Col span={3} lg={3} md={3} sm={24} xs={24}>FirstName :</Col>
                                    <Col span={4} lg={4} md={4} sm={24} xs={24}>{firstName}</Col>
                                    <Col span={2} lg={2} md={2} sm={24} xs={24}>LastName :</Col>
                                    <Col span={10} lg={10} md={10} sm={24} xs={24}>{lastName}</Col>
                                </Row>
                                <Row style={{marginTop: '1%'}}>
                                    <Col span={3} lg={3} md={3} sm={24} xs={24}>Phone Number :</Col>
                                    <Col span={21} lg={21} md={21} sm={24} xs={24}>{phoneNumber}</Col>
                                </Row>
                                <h4 style={{marginTop: '3%', color: '#323276', fontSize: 14}}>Company</h4>
                                <Row style={{marginTop: '2%'}}>
                                    <Col span={3} lg={3} md={3} sm={24} xs={24}>Name :</Col>
                                    <Col span={10} lg={10} md={10} sm={24} xs={24}>{companyName}</Col>
                                </Row>
                                <Row style={{marginTop: '1%'}}>
                                    <Col span={3} lg={3} md={3} sm={24} xs={24}>Address :</Col>
                                    <Col span={21} lg={21} md={21} sm={24} xs={24}>{companyAddress}</Col>
                                </Row>
                                <h4 style={{marginTop: '3%', color: '#323276', fontSize: 14}}>Site</h4>
                                <Row style={{marginTop: '2%'}}>
                                    <Col span={3} lg={3} md={3} sm={24} xs={24}>Name :</Col>
                                    <Col span={10} lg={21} md={21} sm={24} xs={24}>{siteName}</Col>
                                </Row>
                                <Row style={{marginTop: '1%'}}>
                                    <Col span={3} lg={3} md={3} sm={24} xs={24}>Address :</Col>
                                    <Col span={21} lg={21} md={21} sm={24} xs={24}>{siteAddress}</Col>
                                </Row>
                            </Row>
                            <Row style={{marginTop: '8%'}}>
                                <h4 style={{color: '#323276', fontSize: 14}}>Security information</h4>
                                <Row>
                                    <Form.Item label={'Email'} style={{width: '80%'}}>
                                        {getFieldDecorator('username',
                                            {
                                                initialValue: email,
                                                rules: [
                                                    {type: 'email'},
                                                    {required: true, message: 'Email is required'}
                                                ]
                                            })(
                                            <Input
                                                placeholder="E-mail"
                                                disabled={preview || !editMode || usernameRoleEnable}
                                                style={{borderColor: existEmail ? 'red' : undefined}}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        )}
                                    </Form.Item>
                                    {existEmail ? <span style={{color: 'red'}}>
                                            This user or email is already in the system.
                                    </span> : null}
                                    <h4 style={{color: '#323276', marginTop: '5%', fontSize: 14}}>Setting Role</h4>
                                    <Form.Item label={'Role'} style={{width: '80%'}}>
                                        {getFieldDecorator('roles',
                                            {
                                                initialValue: selectedRoles,
                                                rules: [{required: true, message: 'Role is required'}]
                                            })(
                                            <Select
                                                mode="multiple"
                                                placeholder={'Role'}
                                                disabled={searchEditRole || preview || !usernameRoleEnable}
                                                // onChange={handleSelectRoles}
                                            >
                                                {valueRoles.map((roles: Roles) => {
                                                    return (<Option value={roles.name}
                                                        key={roles.id}>{roles.name}</Option>)
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Row>
                                {(preview || editMode)
                                    ? <div>
                                        <Row style={{marginTop: '8%'}}>
                                            <Button style={{borderColor: '#FA541C', color: '#FA541C'}}
                                                onClick={handleResend} loading={isLoading}>Resend</Button>
                                        </Row>
                                        <Row style={{marginTop: '2%'}}>
                                            <span>Please click &quot;Resend&quot; to resend account verification link</span>
                                        </Row>
                                    </div>
                                    : null}
                                <Row style={{marginTop: '20%'}}>
                                    {!preview
                                        ? <div>
                                            <Button type="primary" htmlType="submit" loading={loading} style={{
                                                float: 'right',
                                                marginRight: 10,
                                                marginBottom: '5px'
                                            }}>Submit</Button>
                                            <Button onClick={leavePageModal}
                                                style={{float: 'right', marginRight: 10}}>Cancel</Button>
                                        </div>
                                        : null}

                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}
const MyUserRolesForm = Form.create<Props>()(UserRoleFrom)

export default connect(
    mapStateToProps, {
        addUserRole,
        showActiveRoleList,
        editUser
    }
)(MyUserRolesForm)
