import React, { useRef } from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/es/select'
import { IncidentFilter } from '../model'
import { PriorityLevel } from '../../priority'

interface Props {
    onChanged: (value: string[]) => void
    criteria?: IncidentFilter
    priorities: PriorityLevel[]
    isLoading?: Function
}

export const PriorityFilter: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const ref = useRef<any>(null)
    const onRequesterChange = (value: SelectValue) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Priority</p>
            <Select ref={ref} mode="multiple" style={{ width: '100%' }} onChange={(e) => {onRequesterChange(e) }} value={props.criteria?.priorities?.split(',') || []} placeholder={'Select a priority'} allowClear>
                {props.priorities?.map((it, index) => { return <Option key={index} value={it.name}>{it.name}</Option> })}
            </Select><br />
        </>
    )
}
