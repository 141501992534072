import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import ImageResize from 'quill-image-resize-module-react'
import ReactQuill, { Quill } from 'react-quill'
import { UploadFile } from 'antd/lib/upload/interface'
import KnowledgeFormUpload from '../../../../../knowledge-management/knowledge/uploads/KnowledgeFormUpload'

interface Params {
    label: string
    isrequired: boolean
    isUploadFile: boolean
    getData: Function
    getUploadFile?: Function
    isSubmit: boolean
    displayIndex: number
    intializeData?: string
    disable?: boolean
}

Quill.register('modules/imageResize', ImageResize)
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link'
]

type Props = Params
const CustomTextEditor = (props: Props) => {
    const [detail, setDetail] = useState<string>('')
    const [textError, setTextError] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])

    useEffect(() => {
        if (props.isSubmit && props.isrequired) {
            const detailValue = detail || ''
            const CheckValue = (detailValue).replace(/<[^>]*>/g, '')
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (CheckValue === '' || CheckValue.length <= 2 || !pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                if (CheckValue === '') {
                    setTextError('Detail is required')
                } else {
                    if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                        if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                            setTextError('')
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    } else {
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    }
                }
            }
        }
    }, [props.isSubmit])

    useEffect(() => {
        if (props.intializeData) {
            setDetail(props.intializeData)
        }
    }, [props.intializeData])

    const onTextEditorChange = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const element = document.getElementsByClassName('ql-editor')?.item(props.displayIndex) as HTMLElement
        if (value) {
            setDetail(value)
            props.getData(value)
            if (props.isrequired) {
                if (CheckValue === '') {
                    element.style.border = '1px solid red'
                    setTextError('Detail is required')
                } else {
                    if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                        if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                            element.style.border = '0px solid red'
                            setTextError('')
                        } else {
                            element.style.border = '1px solid red'
                            setTextError('Enter more than 3 characters')
                        }
                    } else {
                        element.style.border = '1px solid red'
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    }
                }
            }
        }
    }

    const handleSetFileList = (fileList: UploadFile[]) => {
        setFileList(fileList)
        if (props.getUploadFile) {
            props.getUploadFile(fileList)
        }
    }

    return (
        <div>
            <Form.Item label={props.label} required={props.isrequired} className='custom-formLabel-wo'>
                <ReactQuill modules={modules} readOnly={props.disable} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hidden', border: textError === '' ? '1px solid #f2f2f2' : '1px solid #fa541c', backgroundColor: props.disable ? '#F8F8F9' : 'white' }} />
                <span style={{ color: 'red' }}>{textError}</span>
            </Form.Item>
            {props.isUploadFile ? <KnowledgeFormUpload fileList={fileList} setFileList={handleSetFileList} /> : null}
        </div>
    )
}

export default CustomTextEditor
