import {Dispatch} from 'redux'
import {DataTotal} from '.'
import {decryptBody, encryptBody} from '../common-misc/encryptBody'
import {axiosGet} from '../incident-management/rest'
import {axiosGetDashboard, axiosPostDashboard} from '../incident-management/restDashboard'
import {
    Dashboard,
    DataForChart,
    Color,
    ChartType,
    GroupType,
    ColorSLA,
    ColortotalPubKm,
    ColorToplikeKm,
    KnowledgeDashboard
} from './model'
import {
    GetIncidentBacklogMyticketSuccessEvent,
    GetOpenSLAMyGroupSuccessEvent,
    GetOpenSLAMyticketSuccessEvent,
    GetOpenSLATotalGroupSuccessEvent,
    GetOpenTicketMyGroupSuccessEvent,
    GetOpenTicketTotalGroupSuccessEvent,
    GetOpenbyPriorityTicketMyGroupSuccessEvent
    ,
    GetOpenbyPriorityTicketMyticketSuccessEvent,
    GetOpenbyPriorityTicketTotalGroupSuccessEvent,
    GetOpenbyStatusTicketMyGroupSuccessEvent,
    GetOpenbyStatusTicketMyticketSuccessEvent
    ,
    GetOpenbyStatusTicketTotalGroupSuccessEvent,
    GetRespondedSuccessEvent,
    GetRemainderSuccessEvent,
    GetSatisfactionReportSuccessEvent,
    GetEmailSatisfactionNotDoneEvent
    ,
    GetSatisfactionExportSuccessEvent,
    GetAllKnowledgeDahsboardSuccessEvent
} from './state-event'
import {axiosGet as axiosGetKM} from '../knowledge-management/rest'
import {convertResponseToFile} from '../ReportComponent/components/Services/Service'

export const getDashboard = (Group: GroupType, created: string, Chart: ChartType, startDate, endDate) => {
    return async (dispatch: Dispatch) => {
        try {
            let Uri: string
            let config: object
            if (created === 'anytime' && startDate !== undefined && endDate !== undefined) {
                Uri = `/api/tickets/${Chart}`
                config = {
                    params: {
                        showBy: Group,
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            } else {
                Uri = `/api/tickets/${Chart}`
                config = {
                    params: {
                        showBy: Group,
                        createdScope: created
                    }
                }
            }
            const response = await axiosGetDashboard<Dashboard>(Uri, config)
            if (response.data.data) {
                if (Group === GroupType.TotalGroup) {
                    if (Chart === ChartType.OpenTicket) {
                        dispatch(GetOpenTicketTotalGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenByStatus) {
                        dispatch(GetOpenbyStatusTicketTotalGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenByPriority) {
                        dispatch(GetOpenbyPriorityTicketTotalGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenSLA) {
                        dispatch(GetOpenSLATotalGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    }
                } else if (Group === GroupType.MyGroup) {
                    if (Chart === ChartType.OpenTicket) {
                        dispatch(GetOpenTicketMyGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenByStatus) {
                        dispatch(GetOpenbyStatusTicketMyGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenByPriority) {
                        dispatch(GetOpenbyPriorityTicketMyGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenSLA) {
                        dispatch(GetOpenSLAMyGroupSuccessEvent.build(decryptBody(response.data.data!!)))
                    }
                } else if (Group === GroupType.MyTicket) {
                    if (Chart === ChartType.OpenByStatus) {
                        dispatch(GetOpenbyStatusTicketMyticketSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenByPriority) {
                        dispatch(GetOpenbyPriorityTicketMyticketSuccessEvent.build(decryptBody(response.data.data!!)))
                    } else if (Chart === ChartType.OpenSLA) {
                        dispatch(GetOpenSLAMyticketSuccessEvent.build(decryptBody(response.data.data!!)))
                    }
                }
            }
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getSatDashboard = (created: string, Chart: ChartType, startDate, endDate) => {
    return async (dispatch: Dispatch) => {
        try {
            let Uri: string
            let params: object
            if (created === 'anytime' && startDate !== undefined && endDate !== undefined) {
                Uri = `/api/tickets/${Chart}`
                params = {
                    startDate: startDate,
                    endDate: endDate
                }
            } else {
                Uri = `/api/tickets/${Chart}`
                params = {
                    createdScope: created
                }
            }
            const response = await axiosGetDashboard<Dashboard>(Uri, {params})
            if (response.data.data) {
                if (Chart === ChartType.Responded) {
                    dispatch(GetRespondedSuccessEvent.build(decryptBody(response.data.data!!)))
                }
                if (Chart === ChartType.Remainder) {
                    dispatch(GetRemainderSuccessEvent.build(decryptBody(response.data.data!!)))
                }
            }
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getIncidentBacklog = (showBy: string, created?: string, startDate?: string, endDate?: string, sortBy?: string, orderBy?: string, skip?: string, limit?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    showBy: showBy,
                    createdScope: created,
                    startDate: startDate,
                    endDate: endDate,
                    sort: `${sortBy},${orderBy}`,
                    page: skip,
                    size: limit,
                    type: 1
                }
            }
            const response = await axiosGetDashboard<Dashboard>('/api/tickets/sla-backlog', config)
            if (response.data.data) {
                dispatch(GetIncidentBacklogMyticketSuccessEvent.build(decryptBody(response.data.data!!)))
                return decryptBody(response.data.data!!)
            } else {
                return []
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getIncidentBacklogNOSLA = (showBy: string, created?: string, startDate?: string, endDate?: string, sortBy?: string, orderBy?: string, skip?: string, limit?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    showBy: showBy,
                    createdScope: created,
                    startDate: startDate,
                    endDate: endDate,
                    sort: `${sortBy},${orderBy}`,
                    page: skip,
                    size: limit
                }
            }
            const response = await axiosGet<Dashboard>('/api/dashboard/backlog', config)
            if (response.data.data) {
                dispatch(GetIncidentBacklogMyticketSuccessEvent.build(decryptBody(response.data.data!!)))
                return decryptBody(response.data.data!!)
            } else {
                return []
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const genReport = async (showBy: string, created?: string, startDate?: string, endDate?: string, sortBy?: string, orderBy?: string, skip?: string, limit?: string) => {
    try {
        const config = {
            params: {
                showBy: showBy,
                createdScope: created,
                startDate: startDate,
                endDate: endDate,
                orderBy: orderBy,
                skip: skip,
                limit: limit,
                backLogType: 1
            }
        }
        const response = await axiosGetDashboard('/api/tickets/sla-backlog', config)
        return decryptBody(response.data.data)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const genReportNOSLA = async (showBy: string, created?: string, startDate?: string, endDate?: string, sortBy?: string, orderBy?: string, skip?: string, limit?: string) => {
    try {
        const params = {
            showBy: showBy,
            createdScope: created,
            startDate: startDate,
            endDate: endDate,
            sort: `${sortBy},${orderBy}`,
            page: skip,
            size: limit
        }
        const response = await axiosGetDashboard('/api/tickets/incidentBacklog', {params})
        return decryptBody(response.data.data)
    } catch (error) {
        console.log(error)
        throw error
    }
}

// export const downloadReport = (reportName) => {
//     return async () => {
//         try {
//             await axiosPostDashboard(`/api/ticket/downloadReport?reportName=${reportName}`)
//         } catch (error) {
//             console.log(error)
//             throw error
//         }
//     }
// }

export const getAllCategoryKMDashboard = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet('/km/api/categories')
            if (response.data.data) {
                dispatch(GetIncidentBacklogMyticketSuccessEvent.build(decryptBody(response.data.data!!)))
                return decryptBody(response.data.data!!)
            } else {
                return []
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const ConvertDataForChart = (data, customColor?: string[]): DataForChart => {
    const Data: DataForChart = {
        labels: [],
        datasets: []
    }
    const value: Array<number> = []
    if (data) {
        Object.keys(data).forEach((it) => {
            Data.labels.push(it)
            value.push(data[it])
        })

        if (Object.keys(data).includes('Due Soon')) {
            Data.datasets.push({data: value, backgroundColor: ColorSLA, hoverBackgroundColor: ColorSLA})
        } else if (Object.keys(data).includes('Very Satisfied') || Object.keys(data).includes('Waiting for Respond') || Object.keys(data).includes('CSAT Responded')) {
            Data.datasets.push({data: value, backgroundColor: customColor!!, hoverBackgroundColor: customColor!!})
        } else {
            Data.datasets.push({data: value, backgroundColor: Color, hoverBackgroundColor: Color})
        }
    }
    return Data
}
export const ConvertDataForChartPriority = (data, customColor?: string[]): DataForChart => {
    const Data: DataForChart = {
        labels: [],
        datasets: []
    }
    const value: Array<number> = []
    if (data) {
        Object.keys(data).forEach((it) => {
            Data.labels.push(it)
            value.push(data[it])
        })
        Data.datasets.push({data: value, backgroundColor: customColor!!, hoverBackgroundColor: customColor!!})
    }
    return Data
}
export const getEmailSatisfactionNotDone = (created?: string, startDate?: string, endDate?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    createdScope: created === 'anytime' ? '' : created,
                    startDate: startDate || null,
                    endDate: endDate || null
                }
            }
            const response = await axiosGetDashboard<DataTotal>('/api/satisfactions/emails/pending-evaluation', config)
            dispatch(GetEmailSatisfactionNotDoneEvent.build(decryptBody(response.data.data!!)))
            return decryptBody(response.data.data!!)
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getSatisfactionReport = (created?: string, startDate?: string, endDate?: string, sortBy?: string, orderBy?: string, skip?: string, limit?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    createdScope: created === 'anytime' ? '' : created,
                    startDate: startDate || null,
                    endDate: endDate || null,
                    sort: `${sortBy},${orderBy}`,
                    page: skip || 0,
                    size: limit
                }
            }
            const response = await axiosGetDashboard<Dashboard>('/api/satisfactions/report', config)
            dispatch(GetSatisfactionReportSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getSatisfactionExport = (created?: string, startDate?: string, endDate?: string, sortBy?: string, orderBy?: string, skip?: string, limit?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    createdScope: created === 'anytime' ? '' : created,
                    startDate: startDate || null,
                    endDate: endDate || null,
                    sort: `${sortBy},${orderBy}`,
                    page: skip || 0,
                    size: limit
                }
            }
            const response = await axiosGetDashboard<Dashboard>('/api/satisfactions/export', config)
            dispatch(GetSatisfactionExportSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getKnowledgeDashboard = (Group: number | string, created: string, startDate, endDate) => {
    return async (dispatch: Dispatch) => {
        try {
            const Uri = '/api/knowledge'
            let config: object
            if (created === 'anytime' && startDate !== undefined && endDate !== undefined) {
                config = {
                    params: {
                        showCategoryId: Group || null,
                        startDate: startDate || null,
                        endDate: endDate || null
                    }
                }
            } else {
                config = {
                    params: {
                        showCategoryId: Group || null,
                        createdScope: created || null
                    }
                }
            }
            const response = await axiosGetDashboard<KnowledgeDashboard>(Uri, config)
            if (response.data.data) {
                dispatch(GetAllKnowledgeDahsboardSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getKnowledgeDashboardWithFilter = async (Group, created, startDate, endDate, ordinalNumber, page, pageSize) => {
    try {
        const Uri = '/api/knowledge'
        let config: object
        if (created === 'anytime' && startDate !== undefined && endDate !== undefined) {
            config = {
                params: {
                    showCategoryId: Group || null,
                    startDate: startDate || null,
                    endDate: endDate || null,
                    ordinalNumber: ordinalNumber || null,
                    page: page || 0,
                    pageSize: pageSize || 10
                }
            }
        } else {
            config = {
                params: {
                    showCategoryId: Group || null,
                    createdScope: created || null,
                    ordinalNumber: ordinalNumber || null,
                    page: page || 0,
                    pageSize: pageSize || 10
                }
            }
        }
        const response = await axiosGetDashboard<KnowledgeDashboard>(Uri, config)
        return decryptBody((response.data.data!!))
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getKMDashboardCategory = async () => {
    try {
        const response = await axiosGetKM('/api/categories')
        return decryptBody((response.data.data!!))
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const ConvertTotalKnowledgeToChart = (data, customColor?: string[]): DataForChart => {
    const Data: DataForChart = {
        labels: [],
        datasets: []
    }
    const value: Array<number> = []
    if (data) {
        Object.keys(data).forEach((it) => {
            if (it === 'totalPublishKm') {
                Data.labels.push('Status Published')
                value.push(data[it])
            }
        })
        if (Object.keys(data).includes('totalPublishKm')) {
            Data.datasets.push({
                data: value,
                backgroundColor: ColortotalPubKm,
                hoverBackgroundColor: ColortotalPubKm,
                borderWidth: 0
            })
        }
    }
    return Data
}

export const ConvertTopLikeKnowledgeToChart = (data, customColor?: string[]): DataForChart => {
    const Data: DataForChart = {
        labels: [],
        datasets: []
    }
    const value: Array<number> = []
    const data02: object[] = []
    if (data) {
        Object.keys(data).forEach((it) => {
            if (it === 'topLikeKm') {
                data02.push(...data[it])
            }
        })

        data02.map((it) => {
            Object.keys(it).forEach((it02) => {
                if (it02 === 'amountLike') {
                    Data.labels.push(it['ordinalNumber'] === 1 ? '1st' : it['ordinalNumber'] === 2 ? '2nd' : '3rd')
                    value.push(it[it02])
                }
            })
        })
        if (Object.keys(data).includes('topLikeKm')) {
            if (data02.length === 1) {
                Data.datasets.push({data: value, backgroundColor: ['#69BB57'], hoverBackgroundColor: ['#69BB57']})
            } else {
                Data.datasets.push({data: value, backgroundColor: ColorToplikeKm, hoverBackgroundColor: ColorToplikeKm})
            }
        }
    }
    return Data
}

export const getExportUser = async (search?: string, filter?: any) => {
    try {
        const body = {
            search: search || null,
            filter: filter || null
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPostDashboard('/api/users/export', encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, 'User.xlsx')
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getExportCategory = async () => {
    try {
        const response = await axiosGetDashboard('/api/tickets/categories/export', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, 'Category.xlsx')
    } catch (err) {
        console.log(err)
        throw err
    }
}
