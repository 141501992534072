import {
    axiosFactory as commonAxiosFactory,
    axiosNonAuthFactory,
    ResponseContentDetails,
    ResponseDetails
} from '../common-rest'
import {BASE_URL, BASE_URL_AUTH} from './service'
import {AxiosRequestConfig} from 'axios'

export const axiosFactory = commonAxiosFactory(BASE_URL)
export const axiosFactoryAuth = commonAxiosFactory(BASE_URL_AUTH)
export const axiosFactoryWithOutBearer = axiosNonAuthFactory(BASE_URL)

// for sla application
export function axiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseDetails<T>>(url, config)
}
export function axiosGetWithPagination<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseContentDetails<T>>(url, config)
}
export function axiosDelete<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().delete<ResponseDetails<T>>(url, config)
}
export function axiosPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().post<ResponseDetails<T>>(url, data, config)
}
export function axiosPut<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().put<ResponseDetails<T>>(url, data, config)
}
export function axiosPatch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().patch<ResponseDetails<T>>(url, data, config)
}

// for people info

export function axiosGetAuth<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactoryAuth().get<ResponseDetails<T>>(url, config)
}

export function axiosPatchWithOutBearer<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactoryWithOutBearer().patch<ResponseDetails<T>>(url, data, config)
}

export function axiosNonAuthGet<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactoryWithOutBearer().get<ResponseDetails<T>>(url, config)
}
