import {Dispatch} from 'redux'
import { decryptBody, encryptBody } from '../../../../common-misc/encryptBody'
import {axiosGet, axiosPost} from '../../../rest'
import { Charging, ChargingGroup } from '../../Charging/Model'
import {JobWorkLog, WorklogResponse, WoWorklog} from './model'
import {GetAllWorklogByJobNoSuccessEvent, WorklogAddedEvent} from './state-event'
import { herderPostPatch } from '../../../../common-misc'

// export const getAllWorkLogByJobNo = (jobNo: string) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const response = await axiosGet<JobWorkLog[]>(`/api/work-logs/details/${jobNo}`)
//             dispatch(GetAllWorklogByJobNoSuccessEvent.build(response.data.data!!))
//             return response.data.data
//         } catch (err) {
//             console.log(err.response)
//             throw err
//         }
//     }
// }

export const addWorkLog = (workLog: JobWorkLog) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<JobWorkLog>('/api/work-logs', workLog)
            dispatch(WorklogAddedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAllWorkLogByWoNo = async (woNo: string) => {
    try {
        const response = await axiosGet<WorklogResponse[]>(`/api/work-logs/${woNo}/list`)
        return decryptBody(response.data.data!!) 
    } catch (err) {
        console.log(err.response)
        throw err
    }
}

export const addWoWorkLog = async (workLog: WoWorklog) => {
    try {
        const encryptData = encryptBody(JSON.stringify(workLog))
        const response = await axiosPost<WorklogResponse[]>('/api/wo-worklogs', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!) 
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getExpenseCost = async() => {
    try {
        const response = await axiosPost<ChargingGroup>('...')
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const addExpenseCost = async(ExpenseJob:Charging) => {
    const encryptData = encryptBody(JSON.stringify(ExpenseJob))
    try {
        const response = await axiosPost<ChargingGroup>('...',encryptData)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const deleteExpenseCost = async(id:number) => {
    try {
        const response = await axiosPost<ChargingGroup>(`.../${id}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}


