import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const DataModelContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const DataModelReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_DataModel':
        return action.payload
    default:
        return state
    }
}

export const DataModelProvider = (props: props) => {
    const [DataModelState, DataModelDispatch] = useReducer(DataModelReducer, initialState)

    const GetDataModel = (payload) => {
        DataModelDispatch({ type: 'GET_DataModel', payload })
    }

    return (
        <DataModelContext.Provider value={{ DataModelState, GetDataModel }}>
            {props.children}
        </DataModelContext.Provider>
    )
}
