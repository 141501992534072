import { StatusStateEvent, GetAllStatusSuccessEvent } from './state-event'
import {TicketStatus} from '../incident'

export const statusStateReducer = (state: TicketStatus[] = [], event: StatusStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllStatusSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
