import {TablePagination, UserRolesAndPermissions} from './model'
import {
    CommonMiscStateEvent,
    IncidentTableTotalDataEvent,
    UpdateBusinessHourTableCurrentPageEvent,
    UpdateBusinessHourTableTotalDataEvent,
    UpdateSlaPolicyTableCurrentPageEvent,
    UpdateSlaPolicyTableTotalDataEvent,
    UpdateUserRolesAndPermissionsEvent
} from './state-event'

export const tablePaginationStateReducer = (state: TablePagination = {}, event: CommonMiscStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateSlaPolicyTableCurrentPageEvent.type) {
        state.slaPolicyTableCurrentPage = event.payload || 1
    } else if (event.type === UpdateSlaPolicyTableTotalDataEvent.type) {
        state.slaPolicyTableTotalData = event.payload
    } else if (event.type === UpdateBusinessHourTableCurrentPageEvent.type) {
        state.businessHourTableCurrentPage = event.payload || 1
    } else if (event.type === UpdateBusinessHourTableTotalDataEvent.type) {
        state.businessHourTableTotalData = event.payload
    } else if (event.type === IncidentTableTotalDataEvent.type) {
        state.incidentsTotal = event.payload
    }
    return state
}

export const userRolesAndPermissionsReducer = (state: UserRolesAndPermissions = {}, event: CommonMiscStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateUserRolesAndPermissionsEvent.type) {
        state = event.payload
    }
    return state
}
