export default class PageService {
    private static instance: PageService
    page: number = 1

    static getInstance(): PageService {
        if (!PageService.instance) {
            PageService.instance = new PageService()
        }
        return PageService.instance
    }
}
