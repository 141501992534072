import {
    axiosFactory as commonAxiosFactory,
    axiosNonAuthFactory,
    ResponseContentDetails,
    ResponseDetails
} from '../common-rest'
import {BASE_URL_DASHBOARD, BASE_URL_AUTH} from './service'
import {AxiosRequestConfig} from 'axios'

export const axiosFactory = commonAxiosFactory(BASE_URL_DASHBOARD)
export const axiosFactoryAuth = commonAxiosFactory(BASE_URL_AUTH)
export const axiosFactoryWithOutBearer = axiosNonAuthFactory(BASE_URL_DASHBOARD)

// for sla application
export function axiosGetDashboard<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseDetails<T>>(url, config)
}
export function axiosGetDashboardWithPagination<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().get<ResponseContentDetails<T>>(url, config)
}
export function axiosDeleteDashboard<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosFactory().delete<ResponseDetails<T>>(url, config)
}
export function axiosPostDashboard<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().post<ResponseDetails<T>>(url, data, config)
}
export function axiosPutDashboard<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().put<ResponseDetails<T>>(url, data, config)
}
export function axiosPatchDashboard<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosFactory().patch<ResponseDetails<T>>(url, data, config)
}
