import { buildStateEvent, GenericStateEvent } from '../common-redux'
import { CustomFiledWithWorkFlow } from './model'

export interface CustomFiledWithWorkFlowStateEvent<T> extends GenericStateEvent<T> { }

export const GetAllCustomFiledWithWorkFlowSuccessEvent = {
    type: 'GetAllCustomFiledWithWorkFlowSuccessEvent',
    build: (payload: CustomFiledWithWorkFlow[]) => buildStateEvent(GetAllCustomFiledWithWorkFlowSuccessEvent.type, payload)
}

export const AddCustomFiledWithWorkFlowSuccessEvent = {
    type: 'AddCustomFiledWithWorkFlowSuccessEvent',
    build: (payload: CustomFiledWithWorkFlow) => buildStateEvent(AddCustomFiledWithWorkFlowSuccessEvent.type, payload)
}

export const DeleteCustomFiledWithWorkFlowSuccessEvent = {
    type: 'DeleteCustomFiledWithWorkFlowSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteCustomFiledWithWorkFlowSuccessEvent.type, payload)
}

export const UpdateCustomFiledWithWorkFlowSuccessEvent = {
    type: 'UpdateCustomFiledWithWorkFlowSuccessEvent',
    build: (payload: CustomFiledWithWorkFlow) => buildStateEvent(UpdateCustomFiledWithWorkFlowSuccessEvent.type, payload)
}
