import { Row, Form, Col, Tooltip, Input, Button, Icon, Modal, Tag, message, Select, Spin, Empty } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import {Link} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { getAllCategory, getAllItems, getAllSubCategory } from '../category'
import { getAllSupportTeamByPermission } from '../support-team'
import { getAllStatus } from '../status'
import { PriorityLevel, getAllPriority } from '../priority'
import { getAllUrgency } from '../urgency'
import { getAllImpact } from '../impact'
import {getAllGeneralIncidentSetting, getTemplateByid, searchTicketTemplate} from '../general-incident-setting/service'
import { IncidentWithSlaCalculations, templeteList } from './model'

const mapStateToProps = (state: StoreState) => {
    return {
        incidents: state.incidentState?.incidents || [],
        impacts: state.impacts,
        urgencies: state.urgencies,
        priority: state.priorities,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        statuses: state.statuses,
        userRolesAndPermissions: state.userRolesAndPermissions,
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllStatus: (status: string | undefined) => Promise<number>
    getAllPriority: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
}

interface Params {
    isVisible: boolean
    setVisible: Function
}
type Props = Params & FormComponentProps & StateProps & DispatchProps
const { Search } = Input
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal
const { CheckableTag } = Tag
const CloneTicketList: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, resetFields } = props.form
    const [checked, setChecked] = useState<number>()
    const [listTemplete, setListTemplete] = useState<templeteList[]>([])
    const [isLoadingListTemplete, setIsLoadingListTemplete] = useState(false)
    const [isLoadingDetail, setIsLoadingDetail] = useState(false)
    const [IncidentData, setIncidentData] = useState<IncidentWithSlaCalculations>()
    const [PriorityLevelTemplate, setPriorityLevelTemplate] = useState<PriorityLevel>()

    useEffect(() => {
        // fetchDataToImpact().catch((err) => message.error(`Failed loading initial impact data. ${err}`))
        // fetchDataToUrgency().catch((err) => message.error(`Failed loading initial urgency data. ${err}`))
        // fetchDataToStatus(undefined).catch((err) => message.error(`Failed loading initial status data. ${err}`))
        // fetchPriority().catch((err) => message.error(`Failed loading initial priority data. ${err}`))
        // fetchTicketTypeAndChannel()
        const promiseAll: any[] = []
        promiseAll.push(fetchDataToImpact())
        promiseAll.push(fetchDataToUrgency())
        promiseAll.push(fetchDataToStatus(undefined))
        promiseAll.push(fetchPriority())
        promiseAll.push(props.getAllGeneralIncidentSetting())
        Promise.all(promiseAll).catch((err) => {
            message.error(`Failed fetching all Data. ${err}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isVisible) {
            setIsLoadingListTemplete(true)
            searchTicketTemplate(1, 20, 'lastModified', 'desc', undefined, 'true').then((results) => {
                setListTemplete(results.data)
                setIncidentData(undefined)
                resetFields()
            }).catch((err) => {
                message.error(`Failed loading Templete List data. ${err}`)
            }).finally(() => {
                setIsLoadingListTemplete(false)
            })
        } else {
            setChecked(undefined)
        }
    }, [props.isVisible])

    const fetchDataToStatus = (status: string | undefined) => {
        return props.getAllStatus(status)
    }

    const fetchDataToImpact = () => {
        return props.getAllImpact()
    }

    const fetchDataToUrgency = () => {
        return props.getAllUrgency()
    }

    const fetchPriority = () => {
        return props.getAllPriority()
    }

    // const fetchTicketTypeAndChannel = () => {
    //     props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
    // }

    const handleSearchTemplete = (e) => {
        setIsLoadingListTemplete(true)
        if (e?.replace(/ +/g, ' ')?.trim()) {
            searchTicketTemplate(1, 20, 'lastModified', 'desc', e?.replace(/ +/g, ' ')?.trim(), 'true').then((results) => {
                setListTemplete(results.data)
                setIncidentData(undefined)
                resetFields(['Impact', 'Urgency', 'TicketType', 'topic', 'description', 'Assignment_group', 'Assigned_to'])
                setFieldsValue({
                    Search: e?.replace(/ +/g, ' ')?.trim()
                })
            }).catch((err) => {
                message.error(`Failed loading Templete List data. ${err}`)
            }).finally(() => {
                setIsLoadingListTemplete(false)
                setChecked(undefined)
            })
        } else {
            searchTicketTemplate(1, 20, 'lastModified', 'desc', undefined, 'true').then((results) => {
                setListTemplete(results.data)
                setIncidentData(undefined)
                resetFields(['Impact', 'Urgency', 'TicketType', 'topic', 'description', 'Assignment_group', 'Assigned_to'])
            }).catch((err) => {
                message.error(`Failed loading Templete List data. ${err}`)
            }).finally(() => {
                setIsLoadingListTemplete(false)
                setChecked(undefined)
            })
        }
    }

    // function showDeleteConfirm() {
    //     confirm({
    //         title: 'Are you sure?',
    //         content: 'Do you confirm Delete of this data ?',
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         onOk() {
    //             if (IncidentData) {
    //                 DeletedTempleteByID(IncidentData[0].id).then(() => {
    //                     message.success('Delete data successfully.')
    //                     setIsLoadingListTemplete(true)
    //                     searchAllTemplete().then((results) => {
    //                         setListTemplete(results)
    //                         setIncidentData(undefined)
    //                         resetFields()
    //                     }).catch((err) => {
    //                         message.error(`Failed loading Templete List data. ${err}`)
    //                     }).finally(() => {
    //                         setIsLoadingListTemplete(false)
    //                     })
    //                 }).catch((err) => {
    //                     message.error(`Failed Delete data. ${err}`)
    //                 })
    //             }
    //         },
    //         onCancel() {
    //             console.log('Cancel')
    //         }
    //     })
    // }

    const handleCheck = (value: templeteList) => {
        if (value.id) {
            setChecked(value.id)
            setIsLoadingDetail(true)
            getTemplateByid(value.id.toString()).then((templete) => {
                setIncidentData(templete)
                setPriorityLevelTemplate(templete?.priority.priority)
                setFieldsValue({
                    topic: templete?.topic,
                    description: templete?.description,
                    Impact: templete?.priority?.impact?.id,
                    Urgency: templete?.priority?.urgency?.id,
                    TicketType: templete?.ticketType?.id
                    // Assignment_group: templete?.assignmentGroup?.value,
                    // Assigned_to: templete?.assignedTo?.value
                    // Categories: templete[0]?.categoryId?.name,
                    // subcategory: templete[0]?.subcategoryId?.name,
                    // Item: templete[0]?.itemId?.name
                })
            }).finally(() => {
                setIsLoadingDetail(false)
            })
        }
    }

    const cloneData = () => {
        if (IncidentData) {
            sessionStorage.setItem('cloneData', JSON.stringify(IncidentData))
        }
    }

    return (
        <div>
            <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={14} xl={14}>
                    <Form>
                        <Form.Item>
                            <Tooltip placement='bottom' title={'Can Search Ticket Number and Template Name'} overlayStyle={{maxWidth: 500}}>
                                {getFieldDecorator('Search')(
                                    <Search
                                        placeholder="Can Search Ticket Number and Template Name"
                                        onSearch={(e) => handleSearchTemplete(e)}
                                        allowClear
                                        style={{ width: '100%'}}
                                    />
                                )}
                            </Tooltip>
                        </Form.Item>
                    </Form>
                </Col>
                {/* <Col xs={24} sm={24} md={6} xl={6} style={{ textAlign: 'right', marginTop: 5 }}>
                    <Button type='default' onClick={showDeleteConfirm}><Icon type="delete" /> Delete</Button>
                </Col> */}
                <Col xs={24} sm={24} md={10} xl={10} style={{ marginTop: 5, textAlign: 'right'}}>
                    <Link to="/IncidentForm"><div><Button type='primary' onClick={() => cloneData()} disabled={checked === undefined}><Icon type="copy" /> Select Ticket</Button></div></Link>
                </Col>
            </Row>
            <br/><br/>
            <Spin tip="Loading..." spinning={isLoadingListTemplete}>
                <Row gutter={[8, 8]} style={{ border: '2px solid rgb(245, 245, 245, 1)'}}>
                    <Col xs={24} sm={24} md={6} xl={6} style={{ backgroundColor: 'rgba(245, 245, 245, 1)', minHeight: 500 }} className='Box-Scroll-Templete'>
                        {listTemplete.map((data) => {
                            return <CheckableTag
                                checked={checked === data.id}
                                onChange={() => handleCheck(data)}
                                key={data.id?.toString()}
                                style={{
                                    backgroundColor: checked === data.id ? 'rgba(246, 146, 30, 0.2)' : '#fff',
                                    borderColor: checked === data.id ? 'rgba(246, 146, 30, 1)' : 'rgba(229, 229, 229, 1)',
                                    width: '90%',
                                    minHeight: 55,
                                    margin: 5,
                                    padding: 5,
                                    paddingLeft: 15
                                }}>
                                <div>{data.ticketNumber === 'null' ? '-' : data.ticketNumber}</div>
                                <div className='box-word-warp'>{data.templateName}</div>
                            </CheckableTag>
                        })}
                    </Col>

                    <Col xs={24} sm={24} md={18} xl={18}>
                        {checked ? <Spin tip="Loading..." spinning={isLoadingDetail}>
                            <div style={{ textAlign: 'end' }}>
                                <span style={{ marginLeft: 20 }}>
                                    {PriorityLevelTemplate ? (
                                        <Tag style={{ backgroundColor: PriorityLevelTemplate?.tagColor, color: '#fff' }}>{PriorityLevelTemplate?.name}</Tag>
                                    ) : null}
                                </span>
                            </div>
                            <Form layout="vertical" style={{ padding: 10 }}>
                                <Row>
                                    <Row gutter={16}>
                                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item label="Impact">
                                                {getFieldDecorator('Impact')(
                                                    <Select placeholder="Select an impact"
                                                        disabled={true}
                                                    >
                                                        {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                                            return <Option value={item.id} key={index}>{item.name}</Option>
                                                        }))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item label="Urgency">
                                                {getFieldDecorator('Urgency')(
                                                    <Select placeholder="Select an urgency"
                                                        disabled={true}
                                                    >
                                                        {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                                            return <Option value={item.id} key={index}>{item.name}</Option>
                                                        }))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item label="Ticket Type">
                                                {getFieldDecorator('TicketType')(
                                                    <Select placeholder="Select an Ticket Type" disabled={true}>
                                                        {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                                            return <Option value={item.id} key={index}>{item.value}</Option>
                                                        }))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>
                                            <Form.Item label="Topic">
                                                {getFieldDecorator('topic')(
                                                    <Input type="text" placeholder="Topic" maxLength={255} disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ height: 140 }}>
                                            <Form.Item label="Description">
                                                {getFieldDecorator('description')(
                                                    <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 4 }} placeholder={'Description'} disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/* <Row gutter={16}>
                                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item label="Assignment group">
                                                {getFieldDecorator('Assignment_group', { initialValue: '' })(
                                                    <Input placeholder="Assignment group" disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item label="Assigned to">
                                                {getFieldDecorator('Assigned_to', { initialValue: '' })(
                                                    <Input placeholder="Assigned to" disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                    {/* <Row gutter={16}>
                                        <Col span={8} lg={8} md={8} sm={24} xs={24}>
                                            <Form.Item label="Category">
                                                {getFieldDecorator('Categories', { initialValue: '' })(
                                                    <Input placeholder="Category" disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} lg={8} md={8} sm={24} xs={24}>
                                            <Form.Item label="Subcategory">
                                                {getFieldDecorator('subcategory', { initialValue: '' })(
                                                    <Input placeholder="Subcategory" disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} lg={8} md={8} sm={24} xs={24}>
                                            <Form.Item label="Item">
                                                {getFieldDecorator('Item', { initialValue: '' })(
                                                    <Input placeholder="Item" disabled={true}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                </Row>
                            </Form>
                        </Spin> : <Empty style={{ marginTop: 120 }}/>}
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

const MyFormCloneTicketList = Form.create<Props>({ name: 'FormCloneTicketList' })(CloneTicketList)
export default connect(mapStateToProps, {
    getAllCategory,
    getAllSubCategory,
    getAllItems,
    getAllSupportTeamByPermission,
    getAllStatus,
    getAllPriority,
    getAllUrgency,
    getAllImpact,
    getAllGeneralIncidentSetting
})(MyFormCloneTicketList)
