import { Col, Icon, message, Modal, Row } from 'antd'
import React, { useContext } from 'react'
import { AssetRelatedContext } from '../../../../Preventive/ContextAPI/IncidentTicketRelatedContext'
import { deleteRelateAssetAndProblems, getRelateAssetAndProblems } from '../../service'
import { useParams} from 'react-router-dom'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'

interface Param {
    it: any
    isNonApproved?: boolean
}
type Props = Param
const { confirm } = Modal
const DisplayAsset = (props: Props) => {
    const { DeleteAssetRelated, GetAssetRelated } = useContext<any>(AssetRelatedContext)
    const { id }: any = useParams()
    const deleteAssetRelatedTicket = (relateId: number) => {
        if (props.isNonApproved === false) {
            confirm({
                title: 'Are you sure ?',
                content: 'Do you confirm delete of this data ?',
                onOk() {
                    DeleteAssetRelated(relateId)
                    deleteRelateAssetAndProblems(relateId.toString()).then(() => {
                        message.success('Asset deleted successfully')
                        getRelateAssetAndProblems(id).then((data) => {
                            const convertData = data.map((it) => {
                                return {
                                    relateId: it.id,
                                    id: it.asset.id,
                                    serialNumber: it.asset.serialNumber,
                                    status: it.asset.status,
                                    assetName: it.asset.assetName,
                                    assetGroup: it.asset.assetGroup,
                                    site: it.asset.site,
                                    location: it.asset.location,
                                    building: it.asset.building,
                                    floor: it.asset.floor,
                                    people: it.asset.people,
                                    pbAssetOriginal: it.pbAssetOriginal,
                                    active: it.active
                                }
                            })
                            GetAssetRelated(convertData)
                        })
                    })
                },
                onCancel() { }
            })
        }
    }
    console.log(props.it)
    return (
        <div key={props.it.serialNumber} className="TicketRelateCard">
            <Row>
                <Row>
                    <Row>
                        <Col span={22} xl={22} lg={22} md={20}>
                            <div className='text-herder-Orenge'> Serial Number : {props.it?.serialNumber || props.it?.asset?.serialNumber}</div>
                        </Col>
                        <Col span={2} xl={2} lg={2} md={4} className={props.isNonApproved === false ? 'TicketRelateCard-DeleteButton' : ''} style={{ marginLeft: 30 }}>
                            {props.isNonApproved === false || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <Icon onClick={(e) => { deleteAssetRelatedTicket(Number(props.it.relateId)) }} type="delete" /> : null}
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Asset Status</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.assetStatus || props.it?.status || props.it?.asset?.assetStatus || '-'}</p>
                        </Col>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Group</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.group || props.it?.asset?.assetGroup.name || '-'}</p>
                        </Col>
                    </Row>
                    <hr style={{ color: '#D0D5DD' }}/>
                    <Row>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Asset Status</b> </div>
                            <p style={{ color: '#757F8D'}}>{props.it?.assetStatus || props.it?.status || props.it?.asset?.assetStatus || '-'}</p>
                        </Col>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Owner ID</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.people?.employeeId || props.it?.asset?.people?.employeeId} - {props.it?.people?.fullName || props.it?.asset?.people?.fullName }</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Site</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.site || props.it?.asset?.site || '-'}</p>
                        </Col>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Location</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.location || props.it?.asset?.location || '-'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Building</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.building || props.it?.asset?.building || '-'}</p>
                        </Col>
                        <Col span={12}>
                            <div className='custom-formLabel-wo'><b>Floor</b></div>
                            <p style={{ color: '#757F8D'}}>{props.it?.floor || props.it?.asset?.floor || '-'}</p>
                        </Col>
                    </Row>
                </Row>
            </Row>
        </div>
    )
}

export default DisplayAsset
