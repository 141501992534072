import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Table } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { ProblemStatus } from '../../problem-management/problems/Model'
import { Link } from 'react-router-dom'
import { waitingGetSessions } from '../../guard/route'
import { getAllApproveProblem, GetAllStatusProblem, updateProblem } from '../../problem-management/problems/service'
import { ApproveProblem } from './model'
import { ProblemType } from '../../problem-management/general/states/model'
import { addAllTypeProblems } from '../../problem-management/general/states/service'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'

interface Params {
    problemsList: ApproveProblem[]
    totalElements: number
}

type Props = Params & FormComponentProps
const { confirm } = Modal
const ApproveProblems: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, validateFields, resetFields, getFieldValue } = props.form
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [onSearch, setOnSearch] = useState<boolean>(false)
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
    const [isLoadingForButton, setIsLoadingForButton] = useState<boolean>(false)
    const [dataProblem, setDataProblem] = useState<ApproveProblem[]>(props.problemsList)
    const [totalCount, setTotalCount] = useState(props.totalElements)
    const [allStatus, setAllStatus] = useState<ProblemStatus[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [keyLoading, setKeyLoading] = useState<number>()
    const [typeProblems, setTypeProblems] = useState<ProblemType>()
    const [targetCancel, setTargetCancel] = useState<ApproveProblem>()
    const [typeAction, setTypeAction] = useState('')

    useEffect(() => {
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyProblems = res.find((p) => { return p.type === 'Problem' && p.active === true })
                setTypeProblems(filterTypyProblems)
                // const filterTypyPTask = res.filter((p) => { return p.type === 'PTask' && p.active === true })
                // setPTaskTypeProblems(filterTypyPTask)
            }
        })
    }, [])

    useEffect(() => {
        if (typeProblems && typeProblems.id) {
            GetAllStatusProblem(typeProblems.id!!).then((status) => {
                setAllStatus(status)
            })
        }
    }, [typeProblems])

    useEffect(() => {
        getAllApproveProblem(currentPage, pageSize, 'lastModifiedDatetime', 'desc').then((data) => {
            console.log(data)
            setDataProblem(data.content)
            setTotalCount(data.totalElements)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch, currentPage])

    const columns = [{
        title: 'Ticket Number',
        dataIndex: '',
        key: '',
        width: '15%',
        render: (data) => {
            return (
                <Link to={`/ProblemDetailView/${data.id}`} onClick={() => waitingGetSessions()}>{data.number?.split('/')[0]}</Link>
            )
        }
    },
    {
        title: 'Topic',
        dataIndex: 'topic',
        key: 'itemSummary',
        width: '40%'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        // eslint-disable-next-line react/display-name
        render: (data: any, row: ApproveProblem) => {
            return (
                <span>{row?.pbStatus?.status === 'Assess' ? 'Awaiting Approval' : ''}</span>
            )
        }
    },
    {
        title: '',
        key: 'action',
        width: '25%',
        // eslint-disable-next-line react/display-name
        render: (data: ApproveProblem) => {
            if (data?.pbStatus?.status === 'Assess') {
                return (
                    <Row gutter={[16, 16]}>
                        <span className='approval-button-group'>
                            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} style={{ textAlign: 'right' }}>
                                <Button type="default" id={data.id?.toString()} loading={isLoading && data.id === keyLoading} onClick={() => handleCancel(data)} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}><Icon type="stop" /> Cancal</Button>
                            </Col>
                            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} style={{ textAlign: 'right' }}>
                                <Button type="danger" style={{ width: 90 }} id={data.id?.toString()} loading={isLoading && data.id === keyLoading} onClick={() => onReject(data)} disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}><Icon type="close" /> Reject</Button>
                            </Col>
                            <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} style={{ textAlign: 'right' }}>
                                <Button type='primary' style={{ width: 90 }}
                                    id={data.id?.toString()}
                                    disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}
                                    onClick={() => onSubmitApprove(data)} loading={isLoading && data.id === keyLoading}><Icon type="check" /> Approve</Button>
                            </Col>
                        </span>
                    </Row>
                )
            } else if (data?.pbStatus?.status === 'Root Cause Analysis') {
                return (
                    <span>You have Successfully Approved.</span>
                )
            } else if (data?.pbStatus?.status === 'New') {
                return (
                    <span>You have Successfully Rejected.</span>
                )
            } else if (data?.pbStatus?.status === 'Cancel') {
                return (
                    <span>You have Successfully Cancel.</span>
                )
            }
        }
    }]

    const handleOnClickPageNumber = (page: number) => {
        setCurrentPage(page)
        setOnSearch(true)
    }

    const handleCancel = (dataProblems: ApproveProblem) => {
        setIsVisibleModal(true)
        setTargetCancel(dataProblems)
        setTypeAction('cancel')
    }

    const onReject = (dataProblems: ApproveProblem) => {
        setIsVisibleModal(true)
        setTargetCancel(dataProblems)
        setTypeAction('reject')
    }

    const onSubmitApprove = (dataProblems: ApproveProblem) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Approve of this data ?',
            onOk() {
                const filterStatusNew = allStatus.find((status) => status.status.toLowerCase() === 'root cause analysis')
                const convertData:any = { pbStatus: { id: filterStatusNew?.id }}
                if (dataProblems?.id) {
                    setKeyLoading(dataProblems.id)
                    setIsLoading(true)
                    updateProblem(dataProblems?.id.toString(), convertData).then(() => {
                        resetFields(['Reason'])
                        const updateData = {...dataProblems, pbStatus: filterStatusNew}
                        const convertData = dataProblem.map((data) => {
                            if (data.id === dataProblems.id) {
                                return updateData
                            } else {
                                return data
                            }
                        })
                        setDataProblem(convertData)
                    }).finally(() => {
                        setIsLoading(false)
                        setKeyLoading(undefined)
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const onCancel = () => {
        setIsVisibleModal(false)
        resetFields()
    }

    const onApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>, dataProblems?: ApproveProblem) => {
        console.log('onApprove', event.currentTarget.id)
        validateFields((error, row) => {
            if (error) {
                return
            }
            setIsLoadingForButton(true)
            const filterStatusNew = allStatus.find((status) => typeAction === 'cancel' ? status.status.toLowerCase() === 'cancel' : status.status.toLowerCase() === 'new')
            let convertData
            if (typeAction === 'cancel') {
                convertData = {
                    cancelReason: getFieldValue('Reason'),
                    pbStatus: { id: filterStatusNew?.id }
                }
            } else {
                convertData = {
                    rejectReason: getFieldValue('Reason'),
                    pbStatus: { id: filterStatusNew?.id }
                }
            }
            if (dataProblems) {
                setKeyLoading(dataProblems.id)
                setIsLoading(true)
                if (dataProblems?.id) {
                    updateProblem(dataProblems?.id.toString(), convertData).then(() => {
                        resetFields(['Reason'])
                        const updateData = {...dataProblems, pbStatus: filterStatusNew}
                        const convertData = dataProblem.map((data) => {
                            if (data.id === dataProblems.id) {
                                return updateData
                            } else {
                                return data
                            }
                        })
                        setDataProblem(convertData)
                    }).then(() => {
                        message.success('You have Successfully cancel the data.')
                    }).catch(() => {
                        message.error('Error can not Update Data.')
                    }).finally(() => {
                        setIsLoading(false)
                        setKeyLoading(undefined)
                        setIsVisibleModal(false)
                        setIsLoadingForButton(false)
                    })
                }
            }
        })
    }

    return (
        <div>
            <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
                <Form>
                    <Card className='card-height-approval-default'>
                        <Row>
                            <h2 className="main-title">
                            Service Request ({totalCount || props.totalElements || '0'})
                            </h2>
                        </Row>
                        <div style={{ overflowX: 'auto' }}>
                            <Row>
                                <Table
                                    className="table-configherder"
                                    dataSource={dataProblem || props.problemsList || []}
                                    columns={columns}
                                    rowKey="id"
                                    style={{ minWidth: 500 }}
                                    pagination={{
                                        pageSize: pageSize,
                                        current: currentPage,
                                        total: totalCount || 0,
                                        onChange: (event) => { handleOnClickPageNumber(event) }
                                    }}
                                />
                            </Row>
                        </div>
                    </Card>
                </Form>
            </Col>
            <Modal
                title={<div style={{ fontSize: '16px', fontWeight: 600}}><Icon type="question-circle" style={{ color: '#FAAD14', fontSize: '18px' }}/>  Are you sure?</div>}
                visible={isVisibleModal}
                onCancel={onCancel}
                closable={false}
                footer={[
                    <Button key="back" onClick={onCancel}>
                            Cancel
                    </Button>,
                    <Button key="submit" loading={isLoadingForButton} type="primary" onClick={(e) => onApprove(e, targetCancel)}>
                            Submit
                    </Button>
                ]}>
                <div style={{ marginLeft: 15 }}>
                    <div><b>Do you confirm {typeAction === 'cancel' ? 'cancel' : 'reject'} of this data ?</b></div>
                    <Form>
                        <Form.Item label='reason' className='custom-formLabel-wo'>
                            {getFieldDecorator('Reason', {
                                rules: [{ required: true, message: 'Approve reason is required' },
                                    { min: 3, message: 'Enter more than 3 characters' }]
                            })(
                                <Input.TextArea rows={4} placeholder="Reason" autoSize={{ minRows: 4, maxRows: 4 }} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

const ApproveProblemsListForm = Form.create<Props>()(ApproveProblems)
export default ApproveProblemsListForm
