import {buildStateEvent, GenericStateEvent} from '../../../common-redux'
import {WorkFlow} from './Model'

export interface WorkFlowStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllWorkFlowSuccessEvent = {
    type: 'GetAllWorkFlowSuccessEvent',
    build: (payload: WorkFlow[]) => buildStateEvent(GetAllWorkFlowSuccessEvent.type, payload)
}
export const AddWorkFlowSuccessEvent = {
    type: 'AddWorkFlowSuccessEvent',
    build: (payload: WorkFlow) => buildStateEvent(AddWorkFlowSuccessEvent.type, payload)
}
export const UpdateWorkFlowSuccessEvent = {
    type: 'UpdatePeopleSuccessEvent',
    build: (payload: WorkFlow) => buildStateEvent(UpdateWorkFlowSuccessEvent.type, payload)
}
export const UpdateWorkFlowTotalPageSuccessEvent = {
    type: 'UpdateWorkFlowTotalPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateWorkFlowTotalPageSuccessEvent.type, payload)
}
export const UpdateWorkFlowCurrentPageSuccessEvent = {
    type: 'UpdateWorkFlowCurrentPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateWorkFlowCurrentPageSuccessEvent.type, payload)
}
// export const UpdateWorkFlowOrderBySuccessEvent = {
//     type: 'UpdateWorkFlowOrderBySuccessEvent',
//     build: (payload: string) => buildStateEvent(UpdateWorkFlowOrderBySuccessEvent.type, payload)
// }
// export const UpdateWorkFlowFilterSuccessEvent = {
//     type: 'UpdateWorkFlowFilterSuccessEvent',
//     build: (payload: string[]) => buildStateEvent(UpdateWorkFlowFilterSuccessEvent.type, payload)
// }
// export const UpdateWorkFlowSearchValueSuccessEvent = {
//     type: 'UpdateWorkFlowSearchValueSuccessEvent',
//     build: (payload: string) => buildStateEvent(UpdateWorkFlowSearchValueSuccessEvent.type, payload)
// }
// export const UpdateWorkFlowSortBySuccessEvent = {
//     type: 'UpdateWorkFlowSortBySuccessEvent',
//     build: (payload: string) => buildStateEvent(UpdateWorkFlowSortBySuccessEvent.type, payload)
// }
export const DeleteWorkFlowSuccessEvent = {
    type: 'DeleteWorkFlowSuccessEvent',
    build: (payload: number) => buildStateEvent(DeleteWorkFlowSuccessEvent.type, payload)
}
