import { BaseDocument } from '../../common-model'
import {TicketStatus} from '../../incident-management/incident'
import {BaseHistory} from '../sla-policy/model'

export interface SlaGlobalSetting extends BaseDocument, BaseHistory {
    ticketType?: string,
    measurementClockStartType?: SlaMeasurementClockStartType,
    ticketStatuses?: TicketStatus[],
    version?: number
}

export enum TicketType {
    Incident = 'Incident'
}

// export interface TicketStatus {
//     name?: string
//     order?: number
// }

export enum SlaMeasurementClockStartType {
    Attach = 'Attach', // Clock start from first time attached
    Status = 'Status' // Clock start from first time status of ticket been in status to start clock
}
