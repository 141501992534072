export interface UserRole {
    id?: string
    createdBy?: string
    lastModifiedBy?: string
    createdDatetime?: string
    lastModifiedDatetime?: string
    name?: string
    security?: PermissionAndScope
    incident?: PermissionAndScope
    knowledge?: PermissionAndScope
    asset?: PermissionAndScope
    pm?: PermissionAndScope
    serviceCatalog?: PermissionAndScope
    workOrder?: PermissionAndScope
}

export interface PermissionAndScope {
    push(obj: PermissionAndScope)
    permissions?: string[]
    scope?: string
}

export interface RoleState {
    security?: PermissionAndScope
    incident?: PermissionAndScope
    knowledge?: PermissionAndScope
    asset?: PermissionAndScope
    pm?: PermissionAndScope
    serviceCatalog?: PermissionAndScope
    admin?: PermissionAndScope
    workOrder?: PermissionAndScope
}

export enum RoleType {
    Security = 'security',
    Incident = 'incident',
    Knowledge = 'knowledge',
    Asset = 'asset',
    PM = 'pm',
    ServiceCatalog = 'serviceCatalog',
    Admin = 'admin',
    WO = 'workOrder',
    Problem = 'problem'
}

export enum ScopeType {
    GlobalAccess = 'GlobalAccess',
    GroupAccess = 'GroupAccess',
    RestrictedAccess = 'RestrictedAccess',
    SelectedGroupAccess = 'SelectedGroupAccess'
}
