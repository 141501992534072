import { Col, Icon, message, Modal, Row } from 'antd'
import React, { useContext } from 'react'
import { KnowledgeRelatedContext } from '../../../../Preventive/ContextAPI/KnowledgeRelatedContext'
import { deleteRelateKnowledgeAndProblems, getRelateKnowledgeAndProblems } from '../../service'
import { useParams} from 'react-router-dom'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'

interface Param {
    it: any
    isNonApproved?: boolean
}
type Props = Param
const { confirm } = Modal
const DisplayKnowledge = (props: Props) => {
    const { DeleteKnowledgeRelated, GetKnowledgeRelated } = useContext<any>(KnowledgeRelatedContext)
    const isPTask = window.location.pathname.includes('ProblemTaskDetailView')
    const { id }: any = useParams()
    const isRenderPTask = window.location.pathname.includes('/ProblemTaskDetailView')
    const deleteKnowledgeRelatedTicket = (relateId?: string) => {
        if (props.isNonApproved === false) {
            confirm({
                title: 'Are you sure ?',
                content: 'Do you confirm delete of this data ?',
                onOk() {
                    if (relateId) {
                        DeleteKnowledgeRelated(relateId)
                        deleteRelateKnowledgeAndProblems(relateId, isPTask).then(() => {
                            message.success('Knowledge deleted successfully')
                            getRelateKnowledgeAndProblems(id, isRenderPTask).then((data) => {
                                const convertData = data.map((it) => {
                                    return {
                                        relateId: it.id,
                                        id: it.knowledge.id,
                                        kmNumber: it.knowledge.kmNumber,
                                        topic: it.knowledge.topic,
                                        helpful: it.knowledge.helpful,
                                        notHelpful: it.knowledge.notHelpful,
                                        views: it.knowledge.views,
                                        used: it.knowledge.used,
                                        createdBy: it.knowledge.createdBy,
                                        active: it.active
                                    }
                                })
                                GetKnowledgeRelated(convertData)
                            })
                        })
                    }
                },
                onCancel() { }
            })
        }
    }

    return (
        <div>
            <div className="TicketRelateCard">
                <Row>
                    <Row>
                        <Row>
                            <Col span={22} xl={21} lg={19} md={18}>
                                <div className='text-herder-Orenge'> Knowledge Number : {props.it?.ticketNumber || props.it?.kmNumber || props.it?.knowledge?.kmNumber} </div>
                            </Col>
                            <Col span={2} xl={2} lg={2} md={4} className={props.isNonApproved === false ? 'TicketRelateCard-DeleteButton' : ''} style={{ marginLeft: 50 }}>
                                {props.isNonApproved === false || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <Icon onClick={() => deleteKnowledgeRelatedTicket(props.it?.relateId || props.it?.knowledge?.relateId)} type="delete"/> : null}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={22}>
                                <div className='custom-formLabel-wo'><b>Created By</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.createdByDisplay || props.it?.createdBy || props.it?.knowledge?.createdBy}</p>
                            </Col>
                        </Row>
                        <hr style={{ color: '#D0D5DD' }}/>
                        <Row>
                            <Col span={22}>
                                <div className='custom-formLabel-wo'><b>Topic</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.topic || props.it?.knowledge?.topic}</p>
                            </Col>
                        </Row>
                    </Row>
                </Row>
                <br />
                <Row>
                    <Col span={1}>
                        <Icon type="like" />
                    </Col>
                    <Col span={1}>
                        <span>{props.it?.helpful || props.it?.knowledge?.helpful ? props.it?.helpful || props.it?.knowledge?.helpful : 0}</span>
                    </Col>
                    <Col span={1}>
                        <Icon type="dislike"/>
                    </Col>
                    <Col span={1}>
                        <span>{props.it?.notHelpful || props.it?.knowledge?.notHelpful ? props.it?.notHelpful || props.it?.knowledge?.notHelpful : 0}</span>
                    </Col>
                    <Col span={1}>
                        <Icon type="eye" />
                    </Col>
                    <Col span={1}>
                        <span>{props.it?.views || props.it?.knowledge?.views ? props.it?.views || props.it.knowledge?.views : 0}</span>
                    </Col>
                    <Col span={1}>
                        <Icon type="check-square" />
                    </Col>
                    <Col span={1}>
                        <span>{props.it?.used || props.it?.knowledge?.used ? props.it?.used || props.it?.knowledge?.used : 0}</span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DisplayKnowledge
