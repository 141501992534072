import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {People} from '../../authorization-module/people/model'

export interface PeopleManagementStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllPeopleSuccessEvent = {
    type: 'GetAllPeopleSuccessEvent',
    build: (payload: People[]) => buildStateEvent(GetAllPeopleSuccessEvent.type, payload)
}
export const AddPeopleSuccessEvent = {
    type: 'AddPeopleSuccessEvent',
    build: (payload: People) => buildStateEvent(AddPeopleSuccessEvent.type, payload)
}
export const UpdatePeopleSuccessEvent = {
    type: 'UpdatePeopleSuccessEvent',
    build: (payload: People) => buildStateEvent(UpdatePeopleSuccessEvent.type, payload)
}
export const UpdatePeopleTotalPageSuccessEvent = {
    type: 'UpdatePeopleTotalPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdatePeopleTotalPageSuccessEvent.type, payload)
}
export const UpdatePeopleCurrentPageSuccessEvent = {
    type: 'UpdatePeopleCurrentPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdatePeopleCurrentPageSuccessEvent.type, payload)
}
export const DeletePeopleSuccessEvent = {
    type: 'DeletePeopleSuccessEvent',
    build: (payload: string) => buildStateEvent(DeletePeopleSuccessEvent.type, payload)
}
export const UpdatePeopleSortBySuccessEvent = {
    type: 'UpdatePeopleSortBySuccessEvent',
    build: (payload: string) => buildStateEvent(UpdatePeopleSortBySuccessEvent.type, payload)
}
export const UpdatePeopleOrderBySuccessEvent = {
    type: 'UpdatePeopleOrderBySuccessEvent',
    build: (payload: string) => buildStateEvent(UpdatePeopleOrderBySuccessEvent.type, payload)
}
export const UpdatePeopleFilterSuccessEvent = {
    type: 'UpdatePeopleFilterSuccessEvent',
    build: (payload: string[]) => buildStateEvent(UpdatePeopleFilterSuccessEvent.type, payload)
}
export const UpdatePeopleSearchFieldSuccessEvent = {
    type: 'UpdatePeopleSearchFieldSuccessEvent',
    build: (payload: string) => buildStateEvent(UpdatePeopleSearchFieldSuccessEvent.type, payload)
}
export const UpdatePeopleSearchValueSuccessEvent = {
    type: 'UpdatePeopleSearchValueSuccessEvent',
    build: (payload: string) => buildStateEvent(UpdatePeopleSearchValueSuccessEvent.type, payload)
}
