import { Row, Col, Form, Select } from 'antd'
import React from 'react'
import { replaceName } from '../../../common-file'
import { searchFetch } from '../service'

interface param {
    form: any
    name: string,
    required: boolean
    autoCompleteData: string[]
    fullData: string[]
    disableItem: boolean
    setState?: Function,
    maxInput?: number,
    isNotShowArrow?: boolean
}

type Props = param
const {Option} = Select
const CustomSelectWithFilter: React.FC<Props> = (props: Props) => {
    return (
        <Row>
            <Col xl={3} md={0}/>
            <Col xl={16} md={24} style={{marginTop: 10}}>
                <Col xl={4} md={24}>
                    <div style={{
                        textAlign: window.innerWidth > 1024 ? 'right' : 'left',
                        marginRight: 8,
                        marginTop: 4
                    }} className={props.required ? 'ant-form-item-required' : ''}>
                        {props.name}
                    </div>
                </Col>
                <Col xl={20} md={24}>
                    <Form.Item>
                        {props.form.getFieldDecorator(replaceName(props.name), {
                            rules: [
                                {required: props.required, message: `${props.name} is required`},
                                {max: props.maxInput || 250, message: `message max ${props.maxInput || 250} characters`}
                            ]
                        })(
                            <Select placeholder={`Select ${props.name}`}
                                onSearch={(value) => {
                                    searchFetch(value, props.fullData || [], props.setState!!)
                                }}
                                allowClear={true}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(inputValue, option) =>
                                    String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                }
                                disabled={props.disableItem}
                                showArrow={!props.isNotShowArrow}
                            >
                                {(props.autoCompleteData || []).map((item, index) => {
                                    return <Option value={item} key={index} title={item}>{item}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Col>
            <Col xl={5} md={0}/>
        </Row>
    )
}

export default CustomSelectWithFilter
