import React, {useEffect, useState} from 'react'
import {Table} from 'antd'
import {Adjust, ColumnNormal} from './Model'
import moment from 'moment'
import PageService from './ContextAPI/PageService'

interface Param {
    dataTransfer: Adjust[]
    columnsAdjustDataList: string[]
    columnsAssetAdjustmentObjList: ColumnNormal[]
    total: number
    range: any
    handleAdjustChange: Function
}
type Props = Param

const ColumnsAdjustment: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    // useEffect(() => {
    //     if (props.dataTransfer) {
    //         const temp: string[] = []
    //         // https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
    //         props.dataTransfer.forEach((data) => {
    //             const difference = Object.keys(data).filter(x => !listcommon.includes(x))
    //             temp.push(...difference)
    //         })
    //         const uniqueTemp = [...new Set(temp)]
    //         uniqueTemp.forEach((it) => {
    //             const convert = {
    //                 // eslint-disable-next-line
    //                 title: it.includes('adjust') ? 'Data Adjustment\n' + '(' + it.substring(6, it.length - 1) + ')' : it,
    //                 dataIndex: it
    //             }
    //             columnsAdjustData.push(convert)
    //         })
    //         setMyColumns(columnsAdjustData)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.dataTransfer])

    useEffect(() => {
        if (props.columnsAdjustDataList && props.columnsAssetAdjustmentObjList) {
            const temp: ColumnNormal[] = []
            props.columnsAdjustDataList.forEach((it) => {
                const findDataIndex = props.columnsAssetAdjustmentObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    if (findDataIndex.title.includes('Adjust') && !findDataIndex.title.includes('Data')) {
                        findDataIndex.title = 'Data Adjustment\n' + '(' + findDataIndex.title.substring(6, findDataIndex.title.length) + ' )'
                    }
                    temp.push(findDataIndex)
                }
            })
            setTempColumns(temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.columnsAdjustDataList, props.columnsAssetAdjustmentObjList])

    useEffect(() => {
        if (pageSize) {
            PageService.getInstance().data.pageAssetAdjust = 1
            setPage(1)
            props.handleAdjustChange()
        }
    }, [pageSize])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageService.getInstance().data.pageAssetAdjust = 1
        }
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageService.getInstance().data.pageAssetAdjust = page
        props.handleAdjustChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageService.getInstance().data.pageSizeAssetAdjust = pagination.pageSize
    }

    return (
        <div>
            <Table dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns} scroll={{x: true}} rowKey={'id'}
                pagination={ props.range && props.columnsAdjustDataList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" />
        </div>
    )
}
export default ColumnsAdjustment
