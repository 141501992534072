import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {PriorityLevel, PriorityMatrix} from './model'

export interface PriorityStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllPrioritySuccessEvent = {
    type: 'GetAllPrioritySuccessEvent',
    build: (payload: PriorityLevel[]) => buildStateEvent(GetAllPrioritySuccessEvent.type, payload)
}

export const GetPriorityMatrixSuccessEvent = {
    type: 'GetPriorityMatrixSuccessEvent',
    build: (payload: PriorityMatrix) => buildStateEvent(GetPriorityMatrixSuccessEvent.type, payload)
}

export const CreatePriorityMatrixNewVersionSuccessEvent = {
    type: 'CreatePriorityMatrixNewVersionSuccessEvent',
    build: (payload: PriorityMatrix[]) => buildStateEvent(CreatePriorityMatrixNewVersionSuccessEvent.type, payload)
}
