import moment from 'moment'
import { SlaCalculation } from '../sla-management/sla-calculation/model'

export const getLeastTargetTimeOfAllInProgressSlaCalculations = (slaCalculations: SlaCalculation[] | null) => {
    let leastTargetTime = ''
    if (slaCalculations) {
        for (const iterator of slaCalculations) {
            if (iterator.slaStage === 'InProgress') {
                if (leastTargetTime === '') {
                    leastTargetTime = moment(iterator.slaTargetTime).format()
                } else if (moment(leastTargetTime).isAfter(moment(iterator.slaTargetTime))) {
                    leastTargetTime = moment(iterator.slaTargetTime).format()
                }
            }
        }
    }

    return leastTargetTime
}

export const getLeastTargetTimeOfAllInProgressSlaCalculationsReturnModel = (slaCalculations: SlaCalculation[] | null) => {
    let leastTargetTime = ''
    let temp
    if (slaCalculations) {
        for (const iterator of slaCalculations) {
            if (iterator.slaStage === 'InProgress') {
                if (leastTargetTime === '') {
                    leastTargetTime = moment(iterator.slaTargetTime).format()
                    temp = iterator
                } else if (moment(leastTargetTime).isAfter(moment(iterator.slaTargetTime))) {
                    leastTargetTime = moment(iterator.slaTargetTime).format()
                    temp = iterator
                }
            }
        }
    }
    return temp
}
