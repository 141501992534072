import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Select, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { SelfServiceCatagory, SelfServiceCatalog } from '../../Model'
import { StoreState } from '../../../../store'
import { connect } from 'react-redux'
import { getallCatalog, getallCategoryNoRedux, searchCatagory } from './../../service'
interface Param {
    setVisible: Function
    catagoryAction: Function
    catalog?: SelfServiceCatalog
    parents?: SelfServiceCatagory[]
    category?: SelfServiceCatagory
    isVisible?: boolean
    id?: number
    categoryList?: SelfServiceCatagory[]
}

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCatalog: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps & Param
const { Option } = Select
const { TextArea } = Input
const CategoryForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form
    const [, setLockCatalog] = useState(false)
    const [parent, setParent] = useState<SelfServiceCatagory[]>()
    const [CategoryNameList, setCategoryNameList] = useState<string[]>([])

    useEffect(() => {
        props.getallCatalog().catch(err => {
            message.error(err)
        })

        getallCategoryNoRedux().then((data) => {
            const categoryName = data.content.map((value) => {
                return value.name?.toLowerCase()
            })
            if (!props.id) {
                setCategoryNameList(categoryName)
            } else {
                const filterOldName = categoryName.filter((it) => it !== props.category?.name.toLowerCase())
                setCategoryNameList(filterOldName)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    useEffect(() => {
        if (props.isVisible) {
            resetFields()
        }
        if (props.category) {
            setLockCatalog(true)
            setFieldsValue({
                name: props.category?.name,
                catalog: {
                    id: Number(props.category?.catalog?.id) || Number(props.catalog?.id)
                },
                status: props.category?.status,
                description: props.category?.description,
                parent: props.category?.parent
            })
            filterParent(props.category.parent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.category, props.isVisible])

    useEffect(() => {
        if (props.catalog && props.id === undefined) {
            setLockCatalog(true)
            setFieldsValue({
                catalog: {
                    id: Number(props.catalog?.id)
                }
            })
            searchCatagory(1, 100, props.catalog.id, 'createdDatetime', 'desc').then((res) => {
                if (res.content.length !== 0) {
                    const filterParentNull = res.content.filter(cat => cat.parent === null)
                    setParent(filterParentNull?.filter(parent => parent.catalog?.id?.toString() === props.catalog?.id?.toString()))
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.catalog])

    useEffect(() => {
        if (props.parents?.length!! > 0) {
            setParent(props.parents)
        }
    }, [props.parents])

    useEffect(() => {
        if (props.categoryList && props.catalog?.id) {
            searchCatagory(1, 100, props.catalog.id, 'createdDatetime', 'desc').then((res) => {
                if (res.content.length !== 0) {
                    const filterParentNull = res.content.filter(cat => cat.parent === null)
                    setParent(filterParentNull?.filter(parent => parent.catalog?.id?.toString() === props.catalog?.id?.toString()))
                }
            })
        }
    }, [props.categoryList])

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                if (props.setVisible) {
                    props.setVisible(false)
                }

                if (props.catagoryAction) {
                    if (!values.parent.id) {
                        values.parent = null
                    }
                    const tempValues = ({
                        name: values.name.replace(/ +/g, ' ').trim(),
                        catalog: {
                            id: Number(values.catalog?.id)
                        },
                        status: values.status,
                        description: values.description ? values.description.replace(/ +/g, ' ').trim() : '',
                        parent: values.parent
                    })
                    props.catagoryAction(tempValues)
                    setCategoryNameList([...CategoryNameList, values.name.replace(/ +/g, ' ').trim()])
                }
                if (!props.id) {
                    resetFields()
                }
            }
        })
    }
    const handleCancel = () => {
        if (props.setVisible) {
            props.setVisible(false)
            if (!props.id) {
                resetFields()
            }
        }
    }

    const parentOption = () => {
        if ((parent || props.parents) && getFieldValue('catalog.id')) {
            let tempParent
            if (parent?.length === 0) {
                tempParent = props.parents
            } else {
                tempParent = parent
            }
            const filterData = tempParent?.filter((category) => category.parent === null && category.id !== Number(props.id))
            const filterCatalog = filterData?.filter((data) => Number(data.catalog.id) === Number(getFieldValue('catalog.id')))
            if (!tempParent?.find((data) => data.parent?.id === Number(props.id))) {
                return (filterCatalog || []).map((parents) => {
                    return (<Option key={parents.id} value={parents.id}>{parents.name}</Option>)
                })
            }

        }
    }

    const catalogsOption = () => {
        if (props.selfServiceCatalogs) {
            return props.selfServiceCatalogs.content.map((catalog) => {
                return (<Option key={catalog.id} value={catalog.id}>{catalog.name}</Option>)
            })
        }
    }

    const filterParent = (value: string) => {
        if (value !== String(props.id)) {
            searchCatagory(1, 100, value, 'createdDatetime', 'desc').then((res) => {
                if (res.content.length !== 0) {
                    const filterParentNull = res.content.filter(cat => cat.parent === null)
                    setParent(filterParentNull?.filter(parent => parent.catalog?.id?.toString() === value?.toString()))
                }
            })
        } else {
            setParent(props.parents?.filter(parent => parent.catalog?.id?.toString() === value?.toString()))
        }
    }

    const changeParent = (value) => {
        resetFields(['parent.id'])
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        if (pattern.test(value.replace(/ +/g, '').trim())) {
            if (value.replace(/ +/g, '').trim().length >= 3) {
                if (props.form.getFieldValue('name')) {
                    if (CategoryNameList) {
                        if (CategoryNameList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                            callback('The Category Name already exists. It must be unique. Please try another one.')
                        } else {
                            callback()
                        }
                    }
                } else {
                    callback()
                }
            } else {
                callback('Enter more than 3 characters')
            }
        }
        else {
            // eslint-disable-next-line
            if (format.test(value.replace(/ +/g, '').trim())) {
                callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
            } else {
                callback('Enter more than 3 characters')
            }
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit} >
                <Form.Item label={'Catagory Name'}>
                    {getFieldDecorator('name', {
                        rules: [
                            { required: true, message: 'Please input your Catagory Name!' },
                            { max: 60, message: 'max Input 60 characters' },
                            { validator: validateNameUniqueness }
                        ]
                    })(
                        <Input placeholder="Please enter category name" maxLength={60} max={60} />
                    )}
                </Form.Item>
                <Form.Item label={'Catalog Name'}>
                    {getFieldDecorator('catalog.id', {
                        initialValue: props.catalog?.id ? Number(props.catalog?.id) : undefined,
                        rules: [{ required: true, message: 'Please input Catalog Name!' }]
                    })(
                        <Select
                            placeholder="Select Catalog Name"
                            onSelect={filterParent}
                            onChange={changeParent}
                            allowClear
                        >
                            {catalogsOption()}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Parent'}>
                    {getFieldDecorator('parent.id', {
                        rules: [{ required: false, message: 'Please input your Parent!' }]
                    })(
                        <Select placeholder="Select Parent" allowClear>
                            {parentOption()}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Status'}>
                    {getFieldDecorator('status', {
                        rules: [{ required: true, message: 'Please input your Status!' }]
                    })(
                        <Select
                            placeholder="Select Status"
                        >
                            <Option value="Published">Published</Option>
                            <Option value="Draft">Draft</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Description'}>
                    {getFieldDecorator('description', {
                        rules: [{ max: 255, message: 'max Input 255 characters' }]
                    })(
                        <TextArea rows={4} maxLength={255} placeholder='Please enter description' />
                    )}
                </Form.Item>
                <Col span={24} style={{ marginTop: 10 }}>
                    <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                    <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                </Col>
            </Form>
        </>
    )
}

const MyCategoryForm = Form.create<Props>({ name: 'CategoryForm' })(CategoryForm)

export default connect(mapStateToProps,
    { getallCatalog }
)(MyCategoryForm)
