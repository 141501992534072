import React, { memo, FC, useEffect, useState } from 'react'
import {Select, Checkbox, Col, Row, Card, Form} from 'antd'
import { Handle, Position, NodeProps, useUpdateNodeInternals } from 'react-flow-renderer'
import './dnd.css'
import workOrderIcon from '../../../../assets/images/workOrder.png'
import { Properties } from '../../Service/Model'
import { UserSupportTeam } from '../../../../authorization-module/support-team-management/model'
import { StoreState } from '../../../../store'
import { connect } from 'react-redux'

// const onConnect = (params: Connection | Edge) => console.log('handle onConnect', params)
const mapStateToProps = (state: StoreState) => {
    return {
        supportTeams: state.supportTeams
    }
}
const { Option } = Select
type StateProps = ReturnType<typeof mapStateToProps>
type Props = StateProps & NodeProps
const WorkOrderNode: FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.data.form
    const [assignTo, setAssignTo] = useState<UserSupportTeam[]>([])
    const [properties, setProperties] = useState<any>(props.data.properties)
    const updateNodeInternals = useUpdateNodeInternals()
    const isView = window.location.pathname.includes('/WorkflowFormView')
    const isPreviewWorkflow = window.location.pathname.includes('WoFormEdit')

    useEffect(() => {
        if (props.data.properties) {
            // edit mode
            setFieldsValue({
                assignmentGroup: props.data.properties.supportTeam,
                assignmentTo: props.data.properties.assignee,
                notify: props.data.properties.notify
            })
        } else {
            // create mode
            setProperties(prevState => {
                return {
                    ...prevState,
                    notify: true
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        if (props?.supportTeams && props.data.properties?.supportTeam) {
            setAssignTo(props.supportTeams.find((data) => data.name === props.data.properties.supportTeam)?.assignees || [])
        }
    }, [props?.supportTeams])

    useEffect(() => {
        updateNodeInternals(props.id)
    }, [props.data, props.id, updateNodeInternals])

    useEffect(() => {
        props.data.properties = properties
    }, [properties, props.data])

    const onChangeTeam = (e) => {
        setFieldsValue({
            assignmentTo: undefined
        })
        setAssignTo([])
        if (e) {
            if (props.supportTeams) {
                setAssignTo(props.supportTeams.find((data) => data.name === e)?.assignees || [])
            }
        }
        const findAssigngroupId = props.supportTeams.find((data) => { return data.name === e })
        setProperties(prevState => {
            return {
                ...prevState,
                supportTeam: e,
                assignmentGroupId: Number(findAssigngroupId?.id)
            }
        })
    }

    const onChangeAssignTo = (e) => {
        if (e) {
            const targetId = e?.split(':')[0]?.trim()
            const targetUser = e?.split(':')[1]?.trim()
            const findId = assignTo.find((data) => { return data.users.fullName === targetUser && data.users?.employeeId?.toString() === targetId?.toString() })
            if (e !== undefined) {
                setProperties(prevState => {
                    return {
                        ...prevState,
                        assignee: targetUser,
                        assigneeId: Number(findId?.users.id)
                    }
                })
            } else {
                setProperties(prevState => {
                    return {
                        ...prevState,
                        assignee: targetUser,
                        assigneeId: Number(findId?.users.id)
                    }
                })
            }
        } else {
            setProperties(prevState => {
                return {
                    ...prevState,
                    assignee: undefined,
                    assigneeId: undefined
                }
            })
        }
    }

    const onChangeNotify = (e) => {
        if (e?.target) {
            const { checked } = e.target
            setProperties(prevState => {
                return {
                    ...prevState,
                    notify: checked
                }
            })
        }
    }

    return (
        <>
            <Card
                title={
                    <div>
                        <Row>
                            <Col span={5} className="dotForWorkflowIcon" style={{backgroundColor: '#2F80ED'}}><img src={workOrderIcon} alt={'workOrderIcon'} className="workFlowIcon" /></Col>
                            <Col span={19} style={{marginLeft: '10px', width: '80%'}}><p>Work Order</p></Col>
                        </Row>
                    </div>
                }
                className="targetHandleStyle"
            >
                <Form.Item label="Assignment group"
                    // validateStatus={SubmitService.getInstance().isSubmit && props.data.data.supportTeam === undefined ? 'error' : ''}
                >
                    {getFieldDecorator('assignmentGroup', {
                        initialValue: props.data?.properties?.supportTeam || undefined,
                        rules: [{
                            required: true,
                            message: 'Please input Assignment group!'
                        }]
                    })(
                        <Select style={{ width: '100%' }} placeholder="Select assignment group" allowClear onChange={(e) => onChangeTeam(e)} disabled={isView || isPreviewWorkflow} >
                            {props.supportTeams?.map((supportTeam, index) => {
                                return (<Option key={index} value={supportTeam.name}>{supportTeam.name}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Assigned to">
                    {getFieldDecorator('assignmentTo', {
                        initialValue: props.data?.properties?.assignee || undefined
                    })(
                        <Select style={{ width: '100%' }} placeholder="Select Assigned to" allowClear onChange={(e) => onChangeAssignTo(e)} disabled={isView || isPreviewWorkflow}>
                            {assignTo.map((assign, index) => {
                                return (<Option key={index} value={assign.users.employeeId + ' : ' + assign.users.fullName}>{assign.users.employeeId + ' : ' + assign.users.fullName}</Option>)
                            })}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('notify', {
                        initialValue: props.data.properties?.notify || true,
                        valuePropName: 'checked'
                    })(
                        <Checkbox onChange={(e) => onChangeNotify(e)} disabled={isView || isPreviewWorkflow}>Email Notification</Checkbox>
                    )}
                </Form.Item>
            </Card>
            <Handle type="source" position={Position.Bottom} id="w" isConnectable={props.isConnectable} />
        </>
    )
}
// const WorkOrderNodes = Form.create<Props>()(WorkOrderNode)
export default connect(mapStateToProps, {})(memo(WorkOrderNode))
