/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {Button, Col, Form, Input, Row, Select} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import {dropdownDataPublishWorkflow, dropdownDataVisibleTo, dropdownDataRetireWorkflow} from '../knowledge/mock'
import {Category} from './model'
import {createFolder, Folder, GroupsAndManagedBy, updateFolder} from '../folder'
import {createCategory, getAllCategory, updateCategory} from './service'
import {message} from 'antd/es'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'

const {Option} = Select

interface dropdownData {
    id: string
    value: string
}

interface DispatchProps {
    getAllCategory: (groupUser?: string[]) => Promise<number>
}

enum FormType {
    Name = 'Name',
    Description = 'Description',
    Category = 'Category',
    ManageBy = 'Manage By',
    PublishWorkflow = 'Publish Workflow',
    RetireWorkflow = 'Retire Workflow',
    VisibleTo = 'Visible To',
    Groups = 'Groups'
}

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.knowledgeCategories,
        supportTeams: state.supportTeams,
        kmMapRole: state.kmMapRole
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
    categories: Category[]
    isFormVisible: boolean
    isCategoryForm: boolean
    categoryName?: string
    editData?: any
    closeForm: () => void
    setEditedTitle: (data) => void
    kmRole: string[]
}

type Props = StateProps & OwnProps & FormComponentProps & DispatchProps

const CategoryFrom: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue, getFieldValue, resetFields, validateFields, setFields} = props.form
    const [folderNames, setFolderNames] = useState<string[]>([])
    const [defaultCategoryName, setDefaultCategoryName] = useState<string | undefined>('')
    const [defaultFolderName, setDefaultFolderName] = useState<string | undefined>('')
    const [isLoadingCategory, setIsLoadingCategory] = useState<boolean>(false)
    const [isLoadingFolder, setIsLoadingFolder] = useState<boolean>(false)
    const username = sessionStorage.getItem('username')
    const [editedData, setEditedData] = useState<Object>({})

    useEffect(() => {
        setFolderNames([])
        cleanData()
        if (props.isFormVisible) {
            if (props.categoryName) {
                setFieldsValue({
                    Category: props.categoryName
                })
            }
            if (props.editData) {
                if (Object.keys(editedData).length === 0) {
                    initField(props.editData)
                } else {
                    initField(editedData)
                    setEditedData({})
                }
            }
        }
        checkPermissionEdit()
    }, [props.isFormVisible])

    const initField = (data) => {
        if (props.isCategoryForm) {
            const category: Category = data
            setFieldsValue({ Name: category.categoryName })
            setFieldsValue({ Description: category.description })
            setDefaultCategoryName(category.categoryName)
        } else {
            const folder: Folder = data

            const manageBy = (folder.managedBy ?? []).map((item) => {
                return item.groupName
            })
            const groups = (folder.groups ?? []).map((item) => {
                return item.groupName
            })
            setFieldsValue({
                Name: folder.folderName,
                Description: folder.description,
                'Manage By': manageBy || [],
                'Publish Workflow': folder.publicWorkflow,
                'Retire Workflow': folder.retireWorkflow,
                'Visible To': folder.visibleTo,
                Groups: groups
            })
            setDefaultFolderName(folder.folderName)
        }
    }

    const checkPermissionEdit = (): boolean => {
        const folder: Folder = props.editData
        if (!checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
            return true
        } else {
            if (folder?.managedBy?.length !== 0 && !checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle')) {
                // return !folder?.managedBy?.some(it => it.groupName === dataRoleVspace?.name!!)
                return !folder?.managedBy?.some(it => props.kmRole.includes(it.groupName))
            } else {
                return false
            }
        }
    }

    const supportTeamsList = (props.supportTeams || []).map((supportTeam) => {
        const data: dropdownData = {id: supportTeam.name, value: supportTeam.name}
        return data
    }) || []

    const cleanData = () => {
        resetFields()
    }

    const createMenu = (data: dropdownData[], id: FormType, isRequire = true) => {
        return getFieldDecorator(id.toString(), {rules: [{required: isRequire}]})(
            <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select"
                allowClear={true}
                onChange={(value) => handleCleardata(value)}
            >
                {(data || []).map((data) => {
                    return (
                        <Option value={`${data.id}`} key={data.id}>
                            {data.value}
                        </Option>
                    )
                })}
            </Select>
        )
    }

    const createMultipleSelectMenu = (data: dropdownData[], id: FormType, isRequire = true) => {
        return getFieldDecorator(id.toString(), {rules: [{required: isRequire}]})(
            <Select
                showSearch
                mode="multiple"
                style={{width: '100%'}}
                placeholder="Select"
            >
                {(data || []).map((data) => {
                    return (
                        <Option value={`${data.id}`} key={data.id}>
                            {data.value}
                        </Option>
                    )
                })}
            </Select>
        )
    }

    const CategoriesList = props.categories.map((category) => {
        return {
            id: category.id!,
            value: category.categoryName!
        }
    })

    const submitFrom = () => {
        validateFields(
            (error) => {
                if (!error) {
                    const trimName = getFieldValue(FormType.Name).replace(/ +/g, ' ').trim()
                    const trimDesc = getFieldValue(FormType.Description)?.replace(/ +/g, ' ').trim()
                    if (props.isCategoryForm) {
                        setIsLoadingCategory(true)
                        const category: Category = {
                            categoryName: trimName,
                            description: trimDesc,
                            createdBy: username || '',
                            lastModifiedBy: username || ''
                            // isKmVspace: !!roleVspace
                        }
                        if (props.editData) {
                            setIsLoadingCategory(true)
                            category.id = props.editData.id
                            updateCategory(category).then(() => {
                                props.getAllCategory(props.kmMapRole).catch((err) => message.error(`Failed fetching category. ${err}`)).finally(() => {
                                    setIsLoadingCategory(false)
                                    props.closeForm()
                                })
                                message.success('The update has finished successfully.')
                            }).catch((error) => {
                                setIsLoadingCategory(false)
                                if (error.status === 409) {
                                    setFields({
                                        Name: {
                                            value: getFieldValue(FormType.Name),
                                            errors: [new Error(error.data.message)]
                                        }
                                    })
                                } else {
                                    message.error(error.data.message)
                                }
                            })
                        } else {
                            createCategory(category).then(() => {
                                props.getAllCategory(props.kmMapRole).catch((err) => message.error(`Failed fetching category. ${err}`)).finally(() => {
                                    setIsLoadingCategory(false)
                                    props.closeForm()
                                })
                                message.success('You have successfully saved the data.')
                            }).catch((error) => {
                                setIsLoadingCategory(false)
                                if (error.status === 409) {
                                    setFields({
                                        Name: {
                                            value: getFieldValue(FormType.Name),
                                            errors: [new Error(error.data.message)]
                                        }
                                    })
                                } else {
                                    message.error(error.data.message)
                                }
                            })
                        }
                    } else {
                        setIsLoadingFolder(true)
                        let ConvertDataGroups: GroupsAndManagedBy[] = []
                        let ConvertDataManagedBy: GroupsAndManagedBy[] = []

                        ConvertDataManagedBy = (getFieldValue(FormType.ManageBy) || []).map((it) => {
                            return {
                                id: undefined,
                                folderId: undefined,
                                groupName: it
                            }
                        })

                        ConvertDataGroups = (getFieldValue(FormType.Groups) || []).map((it) => {
                            return {
                                id: undefined,
                                folderId: undefined,
                                groupName: it
                            }
                        })
                        const folder: Folder = {
                            folderName: trimName,
                            description: trimDesc,
                            categoryId: getFieldValue(FormType.Category),
                            managedBy: ConvertDataManagedBy,
                            publicWorkflow: getFieldValue(FormType.PublishWorkflow),
                            retireWorkflow: getFieldValue(FormType.RetireWorkflow),
                            visibleTo: getFieldValue(FormType.VisibleTo),
                            groups: ConvertDataGroups,
                            createdBy: username || '',
                            lastModifiedBy: username || ''
                        }
                        console.log(folder);

                        if (props.editData) {
                            setIsLoadingFolder(true)
                            folder.id = props.editData.id
                            updateFolder(folder).then(() => {
                                message.success('The update has finished successfully.')
                                if (props.editData.categoryId.toString() !== folder.categoryId) {
                                    const categoryId = sessionStorage.getItem('active_key_path')?.split(',')[0]
                                    if (categoryId != null) {
                                        sessionStorage.setItem('active_key_path', categoryId)
                                    }
                                }
                                props.getAllCategory(props.kmMapRole).catch((err) => message.error(`Failed fetching category. ${err}`)).finally(() => {
                                    setIsLoadingFolder(false)
                                    props.closeForm()
                                })
                            }).catch((error) => {
                                setIsLoadingFolder(false)
                                if (error.status === 409) {
                                    setFields({
                                        Name: {
                                            value: getFieldValue(FormType.Name),
                                            errors: [new Error(error.data.message)]
                                        }
                                    })
                                } else {
                                    message.error(error.data.message)
                                }
                            })
                        } else {
                            setIsLoadingFolder(true)
                            createFolder(folder).then(() => {
                                message.success('You have successfully saved the data.')
                                props.getAllCategory(props.kmMapRole).catch((err) => message.error(`Failed fetching category. ${err}`))
                                setIsLoadingFolder(false)
                                props.closeForm()
                            }).catch((error) => {
                                setIsLoadingFolder(false)
                                if (error.status === 409) {
                                    setFields({
                                        Name: {
                                            value: getFieldValue(FormType.Name),
                                            errors: [new Error(error.data.message)]
                                        }
                                    })
                                } else {
                                    message.error(error.data.message)
                                }
                            })
                        }
                    }
                }
            }
        )
    }

    const onClose = () => {
        props.closeForm()
    }

    const FocusFolderName = () => {
        if (props.categories) {
            props.categories.forEach((cat) => {
                if (cat.id === props.categoryName) {
                    if (cat.folderList?.length !== 0) {
                        // eslint-disable-next-line no-unused-expressions
                        cat.folderList?.map((it) => {
                            folderNames.push(it.folderName)
                        })
                    }
                }
            })
        }
    }

    const validateNameCategory = (_: any, value: any, callback) => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        if (value) {
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    if (props.form.getFieldValue(FormType.Name)) {
                        if (props.categories) {
                            const categoryName = CategoriesList.map((it) => {
                                return it.value?.toLowerCase()
                            })
                            if (value.trim() === defaultCategoryName) {
                                callback()
                            } else if (categoryName.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                                callback('The Category Name already exists. It must be unique. Please try another one.')
                            } else {
                                callback()
                            }
                        }
                    } else {
                        callback()
                    }
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else { callback() }

    }

    const validateDescription = (_rule: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 letters')
                }
            }
        } else {
            callback()
        }
    }

    const validateNameFolder = (_: any, value: any, callback) => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        if (value) {
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    if (props.form.getFieldValue(FormType.Name)) {
                        if (folderNames) {
                            const folderNameList = folderNames.map((value: any) => {
                                return value?.toLowerCase()
                            })
                            if (value.trim() === defaultFolderName) {
                                callback()
                            } else if (folderNameList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                                callback('The Folder Name already exists. It must be unique. Please try another one')
                            } else {
                                callback()
                            }
                        }

                    } else {
                        callback()
                    }
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else { callback() }

    }

    const handleCleardata = (value) => {
        if (value !== '2') {
            setFieldsValue({ Groups:[] })
        }
    }
    return props.isCategoryForm ? (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label={FormType.Name}>
                        {getFieldDecorator(FormType.Name, {
                            rules: [{
                                required: true,
                                message: 'Name is required'
                            }, { validator: validateNameCategory }]
                        })(
                            <Input placeholder="Please enter category name" maxLength={50} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.Description}>
                        {getFieldDecorator(FormType.Description, { rules: [{ validator: validateDescription }] })(
                            <Input.TextArea rows={4} placeholder="please enter description" maxLength={255} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button type="primary" htmlType="submit"
                        loading={isLoadingCategory}
                        onClick={submitFrom}
                        style={{ float: 'right', marginRight: 10 }}
                        disabled={props.editData ? !checkRolesState(RoleType.Knowledge, 'ManageCategoryFolderArticle') : false}>Submit</Button>
                    <Button onClick={onClose} style={{ float: 'right', marginRight: 10 }}>Cancel</Button>
                </Col>
            </Row>
        </Form>
    ) : (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label={FormType.Name}>
                        {getFieldDecorator(FormType.Name, { rules: [{ required: true }, { validator: validateNameFolder }] })(
                            <Input placeholder="Please enter folder name" onFocus={() => FocusFolderName()}
                                maxLength={50} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.Description}>
                        {getFieldDecorator(FormType.Description, { rules: [{ validator: validateDescription }] })(
                            <Input.TextArea rows={4} placeholder="please enter description" maxLength={255} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.Category}>
                        {createMenu(CategoriesList, FormType.Category)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.ManageBy}>
                        {createMultipleSelectMenu(supportTeamsList, FormType.ManageBy, false)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.PublishWorkflow}>
                        {createMenu(dropdownDataPublishWorkflow.dataList, FormType.PublishWorkflow)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.RetireWorkflow}>
                        {createMenu(dropdownDataRetireWorkflow.dataList, FormType.RetireWorkflow)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={FormType.VisibleTo}>
                        {createMenu(dropdownDataVisibleTo.dataList, FormType.VisibleTo)}
                    </Form.Item>
                </Col>
                {/* {  ( */}
                <Col span={24} className={getFieldValue(FormType.VisibleTo) === '2' ? 'show' : 'hide'}>
                    <Form.Item label={FormType.Groups}>
                        {createMultipleSelectMenu(supportTeamsList, FormType.Groups, getFieldValue(FormType.VisibleTo) === '2')}
                    </Form.Item>
                </Col>
                {/* ) : null} */}
            </Row>
            <Row>
                <Col span={24}>
                    <Button type="primary" htmlType="submit" disabled={props.editData ? checkPermissionEdit() : false}
                        onClick={submitFrom} style={{ float: 'right', marginRight: 10 }}
                        loading={isLoadingFolder}>Submit</Button>
                    <Button onClick={onClose} style={{ float: 'right', marginRight: 10 }}>Cancel</Button>
                </Col>
            </Row>
        </Form>
    )
}

const MyCategoryFrom = Form.create<Props>()(CategoryFrom)

export default connect(mapStateToProps, { getAllCategory })(MyCategoryFrom)
