import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {WorkOrder, WorkOrderList} from './model'

export interface WorkOrderStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllWorkOrderSuccessEvent = {
    type: 'GetAllWorkOrderSuccessEvent',
    build: (payload: WorkOrderList[]) => buildStateEvent(GetAllWorkOrderSuccessEvent.type, payload)
}

export const UpdateTotalWorkOrderSuccessEvent = {
    type: 'UpdateTotalWorkOrderSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateTotalWorkOrderSuccessEvent.type, payload)
}

export const AddWorkOrderSuccessEvent = {
    type: 'AddWorkOrderSuccessEvent',
    build: (payload: WorkOrder) => buildStateEvent(AddWorkOrderSuccessEvent.type, payload)
}

export const DeleteWorkOrderSuccessEvent = {
    type: 'DeleteWorkOrderSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteWorkOrderSuccessEvent.type, payload)
}

export const UpdateWorkOrderSuccessEvent = {
    type: 'UpdateWorkOrderSuccessEvent',
    build: (payload: WorkOrder) => buildStateEvent(UpdateWorkOrderSuccessEvent.type, payload)
}
export const UpdateTotalContentWorkOrderEvent = {
    type: 'UpdateTotalContentWorkOrderEvent',
    build: (payload: number) => buildStateEvent(UpdateTotalContentWorkOrderEvent.type, payload)
}

export const UpdateWorkOrderPageEvent = {
    type: 'UpdateWorkOrderPageEvent',
    build: (payload: number) => buildStateEvent(UpdateWorkOrderPageEvent.type, payload)
}
