export interface ChatInterface {
    author: authorType
    text: any
    order: number
}

export enum authorType {
    User = 'User',
    ChatGPT = 'ChatGPT'
}
