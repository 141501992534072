import {FormComponentProps} from 'antd/es/form'
import React, {useState} from 'react'
import {Button, Checkbox, Col, Form, Input, message, Row} from 'antd'
import {registerFreeUser} from '../../authorization-module/login/service'
import {createHmac} from 'crypto'
import PrivacyPolicy from './PrivacyPolicy'

interface Param {
    current: number
    setCurrent: Function
    freeUser: any
    setFreeUser: Function
    setEmail: Function
}

type Props = Param & FormComponentProps
const Step3Free: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, validateFields } = props.form
    const [confirmDirty, setConfirmDirty] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showPolicy, setIsShowPolicy] = useState(false)

    const onFinish = (e: any) => {
        e.preventDefault()
        validateFields(async (_err, values) => {
            if (!_err) {
                setIsLoading(true)
                const secret = 'MSPTeamWuhoo'
                const password = createHmac('sha256', secret)
                    .update(values.password)
                    .digest('hex')
                props.freeUser.userRegistration.password = password
                props.freeUser.userRegistration.confirm = {
                    email: values.email,
                    isConfirmAccount: false,
                    createdBy: 'system',
                    lastModifiedBy: 'system'
                }
                props.freeUser.userRegistration.active = true

                registerFreeUser(props.freeUser).then(() => {
                    props.setCurrent(props.current + 1)
                    props.setFreeUser(props.freeUser)
                    props.setEmail(values.email)
                    setIsLoading(false)
                }).catch((err) => {
                    if (err?.response?.status === 409) {
                        message.error('This email is already in the system.')
                    } else {
                        message.error('You have unSuccessfully save the data.')
                    }

                    setIsLoading(false)
                })
            }
        })
    }
    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!')
        } else {
            callback()
        }
    }

    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true })
        }
        callback()
    }

    const handleConfirmBlur = e => {
        const { value } = e.target
        setConfirmDirty(confirmDirty || !!value)
    }

    const onChange = () => {
        setIsConfirm(!isConfirm)
    }
    return (
        <div className={'step-content'}>
            <Form
                name="Step3"
                layout={'vertical'}
                onSubmit={onFinish}
            >
                <Row gutter={[8, 8]}>
                    <Col span={6}><span style={{color: 'red'}}>*</span> Email</Col>
                    <Col span={18}>
                        <Form.Item
                            label=""
                        >
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!'
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!'
                                    }
                                ]
                            })(
                                <Input placeholder='Email' maxLength={100} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={6}><span style={{color: 'red'}}>*</span> Password </Col>
                    <Col span={18}>
                        <Form.Item
                            label=""
                        >
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your Password!'
                                    },
                                    {
                                        validator: validateToNextPassword
                                    }
                                ]
                            })(<Input.Password placeholder='Password'/>)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={6}><span style={{color: 'red'}}>*</span> Confirm Password</Col>
                    <Col span={18}>
                        <Form.Item
                            label=""
                        >
                            {getFieldDecorator('confirm', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please confirm your Password!'
                                    },
                                    {
                                        validator: compareToFirstPassword
                                    }
                                ]
                            })(<Input.Password onBlur={handleConfirmBlur} placeholder='Confirm Password'/>)}
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{padding: 10, textAlign: 'justify'}}>
                    <Checkbox onChange={onChange}>
                        We use information about you to create and provide GO Service Tenant. Your information will be protected. By creating an account, you agree to GO Service <span className={'feck-link'} onClick={() => { setIsShowPolicy(true) }}>Privacy Policy</span> Please click the box if you acknowledge and agree.
                    </Checkbox>
                </div>
                <div style={{ textAlign: 'end' }}>
                    <Button type='ghost' htmlType='button' onClick={() => props.setCurrent(props.current - 1)}>Back</Button>
                    <Button type='primary' htmlType='submit' loading={isLoading} disabled={!isConfirm} style={{marginLeft: 5}}>Register</Button>
                </div>
            </Form>
            <PrivacyPolicy isVisible={showPolicy} setIsVisible={setIsShowPolicy}></PrivacyPolicy>
        </div>
    )
}

const MyStep3Free = Form.create<Props>({ name: 'Step3Free' })(Step3Free)
export default MyStep3Free
