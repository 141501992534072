import { buildStateEvent, GenericStateEvent } from '../common-redux'
import { CustomFiledAsset } from './model'

export interface CustomFiledAssetStateEvent<T> extends GenericStateEvent<T> { }

export const GetAllCustomFiledAssetSuccessEvent = {
    type: 'GetAllCustomFiledAssetSuccessEvent',
    build: (payload: CustomFiledAsset[]) => buildStateEvent(GetAllCustomFiledAssetSuccessEvent.type, payload)
}

export const AddCustomFiledAssetSuccessEvent = {
    type: 'AddCustomFiledAssetSuccessEvent',
    build: (payload: CustomFiledAsset[]) => buildStateEvent(AddCustomFiledAssetSuccessEvent.type, payload)
}

export const DeleteCustomFiledAssetSuccessEvent = {
    type: 'DeleteCustomFiledAssetSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteCustomFiledAssetSuccessEvent.type, payload)
}

export const UpdateCustomFiledAssetSuccessEvent = {
    type: 'UpdateCustomFiledAssetSuccessEvent',
    build: (payload: CustomFiledAsset) => buildStateEvent(UpdateCustomFiledAssetSuccessEvent.type, payload)
}
