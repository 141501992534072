import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { nullOrEmptyObject } from '../../common-misc'
import { StoreState } from '../../store'
import { DataForChart } from '../model'
import { Empty } from 'antd'

const mapStateToProps = (state: StoreState) => {
    return {
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    myData?: DataForChart
}

type Props = Param & StateProps
const MyHorizontalBarChart: React.FC<Props> = (props: Props) => {
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [65, 59, 80, 81, 56, 55, 47]
            }
        ]
    }
    return (
        <div>
            {!nullOrEmptyObject(props.myData?.labels) ? <HorizontalBar
                data={props.myData ? props.myData : data}
                height={window.innerWidth < 480 ? 100 : 80}
                options={{
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        titleFontColor: '#fa5414'
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                precision: 0
                            }
                        }]
                    }
                }}
            /> : <div>
                <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            </div>}
        </div>
    )
}

export default connect(mapStateToProps, {

})(MyHorizontalBarChart)
