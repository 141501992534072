import { AutoComplete, Col, Form, Input, Row } from 'antd'
import React from 'react'
import { replaceName } from '../../../common-file'
import { searchFetch } from '../service'

interface Params {
    form: any,
    name: string,
    editable: boolean,
    autoCompleteData: string[],
    fullData: string[],
    required: boolean,
    setState?: Function,
    maxInput?: number,
    disableItem?: boolean
}

type Props = Params
const CustomInput: React.FC<Props> = (props: Props) => {
    const replaceUserToOwner = (name: string) => {
        return name.replace(/User/g, 'Owner')
    }

    return (
        <Row>
            <Col xl={3} md={0} />
            <Col xl={16} md={24} style={{ marginTop: 10 }}>
                <Col xl={4} md={24}>
                    <div style={{textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }} className={props.required ? 'ant-form-item-required' : ''}>
                        {replaceUserToOwner(props.name)}
                    </div>
                </Col>
                <Col xl={20} md={24}>
                    <Form.Item>
                        <Col md={24}>
                            {props.form.getFieldDecorator(replaceName(props.name), {
                                rules: [
                                    { required: props.required, message: `${replaceUserToOwner(props.name)} is required` }
                                ]
                            })(
                                <AutoComplete onChange={(value) => { searchFetch(value, props.fullData || [], props.setState!!) }}
                                    dataSource={props.autoCompleteData || []} id={`${props.name}`} disabled={props.disableItem}>
                                    <Input style={{ maxWidth: '100%' }} type="text" placeholder={`${replaceUserToOwner(props.name)}`} maxLength={props.maxInput} />
                                </AutoComplete>
                            )}
                        </Col>
                    </Form.Item>
                </Col>
            </Col>
            <Col xl={5} md={0} />
        </Row>
    )
}

export default CustomInput
