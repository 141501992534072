import {
    GetAllJobSuccessEvent,
    JobStateEvent
} from './state-event'
import {Job} from './model'

export const jobStateReducer = (state: Job[] = [], event: JobStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllJobSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
