import { BaseEntity } from '../../common-model'
import { UploadLink } from './uploads'

export interface Knowledge extends BaseEntity {
    topic?: string
    status?: string
    categoryName?: string
    categoryId?: number
    folderName?: string
    folderId?: string
    type?: string
    disableComment?: boolean
    detail?: string
    detailText?: string
    tagsList?: TagsList[]
    helpful?: number
    notHelpful?: number
    views?: number
    used?: number
    liked?: number // 0 = not helpful, 1 = helpful, 2 = null
    uploadLink?: MyUploadLink[]
    ticketNumber?: string
    rejectReason?: string
    // isKmVspace?: boolean
    major?: number
    minor?: number
    visible?: boolean
}

export interface KnowledgeList {
    content?: Knowledge[]
    pagination?: Pagination
}

export interface Pagination {
    size?: number
    currentPage?: number
    totalPages?: number
    numberOfElements?: number
    totalElements?: number
}

export enum KnowledgeStatus {
    MyNote = 'MyNote',
    Draft = 'Draft',
    AwaitingApproval = 'AwaitingApproval',
    AwaitingRetire = 'AwaitingRetire',
    Rejected = 'Rejected',
    Published = 'Published',
    Retired = 'Retired',
    Outdated = 'Outdated'
}

export interface TagsList {
    id?: number
    knowledgeId?: number
    tag: string
}

export interface MyUploadLink extends UploadLink{
    id?: number
    knowledgeId?: number
}
