import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {SlaPolicy} from './model'

export interface SlaPolicyStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllSlaPolicySuccessEvent = {
    type: 'GetAllSlaPolicySuccessEvent',
    build: (payload: SlaPolicy[]) => buildStateEvent(GetAllSlaPolicySuccessEvent.type, payload)
}

export const AddSlaPolicySuccessEvent = {
    type: 'AddSlaPolicySuccessEvent',
    build: (payload: SlaPolicy) => buildStateEvent(AddSlaPolicySuccessEvent.type, payload)
}

export const DeleteSlaPolicySuccessEvent = {
    type: 'DeleteSlaPolicySuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteSlaPolicySuccessEvent.type, payload)
}

export const UpdateSlaPolicySuccessEvent = {
    type: 'UpdateSlaPolicySuccessEvent',
    build: (payload: SlaPolicy) => buildStateEvent(UpdateSlaPolicySuccessEvent.type, payload)
}

export const UpdateTotalContentSlaPolicyEvent = {
    type: 'UpdateTotalContentSlaPolicyEvent',
    build: (payload: number) => buildStateEvent(UpdateTotalContentSlaPolicyEvent.type, payload)
}

export const UpdateSlaPolicyPageEvent = {
    type: 'UpdateSlaPolicyPageEvent',
    build: (payload: number) => buildStateEvent(UpdateSlaPolicyPageEvent.type, payload)
}
