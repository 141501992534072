import React, {useEffect, useState} from 'react'
import {Table} from 'antd'
import {ColumnNormal, NewAsset} from './Model'
import PageService from './ContextAPI/PageService'

interface Param {
    dataTransfer: NewAsset[]
    columnsNewAssetList: string[]
    columnsNewAssetObjList: ColumnNormal[]
    total: number
    range: any
    handleAssetChange: Function
}

type Props = Param
const ColumnNewAsset: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        if (props.columnsNewAssetList && props.columnsNewAssetObjList) {
            const temp: ColumnNormal[] = []
            props.columnsNewAssetList.forEach((it) => {
                const findDataIndex = props.columnsNewAssetObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    temp.push(findDataIndex)
                }
            })
            setTempColumns(temp)
        }
    }, [props.columnsNewAssetList, props.columnsNewAssetObjList])

    useEffect(() => {
        if (pageSize) {
            PageService.getInstance().data.pageAsset = 1
            setPage(1)
            props.handleAssetChange()
        }
    }, [pageSize])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageService.getInstance().data.pageAsset = 1
        }
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageService.getInstance().data.pageAsset = page
        props.handleAssetChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageService.getInstance().data.pageSizeAsset = pagination.pageSize
    }

    return (
        <div>
            <Table scroll={{x: true}} dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns}
                pagination={ props.range && props.columnsNewAssetList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" rowKey={'serialNumber'}/>
        </div>
    )
}
export default ColumnNewAsset
