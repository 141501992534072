import {Dispatch} from 'redux'
import {axiosGet} from '../rest'
import {GetAllUrgencySuccessEvent} from './state-event'
import {PriorityLevel} from '../priority'
import { checkRedirect } from '../../common-misc'
import { decryptBody } from '../../common-misc/encryptBody'

export const getAllUrgency = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityLevel[]>('/api/priorities-level/urgency')
            dispatch(GetAllUrgencySuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
