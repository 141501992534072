export default class NotifiesShareData {
    private static instance: NotifiesShareData
    notify?: string = undefined

    static getInstance(): NotifiesShareData {
        if (!NotifiesShareData.instance) {
            NotifiesShareData.instance = new NotifiesShareData()
        }
        return NotifiesShareData.instance
    }
}
