import {Button, Col, Form, Input, Row} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import React, {useEffect} from 'react'

interface Param {
    current: number
    setCurrent: Function
    freeUser: any
    setFreeUser: Function
}

type Props = Param & FormComponentProps
const Step2Free: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, validateFields, setFieldsValue } = props.form

    useEffect(() => {
        if (props.freeUser) {
            setFieldsValue({
                firstName: props.freeUser.userRegistration.firstName,
                lastName: props.freeUser.userRegistration.lastName,
                phone: props.freeUser.userRegistration.tel,
                companyName: props.freeUser.userRegistration.company,
                billingAddress: props.freeUser.userRegistration.billingAddress
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onFinish = (e: any) => {
        e.preventDefault()
        validateFields(async (_err, values) => {
            if (!_err) {
                if (props.freeUser.userRegistration) {
                    props.freeUser.userRegistration.firstName = values.firstName
                    props.freeUser.userRegistration.lastName = values.lastName
                    props.freeUser.userRegistration.fullName = values.firstName + ' ' + values.lastName
                    props.freeUser.userRegistration.tel = values.phone
                    props.freeUser.userRegistration.company = values.companyName
                    props.freeUser.userRegistration.billingAddress = values.billingAddress
                    props.freeUser.userRegistration.createdBy = 'system'
                    props.freeUser.userRegistration.lastModifiedBy = 'system'
                    props.setCurrent(props.current + 1)
                    props.setFreeUser(props.freeUser)
                }
            }
        })
    }

    const handleChange = (name: string, value) => {
        if (name === 'firstName') {
            props.freeUser.userRegistration.firstName = value
        } else if (name === 'lastName') {
            props.freeUser.userRegistration.lastName = value
        } else if (name === 'phone') {
            props.freeUser.userRegistration.tel = value
        } else if (name === 'companyName') {
            props.freeUser.userRegistration.company = value
        } else if (name === 'billingAddress') {
            props.freeUser.userRegistration.billingAddress = value
        }
        props.setFreeUser(props.freeUser)
    }

    return (
        <div className={'step-content'}>
            <Form
                name="Step2"
                layout={'vertical'}
                onSubmit={onFinish}
            >
                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <p><span style={{color: 'red'}}>*</span> Full Name</p></Col>
                    <Col span={9}>
                        <Form.Item >
                            {getFieldDecorator('firstName', {
                                rules: [{ required: true, message: 'Please input your First Name!' }]
                            })(
                                <Input placeholder='First Name ' maxLength={60} max={60} onChange={(e) => handleChange('firstName', e.target.value)}/>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item>
                            {getFieldDecorator('lastName', {
                                rules: [{ required: true, message: 'Please input your Last Name!' }]
                            })(
                                <Input placeholder='Last Name ' maxLength={60} max={60} onChange={(e) => handleChange('lastName', e.target.value)}/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <span style={{color: 'red'}}>*</span> Phone
                    </Col>
                    <Col span={18}>
                        <Form.Item>
                            {getFieldDecorator('phone', {
                                rules: [
                                    {
                                        pattern: /^[0-9\b]+$/,
                                        message: 'Please enter a 10 digit using numbers only'
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your Phone!'
                                    },
                                    {
                                        min: 10,
                                        message: 'Please enter exactly 10 digits'
                                    }
                                ]
                            })(
                                <Input placeholder='Phone' maxLength={100} onChange={(e) => handleChange('phone', e.target.value)}/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <span style={{color: 'red'}}>*</span> Company Name
                    </Col>

                    <Col span={18}>
                        <Form.Item>
                            {getFieldDecorator('companyName', {
                                rules: [{ required: true, message: 'Please input your Company Name!' }]
                            })(
                                <Input placeholder='Company Name' maxLength={255} max={255} onChange={(e) => handleChange('companyName', e.target.value)}/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <span style={{color: 'red'}}>*</span> Billing Address
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            label=""
                        >
                            {getFieldDecorator('billingAddress', {
                                rules: [{ required: true, message: 'Please input your Billing Address!' }]
                            })(
                                <Input placeholder='Billing Address' maxLength={255} max={255} onChange={(e) => handleChange('billingAddress', e.target.value)}/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ textAlign: 'end' }}>
                    <Button type='ghost' htmlType='button' onClick={() => props.setCurrent(props.current - 1)}>Back</Button>
                    <Button type='primary' htmlType='submit' style={{marginLeft: 5}}>Next</Button>
                </div>
            </Form>
        </div>
    )
}

const MyStep2Free = Form.create<Props>({ name: 'Step2Free' })(Step2Free)
export default MyStep2Free
