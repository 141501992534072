import {
    AddBusinessHourSuccessEvent,
    BusinessHourStateEvent,
    DeleteBusinessHourSuccessEvent,
    GetAllBusinessHourSuccessEvent,
    UpdateBusinessHourSuccessEvent,
    UpdateBusinessHourPageEvent,
    UpdateTotalContentBusinessHourEvent
} from './state-event'
import {BusinessHour, BusinessHoursPagination} from './model'

export const businessHourStateReducer = (state: BusinessHour[] = [], event: BusinessHourStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllBusinessHourSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddBusinessHourSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteBusinessHourSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateBusinessHourSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}

export const businessHourPaginationStateReducer = (state: BusinessHoursPagination = {}, event: BusinessHourStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateTotalContentBusinessHourEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateBusinessHourPageEvent.type) {
        state.page = event.payload
    } else {
        return state
    }
    return state
}
