import {Dispatch} from 'redux'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import {axiosGet, axiosPost} from '../rest'
import {PriorityLevelProblem, PriorityMatrixProblem} from './model'
import {
    GetAllPriorityProblemSuccessEvent,
    GetPriorityMatrixProblemSuccessEvent,
    CreatePriorityMatrixNewProblemVersionSuccessEvent
} from './state-event'

export const getAllPriorityProblem = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityLevelProblem[]>('/api/priority-levels/priorities?types=Priority')
            dispatch(GetAllPriorityProblemSuccessEvent.build(decryptBody(response.data.data!!)?.priority))
            return response.status
        } catch (err) {
            console.log(err.response)
            checkRedirect(err.response.status)
            throw err
        }
    }
}

export async function resolvePriorityProblem(impact: number, urgency: number, incidentId?: number): Promise<PriorityMatrixProblem> {
    try {
        const params = {
            id: incidentId
        }
        const response = await axiosGet<PriorityMatrixProblem>(`/api/priorities/${impact}/${urgency}`, {params})
        return decryptBody(response.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const getPriorityConfigurationProblem = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityMatrixProblem>('/api/priority-levels/priorities?types=PriorityMatrix')
            dispatch(GetPriorityMatrixProblemSuccessEvent.build(decryptBody(response.data.data!!)?.priorityMatrix))
            return response.status
        } catch (err) {
            console.log(err.response)
            checkRedirect(err.response.status)
            throw err
        }
    }
}

export const createPriorityMatrixNewVersionProblem = (patch: PriorityMatrixProblem[] | undefined) => {
    return async (dispatch: Dispatch) => {
        try {
            // const id = patch.id!!
            // delete patch.id
            // console.log(patch)
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPost<PriorityMatrixProblem[]>('/api/priorities/priority-matrixs', encryptData, herderPostPatch)
            dispatch(CreatePriorityMatrixNewProblemVersionSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err.response.status)
            throw err
        }
    }
}

export const createNewProblemPriorityLevel = async (newItem: any) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<PriorityLevelProblem[]>('/api/priority-levels/setting', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        checkRedirect(err.response.status)
        throw err.response
    }
}
