import { Col, Form, Icon, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { replaceName } from '../../../common-file'

interface Params {
    form: any,
    name: string,
    options: string[],
    required: boolean,
    isEdit?: boolean,
    isSubmitSuccess?: boolean,
    getEditMode?: Function,
    disableItem?: boolean
    getDataSelection?: Function
}

type Props = Params
const { Option } = Select
const CustomSelect: React.FC<Props> = (props: Props) => {
    const [isDisable, setIsDisable] = useState<boolean>(true)

    useEffect(() => {
        if (props.isSubmitSuccess) {
            setIsDisable(true)
        }
    }, [props.isSubmitSuccess])

    const createOption = (dataSource: string[]) => {
        return dataSource.map((data) => {
            return (<Option key={data} >{data}</Option>)
        })
    }

    const toggleDisableItem = () => {
        setIsDisable(!isDisable)
        if (props.getEditMode) {
            props.getEditMode(!isDisable)
        }
    }

    const handleSelectionChange = (e) => {
        if (e && props.getDataSelection) {
            props.getDataSelection(e)
        }
    }

    const replaceUserToOwner = (name: string) => {
        if (name && name.includes('User')) {
            return name.replace(/User/g, 'Owner')
        }
        return name
    }

    return (
        <Row>
            <Col xl={3} md={0} />
            <Col xl={16} style={{ marginTop: 10 }}>
                <Col xl={4} md={24}>
                    <div style={{ textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }} className={props.required ? 'ant-form-item-required' : ''}>
                        {replaceUserToOwner(props.name)}
                    </div>
                </Col>

                <Col xl={20} md={props.getEditMode ? 22 : 24} sm={props.getEditMode ? 22 : 24} xs={props.getEditMode ? 22 : 24}>
                    <Form.Item>
                        {props.form.getFieldDecorator(replaceName(props.name), {
                            initialValue: props.options[5]
                        })(
                            <Select
                                placeholder={'Select ' + replaceUserToOwner(props.name)}
                                style={{ width: '100%' }}
                                disabled={props.disableItem}
                                onSelect={handleSelectionChange}
                                allowClear={true}
                            >{createOption(props.options)}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Col>
            <Col xl={5} md={props.getEditMode ? 2 : 0} sm={props.getEditMode ? 2 : 0} xs={props.getEditMode ? 2 : 0}>
                {props.isEdit ? isDisable ? <Icon type='edit' style={{ marginTop: window.innerWidth < 768 ? 15 : 25, marginLeft: 10 }} className="feck-link" onClick={() => toggleDisableItem()} />
                    : <Icon type='close' style={{ marginTop: window.innerWidth < 768 ? 15 : 25, marginLeft: 10 }} className="feck-cancel" onClick={() => toggleDisableItem()} /> : null}
            </Col>
        </Row>
    )
}

export default CustomSelect
