import {
    ROLES_DATA_SHOW,
    RoleStateEvent,
    UpdateUserRoleCurrentPageSuccessEvent,
    UpdateUserRoleTotalPageSuccessEvent,
    USER_ROLES_DATA_ADD,
    USER_ROLES_DATA_DELETE,
    USER_ROLES_DATA_EDIT,
    USER_ROLES_DATA_SHOW,
    UserRoleStateEvent
} from './type'
import {ResponseUserRole, Roles, UserRolesPagination} from './model'

export const userRolesReducer = (state: ResponseUserRole[] = [], event: UserRoleStateEvent<any> = { type: undefined, payload: undefined}): ResponseUserRole[] => {
    switch (event.type) {
    case USER_ROLES_DATA_SHOW.type:
        return event.payload || []
    case USER_ROLES_DATA_ADD.type:
        return [event.payload, ...state]
    case USER_ROLES_DATA_EDIT.type:
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    case USER_ROLES_DATA_DELETE.type:
        return state.filter((it) => it.id !== event.payload)
    default:
        return state
    }
}
export const rolesReducer = (state: Roles[] = [], event: RoleStateEvent<any> = { type: undefined, payload: undefined}): Roles[] => {
    if (event.type === ROLES_DATA_SHOW.type) {
        return event.payload || []
    } else {
        return state
    }
}

export const userRolesPaginationStateReducer = (state: UserRolesPagination = {}, event: UserRoleStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateUserRoleTotalPageSuccessEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateUserRoleCurrentPageSuccessEvent.type) {
        state.page = event.payload
    } else {
        return state
    }
    return state
}
