import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const DataSerialNumberContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const DataSerialNumberReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_DataSerialNumber':
        return action.payload
    default:
        return state
    }
}

export const DataSerialNumberProvider = (props: props) => {
    const [DataSerialNumberState, DataSerialNumberDispatch] = useReducer(DataSerialNumberReducer, initialState)

    const GetDataSerialNumber = (payload) => {
        DataSerialNumberDispatch({ type: 'GET_DataSerialNumber', payload })
    }

    return (
        <DataSerialNumberContext.Provider value={{ DataSerialNumberState, GetDataSerialNumber }}>
            {props.children}
        </DataSerialNumberContext.Provider>
    )
}
