/* eslint-disable no-unused-expressions */
import { Dispatch } from 'redux'
import { UserRole, PermissionAndScope, RoleState, RoleType } from './model'
import { SavePermissionAndScopeListEvent } from './state-event'
import { Permissions } from './permissions'
import { tokenDecode } from '../../common-misc'

const tempMapping: RoleState[] = []

export const RoleUtils = {
    initRoleState: (permissionAndScope: (PermissionAndScope | undefined)[]) => {
        const rolesMerge: PermissionAndScope[] = []
        let rolesScopeList = permissionAndScope.map(role => role?.scope)
        rolesScopeList = rolesScopeList.filter(scope => typeof scope === 'string')
        rolesScopeList = RoleUtils.removeDups(rolesScopeList)
        for (const role of permissionAndScope) {
            for (let index = 0; index < rolesScopeList.length; index++) {
                if (role && role.permissions && role.scope === rolesScopeList[index]) {
                    if (rolesMerge[index] === undefined) {
                        rolesMerge[index] = role
                    } else {
                        const oldData = rolesMerge[index].permissions || []
                        rolesMerge[index].permissions = RoleUtils.removeDups([...oldData, ...role.permissions])
                    }
                }
            }
        }
        return rolesMerge
    },
    removeDups: (names) => {
        const unique = {}
        names.forEach(function (i) {
            if (!unique[i]) {
                unique[i] = true
            }
        })
        return Object.keys(unique)
    }
}

export function dispatchRoles(dispatch: Dispatch, roles) {
    if (roles) {
        // const rolesMerge: RoleState | undefined = mergeRoles()
        // // const rolesMerge: RoleState = {
        // //     security: RoleUtils.initRoleState(roles.map(role => role.security)),
        // //     incident: RoleUtils.initRoleState(roles.map(role => role.incident)),
        // //     knowledge: RoleUtils.initRoleState(roles.map(role => role.knowledge)),
        // //     asset: RoleUtils.initRoleState(roles.map(role => role.asset)),
        // //     pm: RoleUtils.initRoleState(roles.map(role => role.pm)),
        // //     serviceCatalog: RoleUtils.initRoleState(roles.map(role => role.serviceCatalog))
        // // }
        // if (rolesMerge) {
        //     dispatch(SavePermissionAndScopeListEvent.build(rolesMerge))
        // }
        dispatch(SavePermissionAndScopeListEvent.build(roles))
    }
}

// export const mergeRoles = (roles) => {
//     if (roles) {
//         // console.log('roles', roles)
//         // const rolesMerge: RoleState = {
//         //     security: RoleUtils.initRoleState([roles.security]),
//         //     incident: RoleUtils.initRoleState([roles.incident]),
//         //     knowledge: RoleUtils.initRoleState([roles.knowledge]),
//         //     asset: RoleUtils.initRoleState([roles.asset]),
//         //     pm: RoleUtils.initRoleState([roles.pm]),
//         //     serviceCatalog: RoleUtils.initRoleState([roles.serviceCatalog]),
//         //     admin: RoleUtils.initRoleState([roles.admin])
//         // }
//         console.log('rolesMerged: ', roles)
//         return roles
//     }
// }

export const loadRolesState = () => {
    return async (dispatch: Dispatch) => {
        try {
            const userRolesString = tokenDecode()?.permission
            if (!userRolesString) {
                throw Error('no userRoles')
            }
            const userRoles: any = userRolesString
            dispatchRoles(dispatch, userRoles)
            return 200
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const checkRolesState = (roleType: RoleType, permissionName: string, createdBy?: string) => {
    const localRoles = tokenDecode()?.permission
    const rolesMapping = permissionMapping(JSON.stringify(localRoles))
    let roleState: any = Permissions.getInstance().roleState
    if (rolesMapping) {
        roleState = rolesMapping
    }
    // console.log(roleState)
    if (Object.keys(roleState).length === 0) {
        return false
    }
    const checkAccessPermission = (roles: PermissionAndScope) => {
        if (roles.permissions?.includes(permissionName)) { return true }
        return false
    }
    if (roleType === RoleType.Security) {
        const securityRoles = roleState.security
        if (checkAccessPermission(securityRoles)) return true
    } else if (roleType === RoleType.Incident) {
        if (roleState.incident.length > 1) {
            const arrs = [].concat(roleState.incident.filter((it) => { return it.permissions.length !== 0 }))
            if (checkAccessPermission(arrs[0])) return true
        } else {
            const incidentRoles = roleState.incident
            if (checkAccessPermission(incidentRoles)) return true
        }
    } else if (roleType === RoleType.Knowledge) {
        const knowledgeRoles = roleState.knowledge
        if (checkAccessPermission(knowledgeRoles)) return true
    } else if (roleType === RoleType.Asset) {
        const AssetRoles = roleState.asset
        if (checkAccessPermission(AssetRoles)) return true
    } else if (roleType === RoleType.PM) {
        const PMRoles = roleState.pm
        if (checkAccessPermission(PMRoles)) return true
    } else if (roleType === RoleType.ServiceCatalog) {
        const serviceCatalogRoles = roleState.serviceCatalog
        if (checkAccessPermission(serviceCatalogRoles)) return true
    } else if (roleType === RoleType.Admin) {
        const adminRoles = roleState.admin
        if (checkAccessPermission(adminRoles)) return true
    } else if (roleType === RoleType.WO) {
        const woRoles = roleState.workOrder
        if (checkAccessPermission(woRoles)) return true
    } else if (roleType === RoleType.Problem) {
        const problemRoles = roleState.problem
        if (checkAccessPermission(problemRoles)) return true
    }

    return false
}

export const permissionMapping = (value: string, refreshTempMapping?: any) => {
    if (refreshTempMapping) {
        if (value) {
            const temp = JSON.parse(value)
            if (temp.admin?.permissions?.length !== undefined) {
                for (let i = 0; i < temp.admin.permissions.length; i++) {
                    if (temp.admin.permissions[i] === '01') temp.admin.permissions[i] = 'ManageRoleAndPermission'
                    if (temp.admin.permissions[i] === '02') temp.admin.permissions[i] = 'ManagePeopleUserGroup'
                    if (temp.admin.permissions[i] === '03') temp.admin.permissions[i] = 'ManageGeneralSetting'
                    if (temp.admin.permissions[i] === '04') temp.admin.permissions[i] = 'ManageTicketSetting'
                    if (temp.admin.permissions[i] === '05') temp.admin.permissions[i] = 'ManageSLASetting'
                    if (temp.admin.permissions[i] === '06') temp.admin.permissions[i] = 'ManageWorkflow'
                    if (temp.admin.permissions[i] === '07') temp.admin.permissions[i] = 'ManageProblemSetting'
                }
            }
            if (temp.security.permissions.length !== undefined) {
                for (let i = 0; i < temp.security.permissions.length; i++) {
                    if (temp.security.permissions[i] === '11') temp.security.permissions[i] = 'ManageTicketAccess'
                    if (temp.security.permissions[i] === '12') temp.security.permissions[i] = 'ManageKnowledgeAccess'
                    if (temp.security.permissions[i] === '13') temp.security.permissions[i] = 'ManageAssetAccess'
                    if (temp.security.permissions[i] === '14') temp.security.permissions[i] = 'ManagePMAccess'
                    if (temp.security.permissions[i] === '15') temp.security.permissions[i] = 'ManageServiceCatalogAccess'
                    if (temp.security.permissions[i] === '16') temp.security.permissions[i] = 'ManageWorkOrderAccess'
                    if (temp.security.permissions[i] === '17') temp.security.permissions[i] = 'ManageProblemAccess'
                }
            }
            if (temp.incident.permissions.length !== undefined) {
                for (let i = 0; i < temp.incident.permissions.length; i++) {
                    if (temp.incident.permissions[i] === '21') temp.incident.permissions[i] = 'CreateIncident'
                    if (temp.incident.permissions[i] === '22') temp.incident.permissions[i] = 'UpdateIncident'
                    if (temp.incident.permissions[i] === '23') temp.incident.permissions[i] = 'DeleteIncident'
                    if (temp.incident.permissions[i] === '24') temp.incident.permissions[i] = 'ViewIncident'
                    if (temp.incident.permissions[i] === '25') temp.incident.permissions[i] = 'CloseIncident'
                    if (temp.incident.permissions[i] === '26') temp.incident.permissions[i] = 'SatisfactionTicket'
                    if (temp.incident.permissions[i] === '27') temp.incident.permissions[i] = 'ViewReportTicket'
                }
            }
            if (temp.knowledge.permissions.length !== undefined) {
                for (let i = 0; i < temp.knowledge.permissions.length; i++) {
                    if (temp.knowledge.permissions[i] === '31') temp.knowledge.permissions[i] = 'ManageCategoryFolderArticle'
                    if (temp.knowledge.permissions[i] === '32') temp.knowledge.permissions[i] = 'ApproveOrRejectKnowledge'
                }
            }
            if (temp.asset.permissions.length !== undefined) {
                for (let i = 0; i < temp.asset.permissions.length; i++) {
                    if (temp.asset.permissions[i] === '41') temp.asset.permissions[i] = 'CreateAsset'
                    if (temp.asset.permissions[i] === '42') temp.asset.permissions[i] = 'UpdateAsset'
                    if (temp.asset.permissions[i] === '43') temp.asset.permissions[i] = 'DeleteAsset'
                    if (temp.asset.permissions[i] === '44') temp.asset.permissions[i] = 'ViewAsset'
                    if (temp.asset.permissions[i] === '45') temp.asset.permissions[i] = 'ViewAssetReport'
                }
            }
            if (temp.pm.permissions.length !== undefined) {
                for (let i = 0; i < temp.pm.permissions.length; i++) {
                    if (temp.pm.permissions[i] === '51') temp.pm.permissions[i] = 'CreateUpdateDeletePMPlan'
                    if (temp.pm.permissions[i] === '52') temp.pm.permissions[i] = 'PMList'
                    if (temp.pm.permissions[i] === '53') temp.pm.permissions[i] = 'AddNewAsset'
                    if (temp.pm.permissions[i] === '54') temp.pm.permissions[i] = 'CompletedPM'
                    if (temp.pm.permissions[i] === '55') temp.pm.permissions[i] = 'ReportPM'
                    if (temp.pm.permissions[i] === '56') temp.pm.permissions[i] = 'ApprovePM'
                }
            }
            if (temp.serviceCatalog.permissions.length !== undefined) {
                for (let i = 0; i < temp.serviceCatalog.permissions.length; i++) {
                    if (temp.serviceCatalog.permissions[i] === '61') temp.serviceCatalog.permissions[i] = 'ManageCatalogCategoryItemCatalog'
                    // if (temp.serviceCatalog.permissions[i] === '62') temp.serviceCatalog.permissions[i] = 'ManageWorkflow'
                    // if (temp.serviceCatalog.permissions[i] === '63') temp.serviceCatalog.permissions[i] = 'ApproveOrRejectWorkorder'
                    // if (temp.serviceCatalog.permissions[i] === '64') temp.serviceCatalog.permissions[i] = 'ApproveOrRejectJob'
                }
            }
            if (temp.workOrder.permissions.length !== undefined) {
                for (let i = 0; i < temp.workOrder.permissions.length; i++) {
                    if (temp.workOrder.permissions[i] === '71') temp.workOrder.permissions[i] = 'CreateWorkOrder'
                    if (temp.workOrder.permissions[i] === '72') temp.workOrder.permissions[i] = 'UpdateWorkOrder'
                    if (temp.workOrder.permissions[i] === '73') temp.workOrder.permissions[i] = 'UpdateJob'
                    if (temp.workOrder.permissions[i] === '74') temp.workOrder.permissions[i] = 'CloseWorkOrder'
                    if (temp.workOrder.permissions[i] === '75') temp.workOrder.permissions[i] = 'ApproveOrRejectWorkOrder'
                    if (temp.workOrder.permissions[i] === '76') temp.workOrder.permissions[i] = 'ApproveOrRejectJob'
                    if (temp.workOrder.permissions[i] === '77') temp.workOrder.permissions[i] = 'ViewWorkOrder'
                }
            }
            if (temp.problem.permissions.length !== undefined) {
                for (let i = 0; i < temp.problem.permissions.length; i++) {
                    if (temp.problem.permissions[i] === '81') temp.problem.permissions[i] = 'CreateProblem'
                    if (temp.problem.permissions[i] === '82') temp.problem.permissions[i] = 'UpdateProblem'
                    if (temp.problem.permissions[i] === '83') temp.problem.permissions[i] = 'ViewProblem'
                    if (temp.problem.permissions[i] === '84') temp.problem.permissions[i] = 'CloseProblem'
                    if (temp.problem.permissions[i] === '85') temp.problem.permissions[i] = 'CreateProblemTask'
                    if (temp.problem.permissions[i] === '86') temp.problem.permissions[i] = 'UpdateProblemTask'
                    if (temp.problem.permissions[i] === '87') temp.problem.permissions[i] = 'ApprovalProblem'
                }
            }

            return temp
        }
    } else {
        if (value) {
            const element = JSON.parse(value)
            if (tempMapping.length === 0 && element?.length !== 0) {
                // element?.forEach(temp => {
                if (element.admin && element.admin?.length !== 0) {
                    for (let i = 0; i < element.admin.permissions.length; i++) {
                        if (element.admin.permissions[i] === '01') element.admin.permissions[i] = 'ManageRoleAndPermission'
                        if (element.admin.permissions[i] === '02') element.admin.permissions[i] = 'ManagePeopleUserGroup'
                        if (element.admin.permissions[i] === '03') element.admin.permissions[i] = 'ManageGeneralSetting'
                        if (element.admin.permissions[i] === '04') element.admin.permissions[i] = 'ManageTicketSetting'
                        if (element.admin.permissions[i] === '05') element.admin.permissions[i] = 'ManageSLASetting'
                        if (element.admin.permissions[i] === '06') element.admin.permissions[i] = 'ManageWorkflow'
                        if (element.admin.permissions[i] === '07') element.admin.permissions[i] = 'ManageProblemSetting'
                    }
                }
                if (element.security.length !== 0) {
                    for (let i = 0; i < element.security.permissions.length; i++) {
                        if (element.security.permissions[i] === '11') element.security.permissions[i] = 'ManageTicketAccess'
                        if (element.security.permissions[i] === '12') element.security.permissions[i] = 'ManageKnowledgeAccess'
                        if (element.security.permissions[i] === '13') element.security.permissions[i] = 'ManageAssetAccess'
                        if (element.security.permissions[i] === '14') element.security.permissions[i] = 'ManagePMAccess'
                        if (element.security.permissions[i] === '15') element.security.permissions[i] = 'ManageServiceCatalogAccess'
                        if (element.security.permissions[i] === '16') element.security.permissions[i] = 'ManageWorkOrderAccess'
                        if (element.security.permissions[i] === '17') element.security.permissions[i] = 'ManageProblemAccess'
                    }
                    element.security.scope = scopeMapping(element.security.scope)
                }
                if (element.incident.length !== 0) {
                    for (let i = 0; i < element.incident.permissions.length; i++) {
                        if (element.incident.permissions[i] === '21') element.incident.permissions[i] = 'CreateIncident'
                        if (element.incident.permissions[i] === '22') element.incident.permissions[i] = 'UpdateIncident'
                        if (element.incident.permissions[i] === '23') element.incident.permissions[i] = 'DeleteIncident'
                        if (element.incident.permissions[i] === '24') element.incident.permissions[i] = 'ViewIncident'
                        if (element.incident.permissions[i] === '25') element.incident.permissions[i] = 'CloseIncident'
                        if (element.incident.permissions[i] === '26') element.incident.permissions[i] = 'SatisfactionTicket'
                        if (element.incident.permissions[i] === '27') element.incident.permissions[i] = 'ViewReportTicket'
                    }
                    element.incident.scope = scopeMapping(element.incident.scope)
                }
                if (element.knowledge.length !== 0) {
                    for (let i = 0; i < element.knowledge.permissions.length; i++) {
                        if (element.knowledge.permissions[i] === '31') element.knowledge.permissions[i] = 'ManageCategoryFolderArticle'
                        if (element.knowledge.permissions[i] === '32') element.knowledge.permissions[i] = 'ApproveOrRejectKnowledge'
                    }
                    element.knowledge.scope = scopeMapping(element.knowledge.scope)
                }
                if (element.asset.length !== 0) {
                    for (let i = 0; i < element.asset.permissions.length; i++) {
                        // if (temp.asset.permissions[i] === '41') temp.asset.permissions[i] = 'CreateUpdateAsset'
                        if (element.asset.permissions[i] === '41') element.asset.permissions[i] = 'CreateAsset'
                        if (element.asset.permissions[i] === '42') element.asset.permissions[i] = 'UpdateAsset'
                        if (element.asset.permissions[i] === '43') element.asset.permissions[i] = 'DeleteAsset'
                        if (element.asset.permissions[i] === '44') element.asset.permissions[i] = 'ViewAsset'
                        if (element.asset.permissions[i] === '45') element.asset.permissions[i] = 'ViewAssetReport'
                    }
                }
                if (element.pm.length !== 0) {
                    for (let i = 0; i < element.pm.permissions.length; i++) {
                        if (element.pm.permissions[i] === '51') element.pm.permissions[i] = 'CreateUpdateDeletePMPlan'
                        if (element.pm.permissions[i] === '52') element.pm.permissions[i] = 'PMList'
                        if (element.pm.permissions[i] === '53') element.pm.permissions[i] = 'AddNewAsset'
                        if (element.pm.permissions[i] === '54') element.pm.permissions[i] = 'CompletedPM'
                        if (element.pm.permissions[i] === '55') element.pm.permissions[i] = 'ReportPM'
                        if (element.pm.permissions[i] === '56') element.pm.permissions[i] = 'ApprovePM'
                    }
                }
                if (element.serviceCatalog.length !== 0) {
                    for (let i = 0; i < element.serviceCatalog.permissions.length; i++) {
                        if (element.serviceCatalog.permissions[i] === '61') element.serviceCatalog.permissions[i] = 'ManageCatalogCategoryItemCatalog'
                        // if (element.serviceCatalog.permissions[i] === '62') element.serviceCatalog.permissions[i] = 'ManageWorkflow'
                        // if (element.serviceCatalog.permissions[i] === '63') element.serviceCatalog.permissions[i] = 'ApproveOrRejectWorkorder'
                        // if (element.serviceCatalog.permissions[i] === '64') element.serviceCatalog.permissions[i] = 'ApproveOrRejectJob'
                    }
                    element.serviceCatalog.scope = scopeMapping(element.serviceCatalog.scope)
                }
                if (element.workOrder?.length !== 0) {
                    for (let i = 0; i < element.workOrder.permissions.length; i++) {
                        if (element.workOrder.permissions[i] === '71') element.workOrder.permissions[i] = 'CreateWorkOrder'
                        if (element.workOrder.permissions[i] === '72') element.workOrder.permissions[i] = 'UpdateWorkOrder'
                        if (element.workOrder.permissions[i] === '73') element.workOrder.permissions[i] = 'UpdateJob'
                        if (element.workOrder.permissions[i] === '74') element.workOrder.permissions[i] = 'CloseWorkOrder'
                        if (element.workOrder.permissions[i] === '75') element.workOrder.permissions[i] = 'ApproveOrRejectWorkOrder'
                        if (element.workOrder.permissions[i] === '76') element.workOrder.permissions[i] = 'ApproveOrRejectJob'
                        if (element.workOrder.permissions[i] === '77') element.workOrder.permissions[i] = 'ViewWorkOrder'
                    }
                    element.workOrder.scope = scopeMapping(element.workOrder.scope)
                }
                if (element.problem.length !== 0) {
                    for (let i = 0; i < element.problem.permissions.length; i++) {
                        if (element.problem.permissions[i] === '81') element.problem.permissions[i] = 'CreateProblem'
                        if (element.problem.permissions[i] === '82') element.problem.permissions[i] = 'UpdateProblem'
                        if (element.problem.permissions[i] === '83') element.problem.permissions[i] = 'ViewProblem'
                        if (element.problem.permissions[i] === '84') element.problem.permissions[i] = 'CloseProblem'
                        if (element.problem.permissions[i] === '85') element.problem.permissions[i] = 'CreateProblemTask'
                        if (element.problem.permissions[i] === '86') element.problem.permissions[i] = 'UpdateProblemTask'
                        if (element.problem.permissions[i] === '87') element.problem.permissions[i] = 'ApprovalProblem'
                    }
                    element.problem.scope = scopeMapping(element.problem.scope)
                }
                // })
                tempMapping.push(element)
                return element
            } else {
                return tempMapping[0]
            }
        }
    }
}

export const scopeMapping = (value: any) => {
    let tmpValue = ''
    if (value?.toString() === '0') tmpValue = 'GlobalAccess'
    if (value?.toString() === '1') tmpValue = 'SelectedGroupAccess'
    if (value?.toString() === '2') tmpValue = 'GroupAccess'
    if (value?.toString() === '3') tmpValue = 'RestrictedAccess'
    return tmpValue
}
