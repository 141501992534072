import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { Categories, SubCategory, Item } from './model'

export interface CategoryStateEvent<T> extends GenericStateEvent<T> {}
export interface SubCategoryStateEvent<T> extends GenericStateEvent<T> {}
export interface ItemsStateEvent<T> extends GenericStateEvent<T> {}
export interface CategoryManagementStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllCategorySuccessEvent = {
    type: 'GetAllCategorySuccessEvent',
    build: (payload: Categories[]) => buildStateEvent(GetAllCategorySuccessEvent.type, payload)
}

export const UpdateCategorySuccessEvent = {
    type: 'UpdateCategorySuccessEvent',
    build: (payload: Categories) => buildStateEvent(UpdateCategorySuccessEvent.type, payload)
}

export const DeleteCategorySuccessEvent = {
    type: 'DeleteCategorySuccessEvent',
    build: (payload: number) => buildStateEvent(DeleteCategorySuccessEvent.type, payload)
}

export const DeleteAllCategorySuccessEvent = {
    type: 'DeleteAllCategorySuccessEvent',
    build: (payload: Categories[]) => buildStateEvent(DeleteAllCategorySuccessEvent.type, payload)
}

export const AddCategorySuccessEvent = {
    type: 'AddCategorySuccessEvent',
    build: (payload: Categories) => buildStateEvent(AddCategorySuccessEvent.type, payload)
}


// ============================================================================================
// ===================================== SubCategory ==========================================
// ============================================================================================


export const GetAllSubCategorySuccessEvent = {
    type: 'GetAllSubCategorySuccessEvent',
    build: (payload: SubCategory[]) => buildStateEvent(GetAllSubCategorySuccessEvent.type, payload)
}

export const AddSubCategorySuccessEvent = {
    type: 'AddSubCategorySuccessEvent',
    build: (payload: SubCategory) => buildStateEvent(AddSubCategorySuccessEvent.type, payload)
}


export const UpdateSubCategorySuccessEvent = {
    type: 'UpdateSubCategorySuccessEvent',
    build: (payload: SubCategory) => buildStateEvent(UpdateSubCategorySuccessEvent.type, payload)
}

export const DeleteSubCategorySuccessEvent = {
    type: 'DeleteSubCategorySuccessEvent',
    build: (payload: number) => buildStateEvent(DeleteSubCategorySuccessEvent.type, payload)
}


// ============================================================================================
// ======================================== Item =============================================
// ============================================================================================

export const GetAllItemsSuccessEvent = {
    type: 'GetAllItemsSuccessEvent',
    build: (payload: Item[]) => buildStateEvent(GetAllItemsSuccessEvent.type, payload)
}

export const AddItemsSuccessEvent = {
    type: 'AddItemsSuccessEvent',
    build: (payload: Item) => buildStateEvent(AddItemsSuccessEvent.type, payload)
}

export const UpdateItemsSuccessEvent = {
    type: 'UpdateItemsSuccessEvent',
    build: (payload: Item) => buildStateEvent(UpdateItemsSuccessEvent.type, payload)
}

export const DeleteItemsSuccessEvent = {
    type: 'DeleteItemsSuccessEvent',
    build: (payload: number) => buildStateEvent(DeleteItemsSuccessEvent.type, payload)
}

export const AddCategoryByExcelSuccessEvent = {
    type: 'AddCategoryByExcelSuccessEvent',
    build: (payload: Categories[]) => buildStateEvent(AddCategoryByExcelSuccessEvent.type, payload)
}

export const UpdateCategoryTotalPageSuccessEvent = {
    type: 'UpdateCategoryTotalPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateCategoryTotalPageSuccessEvent.type, payload)
}

export const UpdateCategoryCurrentPageSuccessEvent = {
    type: 'UpdateCategoryCurrentPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateCategoryCurrentPageSuccessEvent.type, payload)
}