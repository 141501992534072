import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const AssetRelatedContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const AssetRelatedReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_AssetRelated':
        return action.payload
    case 'ADD_AssetRelated':
        return [...state, action.payload]
    case 'DELETE_AssetRelated':
        const filterAssetRelated = state.filter((it) => it.id !== action.payload)
        return filterAssetRelated
    default:
        return state
    }
}

export const AssetRelatedProvider = (props: props) => {
    const [AssetRelatedState, AssetRelatedDispatch] = useReducer(AssetRelatedReducer, initialState)

    const GetAssetRelated = (payload) => {
        AssetRelatedDispatch({ type: 'GET_AssetRelated', payload })
    }

    const AddAssetRelated = (payload) => {
        AssetRelatedDispatch({ type: 'ADD_AssetRelated', payload })
    }

    const DeleteAssetRelated = (payload) => {
        AssetRelatedDispatch({ type: 'DELETE_AssetRelated', payload })
    }
    return (
        <AssetRelatedContext.Provider value={{ AssetRelatedState, GetAssetRelated, AddAssetRelated, DeleteAssetRelated }}>
            {props.children}
        </AssetRelatedContext.Provider>
    )
}