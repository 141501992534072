import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Avatar, Breadcrumb, Button, Col, Form, Icon, Input, message, Modal, Row, Select, Tabs, Upload, Checkbox } from 'antd'
import { Space } from '../../../../common-components/Space'
import '../../CustomStyle.css'
import exportIcon from '../../../../common-file/icon-file'
import { UploadFile } from 'antd/lib/upload/interface'
import { FormComponentProps } from 'antd/lib/form'
import ReactQuill, { Quill } from 'react-quill'
import FieldSetting from './FieldSetting'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { getAllCustomFields } from '../../../../configurable-fields'

import { StoreState } from '../../../../store'
import { SelfServiceCatagory, SelfServiceItem } from '../../Model'
import {
    addCatalogItem,
    deleteItemCatalog,
    getallCatalog,
    getallItemsNoRedux,
    getCatalogItemsById,
    searchCatagory,
    updateCatalogItem
} from '../../service'
import { getAllUrgency } from '../../../../incident-management/urgency'
import { getAllImpact } from '../../../../incident-management/impact'
import { PriorityLevel, getAllPriority } from '../../../../incident-management/priority'
import { getAllGeneralIncidentSetting } from '../../../../incident-management/general-incident-setting/service'
import FSS from '../../../../file-server-storage'
import { UploadLink } from '../../../../file-server-storage/model'
import { DownloadAnyFile } from '../../../../common-misc'
import { MyUploadLink } from '../../../../knowledge-management/knowledge/model'
import { WorkFlow } from '../../../workflow/Service/Model'
import { getAllWorkflowNoRedux } from '../../../workflow/Service/service'
import { PageServiceModelContext } from '../../ContextPage/PagesContext'
import { useLastLocation } from 'react-router-last-location'

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs,
        customFields: state.customFields,
        impacts: state.impacts,
        urgencies: state.urgencies,
        generalIncidentSetting: state.generalIncidentSetting,
        priority: state.priorities
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCatalog: () => Promise<number>
    getAllCustomFields: (value: string) => Promise<number>
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllPriority: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }, { color: [] }],
        ['link', 'image'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'alt', 'height', 'width', 'style', 'size',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align', 'color',
    'link', 'image'
]
const { Option } = Select
const { TabPane } = Tabs
const { confirm } = Modal
const { warning } = Modal
const ItemForm: React.FC<Props> = (props: Props) => {
    const { id }: any = useParams()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [detail, setDetail] = useState<string>('')
    const [catagoryList, setCatagoryList] = useState<SelfServiceCatagory[]>([])
    const [customFields, setCustomFields] = useState<any>()
    const [customFieldsForUsed, setCustomFieldsForUsed] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [isIncident, setIsIncident] = useState<boolean>(false)
    const [isRequest, setIsRequest] = useState<boolean>(false)
    const [isApproval, setIsApproval] = useState<boolean>(true)
    const [dataWorkflow, setDataWorkflow] = useState<WorkFlow[]>([])
    const [workflowId, setWorkflowId] = useState<number>()
    const [dataSource, setDataSource] = useState<SelfServiceItem>()
    const [isEditMode, setIsEditMode] = useState(false)
    const [isRedirect, setIsRedirect] = useState(false)
    const [isRedirectWithPage, setIsRedirectWithPage] = useState(false)
    const [ItemsNameList, setItemsNameList] = useState<string[]>([])
    const params = new URLSearchParams(window.location.search)
    const catalogId = params.get('catalogId')
    const categoryId = params.get('categoryId')
    const isPreven = window.location.pathname.includes('ItemCatalogView')
    const [tempCategory, setTempCategory] = useState<string | undefined>(undefined)
    const history = useHistory()
    const [pictureAvatar, setPictureAvatar] = useState<string>()
    const { PageServiceModelState } = useContext<any>(PageServiceModelContext)
    const lastLocation = useLastLocation()
    const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form
    const [tabpaneKeyPage, setTabpaneKeyPage] = useState<string>('1')

    useEffect(() => {
        if (props.selfServiceCatalogs.content.length === 0) {
            setLoading(true)
            props.getallCatalog().catch(err => {
                message.error(err)
            }).finally(() => {
                setLoading(false)
            })
        }
        props.getAllImpact().catch((err) => message.error(`Failed loading initial impact data. ${err}`))
        props.getAllUrgency().catch((err) => message.error(`Failed loading initial urgency data. ${err}`))
        props.getAllPriority().catch((err) => message.error(`Failed fetching priorities. ${err}`))
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
        if (id) {
            setIsEditMode(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (catalogId && catalogId.toLocaleLowerCase() !== 'undefined') {
            handleSelectCatalog(Number(catalogId))
            setFieldsValue({
                'category.catalog.id': Number(catalogId)
            })
        }
        if (categoryId && categoryId.toLocaleLowerCase() !== 'undefined') {
            setFieldsValue({
                'category.id': Number(categoryId)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogId, categoryId])

    useEffect(() => {
        getallItemsNoRedux().then((data) => {
            const itemName = data.content.map((value) => {
                if (id !== value.id.toString()) {
                    return value.name?.toLowerCase()
                }
            })
            if (id) {
                getCatalogItemsById(id).then(item => {
                    setDataSource(item)
                    setCustomFieldsForUsed(item.fields)
                    const filterOldName = itemName.filter((it) => it !== item?.name.toLowerCase())
                    setItemsNameList(filterOldName)
                })
            } else {
                setItemsNameList(itemName)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (dataSource) {
            setPictureAvatar(dataSource.imageUrl)
            if (dataSource.imageUrl) {
                const attachFile = JSON.parse(dataSource.imageUrl!!)
                const fileList = attachFile?.map((file: UploadLink) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        thumbUrl: exportIcon(file.type),
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(fileList)
            }
            console.log(dataSource)
            setFieldsValue({
                name: dataSource.name,
                'category.catalog.id': dataSource.category.catalog.id,
                type: dataSource.type,
                'category.id': dataSource.category.id,
                status: dataSource.status,
                description: dataSource.description,
                impact: dataSource.impact,
                urgency: dataSource.urgency,
                Expected: dataSource.expectedCompletion,
                Workflow: dataSource.workflowId,
                priority: dataSource.priority
            })

            setIsApproval(dataSource.needApproval || false)
            setWorkflowId(dataSource.workflowId)
            if (dataSource.type.toLowerCase() !== 'incident') {
                setIsRequest(true)
                setIsIncident(false)
            }

            if (dataSource.details) {
                setDetail(dataSource.details)
            }
            fetchCustomFields(dataSource.type)
            handleSelectCatalog(dataSource.category.catalog.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    useEffect(() => {
        if (props.customFields.fields) {
            const customFiledRequire = props.customFields.fields
            if (customFiledRequire.length > 0) {
                setCustomFields(customFiledRequire)
            } else {
                setCustomFields(undefined)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customFields])

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                if (fillOffFileList.length > 1) {
                    fillOffFileList.shift()
                    setFileList(fillOffFileList)
                } else {
                    setFileList(fillOffFileList)
                }
            }
            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const fetchCustomFields = (value) => {
        if (value) {
            if (value.toLowerCase() === 'incident') {
                setIsIncident(true)
                setIsRequest(false)
                props.getAllCustomFields(value)
            } else {
                setIsRequest(true)
                setIsIncident(false)
                getAllWorkflowNoRedux().then((data) => {
                    if (data) {
                        setDataWorkflow(data)
                    }
                })
            }
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const catalogOption = () => {
        if (props.selfServiceCatalogs) {
            return props.selfServiceCatalogs.content.map((catalog) => {
                return (<Option key={catalog.id} value={catalog.id}>{catalog.name}</Option>)
            })
        }
    }

    const catagoryOption = () => {
        if (catagoryList.length > 0) {
            return catagoryList.map((catagory) => {
                return (<Option key={catagory.id} value={catagory.id}>{catagory.name}</Option>)
            })
        }
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const onTextEditorChange = (value: string) => {
        if (value) {
            setDetail(value)
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                setLoading(true)
                let check = false
                let filterDesc
                let checkFieldSetting = false
                if (customFieldsForUsed !== undefined) {
                    filterDesc = customFieldsForUsed?.filter((td) => { return td.name !== 'Description' && td.name !== 'Appointment_Date' })
                    filterDesc.map((it) => {
                        it.defaultValue = it.defaultValue ? it.defaultValue.replace(/ +/g, ' ').trim() : it.defaultValue
                        it.placeholder = it.placeholder ? it.placeholder.replace(/ +/g, ' ').trim() : it.placeholder
                    })
                } else if (customFields !== undefined) {
                    const tempDataReq = customFields?.filter((td) => { return td?.isRequireFields })
                    tempDataReq.forEach((it) => {
                        if (it.isEditable === false && it.isRequireFields === false && it.isVisible === false && (it.defaultValue === undefined || it.defaultValue === '')) {
                            check = true
                        }
                        it.defaultValue = it.defaultValue ? it.defaultValue.replace(/ +/g, ' ').trim() : it.defaultValue
                        it.placeholder = it.placeholder ? it.placeholder.replace(/ +/g, ' ').trim() : it.placeholder
                        delete it.active
                        delete it.createdBy
                        delete it.createdDatetime
                        delete it.lastModifiedBy
                        delete it.lastModifiedDate
                        // delete it?.isDisableRequireFields
                        delete it?.defaultValueTemp
                        delete it?.lastModifiedDatetime
                    })
                    filterDesc = tempDataReq
                }
                if (filterDesc.length === 0) {
                    checkFieldSetting = true
                } else {
                    for (const it of filterDesc) {
                        if (it.name === '' || it.label === '') {
                            message.error('Field Label and Display Label in Field setting is required')
                            checkFieldSetting = false
                            break
                        } else if ((it.isEditable === false && it.isVisible === true) || (it.isRequireFields === true && it.isEditable === false && it.isVisible === false) && (it.defaultValue === null || it.defaultValue === '')) {
                            message.error('Default Value in Field setting is required')
                            checkFieldSetting = false
                            break
                        } else {
                            checkFieldSetting = true
                        }
                    }
                }
                const convertData: SelfServiceItem = {
                    category: values.category,
                    name: values.name.replace(/ +/g, ' ').trim(),
                    description: values.description ? values.description.replace(/ +/g, ' ').trim() : '',
                    type: values.type,
                    status: values.status,
                    details: detail,
                    fields: filterDesc || [],
                    impact: values.impact,
                    urgency: values.urgency,
                    workflowId: workflowId,
                    needApproval: values.type === 'Incident' ? false : isApproval,
                    expectedCompletion: values.Expected,
                    priority: values.priority
                }

                console.log(convertData)
                if (!check && checkFieldSetting) {
                    try {
                        if (dataSource && id) {
                            saveItemCatalog(convertData, id)
                        } else {
                            saveItemCatalog(convertData)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    // message.error('Please add defaultValue')
                    setLoading(false)
                }
            } else {
                if (tabpaneKeyPage !== '2') {
                    for (const it of customFieldsForUsed) {
                        if (it.name === '' || it.label === '') {
                            message.error('Field Label and Display Label in Field setting is required')
                            break
                        } else if ((it.isEditable === false && it.isVisible === true) || (it.isRequireFields === true && it.isEditable === false && it.isVisible === false) && (it.defaultValue === null || it.defaultValue === '')) {
                            message.error('Default Value in Field setting is required')
                            break
                        } else {
                        }
                    }
                }
            }
        })
    }

    const uploadImageInTextArea = (value: string, callBack: Function) => {
        if (value.search('<img')) {
            const MyFileList: UploadFile[] = []
            const temp: string[] = []
            const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi
            const HTMLTags = value.match(pattern)
            if (HTMLTags) {
                // when new image is not last
                for (let i = 0; i < HTMLTags.length; i++) {
                    if (HTMLTags[i].search('<img') !== -1) {
                        const tagDatasource = HTMLTags[i].split('<img src="data:')
                        tagDatasource.forEach(tagData => {
                            if (tagData.search('image') !== -1) {
                                temp.push(tagData.split('"')[0])
                            }
                        })
                    } else {
                        temp.push(HTMLTags[i])
                    }
                }
            }

            const newValue: string[] = []
            temp.forEach((it, index) => {
                if (it && it.startsWith('image')) {
                    const fileType = it.split(';')[0]
                    const fileExtention = fileType.split('/')[1] || ''
                    const deleteTagHtml = 'data:' + it.split('">')[0]
                    const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
                    const ConvertUploadLink: UploadFile = {
                        uid: `${id}-${ts}`,
                        name: `${id}-${ts}.${fileExtention}`,
                        type: fileType,
                        url: deleteTagHtml,
                        size: deleteTagHtml.length
                    }
                    MyFileList.push(ConvertUploadLink)
                }
            })

            if (MyFileList.length === 0) callBack(value)

            FSS.putFile(MyFileList, '/knowledgeEditor/', (uploadLink) => {
                if (uploadLink) {
                    // eslint-disable-next-line no-unused-expressions
                    uploadLink?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                    if (HTMLTags) {
                        let imgIndex = 0
                        HTMLTags.forEach((it, index) => {
                            if (HTMLTags[index].search('<img') !== -1) {
                                const inside64 = HTMLTags[index].split('<img src="data:')
                                console.log('inside64', inside64)
                                inside64.forEach(tagData => {
                                    if (tagData.startsWith('image')) {
                                        const insideReplace = tagData.split('"')[0]
                                        imgIndex++
                                        newValue.push(...tagData.replace(insideReplace, `<img src="${uploadLink[imgIndex - 1].url}`))
                                    } else {
                                        newValue.push(...tagData)
                                    }
                                })
                            } else {
                                newValue.push(HTMLTags[index])
                            }
                        })
                    }
                    const joinV = newValue.join('')
                    console.log(joinV)
                    setDetail(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
                    callBack(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
                }
            })
        }
    }

    const saveItemCatalog = (data: SelfServiceItem, id?: number) => {
        uploadImageInTextArea(detail, (newDetail) => {
            data.details = newDetail || ''
            if (fileList.length > 0 && fileList.length <= 5) {
                FSS.putFile(fileList, '/ItemCatalog/', (uploadLink: UploadLink[]) => {
                    console.debug(uploadLink)
                    if (data.id) {
                        const dataLink = uploadLink.map((it) => {
                            return {
                                knowledgeId: data.id,
                                name: it.name,
                                url: it.url,
                                type: it.type
                            } as MyUploadLink
                        })
                        data.imageUrl = JSON.stringify(dataLink)
                    } else {
                        data.imageUrl = JSON.stringify(uploadLink)
                    }
                    console.log('data', data)
                    if (id) {
                        updateCatalogItem(id, data).then(() => {
                            message.success('The Update has finished successfully.')
                        }).catch((err) => {
                            message.error(err)
                        }).finally(() => {
                            // Done
                            setLoading(false)
                            if (catalogId) {
                                history.goBack()
                            } else {
                                setIsRedirect(true)
                            }
                        })
                    } else {
                        addCatalogItem(data).then(() => {
                            message.success('You have Successfully save the data.')
                        }).catch((err) => {
                            message.error(err)
                        }).finally(() => {
                            // Done
                            setLoading(false)
                            if (catalogId) {
                                history.goBack()
                            } else {
                                setIsRedirect(true)
                            }
                        })
                    }
                })
            } else if (fileList.length > 5) {
                message.error('Upload File Max 5')
            } else {
                if (id) {
                    data.imageUrl = ''
                    updateCatalogItem(id, data).then(() => {
                        message.success('The Update has finished successfully.')
                    }).catch((err) => {
                        message.error(err)
                    }).finally(() => {
                        // Done
                        setLoading(false)
                        if (catalogId) {
                            history.goBack()
                        } else {
                            setIsRedirect(true)
                        }
                    })
                } else {
                    addCatalogItem(data).then(() => {
                        message.success('You have Successfully save the data.')
                    }).catch((err) => {
                        message.error(err)
                    }).finally(() => {
                        // Done
                        setLoading(false)
                        if (catalogId) {
                            history.goBack()
                        } else {
                            setIsRedirect(true)
                        }
                    })
                }
            }
        })
    }

    const handleCancel = () => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                resetFields()
                setIsRedirect(true)
            }
        })
    }

    const handleSelectCatalog = (catalogId) => {
        searchCatagory(0, 9999, `${catalogId}`).then(categories => {
            if (categories) {
                setCatagoryList(categories.content)
            }
        })
    }

    const selectData = () => {
        if (dataSource?.status !== 'Published') {
            setIsEditMode(!isEditMode)
        } else {
            warning({
                title: 'Warning',
                content: 'Please change your status to Draft to make connections.',
                onOk() {
                    console.log('ok')
                }
            })
        }
    }

    const isDeleteData = () => {
        if (dataSource?.status !== 'Published') {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete of this data ?',
                onOk() {
                    deleteItemCatalog(id).then((res) => {
                        if (lastLocation.pathname.substring(0, 13) === '/CategoryView' || lastLocation.pathname.substring(0, 13) === '/CatalogView/') {
                            history.goBack()
                        } else {
                            setIsRedirectWithPage(true)
                        }
                    }).catch((err) => {
                        message.error('Delete Data Error' || err)
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } else {
            warning({
                title: 'Warning',
                content: 'Please change your status to Draft to make connections.',
                onOk() {
                    console.log('ok')
                }
            })
        }
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        if (value) {
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    if (props.form.getFieldValue('name')) {
                        if (ItemsNameList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                            callback('The Item Name already exists. It must be unique. Please try another one.')
                        } else {
                            callback()
                        }
                    } else {
                        callback()
                    }
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else if (value.replace(/ +/g, '').trim().length <= 3) {
                    callback('Enter more than 3 characters')
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    const oppositeStatus = (status: string) => {
        if (status === 'Published') {
            return 'Draft'
        } else {
            return 'Published'
        }
    }

    const checkCategory = () => {
        props.form.setFieldsValue({
            'category.id': undefined
        })
        setTempCategory(undefined)
    }

    const handleChangeStatus = (status: string) => {
        confirm({
            title: 'Are you sure?',
            content: `Do you confirm Change status to ${oppositeStatus(status)} ?`,
            onOk() {
                const tempDataSource = dataSource
                if (tempDataSource) {
                    tempDataSource.status = oppositeStatus(status)
                    delete tempDataSource.createdDatetime
                    updateCatalogItem(id, tempDataSource).then(() => {
                        setDataSource(tempDataSource)
                        message.success('The Update has finished successfully.')
                    }).catch((err) => {
                        message.error('Update Error')
                        message.error(err)
                    }).finally(() => {
                        setFieldsValue({
                            status: tempDataSource.status
                        })
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const onChangeCheckApproval = () => {
        setIsApproval(!isApproval)
    }

    const getWorkflowID = (value) => {
        setWorkflowId(value)
    }

    return (
        <>
            {isRedirectWithPage ? <Redirect to={`/CatalogList?path=Item Catalog&page=${PageServiceModelState.pageItemCatalog || 1}`} /> : null}
            {isRedirect ? <Redirect to='/CatalogList?path=Item Catalog' /> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/CatalogList'}>Maintain Catalog</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/CatalogList?path=Item Catalog">Item Catalog</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{id ? dataSource?.name : 'Create Item Catalog'}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <Form onSubmit={handleSubmit} >
                <div className={'My-card'}>
                    <p className={'HerderText'}>{selectData}{!isEditMode && isPreven ? <>Edit </> : <div></div>} {id ? dataSource?.name : 'Create Item Catalog'} {id ? <><Icon type="edit" style={{ margin: '0px 5px' }} onClick={() => selectData()} /> <Icon type="delete" onClick={() => isDeleteData()} /></> : null}</p>
                    <br />
                    <Row gutter={[16, 16]}>
                        <Col span={4} style={{ zIndex: 2 }}>
                            <Col span={24} style={{
                                textAlign: 'center',
                                // paddingTop: 24,
                                maxWidth: 'min-content'
                                // paddingLeft: 50
                            }}>
                                {/* Picture Avatar */}
                                <Avatar shape="square" size={170} icon="user" src={fileList?.length > 0 ? fileList[0]?.url : pictureAvatar} />
                                <Upload {...uploadProps}
                                    fileList={fileList}
                                    onPreview={(e: any) => handlePreview(e)}
                                    disabled={isEditMode}
                                >
                                    <Button style={{ marginTop: 10 }} disabled={isEditMode} ><Icon type="upload" />Upload Picture</Button>
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} >
                                    <img alt={previewImage} style={{ width: '170' }} src={previewImage} />
                                </Modal>
                            </Col>
                        </Col>
                        <Col span={20} style={{ zIndex: 2 }}>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label={'Item Name :'}>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                { required: true, message: 'Please input  Item Name!' },
                                                { max: 60, message: 'max Input 60 characters' },
                                                { validator: validateNameUniqueness }
                                            ]
                                        })(
                                            <Input placeholder="Item Name" disabled={isEditMode} maxLength={60} max={60} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label={'Catalog Name :'}>
                                        {getFieldDecorator('category.catalog.id', {
                                            rules: [{ required: true, message: 'Please Select Catalog Name!' }]
                                        })(
                                            <Select
                                                onChange={checkCategory}
                                                placeholder="Select Catalog Name"
                                                onSelect={(e) => handleSelectCatalog(e)}
                                                disabled={isEditMode}
                                                allowClear={true}
                                            // disabled={true}
                                            >
                                                {catalogOption()}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={'Type :'}>
                                        {getFieldDecorator('type', {
                                            rules: [{ required: true, message: 'Please Select Type!' }]
                                        })(
                                            <Select
                                                placeholder="Select Type"
                                                allowClear={true}
                                                onSelect={(e) => fetchCustomFields(e)}
                                                disabled={isEditMode}
                                            >
                                                {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                                    return <Option value={item.value}
                                                        key={index}>{item.value}</Option>
                                                }))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label={'Category Name :'}>
                                        {getFieldDecorator('category.id', {
                                            initialValue: tempCategory,
                                            rules: [{ required: true, message: 'Please Select Catagory Name!' }]
                                        })(
                                            <Select
                                                placeholder="Select Category Name"
                                                disabled={isEditMode}
                                                allowClear={true}
                                            >
                                                {catagoryOption()}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={isEditMode ? 8 : 12}>
                                    <Form.Item label={'Status :'}>
                                        {getFieldDecorator('status', {
                                            rules: [{ required: true, message: 'Please Select Status!' }]
                                        })(
                                            <Select
                                                placeholder="Select Status"
                                                disabled={isEditMode}
                                            >
                                                <Option value="Published">Published</Option>
                                                <Option value="Draft">Draft</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                {isEditMode ? <Col span={4}>
                                    <Button className='btn-status' style={{ width: '100%' }} icon='sync' onClick={() => handleChangeStatus(getFieldValue('status'))} >Change to {oppositeStatus(getFieldValue('status'))}</Button>
                                </Col> : null}
                            </Row>
                            <Row gutter={16}>
                                {isIncident ? <>
                                    <Col span={12}>
                                        <Form.Item label={'Impact :'}>
                                            {getFieldDecorator('impact', {
                                                initialValue: Number(dataSource?.impact) || undefined,
                                                rules: [{ required: true, message: 'Please input your Impact!' }]
                                            })(
                                                <Select
                                                    placeholder="Select Impact"
                                                    disabled={isEditMode}
                                                >
                                                    {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                                        return <Option value={item.id}
                                                            key={index}>{item.name}</Option>
                                                    }))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Urgency :'}>
                                            {getFieldDecorator('urgency', {
                                                initialValue: Number(dataSource?.urgency) || undefined,
                                                rules: [{ required: true, message: 'Please input your Urgency!' }]
                                            })(
                                                <Select
                                                    placeholder="Select Urgency"
                                                    disabled={isEditMode}
                                                >
                                                    {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                                        return <Option value={item.id}
                                                            key={index}>{item.name}</Option>
                                                    }))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </> : null}
                                {isRequest ? <>
                                    <Col span={12}>
                                        <Form.Item label={'Priority :'}>
                                            {getFieldDecorator('priority', {
                                                initialValue: Number(dataSource?.priority) || undefined,
                                                rules: [{ required: true, message: 'Please input your Priority!' }]
                                            })(
                                                <Select
                                                    placeholder="Select Priority"
                                                    disabled={isEditMode}
                                                >
                                                    {props.priority.length === 0 ? null : (props.priority.map((item: PriorityLevel, index: number) => {
                                                        return <Option value={item.number}
                                                            key={index}>{item.name}</Option>
                                                    }))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Expected completion :'}>
                                            {getFieldDecorator('Expected', {
                                                initialValue: dataSource?.expectedCompletion,
                                                rules: [{ required: true, message: 'Please input your Expected completion!' }, {
                                                    pattern: /^[0-9\b]+$/,
                                                    message: 'Please enter numbers only'
                                                }]
                                            })(
                                                <Input addonAfter={<div>Day</div>} placeholder="Expected completion" disabled={isEditMode} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </> : null}
                            </Row>
                        </Col>
                        <Row >
                            <Col span={24}>
                                <Tabs defaultActiveKey="1" onChange={(value) => setTabpaneKeyPage(value)}>
                                    <TabPane tab="Item Details" key="1">
                                        <Row gutter={[8, 8]}>
                                            <Col span={2}>
                                                <span>Description :</span>
                                            </Col>
                                            <Col span={22}>
                                                <Form.Item >
                                                    {getFieldDecorator('description', {
                                                        rules: [{ max: 255, message: 'max Input 255 characters' }]
                                                    })(
                                                        <Input placeholder="Description" disabled={isEditMode} max={255} maxLength={255} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <span>Details :</span>
                                            </Col>
                                            <Col span={22}>
                                                <Form.Item >
                                                    <ReactQuill readOnly={isEditMode} modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hidden', backgroundColor: isEditMode ? '#f5f5f5' : '#ffffff' }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={isRequest ? 'Workflow / Field Setting' : 'Field Setting'} disabled={getFieldValue('type') === undefined} key="2">
                                        <FieldSetting form={props.form} data={customFields} setCustomFieldsForUsed={setCustomFieldsForUsed} customFieldsForUsed={customFieldsForUsed} isEditMode={isEditMode} isRequest={isRequest} dataWorkflow={dataWorkflow} getWorkflowID={getWorkflowID} dataWorkflowId={workflowId} loading={loading} ItemcatalogOldType={dataSource?.type === 'Request'} currentTabpane={tabpaneKeyPage} />
                                    </TabPane>
                                    {isRequest ? <TabPane tab={'Approval'} disabled={getFieldValue('type') === undefined} key="3">
                                        <Checkbox onChange={(e) => onChangeCheckApproval()} checked={isApproval} style={{ height: '220px' }} disabled={isEditMode} >Requester Workorder approval</Checkbox>
                                    </TabPane> : null}
                                </Tabs>
                            </Col>
                            <Col span={24} style={{ marginTop: 10 }}>
                                {!isEditMode ? <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} loading={loading} >Submit</Button> : <></>}
                                {!isEditMode ? <Button style={{ float: 'right' }} onClick={() => handleCancel()} >Cancel</Button> : <></>}
                            </Col>
                        </Row>
                    </Row>
                </div>
            </Form>
        </>
    )
}

const MyItemForm = Form.create<Props>({ name: 'ItemForm' })(ItemForm)
export default connect(mapStateToProps,
    { getallCatalog, getAllCustomFields, getAllUrgency, getAllImpact, getAllGeneralIncidentSetting, getAllPriority }
)(MyItemForm)
