import React, { useState, useEffect } from 'react'
import { Button, Card, Col, message, Modal, Input, Row, Table, Form } from 'antd'
import { getApproveServiceRequest, updateApproveServiceRequestStatus } from './service'
import { FormComponentProps } from 'antd/lib/form'
import { convertCamelCaseToSentenceCase } from '../util'
import { ServiceRequestList, ServiceRequestStatus, ApproveServiceRequest } from './model'
import { Link } from 'react-router-dom'
import { waitingGetSessions } from '../../guard/route'

const pageSize = require('../knowledge/config.json').pageSize

interface Params {
    serviceRequestList: ServiceRequestList
}

type Props = Params & FormComponentProps

const ApprovalSelf: React.FC<Props> = (props: Props) => {
    const [servicereq, setServicereq] = useState<ServiceRequestList>(props.serviceRequestList)
    const [isLoadingForButton, setIsLoadingForButton] = useState<boolean>(false)
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
    const [onSearch, setOnSearch] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [serviceReqId, setServiceReqId] = useState<number>(0)
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
    const [inputReq, setInputReq] = useState<boolean>(false)

    useEffect(() => {
        if (props.serviceRequestList) {
            setServicereq(props.serviceRequestList)
        }
    }, [props.serviceRequestList])

    useEffect(() => {
        if (onSearch) {
            getApproveServiceRequest(sessionStorage.getItem('username') || '', ServiceRequestStatus.NotActioned).then((serviceRequestList) => {
                setServicereq(serviceRequestList)
            }).catch((error) => {
                message.error(error.message)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch, currentPage])

    const columns = [{
        title: 'Ticket Number',
        dataIndex: '',
        key: '',
        width: '15%',
        render: (data) => {
            return (
                <Link to={`/IncidentDetailsView/${data.itemId}`} target="_blank" onClick={() => waitingGetSessions()}>{data.itemNumber?.split('/')[0]}</Link>
            )
        }
    },
    {
        title: 'Topic',
        dataIndex: 'itemSummary',
        key: 'itemSummary',
        width: '45%'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        // eslint-disable-next-line react/display-name
        render: (status: string) => {
            return (
                <span>{convertCamelCaseToSentenceCase(status || '')}</span>
            )
        }
    },
    {
        title: '',
        key: 'action',
        width: '20%',
        // eslint-disable-next-line react/display-name
        render: (serviceReq: ApproveServiceRequest) => {
            if (serviceReq.status === ServiceRequestStatus.AwaitingApproval) {
                return (
                    <Row gutter={[16, 16]}>
                        <span className='approval-button-group'>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="danger" id={serviceReq.id?.toString()} onClick={onReject}>Reject</Button>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="primary"
                                    id={serviceReq.id?.toString()}
                                    onClick={onSubmitApprove}>Approve</Button>
                            </Col>
                        </span>
                    </Row>
                )
            } else if (serviceReq.status === ServiceRequestStatus.Approved) {
                return (
                    <span>You have Successfully Approved.</span>
                )
            } else if (serviceReq.status === ServiceRequestStatus.Rejected) {
                return (
                    <span>You have Successfully Rejected.</span>
                )
            }
            console.log(serviceReq.status);
        }
    }]

    const onApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        console.log('onApprove', event.currentTarget.id)
        validateFields((error, row) => {
            if (error) {
                return
            }
            setIsLoadingForButton(true)
            updateApproveServiceRequestStatus(inputReq ? 'reject' : 'approve', serviceReqId, row.Reason).then(() => {
                message.success(`You have Successfully ${inputReq ? 'reject' : 'approve'} the data.`)
                updateServiceRequestStatus(serviceReqId)
                setIsVisibleModal(false)
                setIsLoadingForButton(false)
                setFieldsValue({ Reason: undefined })
            }).catch(() => {
                message.error('Error can not Update Data.')
            }).finally(() => {
                setIsVisibleModal(false)
                setIsLoadingForButton(false)
            })
        })
    }

    const onReject = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setInputReq(true)
        setServiceReqId(Number(event.currentTarget.id))
        setIsVisibleModal(true)
    }

    const onSubmitApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setInputReq(false)
        setServiceReqId(Number(event.currentTarget.id))
        setIsVisibleModal(true)
    }

    const updateServiceRequestStatus = (result: Number) => {
        const newContent = servicereq.content?.map((serviceReq) => {
            if (serviceReq.id === result) {
                if (inputReq) {
                    serviceReq.status = ServiceRequestStatus.Rejected
                } else {
                    serviceReq.status = ServiceRequestStatus.Approved
                }
                return serviceReq
            } else {
                return serviceReq
            }
        })
        const newServiceReq = servicereq
        newServiceReq.content = newContent
        setServicereq(newServiceReq)
    }

    const handleOnClickPageNumber = (page: number) => {
        setCurrentPage(page)
        setOnSearch(true)
    }

    const onCancel = () => {
        setIsVisibleModal(false)
        resetFields()
    }

    return (
        <>
            <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
                <Card className='card-height-approval-default'>
                    <Row>
                        <h2 className="main-title">
                            Service Request ({servicereq?.totalElements || '0'})
                        </h2>
                    </Row>
                    <div style={{ overflowX: 'auto' }}>
                        <Row>
                            <Table
                                className="table-configherder"
                                dataSource={servicereq?.content || []}
                                columns={columns}
                                rowKey="id"
                                style={{ minWidth: 500 }}
                                pagination={{
                                    pageSize: pageSize,
                                    current: currentPage,
                                    total: servicereq?.totalElements || 0,
                                    onChange: (event) => { handleOnClickPageNumber(event) }
                                }}
                            />
                        </Row>
                    </div>
                </Card>
                <Modal
                    title="Reason"
                    visible={isVisibleModal}
                    onCancel={onCancel}
                    footer={[
                        <Button key="back" onClick={onCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" loading={isLoadingForButton} type="primary" onClick={onApprove}>
                            Submit
                        </Button>
                    ]}>
                    <Form>
                        <Form.Item label='reason'>
                            {getFieldDecorator('Reason', {
                                rules: [{ required: inputReq, message: 'Approve reason is required' },
                                    { min: inputReq ? 3 : 0, message: 'Enter more than 3 characters' }]
                            })(
                                <Input.TextArea rows={4} placeholder="please enter description" />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
        </>
    )
}

const ApprovalSelfListForm = Form.create<Props>()(ApprovalSelf)

export default ApprovalSelfListForm
