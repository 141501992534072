import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { PageServiceModel } from '../Model'

export const PageServiceModelContext = createContext({})

const initialState: PageServiceModel = {
    pageCatalog: 1,
    pageCategory: 1,
    pageItemCatalog: 1
}

interface Param{}

type props = PropsWithChildren<Param>
const PageServiceModelReducer = (state: PageServiceModel, action: {type: string; payload: any}) => {
    switch (action.type) {
    case 'GET_PageServiceModel':
        return action.payload as PageServiceModel
    default:
        return state
    }
}

export const PageServiceModelProvider = (props: props) => {
    const [PageServiceModelState, PageServiceModelDispatch] = useReducer(PageServiceModelReducer, initialState)
    const GetPageServiceModel = (payload: PageServiceModel) => {
        PageServiceModelDispatch({type: 'GET_PageServiceModel', payload})
    }
    return (
        <PageServiceModelContext.Provider value = {{ PageServiceModelState, GetPageServiceModel}} >
            {props.children}
        </PageServiceModelContext.Provider>
    )
}
