import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const AppointLocationContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const AppointLocationReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_AppointLocation':
        sessionStorage.setItem('appoint_location', JSON.stringify(action.payload))
        return action.payload
    case 'ADD_AppointLocation':
        return [...state, action.payload]
    default:
        return state
    }
}

export const AppointLocationProvider = (props: props) => {
    const [AppointLocationState, AppointLocationDispatch] = useReducer(AppointLocationReducer, initialState)

    const GetAppointLocation = (payload) => {
        AppointLocationDispatch({ type: 'GET_AppointLocation', payload })
    }

    const AddAppointLocation = (payload) => {
        AppointLocationDispatch({ type: 'ADD_AppointLocation', payload })
    }

    return (
        <AppointLocationContext.Provider value={{ AppointLocationState, GetAppointLocation, AddAppointLocation }}>
            {props.children}
        </AppointLocationContext.Provider>
    )
}
