import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {SelfServiceCatalog, SelfServiceCatagory, SelfServiceItem} from './Model'

export interface SelfServiceStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllSelfServiceCatalogSuccessEvent = {
    type: 'GetAllSelfServiceCatalogSuccessEvent',
    build: (payload: SelfServiceCatalog[]) => buildStateEvent(GetAllSelfServiceCatalogSuccessEvent.type, payload)
}

export const AddSelfServiceCatalogSuccessEvent = {
    type: 'AddSelfServiceCatalogSuccessEvent',
    build: (payload: SelfServiceCatalog) => buildStateEvent(AddSelfServiceCatalogSuccessEvent.type, payload)
}

export const UpdateSelfServiceCatalogSuccessEvent = {
    type: 'UpdateSelfServiceCatalogSuccessEvent',
    build: (payload: SelfServiceCatalog) => buildStateEvent(UpdateSelfServiceCatalogSuccessEvent.type, payload)
}

export const DeleteSelfServiceCatalogSuccessEvent = {
    type: 'DeleteSelfServiceCatalogSuccessEvent',
    build: (payload: SelfServiceCatalog) => buildStateEvent(DeleteSelfServiceCatalogSuccessEvent.type, payload)
}

export const GetAllSelfServiceCatagorySuccessEvent = {
    type: 'GetAllSelfServiceCatagorySuccessEvent',
    build: (payload: SelfServiceCatagory[]) => buildStateEvent(GetAllSelfServiceCatagorySuccessEvent.type, payload)
}

export const AddSelfServiceCatagorySuccessEvent = {
    type: 'AddSelfServiceCatagorySuccessEvent',
    build: (payload: SelfServiceCatagory) => buildStateEvent(AddSelfServiceCatagorySuccessEvent.type, payload)
}

export const GetAllSelfServiceItemSuccessEvent = {
    type: 'GetAllSelfServiceItemSuccessEvent',
    build: (payload: SelfServiceItem[]) => buildStateEvent(GetAllSelfServiceItemSuccessEvent.type, payload)
}

export const DeleteSelfServiceCatagorySuccessEvent = {
    type: 'DeleteSelfServiceCatagorySuccessEvent',
    build: (payload: number) => buildStateEvent(DeleteSelfServiceCatagorySuccessEvent.type, payload)
}
