import React, {useEffect, useState} from 'react'
import {Row, Col, Breadcrumb, Button, Icon, Table, Badge, message} from 'antd'
import {Space} from '../common-components/Space'
import SubTablePackage from './Components/SubTablePackage'
import {Link} from 'react-router-dom'
import {getAllOrder, getDashboardPackageByTenantId} from './Services/Service'
import {PackageDashboard, Order, subTableOrder} from './Services/model'
import {StoreState} from '../store'
import {connect} from 'react-redux'

const mapStateToProps = (state: StoreState) => {
    return {
        orders: state.orders
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllOrder: () => Promise<number>
}

type Props = StateProps & DispatchProps

const SubscriptManagement: React.FC<Props> = (props: Props) => {
    const [Dashboard, setDashboard] = useState<PackageDashboard>()

    useEffect(() => {
        getDashboardPackageByTenantId().then((res) => {
            if (res) {
                setDashboard(res)
            }
        }).catch((err) => {
            message.error(err?.response?.message || 'Error Dashboard')
        })
        props.getAllOrder().catch((err) => {
            message.error(err?.response?.message || 'Error Tables')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'Order Number',
            dataIndex: '',
            key: '',
            render: (order: Order) => (
                <>
                    <Link to={`/OrderDetail/${order.id}`}>{order.orderNumber}</Link>
                </>
            )
        },
        {
            title: 'Start Date',
            dataIndex: '',
            key: '',
            render: (order: Order) => (
                <>
                    <div >{order.packageSubscription[0].startDate}</div>
                </>
            )
        },
        {
            title: 'End Date',
            dataIndex: '',
            key: '',
            render: (order: Order) => (
                <>
                    <div >{order.packageSubscription[0].endDate}</div>
                </>
            )
        },
        {
            title: 'Total',
            dataIndex: '',
            key: '',
            render: (order: Order) => (
                <>
                    <div >{order.billing.amount}</div>
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (order: Order) => (
                <>
                    <div>{checkServiceActive(order) ? <Badge color={'green'} text={'Active'} /> : <Badge color={'red'} text={'InActive'} />}</div>
                </>
            )
        }
    ]

    const checkServiceActive = (order: Order): boolean => {
        let isCheck = false
        if (order) {
            const filterMainPackage = order.packageSubscription.find((it) => (it.packages.packageType === 'm' || it.packages.packageType === 'f') && it.isEnable)
            if (filterMainPackage) {
                isCheck = true
            }
        }
        return isCheck
    }

    const expandedRowRender = (expandedRow) => {
        const filterMainPackage = expandedRow.packageSubscription.find((it) => it.packages.packageType === 'm' || it.packages.packageType === 'f')
        const filterUserPackage = expandedRow.packageSubscription.find((it) => it.packages.packageType === 'u')
        const filterPeoplePackage = expandedRow.packageSubscription.find((it) => it.packages.packageType === 'p')
        const filterDatabasePackage = expandedRow.packageSubscription.find((it) => it.packages.packageType === 'd')
        const filterStoragePackage = expandedRow.packageSubscription.find((it) => it.packages.packageType === 's')
        const subTableOrders: subTableOrder = {
            id: 1,
            Service: filterMainPackage?.packages?.packageName,
            User: filterUserPackage?.subscriptionTotal || filterMainPackage?.packages.quotaMaster.limitGoServiceUser + ' User',
            People: filterPeoplePackage?.subscriptionTotal || filterMainPackage?.packages.quotaMaster.limitServicePortal + ' People',
            StorageSize: (filterStoragePackage?.subscriptionTotal * filterStoragePackage?.packages.quotaMaster.limitStorageSize) ? (filterStoragePackage?.subscriptionTotal * filterStoragePackage?.packages.quotaMaster.limitStorageSize) + ' GB' : filterMainPackage?.packages.quotaMaster.limitStorageSize + ' GB',
            DatabaseSize: (filterDatabasePackage?.subscriptionTotal * filterDatabasePackage?.packages.quotaMaster.limitDbSize) ? (filterDatabasePackage?.subscriptionTotal * filterDatabasePackage?.packages.quotaMaster.limitDbSize) + ' GB' : filterMainPackage?.packages.quotaMaster.limitDbSize + ' GB'
        }

        return <SubTablePackage title={expandedRow.orderNumber} subTableOrder={subTableOrders}/>
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to="/Setting">Setting</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Subscription</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'Box'}>
                <div><p className={'HerderText'}>My Package</p></div>
                <Row gutter={[16, 16]}>
                    <Col span={3} >
                        <div className={'BoxLimitUser'}>
                            <div><Icon type="user" /> Limit User</div>
                            <div className={'TextLimitUser'} style={{textAlign: 'end'}}>{Dashboard?.limitUser} User</div>
                        </div>
                    </Col>
                    <Col span={3} >
                        <div className={'BoxLimitUser'}>
                            <div><Icon type="team" /> Limit People</div>
                            <div className={'TextLimitUser'} style={{textAlign: 'end'}}>{Dashboard?.limitPeople} User</div>
                        </div>
                    </Col>
                    <Col span={3} >
                        <div className={'BoxLimitUser'}>
                            <div><Icon type="hdd" /> Storage size</div>
                            <div className={'TextLimitUser'} style={{textAlign: 'end'}}>{Dashboard?.storageSize} GB</div>
                        </div>
                    </Col>
                    <Col span={3} >
                        <div className={'BoxLimitUser'}>
                            <div><Icon type="database" /> Database size</div>
                            <div className={'TextLimitUser'} style={{textAlign: 'end'}}>{Dashboard?.databaseSize} GB</div>
                        </div>
                    </Col>
                    <Col span={12} style={{textAlign: 'end'}}>
                        <Link to={'/PackageDetail'}><Button type={'primary'}>Create Package</Button></Link>
                    </Col>
                </Row>
                <Space size={20} />
                <Table
                    dataSource={props.orders}
                    columns={columns}
                    expandedRowRender={expandedRowRender}
                    rowKey={'id'}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {getAllOrder})(SubscriptManagement)
