import React from 'react'
import {Collapse} from 'antd'
import TableJob from './TableJob'

interface Params {
    woId: string,
    username: string,
    handleIsChangeStatus: (boolean) => void,
    permission?: boolean,
    roleId?: number,
    isCloseAndCancel: boolean,
    woNumber: string
}

type Props = Params
const { Panel } = Collapse

const ExpandList: React.FC<Props> = (props: Props) => {
    const callback = (key) => {
        console.log(key)
    }

    return (
        <>
            <Collapse defaultActiveKey={['1']} onChange={callback} >
                <Panel header={props.woNumber} key="1">
                    <TableJob woId={props.woId} username={props.username}
                        handleIsChangeStatus={props.handleIsChangeStatus}
                        roleId={props.roleId} isCloseAndCancel={props.isCloseAndCancel}/>
                </Panel>
            </Collapse>
        </>
    )
}

export default ExpandList
