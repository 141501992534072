import { CommentResponseModel, LikeCommentModel } from './model'
import { axiosGet, axiosPost } from '../../rest'
import { checkRedirect, herderPostPatch } from '../../../common-misc'
import { decryptBody, encryptBody } from '../../../common-misc/encryptBody'

export const getCommentByKnowledgeId = async (knowledgeId: string) => {
    try {
        const response = await axiosGet<CommentResponseModel[]>(`/api/comments/${knowledgeId}/list`)
        return decryptBody(response.data.data) || []
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const addComment = async (comment: CommentResponseModel) => {
    try {
        const encryptData = encryptBody(JSON.stringify(comment))
        const response = await axiosPost<CommentResponseModel>('/api/comments', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const likeComment = async (body: LikeCommentModel) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/likes', encryptData, herderPostPatch)
        return response.status === 201
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}
