import {Breadcrumb, Spin, Tabs} from 'antd'
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {People} from '../../authorization-module/people/model'
import {Space} from '../../common-components/Space'
import {AssetStateContext} from '../../Preventive/ContextAPI/AssetStateContext'
import {LocationBuildingContext} from '../../Preventive/ContextAPI/LocationBuildingContext'
import {PeopleContext} from '../../Preventive/ContextAPI/UserHRContext'
import {Asset} from '../../Preventive/Model'
import {getAssetByID, getDataItems, getDataLocationBuilding} from '../../Preventive/services'
import TabAssetDetails from './components/TabAssetDetails'
import TabHistory from './components/TabHistory'
import { getAllAssetGroup } from './service'
import { AssetGroups } from '../../configurable-fields'

// import { getFieldDetailAsset } from './service'

interface Params {

}

type Props = Params
const { TabPane } = Tabs
const AssetPreviewTab: React.FC<Props> = (props: Props) => {
    const { id, serialNo } = useParams<{ id: string, serialNo: string }>()
    const [assetOwnerLocation, setAssetOwnerLocation] = useState<Asset>()
    const [isLoading, setIsLoading] = useState(false)
    const [assetType, setAssetType] = useState<AssetGroups[]>([])
    const { GetAssetState } = useContext<any>(AssetStateContext)
    const { GetLocationBuilding } = useContext<any>(LocationBuildingContext)
    const [dataHR, setDataHR] = useState<People>()

    useEffect(() => {
        fetchData(id)
        loadFilter()
        getAllAssetGroup().then((res) => {
            if (res) {
                setAssetType(res)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = (id) => {
        setIsLoading(true)
        getAssetByID(id).then((res) => {
            setAssetOwnerLocation(res)
            setDataHR(res.people)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const loadFilter = async () => {
        getDataItems('assetState').then((res) => {
            if (GetAssetState) {
                GetAssetState(res)
            }
        })

        getDataLocationBuilding().then((res) => {
            if (GetLocationBuilding) {
                GetLocationBuilding(res)
            }
        })

        // getAllPeople().then((res) => {
        //     if (GetPeople) {
        //         GetPeople(res)
        //     }
        // })
    }

    const tagUpdate = (e: boolean) => {
        if (e) {
            fetchData(id)
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/assetList'}>Asset List</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{serialNo}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Spin spinning={isLoading}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Asset Details" key="1">
                        <TabAssetDetails assetData={assetOwnerLocation} tagUpdate={tagUpdate} assetType={assetType} />
                    </TabPane>
                    {/* <TabPane tab="Location Details" key="2">
                    <TabLocationDetailForm assetData={assetOwnerLocation} tagUpdate={tagUpdate} assetType={assetType} />
                </TabPane>
                <TabPane tab="Owner Details" key="3">
                    <TabUserDetailsForm assetData={assetOwnerLocation} dataHr={dataHR} tagUpdate={tagUpdate} assetType={assetType} />
                </TabPane>
                {assetOwnerLocation?.assetGroupType?.assetGroup.name === 'Peripheral'
                    ? <TabPane tab='SET Peripheral Details' key="4">
                        <TabLoanDetailsForm assetData={assetOwnerLocation} assetType={assetType} />
                    </TabPane>
                    : undefined
                }
                <TabPane tab="Date Details" key="5">
                    <TabDateDetailForm assetData={assetOwnerLocation} assetType={assetType} />
                </TabPane> */}
                    <TabPane tab="History and Related" key="6">
                        <TabHistory assetData={assetOwnerLocation} serialNo={serialNo} />
                    </TabPane>
                </Tabs>
            </Spin>
        </div>
    )
}

export default AssetPreviewTab
