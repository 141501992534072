import { CustomFiledProblem } from './model'
import {
    CustomFiledProblemStateEvent,
    GetAllCustomFiledProblemSuccessEvent
} from './state-event'

export const CustomFiledProblemStateReducer = (state: CustomFiledProblem[] = [], event: CustomFiledProblemStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllCustomFiledProblemSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
