
import { buildStateEvent, GenericStateEvent } from '../../../common-redux'
import { CustomFiledProblem } from './model'

export interface CustomFiledProblemStateEvent<T> extends GenericStateEvent<T> { }

export const GetAllCustomFiledProblemSuccessEvent = {
    type: 'GetAllCustomFiledProblemSuccessEvent',
    build: (payload: CustomFiledProblem[]) => buildStateEvent(GetAllCustomFiledProblemSuccessEvent.type, payload)
}

export const AddCustomFiledProblemSuccessEvent = {
    type: 'AddCustomFiledProblemSuccessEvent',
    build: (payload: CustomFiledProblem) => buildStateEvent(AddCustomFiledProblemSuccessEvent.type, payload)
}

export const DeleteCustomFiledProblemSuccessEvent = {
    type: 'DeleteCustomFiledProblemSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteCustomFiledProblemSuccessEvent.type, payload)
}

export const UpdateCustomFiledProblemSuccessEvent = {
    type: 'UpdateCustomFiledProblemSuccessEvent',
    build: (payload: CustomFiledProblem) => buildStateEvent(UpdateCustomFiledProblemSuccessEvent.type, payload)
}
