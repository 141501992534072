import { Comment } from './model'
import { CommentAddedEvent, CommentStateEvent, GetCommentListByIncidentIdSuccessEvent } from './state-event'

export const commentStateReducer = (state: Comment[] = [], event: CommentStateEvent<any> = { type: undefined, payload: undefined}): Comment[] => {
    if (event.type === GetCommentListByIncidentIdSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === CommentAddedEvent.type) {
        if (state.find((it) => it.id === event.payload.id)) {
            return state
        } else {
            return [event.payload, ...state]
        }
    } else {
        return state
    }
}
