import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const WorkflowRelatedSelectContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const WorkflowRelatedSelectReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_WorkflowRelatedSelect':
        return action.payload
    case 'ADD_WorkflowRelatedSelect':
        return [...state, action.payload]
    case 'UpdateWorkflowRelatedSelect':
        return state.filter((it) => it.id !== action.payload)
    case 'DELETE_WorkflowRelatedSelect':
        const filterWorkflowRelatedSelect = state.filter((it) => it.id !== action.payload)
        return filterWorkflowRelatedSelect
    default:
        return state
    }
}

export const WorkflowRelatedSelectProvider = (props: props) => {
    const [WorkflowRelatedSelectState, WorkflowRelatedSelectDispatch] = useReducer(WorkflowRelatedSelectReducer, initialState)

    const GetWorkflowRelatedSelect = (payload) => {
        WorkflowRelatedSelectDispatch({ type: 'GET_WorkflowRelatedSelect', payload })
    }

    const AddWorkflowRelatedSelect = (payload) => {
        WorkflowRelatedSelectDispatch({ type: 'ADD_WorkflowRelatedSelect', payload })
    }

    const UpdateWorkflowRelatedSelect = (payload) => {
        WorkflowRelatedSelectDispatch({ type: 'UpdateWorkflowRelatedSelect', payload })
    }

    const DeleteWorkflowRelatedSelect = (payload) => {
        WorkflowRelatedSelectDispatch({ type: 'DELETE_WorkflowRelatedSelect', payload })
    }
    return (
        <WorkflowRelatedSelectContext.Provider value={{ WorkflowRelatedSelectState, GetWorkflowRelatedSelect, AddWorkflowRelatedSelect, UpdateWorkflowRelatedSelect, DeleteWorkflowRelatedSelect }}>
            {props.children}
        </WorkflowRelatedSelectContext.Provider>
    )
}
