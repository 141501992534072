import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const CommentsPicturesContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const CommentsPicturesReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_CommentsPictures':
        sessionStorage.setItem('commentPicture', JSON.stringify(action.payload))
        return action.payload
    case 'ADD_CommentsPictures':
        return [...state, action.payload]
    default:
        return state
    }
}

export const CommentsPicturesProvider = (props: props) => {
    const [CommentsPicturesState, CommentsPicturesDispatch] = useReducer(CommentsPicturesReducer, initialState)

    const GetCommentsPictures = (payload) => {
        CommentsPicturesDispatch({ type: 'GET_CommentsPictures', payload })
    }

    const AddCommentsPictures = (payload) => {
        CommentsPicturesDispatch({ type: 'ADD_CommentsPictures', payload })
    }

    return (
        <CommentsPicturesContext.Provider value={{ CommentsPicturesState, GetCommentsPictures, AddCommentsPictures }}>
            {props.children}
        </CommentsPicturesContext.Provider>
    )
}
