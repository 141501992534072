import { WorklogStateEvent, GetAllWorklogByIncidentIdSuccessEvent } from './state-event'
import { Worklog } from './model'

export const worklogStateReducer = (state: Worklog[] = [], event: WorklogStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllWorklogByIncidentIdSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
