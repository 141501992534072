import React, {useEffect, useState} from 'react'
import {
    Row, Col, Form, Breadcrumb, Card, Empty, message, Spin, Select
} from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Link } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import TableSelectLocationForPrint from './TableSelectLocationForPrint'
import {getAssetSignedForPrint, getSearchAllFieldValue, searchFetch} from '../asset-management/service'

const { Option } = Select
type Props = FormComponentProps

const SelectLocationForPrint: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [getDataSearch, setGetDataSearch] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)
    const [locations, setLocations] = useState<string[]>()
    const [filterLocation, setFilterLocation] = useState<string[]>()

    useEffect(() => {
        getSearchAllFieldValue('location').then(res => {
            setLocations(res)
            setFilterLocation(res.slice(0, 20))
        }).catch(err => {
            message.error(`You have unSuccessfully get the data location. ${err}`)
        })
    }, [])

    useEffect(() => {
        if (window.innerWidth > 765) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth])

    const onSearch = value => {
        setIsLoading(true)
        if (value) {
            getAssetSignedForPrint(value).then((res) => {
                setIsLoading(false)
                if (res.length > 0) {
                    setGetDataSearch(res)
                } else {
                    setIsLoading(false)
                    setGetDataSearch(undefined)
                    message.info('Data Not Found')
                }
            }).catch((_err) => {
                setIsLoading(false)
                console.log(_err)
            })
        } else {
            setGetDataSearch(undefined)
            setIsLoading(false)
        }
    }

    const handleOnSearch = (location) => {
        searchFetch(location, locations || [], setFilterLocation)
    }

    const createOption = (dataSource: string[]) => {
        const options = dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
        return options
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/Preventive'} >Preventive Maintenance</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Select location for print</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card style={{ overflow: 'auto' }}>
                <div><h3 className='main-title'>Select location for print</h3></div>
                <Row>
                    <Col span={ isChangeSize ? 5 : 24} style={{ margin: '0 10px' }}>
                        <Form>
                            <Form.Item>
                                {getFieldDecorator('search',
                                    {
                                        initialValue: undefined
                                    })(
                                    <Select
                                        placeholder='Search location of asset'
                                        style={{ width: '100%' }}
                                        showSearch
                                        // optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children!!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        allowClear
                                        onSearch={(value) => { handleOnSearch(value) }}
                                        onChange={(value) => onSearch(value) }
                                    >{createOption(filterLocation || [])}
                                    </Select>
                                    // <Search placeholder="Search location of asset" allowClear onSearch={onSearch} style={{ width: '100%'}} />
                                )}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                {!getDataSearch ? <Empty style={{minHeight: 420}} image={Empty.PRESENTED_IMAGE_SIMPLE}/> : <Spin spinning={isLoading}>
                    <TableSelectLocationForPrint data={getDataSearch}/>
                </Spin>}
            </Card>
        </div>
    )
}

const SelectLocationForPrintWithFrom = Form.create<Props>({})(SelectLocationForPrint)

export default SelectLocationForPrintWithFrom
