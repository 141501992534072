import { SupportTeam } from '../incident/model'
import { GetAllSupportTeamWithPermissionSuccessEvent, SupportTeamStateEvent } from './state-event-with-permission'

export const supportTeamWithPermissionStateReducer = (state: SupportTeam[] = [], event: SupportTeamStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllSupportTeamWithPermissionSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
