import { Col, Row, Select } from 'antd'
import React from 'react'

interface Param {
    onChangedSort: (sortBy) => void
    onChangedOrder: (orderBy) => void
    sortBy?: string
    orderBy?: string
    isLoading?: Function
}

const { Option } = Select;
const SortAndOrderTicketFilter = (props: Param) => {


    const handleChangeSortby = (value) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChangedSort(value)
    }

    const handleChangeOrderby = (value) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChangedOrder(value)
    }

    return (
        <div>
            <Row>
                <span>Sort By</span>
            </Row>
            <Row gutter={12}>
                <Col span={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <br />
                    <Select value={props.sortBy} style={{ width: '100%' }} onChange={handleChangeSortby}>
                        <Option value="createdDatetime">Created date</Option>
                        <Option value="lastModifiedDatetime">Last modify date</Option>
                        <Option value="priority">Priority</Option>
                        <Option value="ticketStatus">Status</Option>
                        <Option value="number">Ticket number</Option>
                    </Select>
                </Col>
                <Col span={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <br />
                    <Select value={props.orderBy} style={{ width: '100%' }} onChange={handleChangeOrderby}>
                        <Option value="DESC">Descending</Option>
                        <Option value="ASC">Ascending</Option>
                    </Select>
                </Col>
            </Row>
            <br />
        </div>
    )
}

export default SortAndOrderTicketFilter