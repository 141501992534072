import React, {useEffect, useState} from 'react'
import {Table, Tag, Tooltip} from 'antd'
import {ColumnNormal, PMStatus} from './Model'
import PageService from './ContextAPI/PageService'
import ReactHtmlParser from 'react-html-parser'

interface Param {
    dataTransfer: PMStatus[]
    columnsPMStatusList: string[]
    columnsPMStatusObjList: ColumnNormal[]
    total: number
    range: any
    handleStatusChange: Function
}
type Props = Param

export const columns = [
    {
        title: 'PM Plan Date',
        dataIndex: 'pmPlanDate'
    },
    {
        title: 'PM ID',
        dataIndex: 'pmId'
    },
    {
        title: 'Created Date',
        dataIndex: 'pmPlanCreatedDate'
    },
    {
        title: 'Actual Date',
        dataIndex: 'actualPmDate'
    },
    {
        title: 'Completed Date',
        dataIndex: 'completedDate'
    },
    {
        title: 'PM Name',
        dataIndex: 'pmName'
    },
    {
        title: 'PM ID Status',
        dataIndex: 'pmIDStatus'
    },
    {
        title: 'Pending Reason',
        dataIndex: 'pendingReason'
    },
    {
        title: 'Group',
        dataIndex: 'group'
    },
    {
        title: 'Serial Number',
        dataIndex: 'serialNumber'
    },
    {
        title: 'Asset status',
        dataIndex: 'assetStatus'
    },
    {
        title: 'PM Status',
        dataIndex: 'pmStatus'
    },
    {
        title: 'New Appoint Datetime',
        dataIndex: 'newAppointDatetime'
    },
    {
        title: 'Asset Tag',
        dataIndex: 'assetTag'
    },
    {
        title: 'Asset Name',
        dataIndex: 'assetName'
    },
    {
        title: 'Manufacture',
        dataIndex: 'manufacturer'
    },
    {
        title: 'Model',
        dataIndex: 'model'
    },
    {
        title: 'Brand',
        dataIndex: 'brand'
    },
    {
        title: 'Type',
        dataIndex: 'type'
    },
    {
        title: 'Site',
        dataIndex: 'site'
    },
    {
        title: 'Location',
        dataIndex: 'location'
    },
    {
        title: 'Building',
        dataIndex: 'building'
    },
    {
        title: 'Floor',
        dataIndex: 'floor'
    },
    {
        title: 'Zone',
        dataIndex: 'zone'
    },
    {
        title: 'User ID',
        dataIndex: 'userID'
    },
    {
        title: ' User Name',
        dataIndex: 'username'
    },
    {
        title: 'Department',
        dataIndex: 'department'
    },
    // {
    //     title: 'Supervisor ID',
    //     dataIndex: 'supervisorId'
    // },
    // {
    //     title: 'Supervisor Name',
    //     dataIndex: 'supervisorName'
    // },
    // {
    //     title: 'Loan ID',
    //     dataIndex: 'loanID'
    // },
    // {
    //     title: 'SET',
    //     dataIndex: 'set'
    // },
    {
        title: 'Paired IT Asset',
        dataIndex: 'pairedItAsset'
    }
]

const ColumnsPMStatus: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        if (props.columnsPMStatusList && props.columnsPMStatusObjList) {
            const temp: ColumnNormal[] = []
            props.columnsPMStatusList.forEach((it) => {
                const findDataIndex = props.columnsPMStatusObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    if (findDataIndex.dataIndex === 'PMLocation') {
                        findDataIndex.render = (data) => {
                            const spitData = data?.split(',')
                            const tempPMlocation: string[] = []
                            if (spitData) {
                                // eslint-disable-next-line no-unused-expressions
                                spitData?.forEach((it, index) => {
                                    if (index > 2) {
                                        tempPMlocation.push('- ' + it)
                                    }
                                })
                            }

                            return (
                                <span>
                                    {spitData?.map((it, index) => {
                                        if (index <= 2) {
                                            return <Tag color='geekblue' key={index}>{it}</Tag>
                                        }
                                    })}
                                    {spitData?.length > 3 && <Tooltip placement='bottom' title={ReactHtmlParser(tempPMlocation.join('</br>'))}><Tag color='geekblue'> +{spitData?.length - 3}</Tag></Tooltip>}
                                </span>
                            )
                        }
                    }
                    temp.push(findDataIndex)
                }
            })
            setTempColumns(temp)
        }
    }, [props.columnsPMStatusList, props.columnsPMStatusObjList])

    useEffect(() => {
        console.log(pageSize)
        if (pageSize) {
            setPage(1)
            PageService.getInstance().data.pagePm = 1
            props.handleStatusChange()
        }
    }, [pageSize])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageService.getInstance().data.pagePm = 1
        }
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageService.getInstance().data.pagePm = page
        props.handleStatusChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageService.getInstance().data.pageSizePm = pagination.pageSize
    }

    return (
        <div>
            <Table dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns} scroll={{x: true}}
                pagination={ props.range && props.columnsPMStatusList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" rowKey={'serialNumber' + 'pmId'} />
        </div>
    )
}
export default ColumnsPMStatus
