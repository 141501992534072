import React, {createContext, PropsWithChildren, useReducer} from 'react'
import {AssetGroupType} from '../Model'

export const AssetGroupContext = createContext({})

const initialState: AssetGroupType[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const AssetGroupReducer = (state: AssetGroupType[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_AssetGroup':
        sessionStorage.setItem('assetGroup', JSON.stringify(action.payload))
        return action.payload || [] as AssetGroupType[]
    case 'ADD_AssetGroup':
        return [...state, action.payload]
    default:
        return state
    }
}

export const AssetGroupProvider = (props: props) => {
    const [AssetGroupState, AssetGroupDispatch] = useReducer(AssetGroupReducer, initialState)

    const GetAssetGroup = (payload: AssetGroupType[]) => {
        AssetGroupDispatch({ type: 'GET_AssetGroup', payload })
    }

    const AddAssetGroup = (payload: AssetGroupType) => {
        AssetGroupDispatch({ type: 'ADD_AssetGroup', payload })
    }

    return (
        <AssetGroupContext.Provider value={{ AssetGroupState, GetAssetGroup, AddAssetGroup }}>
            {props.children}
        </AssetGroupContext.Provider>
    )
}
