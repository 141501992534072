import React, { createContext, useReducer, PropsWithChildren } from 'react'
import { PMStatus } from '../Model'

export const PMStatusContext = createContext({})

const initialState: PMStatus[] = []

interface Param{}

type props = PropsWithChildren<Param>
const PMStatusReducer = (state: PMStatus[], action: {type: string; payload: any}) => {
    switch (action.type) {
    case 'GET_PMStatus':
        return action.payload || [] as PMStatus[]
    default:
        return state
    }
}

export const PMStatusProvider = (props: props) => {
    const [PMStatusState, PMStatusDispatch] = useReducer(PMStatusReducer, initialState)
    const GetPMStatus = (payload: PMStatus[]) => {
        PMStatusDispatch({type: 'GET_PMStatus', payload})
    }
    return (
        <PMStatusContext.Provider value = {{ PMStatusState, GetPMStatus}} >
            {props.children}
        </PMStatusContext.Provider>
    )
}
