import { Spin } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import React, { useEffect, useRef, useState } from 'react'
import ReactFlow, {
    ReactFlowProvider,
    Edge,
    Node,
    useNodesState,
    useEdgesState,
    Background
} from 'react-flow-renderer'
import { WorkFlow } from '../Service/Model'
import JobNodes from './FlowComponent/JobNode'
import WorkOrderNodes from './FlowComponent/WorkOrderNode'
import Approvals from './FlowComponent/Approval'
import {getWorkFlowByIdForWOPreview } from '../Service/service'

interface Param {
    visibleModal?: boolean
    idWorkflow?: number
    createDateWorkOrder: string
    closeModal: Function
    getDatafromPreview: Function
}

type Props = Param & FormComponentProps
const nodeTypes = {
    jobNode: JobNodes,
    workOrderNode: WorkOrderNodes,
    approval: Approvals
}
const PreviewWorkflowWo: React.FC<Props> = (props: Props) => {
    const [workflowData, setWorkflowData] = useState<WorkFlow>()
    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])
    const [loading, setLoading] = useState<boolean>(false)

    const reactFlowWrapper = useRef<any>(null)
    const reactFlowInstance = useRef<any>(null)
    
    // useEffect(() => {
    //     setTimeout(() => {
    //         localStorage.clear()
    //     }, 1000)
    // }, [])

    useEffect(() => {
        if (props.idWorkflow) {
            setLoading(true)
            getWorkFlowByIdForWOPreview(props.idWorkflow, props.createDateWorkOrder).then((res: any) => {
                if (res) {
                    setWorkflowData(res)
                    const flow = JSON.parse(res.renderingAttributes || [])
                    if (flow) {
                        flow.nodes.map((it: Node) => {
                            if (JSON.stringify(it.data) !== '{}') {
                                it.data.form = props.form
                            } else {
                                it.data.form = props.form
                            }
                        })
                        setNodes((flow.nodes as Node[]) || [])
                        setEdges((flow.edges as Edge[]) || [])
                    }
                }
            }).finally(() => {
                setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idWorkflow])

    useEffect(() => {
        if (workflowData) {
            props.getDatafromPreview(workflowData)
        }
    },[workflowData])

    useEffect(() => {
        if (props.visibleModal && reactFlowInstance.current) {
            setTimeout(() => {
                reactFlowInstance.current.fitView()
            }, 0)
        }
    }, [props.visibleModal])

    return (
        <Spin spinning={loading}>
            <div className="dndflow" style={{ height: 570, width: '100%' }}>
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={nodeTypes}
                            fitView
                            onInit={(instance) => {
                                reactFlowInstance.current = instance
                            }}
                        >
                            <Background color="#99b3ec" />
                        </ReactFlow>
                    </div>
                </ReactFlowProvider>
            </div>
        </Spin>
    )
}

export default PreviewWorkflowWo