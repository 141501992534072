import { Button, Form, Input, message, Row } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useEffect, useState } from 'react'
import { Problem } from '../Model'
import KnowledgeFormUpload from '../../../knowledge-management/knowledge/uploads/KnowledgeFormUpload'
import { updateProblem, updatePTaskById } from '../service'
import FSS from '../../../file-server-storage'
import { UploadLink } from '../../../file-server-storage/model'

interface params {
    id?: string
    problem?: Problem
    setClosed?: Function
    reloadData?: Function
    isRenderPTask?: boolean
}
type Props = params & FormComponentProps
const ModalEditTitle: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator} = props.form
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.problem) {
            const uploadFile = props.problem.pbUpload?.map((file) => {
                const uploadFile: UploadFile = {
                    uid: file.name,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                    type: file.type,
                    size: 0
                }
                return uploadFile
            })
            setFileList(uploadFile || [])
        }
    }, [props.problem])

    const handleSubmit = (e) => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (!err) {
                if (props.id && props.problem) {
                    setIsLoading(true)
                    const convertData:any = {
                        // ...props.problem,
                        topic: values.Label,
                        description: values.Description
                    }
                    FSS.putFile(fileList, '/problem/', (uploadLink: UploadLink[]) => {
                        convertData.pbUpload = uploadLink?.map((data) => {
                            return {
                                name: data.name,
                                url: data.url,
                                type: data.type
                            } as UploadLink
                        })
                    })
                    if (props.isRenderPTask) {
                        updatePTaskById(props.id!!, convertData).then(() => {
                            message.success('The update has finished successfully.')
                            if (props.setClosed && props.reloadData) {
                                props.setClosed(false)
                                props.reloadData(true)
                            }
                        }).finally(() => { setIsLoading(false) })
                    } else {
                        updateProblem(props.id!!, convertData).then(() => {
                            message.success('The update has finished successfully.')
                            if (props.setClosed && props.reloadData) {
                                props.setClosed(false)
                                props.reloadData(true)
                            }
                        }).finally(() => { setIsLoading(false) })
                    }
                }
            }
        })
    }

    const closeModal = () => {
        if (props.setClosed) {
            props.setClosed(false)
        }
    }

    return (
        <div>
            <Form>
                <Form.Item label={'Topic'} className='custom-formLabel-wo'>
                    {getFieldDecorator('Label', {
                        initialValue: props.problem?.topic,
                        rules: [{required: true, message: 'Please input Label'}]
                    })(
                        <Input placeholder='label'/>
                    )}
                </Form.Item>
                <Form.Item label={'Description'} className='custom-formLabel-wo'>
                    {getFieldDecorator('Description', {
                        initialValue: props.problem?.description,
                        rules: [{required: true, message: 'Please input Description'}]
                    })(
                        <Input.TextArea rows={4} placeholder='Description'/>
                    )}
                </Form.Item>
                <Row>
                    <KnowledgeFormUpload fileList={fileList} setFileList={(fileList: UploadFile[]) => { setFileList(fileList) }} />
                </Row>

                <br />
                <hr />
                <Form.Item style={{ textAlign: 'end' }}>
                    <Button htmlType="button" style={{ marginRight: 10 }} onClick={() => closeModal()}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={handleSubmit} loading={isLoading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

const ModalEditTitleForm = Form.create<Props>()(ModalEditTitle)
export default ModalEditTitleForm
