import React from 'react'
import { Layout, Tooltip } from 'antd'
import ReactHtmlParser from 'react-html-parser'
import packageJson from '../../package.json'
const { Footer } = Layout

const DefaultFooter: React.FC = () => {
    const [fixVersion, description] = packageJson.description.split('|')
    return (
        <Footer id='Footer-Login'>
            <div style={{ textAlign: 'center' }}>G-Able Company Limited <Tooltip placement="top" title={ReactHtmlParser(description)}><span style={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>{packageJson.version}.{fixVersion}</span></Tooltip></div>
        </Footer>
    )
}

export default DefaultFooter
